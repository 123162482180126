/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable arrow-body-style */
/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
/* eslint-disable prettier/prettier */
import React, { useEffect, useContext, useState } from "react";
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import "./UpdateMeeting.scss";
import { FaTrash } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, useParams } from "react-router-dom";
import { AuthContext } from "../../../../Context/AuthContext";
import {
  updateTeamMeeting,
  sendMail,
  // AllEmployee,
  getMeetingData,
} from "../../../../api/teamMeeting";
import { getAllEmployeeMergedData } from "../../../../api/employee";
import MeetingModal from "../MeetingModal";
// import {
//   allWorkStream,
//   getAssigendStreamsByEmail,
// } from "../../../../api/workStream";

export default function UpdateMeeting() {
  const { oid, logged } = useContext(AuthContext);
  const [selectedUser, setSelectedUser] = useState([]);
  const [meetingName, setMeetingName] = useState("");
  const [meetingDate, setMeetingDate] = useState("");
  const [meetingTime, setMeetingTime] = useState("");
  const [workstream, setWorkStream] = useState("");
  const [meetingType, setMeetingType] = useState("");
  const [description, setDescription] = useState("");
  const [meetingLink, setMeetingLink] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(true);
  const [updateTrack, setUpdateTrack] = useState(false);
  const [id, setId] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [currentUserWorkStreams, setCurrentUserWorkStreams] = useState([]);
  const params = useParams();
  const history = useHistory();
  const [singleMeetingData, setSingleMeetingData] = useState([]);

  const [combineEmailName, setCombineEmailName] = useState([]);

  const [show, setShow] = useState(false);
  let result = [];
  const handleClose = () => {
    setShow(false);
  };

  const handleConfirm = () => {
    setSelectedUser(result);
    setUpdateTrack(true);
    setShow(false);
  };

  const handleShow = () => setShow(true);

  // select user function
  const handleAddNewUser = (value) => {
    result = value;
  };

  // remove user function
  const removeUser = (id) => {
    const res = selectedUser.filter((data, index) => index !== id);
    setSelectedUser(res);
    setUpdateTrack(true);
  };

  // update Meeting
  const updateMeeting = (e) => {
    e.preventDefault();
    // update data
    const updateData = {
      meetingName,
      meetingDate,
      meetingTime,
      workstream,
      description,
      meetingLocation: meetingLink,
      meetingTimezone: timeZone,
      addGuest: selectedUser.map((su) => su),
      meetingType,
    };
    console.log(updateData);
    if (id && createdAt) {
      const response = updateTeamMeeting(id, createdAt, updateData);
      if (response) {
        toast("Update Meeting Successfully");
      }
    }
    setTimeout(() => {
      history.push("/meetings");
    }, 1800);
  };

  // sent email to newly added user
  const SentMailToUser = () => {
    if (
      meetingName &&
      meetingDate &&
      meetingTime &&
      selectedUser &&
      workstream &&
      description &&
      meetingLink &&
      timeZone &&
      checked === true
    ) {
      selectedUser.map(async (item) => {
        const insertData = {
          meetingName,
          meetingDate,
          meetingTime,
          to: item,
          from: "testingsidsj@gmail.com",
          workstream,
          description,
          meetingLink,
          timeZone,
        };
        sendMail(insertData);
      });
    }
  };

  // cancel button
  const cancelButton = () => {
    history.push("/meetings");
  };

  useEffect(async () => {
    // get all meeting related information
    if (oid) {
      const getEmployeeNameEmail = async () => {
        const EmployeeData = await getAllEmployeeMergedData(oid);

        const formattedUserEmail = EmployeeData?.map((item) =>
          item.emailAddress ? item.emailAddress : item.adminEmail
        ).filter((email) => email !== logged);

        const formattedName =
          EmployeeData?.length > 0 &&
          EmployeeData?.filter((item) =>
            item.emailAddress
              ? item.emailAddress !== logged
              : item.adminEmail !== logged
          )?.map((i) =>
            i.displayName
              ? i.displayName
              : i.adminFirstName.concat(` ${i.adminLastName}`)
          );

        const profilePicture =
          EmployeeData?.length > 0 &&
          EmployeeData?.filter((item) =>
            item.emailAddress
              ? item.emailAddress !== logged
              : item.adminEmail !== logged
          )?.map((i) =>
            i.ProfilePicture ? i.ProfilePicture : i.profilePicture
          );

        setCombineEmailName(
          formattedName?.map((value, index) => ({
            name: value,
            email: formattedUserEmail[index],
            profile: profilePicture[index],
          }))
        );
      };
      getEmployeeNameEmail();
    }

    // const allWorkStreams = await allWorkStream(oid);
    // const currentUserWorkStreamsValue = await getAssigendStreamsByEmail(logged);
    // console.log(currentUserWorkStreamsValue);

    // if (currentUserWorkStreamsValue?.length > 0) {
    //   const filteredArray = allWorkStreams?.filter((item1) => {
    //     return currentUserWorkStreamsValue.some(
    //       (item2) => item2.workStreamId === item1.id
    //     );
    //   });
    //   setCurrentUserWorkStreams(filteredArray);
    // } else {
    //   setCurrentUserWorkStreams([]);
    // }

    if (oid && params.id) {
      const res = await getMeetingData(oid, params.id);
      const Emails = await getAllEmployeeMergedData(oid);
      if (res && Emails) {
        setLoading(false);
      }
      const formattedUserEmail = Emails?.map((item) =>
        item.emailAddress ? item.emailAddress : item.adminEmail
      ).filter((email) => email !== logged);
      const formattedName =
        Emails?.length > 0 &&
        Emails?.filter((i) =>
          i.emailAddress ? i.emailAddress !== logged : i.adminEmail !== logged
        )?.map((i) =>
          i.displayName
            ? i.displayName
            : i.adminFirstName.concat(` ${i.adminLastName}`)
        );

      const profilePicture =
        Emails?.length > 0 &&
        Emails?.filter((item) => item.emailAddress !== logged)?.map((i) =>
          i.ProfilePicture ? i.ProfilePicture : i.profilePicture
        );

      const combineEmailName = formattedName?.map((value, index) => ({
        name: value,
        email: formattedUserEmail[index],
        profile: profilePicture[index],
      }));
      const filteredArray = combineEmailName.filter((item) =>
        res[0]?.addGuest.includes(item.value)
      );
      setSingleMeetingData(res);
      setSelectedUser(res[0]?.addGuest);
      setCreatedAt(res[0]?.createdAt);
      setId(res[0]?.id);
      setMeetingName(res[0]?.meetingName);
      setMeetingDate(res[0]?.meetingDate);
      setMeetingTime(res[0]?.meetingTime);
      setWorkStream(res[0]?.workstream);
      setDescription(res[0]?.description);
      setMeetingLink(res[0]?.meetingLocation);
      setTimeZone(res[0]?.meetingTimezone);
      setMeetingType(res[0]?.meetingType);
    }
  }, [oid, params.id]);

  return (
    <>
      <Container fluid>
        {loading ? (
          <div className="d-flex justify-content-center">
            <Spinner className="rt-spinner" animation="grow" />
          </div>
        ) : !singleMeetingData ? (
          <div className="d-flex justify-content-center">
            <Spinner className="rt-spinner" animation="grow" />
          </div>
        ) : (
          singleMeetingData.map((item, index) => (
            <div className="bg-white rounded p-4 update-meeting" key={index}>
              <h2 className="py-3 title">Update Meetings</h2>
              <Row>
                <Col lg={12}>
                  <Form onSubmit={updateMeeting}>
                    <Row>
                      <Col lg={6}>
                        <Form.Group className="mb-4">
                          <Form.Control
                            type="text"
                            placeholder="Name your meeting"
                            defaultValue={item.meetingName}
                            onChange={(e) => {
                              setMeetingName(e.target.value);
                              setUpdateTrack(true);
                            }}
                            required
                          />
                        </Form.Group>
                        <Row>
                          <ToastContainer />
                          <Col lg={6}>
                            <Form.Group className="mb-4">
                              <Form.Control
                                type="date"
                                placeholder="Select Date"
                                min={item.meetingDate}
                                defaultValue={item.meetingDate}
                                onChange={(e) => {
                                  setMeetingDate(e.target.value);
                                  setUpdateTrack(true);
                                }}
                                required
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group className="mb-4">
                              <Form.Control
                                type="time"
                                placeholder="Select Time"
                                defaultValue={item.meetingTime}
                                onChange={(e) => {
                                  setMeetingTime(e.target.value);
                                  setUpdateTrack(true);
                                }}
                                required
                              />
                            </Form.Group>
                          </Col>
                        </Row>

                        <Form.Group className="mb-4">
                          <Form.Select
                            as="select"
                            onChange={(e) => {
                              setWorkStream(e.target.value);
                              setUpdateTrack(true);
                            }}
                            defaultValue={item.workstream}
                            required
                          >
                            <option selected disabled value="">
                              Select your workstream
                            </option>
                            <option value="Personal">Personal</option>
                            {currentUserWorkStreams?.map((item) => (
                              <option
                                key={item.workStreamId}
                                value={item.workStreamName}
                              >
                                {item.workStreamName}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-4">
                          <Form.Control
                            as="textarea"
                            placeholder="Add description"
                            style={{ height: "100px" }}
                            defaultValue={item.description}
                            onChange={(e) => {
                              setDescription(e.target.value);
                              setUpdateTrack(true);
                            }}
                            required
                          />
                        </Form.Group>

                        <Form.Group className="mb-4" controlId="formBasicEmail">
                          <Form.Control
                            type="text"
                            placeholder="Meeting Link"
                            defaultValue={item.meetingLocation}
                            onChange={(e) => {
                              setMeetingLink(e.target.value);
                              setUpdateTrack(true);
                            }}
                            required
                          />
                        </Form.Group>

                        <Form.Group className="mb-4">
                          <Form.Select
                            as="select"
                            defaultValue={item.meetingTimezone}
                            required
                          >
                            <option value={item.meetingTimezone}>
                              {item.meetingTimezone}
                            </option>
                          </Form.Select>
                        </Form.Group>
                      </Col>

                      <Col lg={6}>
                        <Form.Group className="mb-4">
                          <Form.Select
                            as="select"
                            onChange={(e) => {
                              setMeetingType(e.target.value);
                              setUpdateTrack(true);
                            }}
                            defaultValue={item.meetingType}
                            required
                          >
                            <option selected disabled value="">
                              Select Meeting Type
                            </option>
                            <option value="Does Not Repeat">
                              Does Not Repeat
                            </option>
                            <option value="Daily">Daily</option>
                            <option value="Weekly">Weekly</option>
                            <option value="Every Two Weeks">
                              Every Two Weeks
                            </option>
                            <option value="Monthly">Monthly</option>
                          </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-2">
                          <button
                            type="button"
                            data-toggle="modal"
                            data-target="#exampleModalLong"
                            onClick={handleShow}
                            className="form-control create-meeting attendees-select"
                          >
                            Select Attendees
                          </button>
                          <MeetingModal
                            show={show}
                            handleClose={handleClose}
                            selectedUser={selectedUser}
                            options={combineEmailName}
                            handleAddNewUserImage={handleAddNewUser}
                            handleConfirm={handleConfirm}
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3 pl-4 custom-margin"
                          controlId="attendees"
                        >
                          {selectedUser.length > 0 &&
                            selectedUser?.map((item, index) => (
                              <div className="guest-list" key={index}>
                                <div className="g-list-item">
                                  <div className="g-name">
                                    <img
                                      src={
                                        item.profile !== ""
                                          ? item.profile
                                          : "https://i.ibb.co/vVfrXgh/Group-302.png"
                                      }
                                      alt="Profile"
                                    />
                                    <p>{item.name}</p>
                                  </div>
                                  <FaTrash
                                    className="mx-3 rt-denger"
                                    onClick={() => removeUser(index)}
                                  />
                                </div>
                              </div>
                            ))}
                        </Form.Group>
                        <Form.Check
                          className="attendees-checkbox"
                          checked={checked}
                          onChange={(e) => setChecked(e.target.checked)}
                        />
                        <label className="attendees-label">
                          Ask attendees to contribute to the agenda
                        </label>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12} className="text-center mt-5">
                        <Button
                          size="lg"
                          variant="outline-primary mr-4"
                          onClick={cancelButton}
                        >
                          Cancel
                        </Button>
                        <Button
                          size="lg"
                          variant="primary"
                          type="submit"
                          onClick={() => {
                            SentMailToUser();
                          }}
                          disabled={!updateTrack}
                        >
                          Update
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </div>
          ))
        )}
      </Container>
    </>
  );
}
