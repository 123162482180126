/* eslint-disable prefer-template */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/self-closing-comp */
/* eslint-disable prettier/prettier */

import { useEffect, useContext, useState } from "react";
import { Container, Form, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../Context/AuthContext";
import ClosedMeetingAgenda from "./ClosedMeetingAgenda/ClosedMeetingAgenda";
import "./ClosedMeetingDetails.scss";
import { closeTeamMeeting } from "../../../api/closeMeeting";
function ClosedMeetingDetails() {
  const params = useParams();
  const { oid } = useContext(AuthContext);
  const [selectedUserPicture, setSelectedUserPicture] = useState([]);
  const [meetingName, setMeetingName] = useState("");
  const [meetingDate, setMeetingDate] = useState([]);
  const [description, setDescription] = useState("");

  useEffect(async () => {
    if (oid && params.id) {
      // Closed Team Meeting meeting get api
      const res = await closeTeamMeeting(oid, params.id);
      setSelectedUserPicture(res[0]?.addGuest);
      setMeetingName(res[0]?.meetingName);
      setMeetingDate([res[0]?.meetingDate]);
      setDescription(res[0]?.description);
    }
  }, [oid, params.id]);

  return (
    <Container fluid>
      {meetingName ? (
        <div className="bg-white rounded p-3 metting-detail">
          <div className="d-flex justify-content-between">
            <div className="metting-header">
              <h2 className="pt-3 title">{meetingName}</h2>
              <p>{description}</p>
              <div className="attendies mb-5">
                {selectedUserPicture &&
                  selectedUserPicture?.map((item) => (
                    <img className="meeting-img" src={item.profile} alt="" />
                  ))}
              </div>
            </div>
            <div>
              <div className="date-select">
                <Form>
                  <Form.Group>
                    {meetingDate?.map((item, index) => (
                      <Form.Control as="select">
                        <option value={item} key={index}>
                          {item.slice(5, 7) +
                            "-" +
                            item.slice(8, 10) +
                            "-" +
                            item.slice(0, 4)}
                        </option>
                      </Form.Control>
                    ))}
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>
          <ClosedMeetingAgenda paramsId={params.id}></ClosedMeetingAgenda>
        </div>
      ) : (
        <div className="d-flex justify-content-center">
          <Spinner className="rt-spinner" animation="grow" />
        </div>
      )}
    </Container>
  );
}

export default ClosedMeetingDetails;
