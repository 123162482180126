/* eslint-disable import/named */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-alert */
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import { singleAdminInfo, updateAdminData } from '../../api/superAdmin';

const EditAdminInfo = () => {
    const history = useHistory();
    const { id } = useParams();
    const [adminFirstName, setAdminFirstName] = useState('');
    const [adminLastName, setAdminLastName] = useState('');
    const [adminEmail, setAdminEmail] = useState('');
    const [gender, setGender] = useState('');
    const [dob, setDob] = useState('');
    const [personalPhoneNumber, setPersonalPhoneNumber] = useState('');
    const [alternatePhoneNumber, setAlternatePhoneNumber] = useState('');
    const [personalNumWarning, setPersonalNumWarning] = useState(false);
    const [alternateNumWarning, setAlternateNumWarning] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const handlePersonalNumCheck = (num) => {
        if (num.length < 10) {
            setPersonalNumWarning(true);
        } else {
            setPersonalNumWarning(false);
        }
    };

    const handleAlternateNumCheck = (num) => {
        if (num.length < 10) {
            setAlternateNumWarning(true);
        } else {
            setAlternateNumWarning(false);
        }
    };

    useEffect(() => {
        const retrieveAdminInfo = async () => {
            const response = await singleAdminInfo(id);
            setAdminFirstName(response?.data.adminFirstName);
            setAdminLastName(response?.data.adminLastName);
            setAdminEmail(response?.data.adminEmail);
            setGender(response?.data.gender);
            setDob(response?.data.DOB);
            setPersonalPhoneNumber(response?.data.personalPhoneNumber);
            setAlternatePhoneNumber(response?.data.AlternativePhoneNumber);
        };
        retrieveAdminInfo();
    }, [id]);

    const handleEditAdminInfo = async () => {
        try {
            const updateInfo = {
                adminFirstName,
                adminLastName,
                adminEmail,
                gender,
                DOB: dob,
                personalPhoneNumber,
                AlternativePhoneNumber: alternatePhoneNumber,
            };
            const updateData = await updateAdminData(id, updateInfo);

            if (updateData) {
                toast.success('Admin Profile Updated Successfully!!');
            }

            setTimeout(() => {
                history.push('/admin/list');
            }, 1500);
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-10">
                    <div className="d-flex justify-content-between">
                        <h1 className="pl-4 text-dark pb-3">Edit Admin Profile</h1>

                        <Button variant="dark" onClick={() => history.push('/admin/list')}>
                            Back
                        </Button>
                    </div>
                    <div className="admin-list-table">
                        <form onSubmit={handleSubmit(handleEditAdminInfo)} className="pl-4">
                            <div className="form-row">
                                <div className="form-group col-md-4">
                                    <h6>Admin First Name</h6>
                                    <input
                                        type="text"
                                        className="form-control"
                                        {...register('firstName', {
                                            value: { adminFirstName },
                                            required: {
                                                value: true,
                                                message: 'This field is required!',
                                            },
                                        })}
                                        aria-describedby="textHelp"
                                        placeholder="Admin First Name"
                                        value={adminFirstName}
                                        onChange={(e) => setAdminFirstName(e.target.value)}
                                    />
                                    {errors.firstName?.type === 'required' && (
                                        <span className="input-warning-style">
                                            {errors.firstName.message}
                                        </span>
                                    )}
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group col-md-4">
                                    <h6>Admin Last Name</h6>
                                    <input
                                        type="text"
                                        className="form-control"
                                        {...register('lastName', {
                                            value: { adminLastName },
                                            required: {
                                                value: true,
                                                message: 'This field is required!',
                                            },
                                        })}
                                        value={adminLastName}
                                        placeholder="Admin Last Name"
                                        onChange={(e) => setAdminLastName(e.target.value)}
                                    />
                                    {errors.lastName?.type === 'required' && (
                                        <span className="input-warning-style">
                                            {errors.lastName.message}
                                        </span>
                                    )}
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group col-md-4 one">
                                    <h6>Gender</h6>
                                    <select
                                        {...register('gender', {
                                            value: { gender },
                                            required: {
                                                value: true,
                                                message: 'This field is required!',
                                            },
                                        })}
                                        className="form-control"
                                        value={gender}
                                        onChange={(e) => setGender(e.target.value)}
                                    >
                                        <option selected value="">
                                            Select
                                        </option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Others">Others</option>
                                    </select>
                                    {errors.gender?.type === 'required' && (
                                        <span className="input-warning-style">
                                            {errors.gender.message}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-4 one">
                                    <h6>Date of Birth</h6>
                                    <input
                                        type="date"
                                        className="form-control"
                                        placeholder="DOb"
                                        {...register('date', {
                                            value: { dob },
                                            required: {
                                                value: true,
                                                message: 'This field is required!',
                                            },
                                        })}
                                        value={dob}
                                        onChange={(e) => setDob(e.target.value)}
                                    />
                                    {errors.date?.type === 'required' && (
                                        <span className="input-warning-style">
                                            {errors.date.message}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-4 one">
                                    <h6>Personal Phone Number</h6>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Personal Phone Number"
                                        {...register('phone', {
                                            value: { personalPhoneNumber },
                                            required: {
                                                value: true,
                                                message: 'This field is required!',
                                            },
                                        })}
                                        value={personalPhoneNumber}
                                        onChange={(e) => {
                                            setPersonalPhoneNumber(e.target.value);
                                            handlePersonalNumCheck(e.target.value);
                                        }}
                                    />
                                    {errors.phone?.type === 'required' && (
                                        <span className="input-warning-style">
                                            {errors.phone.message}
                                        </span>
                                    )}
                                    {personalNumWarning && (
                                        <span className="input-warning-style">
                                            Minimum length should be 10.
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-4 one">
                                    <h6>Alternative Phone Number</h6>
                                    <input
                                        type="text"
                                        className="form-control"
                                        {...register('alternatePhone', {})}
                                        placeholder="Alternate Number"
                                        value={alternatePhoneNumber}
                                        onChange={(e) => {
                                            setAlternatePhoneNumber(e.target.value);
                                            handleAlternateNumCheck(e.target.value);
                                        }}
                                    />
                                    {alternateNumWarning && (
                                        <span className="input-warning-style">
                                            Minimum length should be 10.
                                        </span>
                                    )}
                                </div>
                            </div>

                            <button type="submit" className="btn btn-primary mb-5">
                                Submit
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default EditAdminInfo;
