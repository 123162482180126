/* eslint-disable no-useless-escape */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable no-alert */
/* eslint-disable-next-line no-useless-escape */
import { React, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import {
    disableUsers,
    enableUsers,
    getEmployeeData,
    getSingleOrganization,
    updateOrgData,
} from '../../api/superAdmin';
import { updateOrganizationUrl } from '../../api/superAdminApiUrlConstants';

const EditOrganization = () => {
    const [companyName, setCompanyName] = useState('');
    const [billingAddress, setBillingAddress] = useState('');
    const [OfficePhoneNumber, setOfficePhoneNumber] = useState('');
    const [companySize, setCompanySize] = useState('');
    const [website, setWebsite] = useState('');
    const [isActive, setIsActive] = useState();
    const [emp, setEmpInfo] = useState([]);
    const [loading, setLoading] = useState(false);
    const [phoneWarning, setPhoneWarning] = useState(false);
    const [websiteWarning, setWebsiteWarning] = useState(false);
    const { id } = useParams();
    const history = useHistory();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    useEffect(() => {
        const getSingleData = async () => {
            const data = await getSingleOrganization(id);
            setCompanyName(data?.companyName);
            setBillingAddress(data?.billingAddress);
            setCompanySize(data?.companySize);
            setOfficePhoneNumber(data?.OfficePhoneNumber);
            setWebsite(data?.website);
            setIsActive(data?.isActive);
            const employee = await getEmployeeData();
            setEmpInfo(employee);
        };
        getSingleData();
    }, [id]);

    const websiteRegex =
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    const handleCheckWebsite = (value) => {
        const isValid = websiteRegex.test(value);
        if (isValid === false) {
            setWebsiteWarning(true);
        } else {
            setWebsiteWarning(false);
        }
    };

    const handlePhoneWarning = (num) => {
        if (num.length < 10) {
            setPhoneWarning(true);
        } else {
            setPhoneWarning(false);
        }
    };
    const findFilteredEmployee = () => {
        const filterEmployee = emp?.filter((em) => em.Attributes[1].Value === id);
        if (filterEmployee.length > 0) {
            if (isActive) {
                filterEmployee.map(async (fl) => {
                    await enableUsers(fl.Username);
                });
            } else {
                filterEmployee.map(async (fl) => {
                    await disableUsers(fl.Username);
                });
            }
        }
    };

    const handleUpdateOrg = async (e) => {
        findFilteredEmployee();
        if (phoneWarning === true || websiteWarning === true) {
            return;
        }
        setLoading(true);
        const url = `${updateOrganizationUrl}${id}`;
        const updatedData = await updateOrgData(url, {
            companyName,
            billingAddress,
            OfficePhoneNumber,
            companySize,
            website,
            isActive,
        });

        if (updatedData) {
            toast.success('You have successfully updated  Organization!');
            setTimeout(() => {
                history.push('/org/list');
            }, 1500);
        }
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-10">
                    <div className="d-flex justify-content-between">
                        <h1 className="pl-4 text-dark pb-3">Edit Organization</h1>

                        <Button variant="dark" onClick={() => history.push('/org/list')}>
                            Back
                        </Button>
                    </div>
                    <div className="admin-list-table">
                        <form onSubmit={handleSubmit(handleUpdateOrg)} className="pl-4">
                            <div className="form-row">
                                <div className="form-group col-md-4">
                                    <h6>Company Name</h6>
                                    <input
                                        type="text"
                                        className="form-control"
                                        {...register('companyName', {
                                            value: { companyName },
                                            required: {
                                                value: true,
                                                message: 'Please type company name',
                                            },
                                        })}
                                        aria-describedby="textHelp"
                                        placeholder="Company Name"
                                        value={companyName}
                                        onChange={(e) => setCompanyName(e.target.value)}
                                    />
                                    {errors.companyName?.type === 'required' && (
                                        <span className="input-warning-style">
                                            {errors.companyName.message}
                                        </span>
                                    )}
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group col-md-4">
                                    <h6>Billing Address</h6>
                                    <input
                                        type="text"
                                        className="form-control"
                                        {...register('billingAddress', {
                                            value: { billingAddress },
                                            required: {
                                                value: true,
                                                message: 'Please type Billing Address',
                                            },
                                        })}
                                        aria-describedby="textHelp"
                                        placeholder="Billing Address"
                                        value={billingAddress}
                                        onChange={(e) => setBillingAddress(e.target.value)}
                                    />
                                    {errors.billingAddress?.type === 'required' && (
                                        <span className="input-warning-style">
                                            {errors.billingAddress.message}
                                        </span>
                                    )}
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group col-md-4 one">
                                    <h6>Office Phone Number</h6>
                                    <input
                                        type="text"
                                        className="form-control"
                                        {...register('OfficePhoneNumber', {
                                            value: { OfficePhoneNumber },
                                            required: {
                                                value: true,
                                                message: 'Please type Office Phone Number',
                                            },
                                        })}
                                        placeholder="Official Phone Number"
                                        value={OfficePhoneNumber}
                                        onChange={(e) => {
                                            setOfficePhoneNumber(e.target.value);
                                            handlePhoneWarning(e.target.value);
                                        }}
                                    />
                                    {errors.OfficePhoneNumber?.type === 'required' && (
                                        <span className="input-warning-style">
                                            {errors.OfficePhoneNumber.message}
                                        </span>
                                    )}
                                    {phoneWarning && (
                                        <span className="input-warning-style">
                                            Minimum length should be 10.
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-4 one">
                                    <h6>Company Size</h6>
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder="Company Size"
                                        {...register('companySize', {
                                            value: { companySize },
                                            required: {
                                                value: true,
                                                message: 'Please type Company Size',
                                            },
                                        })}
                                        value={companySize}
                                        onChange={(e) => setCompanySize(e.target.value)}
                                    />
                                    {errors.companySize?.type === 'required' && (
                                        <span className="input-warning-style">
                                            {errors.companySize.message}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-4 one">
                                    <h6>Website</h6>
                                    <input
                                        type="text"
                                        id="title"
                                        className="form-control"
                                        placeholder="Website"
                                        {...register('website', {
                                            value: { website },
                                            required: {
                                                value: true,
                                                message: 'Please type website',
                                            },
                                        })}
                                        value={website}
                                        onChange={(e) => {
                                            setWebsite(e.target.value);
                                            handleCheckWebsite(e.target.value);
                                        }}
                                    />
                                    {errors.website?.type === 'required' && (
                                        <span className="input-warning-style">
                                            {errors.website.message}
                                        </span>
                                    )}
                                    {websiteWarning && (
                                        <span className="input-warning-style">
                                            Please give a valid website.
                                        </span>
                                    )}
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary mb-5">
                                Submit
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default EditOrganization;
