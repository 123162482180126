/* eslint-disable arrow-body-style */
/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-named-as-default */
import moment from 'moment/min/moment-with-locales';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Moment from 'react-moment';
import { Link, useParams } from 'react-router-dom';
import { getEmployeeInfo } from '../../../api/employee';
import './EmployeeView.scss';


const EmployeeView = () => {
    const { username } = useParams();
    const [employeeInfo, setEmployeeInfo] = useState({});
    const [employeeProfilePicture, setEmployeeProfilePicture] = useState('https://i.ibb.co/vVfrXgh/Group-302.png');

    Moment.globalMoment = moment;
    Moment.globalFormat = 'MMM D, YYYY';

    useEffect(() => { 
      const getValues = async (userName) =>{
        const responsePersonalInfo =  await getEmployeeInfo(userName);
        setEmployeeInfo(responsePersonalInfo)
        const picture = responsePersonalInfo?.ProfilePicture ? responsePersonalInfo?.ProfilePicture : 'https://i.ibb.co/vVfrXgh/Group-302.png'
        setEmployeeProfilePicture(picture)
      } 
      getValues(username)
    }, [username]);
    return (
        <section className='employee__view'>
            <Container fluid>
            <Row>
                {employeeInfo ? (
                    <Col>
                        <div className='bg-white main-container p-4 rounded-border-12'>
                        <h5 className="section-header mb-4 flex-grow-1">Employee Profile</h5>
                            <Row>
                                <Col className='px-4'>
                                    <h5 className='sub-header mb-3'>Personal Info</h5>
                                    <div className='d-flex flex-column justify-content-start'>
                                        <span className='text-xs mb-3'>Profile Picture</span>
                                         <img
                                          className="profile-upload-icon rounded-circle text-center border-0 mb-3" 
                                          src={employeeProfilePicture}
                                          style={{marginLeft:"-23px"}}
                                          alt="profilePicture"
                                         />
                                    </div>

                                    <div>
                                        <span className='text-xs'>First Name</span>
                                        <p className='text-md'>
                                             {employeeInfo.firstName}
                                        </p>
                                    </div>

                                    <div>
                                        <span className='text-xs'>Middle Name</span>
                                        <p className='text-md'>
                                            {employeeInfo.middleName}
                                        </p>
                                    </div>

                                    <div>
                                        <span className='text-xs'>Last Name</span>
                                        <p className='text-md'>
                                            {employeeInfo.lastName}
                                        </p>
                                    </div>

                                    <div>
                                        <span className='text-xs'>Display Name</span>
                                        <p className='text-md'>
                                            {employeeInfo.displayName}
                                        </p>
                                    </div>

                                    <div>
                                        <span className='text-xs'>Date of Birth</span>
                                        <p className='text-md'>
                                           <Moment>{employeeInfo.dob}</Moment>
                                        </p>
                                    </div>

                                    <div>
                                        <span className='text-xs'>Gender</span>
                                        <p className='text-md'>
                                             {employeeInfo.gender}
                                        </p>
                                    </div>

                                    <div>
                                        <span className='text-xs'>Email Address</span>
                                        <p className='text-md'>
                                             {employeeInfo.emailAddress}
                                        </p>
                                    </div>

                                    <div>
                                        <span className='text-xs'>Mobile Number</span>
                                        <p className='text-md'>
                                            {employeeInfo.mobileNumber}
                                        </p>
                                    </div>
                                </Col>
                                <Col className='px-4'>
                                    <h5 className='sub-header mb-3'>Official Info</h5>
                                    <div>
                                        <span className='text-xs'>Office Location</span>
                                        <p className='text-md'>
                                            {employeeInfo.officeLocation}
                                        </p>
                                    </div>
                                    <div>
                                        <span className='text-xs'>Employment Type</span>
                                        <p className='text-md'>
                                          {employeeInfo.employmentType}
                                        </p>
                                    </div>
                                    <div>
                                        <span className='text-xs'>Designation</span>
                                        <p className='text-md'>
                                          {employeeInfo.designation}
                                        </p>
                                    </div>
                                    <div>
                                        <span className='text-xs'>Employee Role</span>
                                        <p className='text-md'>
                                           {employeeInfo.assignRole}
                                        </p>
                                    </div>
                                    <div>
                                        <span className='text-xs'>Date of Joining</span>
                                        <p className='text-md'>
                                           <Moment>{employeeInfo.dateOfJoining}</Moment> 
                                        </p>
                                    </div>
                                    <div>
                                        <span className='text-xs'>Status</span>
                                        <p className='text-md'>
                                             {employeeInfo.employmentStatus}
                                        </p>
                                    </div>
                                </Col>
                                <div className="action-btns text-center mt-5 d-flex align-items-center justify-content-center gap-1">
                                        <Link to='/employeelist' className="textdec mr-4">
                                             <Button className='custom-btn-fill rounded-3 px-4 py-2'>
                                                Back
                                             </Button>
                                        </Link>
                                </div>
                            </Row>

                        </div>
                    </Col>
                ) : (

                    
                    <></>
                )}
            </Row>
        </Container>
        </section>
    );
};

export default EmployeeView;
