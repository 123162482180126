/* eslint-disable no-nested-ternary */
/* eslint-disable no-alert */
/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-named-as-default */

import { React } from 'react';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({ children, ...rest }) => {
    const userInfo = sessionStorage.getItem('userInfo') || localStorage.userInfo && JSON.parse(localStorage.userInfo);

    return (
        <Route
            {...rest}
            render={({ location }) =>
                userInfo ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
};

export default PrivateRoute;
