/* eslint-disable prettier/prettier */
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import React, { useContext, useEffect, useState } from 'react';
import {
    Button, Col,
    Container, Form,
    Row
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { addAnnouncement } from '../../../api/announcements';
import { getAdminByEmail, getEmployeeByEmail } from '../../../api/profile';
import { AuthContext } from '../../../Context/AuthContext';

const AnnouncementAdd = () => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [adminName, setAdminName] = useState('');
    const [employeeName, setEmployeeName] = useState('');
    const { oid,logged,isAdmin } = useContext(AuthContext);
    const [image, setImage] = useState("https://i.ibb.co/vVfrXgh/Group-302.png");
  

    const history = useHistory();

    const handleSubmit = async(e) => {
        e.preventDefault();

        const data ={
            oid,
            title,
            description,
            createdBy:image,
            creatorEmail:logged,
            creatorName: isAdmin ? adminName : employeeName 
        }
       
        const result = await addAnnouncement(data);

        if(result){
            history.push('/announcementListing');
        }
    };
    useEffect(()=>{
        const getValues = async() =>{
         if(isAdmin){
             const resultAdmin = await getAdminByEmail(logged);
             setAdminName(`${resultAdmin[0].adminFirstName} ${resultAdmin[0].adminLastName}`)
             if(resultAdmin[0].profilePicture !==""){
                 setImage(resultAdmin[0].profilePicture)
             }
         }
         else{
             const resultEmployee = await getEmployeeByEmail(logged);
             setEmployeeName(`${resultEmployee[0]?.firstName} ${resultEmployee[0]?.lastName}`)
             if(resultEmployee[0]?.ProfilePicture !==""){
               setImage(resultEmployee[0]?.ProfilePicture)
             }
         }
        }
        getValues()
     },[image,isAdmin,logged])

    return (
        <Container className="pb-4 fluid">
            <Col lg={12}>
                <div className="d-flex justify-content-end">
                    <Button
                        onClick={() => history.push('/announcementListing')}
                        className="btn-rt-primary my-3"
                        type="button"
                    >
                        Back
                    </Button>
                </div>
                <div className='rt-add-process-doc'>

                    <Form
                        className='p-4'
                        onSubmit={handleSubmit}
                    >
                        <Row>
                            <Col lg={12}>
                                <Form.Group className="mb-4">
                                    <Form.Label className='ml-2'>Title</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Announcement Title here"
                                        required
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={12}>
                                <Form.Group className="mb-4">
                                    <Form.Label className='ml-2'>Description</Form.Label>
                                    <CKEditor  
                                        editor={ClassicEditor}
                                        data={description}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setDescription(data);
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={2}>
                                <Button variant="primary" className='btn-rt-primary' type="submit">
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Col>
        </Container>
    );
};

export default AnnouncementAdd;
