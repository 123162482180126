/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-useless-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/order */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/newline-after-import */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/aria-proptypes */
/* eslint-disable react/jsx-boolean-value */
// eslint-disable-next-line import/newline-after-import
// eslint-disable-next-line arrow-body-style
// eslint-disable-next-line no-plusplus
import Multiselect from "multiselect-react-dropdown";
import React from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import "../../Badges/BadgeModal/BadgeModal.scss";;

const MeetingModal = (props) => {
  const { show,
    handleClose,
    handleConfirm,
    options,
    handleAddNewUserImage,
    detailsPage,
    selectedUser,
    handleCancel
  } = props;

  let finalOptions = options

  if (selectedUser) {
    const filteredOptions = options.filter(el => selectedUser.every(item => item.name !== el.name))
    finalOptions = filteredOptions;
  }

  return (
    <div className="badge-modal">
      <Container>
        <Row>
          <Col className="pt-4">
            <Modal show={show} className={detailsPage ? "meeting-agenda-modal" : "meeting-modal"}>
              <Modal.Body>
                  <Row>
                    <Col>
                      <Multiselect
                        // required
                        displayValue="name"
                        placeholder="Type name of team members"
                        // isObject={true}
                        onRemove={(event) => {
                        }}
                        onSelect={handleAddNewUserImage}
                        selectedValues={selectedUser}
                        options={finalOptions}
                        // avoidHighlightFirstOption={true}
                        // hidePlaceholder={true}
                      />
                      {
                        detailsPage && <div className="mt-4">
                          <Form.Check
                            className="agenda-checkbox"
                          // checked={checked}
                          // onChange={(e) => setChecked(e.target.checked)}
                          />
                          <label className="agenda-label">Ask to add agenda</label>
                        </div>
                      }


                    </Col>
                  </Row>
                  <div className="action-btns d-flex gap-3 justify-content-center mt-3">
                    <Button
                      onClick={handleCancel ? handleCancel : handleClose}
                      className="custom-btn badge-btn"
                    >
                      Cancel
                    </Button>
                    <Button
                      className="custom-btn badge-btn bg-brand"
                      onClick={handleConfirm ? handleConfirm : handleClose}
                    >
                      Confirm
                    </Button>

                  </div>
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MeetingModal;
