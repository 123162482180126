import axios from 'axios';

const data =
    JSON.parse(sessionStorage.getItem('userInfo')) ||
    (localStorage.userInfo && JSON.parse(localStorage.userInfo));

export default axios.create({
    baseURL: 'https://saa8af5ak8.execute-api.us-east-2.amazonaws.com/dev/v1/admin/',
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'x-api-key': 'daF2NQQSpK68lvdXRY82kbYINi5Q8rX7OXypcDs5',
        Authorization: data && data.signInUserSession?.idToken.jwtToken,
    },
});
