/* eslint-disable prettier/prettier */
import React from "react";

function NotFoundPage() {
  return (
    <div>
      <h6>No Data Found</h6>
    </div>
  );
}

export default NotFoundPage;
