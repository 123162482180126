/* eslint-disable no-alert */
/* eslint-disable import/prefer-default-export */

import axios from 'axios';
import siteApi from './siteApi';

// const headers = {
//     'Content-Type': 'application/json',
//     'Access-Control-Allow-Origin': '*',
//     'Access-Control-Allow-Credentials': true,
// };

const superAdminAPi = process.env.REACT_APP_SuperAdmin_API;

const getAdminList = async () => {
    const result = await axios.get(`${superAdminAPi}/adminInfo`);
    return result?.data;
};

const getOrganizationList = async () => {
    const result = await axios.get(`${superAdminAPi}/org`);
    return result?.data;
};

const getSingleOrganization = async (id) => {
    const response = await axios.get(`${superAdminAPi}/org/${id}`);
    return response?.data;
};

const getEmployeeData = async () => {
    const response = await siteApi.get(`/employee`);
    return response?.data.Users;
};

const enableUsers = async (userName) => {
    const response = await siteApi.post('/employee/enableUser', { username: userName });
    return response;
};

const disableUsers = async (userName) => {
    const response = await siteApi.post('/employee/disableUser', { username: userName });
    return response;
};

const updateOrgData = async (url, formData) => {
    const response = axios.put(`${superAdminAPi}${url}`, formData);
    return response;
};

// update Admin Data

const updateAdminData = async (id, formData) => {
    const response = axios.put(`${superAdminAPi}/adminInfo/update/${id}`, formData);
    return response;
};

// add Org

const addOrgData = async (url, formData) => {
    const response = await axios.post(`${superAdminAPi}/${url}`, formData);
    return response;
};

// add Admin Info

const addAdminInfo = async (formData) => {
    const response = await axios.post(`${superAdminAPi}/adminInfo/add`, formData);
    return response;
};

// get Single Admin Data

const singleAdminInfo = async (id) => {
    const response = await axios.get(`${superAdminAPi}/adminInfo/${id}`);
    return response;
};

// get Single Admin Data BY email

const singleAdminInfoByEmail = async (email) => {
    const response = await axios.get(`${superAdminAPi}/adminInfo/getAdmin${email}`);
    return response;
};

// update Admin Profile Picture

const updateAdminProfilePicture = async (id, profilePicture) => {
    const response = await axios.put(
        `${superAdminAPi}/adminInfo/update/profile/${id}`,
        profilePicture
    );
    return response;
};

// send Email

const sendEmailToAdmin = async (formData) => {
    const response = await axios.post(`${superAdminAPi}/adminInfo/sendmail`, formData);
    return response;
};

const deleteAdminFromCognito = async (username) => {
    const response = siteApi.post('/employee/deleteUser', username);
    return response;
};

// delete
const deleteOrgData = async (oid) => {
    const response = await axios.delete(`${superAdminAPi}/org/${oid}`);
    return response;
};
const deleteAdmin = async (id) => {
    const response = await axios.delete(`${superAdminAPi}/adminInfo/delete/${id}`);
    return response;
};

export {
    getAdminList,
    addAdminInfo,
    sendEmailToAdmin,
    getOrganizationList,
    getSingleOrganization,
    getEmployeeData,
    enableUsers,
    disableUsers,
    updateOrgData,
    addOrgData,
    deleteOrgData,
    deleteAdmin,
    singleAdminInfo,
    singleAdminInfoByEmail,
    updateAdminProfilePicture,
    updateAdminData,
    deleteAdminFromCognito,
};
