import React, { useState, useContext } from 'react';
import { Container, Col, Form, Row, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { insertWorkStream } from '../../api/workStream';
import { AuthContext } from '../../Context/AuthContext';

const AddWorkStream = () => {
    const history = useHistory();
    const { oid } = useContext(AuthContext);
    const [workStreamName, setWorkStreamName] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            oid,
            workStreamName,
        };

        const result = await insertWorkStream(data);

        if (result) {
            history.push('/workStream/list');
        }
    };

    return (
        <Container className="pb-4 fluid">
            <Col lg={12}>
                <div className="d-flex justify-content-end">
                    <Button
                        onClick={() => history.push('/workStream/list')}
                        className="btn-rt-primary my-3"
                        type="button"
                    >
                        Back
                    </Button>
                </div>
                <div className="rt-add-process-doc">
                    <Form className="p-4" onSubmit={handleSubmit}>
                        <Row>
                            <Col lg={12}>
                                <Form.Group className="mb-4">
                                    <Form.Label className="ml-2">Work Stream Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Work Stream Name here"
                                        required
                                        value={workStreamName}
                                        onChange={(e) => setWorkStreamName(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={2}>
                                <Button variant="primary" className="btn-rt-primary" type="submit">
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Col>
        </Container>
    );
};

export default AddWorkStream;
