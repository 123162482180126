import React, { useState, useEffect } from 'react';
import { Container, Col, Form, Row, Button } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { getSingleTaskWorkStream, updateTaskStream } from '../../api/taskStream';
const EditTaskStream = () => {
    const history = useHistory();
    const { id } = useParams();
    const [workStreamName, setWorkStreamName] = useState('');

    useEffect(() => {
        const retrieveAnnounceDetails = async () => {
            const response = await getSingleTaskWorkStream(id);
            if (response) {
                setWorkStreamName(response?.workStreamName);
            }
        };
        retrieveAnnounceDetails();
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            workStreamName,
        };
        const result = await updateTaskStream(id, data);

        if (result) {
            history.push('/taskStream/list');
        }
    };
    return (
        <Container className="pb-4 fluid">
            <Col lg={12}>
                <div className="d-flex justify-content-end">
                    <Button
                        onClick={() => history.push('/taskStream/list')}
                        className="btn-rt-primary my-3"
                        type="button"
                    >
                        Back
                    </Button>
                </div>
                <div className="rt-add-process-doc">
                    <Form className="p-4" onSubmit={handleSubmit}>
                        <Row>
                            <Col lg={12}>
                                <Form.Group className="mb-4">
                                    <Form.Label className="ml-2">Work Stream Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Task Stream Name here"
                                        required
                                        value={workStreamName}
                                        onChange={(e) => setWorkStreamName(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={2}>
                                <Button variant="primary" className="btn-rt-primary" type="submit">
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Col>
        </Container>
    );
};

export default EditTaskStream;
