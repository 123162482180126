/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prettier/prettier */
/* eslint-disable import/order */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { AiFillBell } from "react-icons/ai";
// import { notificationList } from '../../../api/notification';
import siteApi from '../../../api/siteApi';


const Navbar = ({socket,firstRender}) => {

    const [notifications, setNotifications] = useState([]);
    const [open, setOpen] = useState(false);
    const [currentUser, setCurrentUser] = useState('');
    const filteredArray = [];

    Auth.currentSession()
    .then((data) => {
        const idToken = data.getIdToken();
        const { email } = idToken.payload;
        setCurrentUser(email)
    })
    .catch((err) => console.log(err));
 

    const loadNotification = async() =>{
        try {
               if(currentUser){
                const response = await siteApi.get(`/meeting/notification/${currentUser}`)
                setNotifications(response?.data);
               }
        } catch (error) {
            console.log(error)
        } 
    }

    useEffect(() => {
        socket?.on("getNotification", (data) => {
              if(data.email ===  currentUser){
                filteredArray.push(data)
              } 
              const notify = notifications
              const mergedArray = notify.concat(...filteredArray);  
              setNotifications(mergedArray);
        });
            loadNotification() 
    }, [!currentUser]);
    
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { month: "short", day: "numeric", hour: "numeric", minute: "numeric", hour12: true };
        const formattedDate = date.toLocaleDateString("en-US", options);
        return formattedDate.replace(",", " at");
    }

    const displayNotification = ({ meetingTitle, meetingDate, meetingTime, createdAt }) => 
    <div className='d-flex'>
        <span className='mr-2 mt-3'>
            <img src="https://www.w3schools.com/howto/img_avatar.png" alt="user" className="rounded" width="50" height="50"/>
        </span>
        <span className="notification">
            <div className='date-format'>{formatDate(createdAt)}</div>
            {
            `You Have a scheduled  meeting ${meetingTitle} meeting in ${meetingDate} at ${meetingTime}`
            }
        </span>
    </div>

    return (
        <div className="navbar">
            <div className="icons">
                <div className="icon" onClick={() => setOpen(!open)}>
                    <AiFillBell className="iconImg"/>
                    {notifications.length > 0 && (
                        <div className="counter"> </div>
                    )}
                </div>
            </div>
            {open && (
                <div className="notifications">
                    <div className="overflow-auto pr-3">
                        <div className="d-flex align-items-center justify-content-between mb-3">
                            <h6>Notifications
                                <span>
                                    {notifications.length > 0 && (
                                        <sup className="ml-2 text-primary">{notifications.length}</sup>
                                    )}
                                </span>
                            </h6>
                            <Button variant="outline-secondary" size='sm' >Mark All as Read</Button>
                        </div>
                        {
                            notifications?.map((n) => displayNotification(n) ) 
                        }
                    </div>
                </div>
            )}
        </div>
    );
};

export default Navbar;
