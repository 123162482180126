/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable prettier/prettier */

import React from "react";
import axios from "axios";
import fileDownload from "js-file-download";
import { BsDownload } from "react-icons/bs";
import "./EmployeeList.scss";
const AddEmployeeUploadFormatFile = () => {
  const handleBulkImportFormatFile = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };
  return (
    <div>
      <p
        className="add-btn text-white cursor border-0 mr-4 mb-0"
        onClick={() => {
          handleBulkImportFormatFile(
            "https://visionbucket163310-dev.s3.us-east-2.amazonaws.com/public//profileImg/UploadOrganizationEmployeeInformation+-+UploadOrganizationEmployeeInformation.csv",
            "AddEmoployeeFormattoOrganization.csv"
          );
        }}
      >
        <BsDownload className="icon custom-icon-style text-white mr-2" /> CSV Format
      </p>
    </div>
  );
};

export default AddEmployeeUploadFormatFile;
