/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useContext } from "react";
import toast from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import "./ChartNode.scss";
import { updateOrganizationChartNode } from "../../../../api/organizationChart";
import { AuthContext } from "../../../../Context/AuthContext";
export default function PersonRole({ personrole, status, selectedNode }) {
  const values = useContext(AuthContext);
  const [roleEdit, setRoleEdit] = useState(false);
  const [value, setValue] = useState([personrole]);
  const [term, setTerm] = useState("");

  // change node field person role
  const changePersonRoles = (personRole) => {
    let updateResult;
    const flag =
      JSON.stringify(personRole) === JSON.stringify(value) ? true : false;
    const {
      id,
      createdAt,
      oid,
      parentID,
      personName,
      photoURL,
      designation,
      tag,
    } = selectedNode;
    const updateData = {
      oid,
      parentID,
      personName,
      designation,
      photoURL,
      personRoles: personRole,
      tag,
    };
    if (flag === false) {
      updateResult = updateOrganizationChartNode(id, createdAt, updateData);
    }
    if (updateResult) {
      toast.success(" Person Roles Updated!", {
        duration: 1000,
        position: "bottom-center",
      });
    }
  };

  const handleRoleEdit = () => {
    if (values.isAdmin && status === true) {
      setRoleEdit(true);
    } else {
      setRoleEdit(false);
    }
  };

  useEffect(() => {
    setValue(personrole);
  }, [personrole]);

  return (
    <div className="mt-3 d-flex flex-column align-items-start tree-text">
      <h6 className="text-muted my-0">Roles</h6>
      {!roleEdit ? (
        <p
          onClick={() => {
            handleRoleEdit();
          }}
          className="mb-0"
        >
          {value?.map((i) => (
            <ul className="roleList d-block text-left">
              <li className="py-0">{i}</li>
            </ul>
          ))}
        </p>
      ) : (
        <textarea
          defaultValue={value?.join("\n")}
          onBlur={(event) => {
            setRoleEdit(false);
            if (term) {
              changePersonRoles([...term.split("\n")]);
            }
            setValue(
              term === ""
                ? [...value.split("\n").filter((a) => a)]
                : [...term.split("\n").filter((a) => a)]
            );
          }}
          onChange={(event) => {
            setTerm(event.target.value);
          }}
        />
      )}
    </div>
  );
}
