/* eslint-disable */
import { useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap'
import SingleComment from './SingleComment';

const TaskComment = ({today,disabled}) => {

    const [comments, setComments] = useState([])
    const [message, setMessage] = useState('');

    const ref = useRef(null);


    const handleComment = e => {

        const taskComment = ref.current
        const commentValue = taskComment.value
        taskComment.value = ""
        setMessage(taskComment.value)


        // ‘id’:””,
        // “oid”:””,
        // “taskId”:””,
        // “commentPersonName”:””,
        // “commentPersonImage”:””,
        // “commentPersonEmail”:””,
        // “commentData”:””,
        // “createdAt”:””,

        const taskCom = {
            commentValue
        }

        const newComment = [taskCom, ...comments]
        setComments(newComment)

        // console.log(newComment)
    }

    const removeComment = e => {
        console.log(e)
        const rm = comments.filter((newCom, i) => i !== e)
        setComments(rm)

    }


    // console.log(commEdit)
    return (
        <>
            <Form id='comment-data' >
                <div className="task-comment-box">
                    <Form.Group className='mb-3' controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Comments</Form.Label>
                        <Form.Control
                         as="textarea" rows={3} ref={ref} value={message}
                         onChange={event => setMessage(event.target.value)}
                         disabled={disabled}
                         />
                    </Form.Group>
                </div>
                <Button className='add-btn text-white cursor border-0  mr-3 px-5 py-2' onClick={handleComment} data-backdrop="static" disabled={!message}>Post</Button>
            </Form>
            <div className="chat mt-5">


                {comments &&
                    comments.map((comment, i) =>

                        <SingleComment
                            comment={comment}
                            today={today}
                            removeComment={removeComment}
                            key={i}
                            index={i}
                        />

                    )
                }


                {/* Nasted Comments  */}
                {/* <div className='d-flex reply'>
                    <img src="https://placeimg.com/192/192/people" alt="" className='rounded-circle mr-3' style={{ height: '40px' }} />
                    <div className="chat-body p-2">
                        <div className="chat-body-hading d-flex justify-content-between">
                            <div className='d-flex align-items-end'>
                                <h6 className='mb-2 mr-2'>Mushifq</h6>
                                <span className='date mb-2'>{today}</span>
                            </div>
                            <div>
                                <MdModeEdit className="edit-ico ml-1" />
                                <AiFillDelete className="delete-ico ml-1" />
                            </div>
                        </div>
                        <p className='chat-text'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut ratione maiores eos veritatis consectetur asperiores nihil aut! Autem repellendus ex inventore quos explicabo animi culpa, ratione, ad numquam maiores sunt?</p>
                    </div>
                </div> */}

            </div>
        </>
    )
}

export default TaskComment