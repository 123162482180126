/* eslint-disable object-shorthand */
import siteApi from './siteApi';
/* eslint-disable import/prefer-default-export */

const getEmployeeByEmail = async (email) => {
    const response = await siteApi.get(`/employee/personalInfo/data/${email}`);
    return response?.data;
};

const getAdminByEmail = async (email) => {
    const response = await siteApi.get(`/adminInfo/data/${email}`);
    return response?.data;
};

const updateProfilePictureEmployee = async (eid, profilePicture) => {
    const response = await siteApi.patch(`/employee/personalInfo/update/profile/${eid}`, {
        ProfilePicture: profilePicture,
    });
    return response;
};

const updateProfilePictureAdmin = async (id, oid, profilePicture) => {
    const response = await siteApi.patch(`/adminInfo/update/picture/${id}/${oid}`, {
        profilePicture,
    });
    return response;
};

export {
    getEmployeeByEmail,
    getAdminByEmail,
    updateProfilePictureEmployee,
    updateProfilePictureAdmin,
};
