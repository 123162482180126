/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { Spinner } from 'react-bootstrap';
import { deleteOrgData, getOrganizationList } from '../../api/superAdmin';
import Table from '../Common/Table';
import Pagination from '../Common/Pagination/Pagination';

const OrganizationList = () => {
    const [organization, setOrganization] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(20);
    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPOst = indexOfLastPost - postsPerPage;
    const currentPosts =
        organization.length > 0 && organization.slice(indexOfFirstPOst, indexOfLastPost);
    // const filteredCurrentPosts = filterData.slice(indexOfFirstPOst, indexOfLastPost);
    const totalPosts = organization.length;
    const paginate = (pageNum) => setCurrentPage(pageNum);
    const nextPage = () => setCurrentPage(currentPage + 1);
    const prevPage = () => setCurrentPage(currentPage - 1);

    useEffect(() => {
        const getOrganizationListData = async () => {
            const orgList = await getOrganizationList();
            setOrganization(orgList);
            setLoading(false);
        };
        getOrganizationListData();
    }, []);

    const handleDelete = async (id, oid) => {
        const deleteOrg = await deleteOrgData(id);
        const filterOrg = organization.filter(org => org.id !== id);
        if (deleteOrg) {
            toast.success('Organization deleted successfully!');
            setShow(false);
        }
        setOrganization(filterOrg);
    }

    const excludeKeys = ['id', 'createdAt', 'isActive'];

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-10 col-sm-12">
                    <h1 className="text-dark p-4 employee-listing">Organization Listing</h1>
                    {
                        loading ? <div className="d-flex justify-content-center">
                            <Spinner className="rt-spinner" animation="grow" />
                        </div> : <div className="admin-list-table">
                            <div>
                                <Table
                                    col={currentPosts}
                                    data={currentPosts}
                                    excludeKeys={excludeKeys}
                                    orgEdit="/org/item"
                                    handleDelete={handleDelete}
                                    show={show}
                                    setShow={setShow}
                                />
                                <Pagination
                                    postsPerPage={postsPerPage}
                                    totalPosts={totalPosts}
                                    paginate={paginate}
                                    nextPage={nextPage}
                                    prevPage={prevPage}
                                    currentPage={currentPage}
                                />
                            </div>

                        </div>
                    }
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default OrganizationList;
