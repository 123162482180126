/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-alert */
/* eslint-disable object-shorthand */
/* eslint-disable prefer-const */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-named-as-default */
import { Storage } from "aws-amplify";
import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  disableUser,
  getEmployeeInfo,
  updateEmployeeInfo,
  updateEmployeeProfilePicture,
  enableUser
} from "../../../api/employee";
import { AuthContext } from "../../../Context/AuthContext";
import ProgressBar from "../AddEmployee/addEmployeeComponents/Progressbar";
import Step1 from "./editEmployeeComponents/Step1";
import Step2 from "./editEmployeeComponents/Step2";
import Step3 from "./editEmployeeComponents/Step3";

const EmployeeEdit = () => {
  const history = useHistory();
  const { username } = useParams();
  const [count, setCount] = useState(1);
  const { oid } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [employeePersonalInfo, setEmployeePersonalInfo] = useState({});
  const [displayName, setDisPlayName] = useState("");
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");

  useEffect(() => {
    const getEmployeeData = async () => {
      const personalInfo = await getEmployeeInfo(username);
      setEmployeePersonalInfo(personalInfo);
      setCountry(personalInfo?.officeLocation?.split(",")[1]);
      setRegion(personalInfo?.officeLocation?.split(",")[0]);
      if (personalInfo) {
        setLoading(false);
      }
    };
    getEmployeeData();
  }, [username]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });

  const handleStepCompletion = () => {
    setCount((cur) => cur + 1);
  };

  const handleGoBackToPreviousStep = () => {
    setCount((cur) => cur - 1);
  };

  async function onSubmit(data) {
    if (count > 3) {
      try {
        let profileImg = "";

        if (data.profilePicture) {
          const removeUrl =
            employeePersonalInfo?.ProfilePicture.split("public/").pop();

          await Storage.remove(removeUrl, { level: "public" });

          const profileImgUrl = `profileImg/${username}${data.profilePicture[0]?.name}`;
          profileImg = `${process.env.REACT_APP_IMAGE_BASE_URL}//${profileImgUrl}`;
          await Storage.put(`/${profileImgUrl}`, data.profilePicture[0], {
            level: "public",
            contentType: data.profilePicture[0]?.type,
          });
        } else {
          profileImg = employeePersonalInfo.ProfilePicture;
        }

        // const responseUpdateProfilePicture = await updateEmployeeProfilePicture(
        //   {
        //     profilePicture: profileImg,
        //   },
        //   username
        // );

        if (data.employmentStatus === "Active") {
          const username1 = {
            username: username,
          };
          const res = await enableUser(username1);
        } else {
          const username1 = {
            username: username,
          };
          const res = await disableUser(username1);
        }


        const {
          employmentType,
          designation,
          firstName,
          lastName,
          displayNames,
          middleName,
          emailAddress,
          mobileNumber,
          gender,
          dateOfBirth,
          dateOfJoining,
          employmentStatus,
        } = data;

        const updateEmployeePersonalDetails = {
          firstName,
          lastName,
          middleName,
          displayName: displayNames,
          emailAddress,
          mobileNumber,
          gender,
          dob: dateOfBirth,
          activeFlag: employeePersonalInfo?.activeFlag
            ? employeePersonalInfo?.activeFlag
            : "False",
          ProfilePicture: profileImg,
          officeLocation: `${region},${country}`,
          employmentType,
          designation,
          dateOfJoining,
          employmentStatus,
        };

        const responseUpdateEmployee = await updateEmployeeInfo(
          username,
          updateEmployeePersonalDetails
        );
      
      

        if (
          responseUpdateEmployee 
        ) {
          toast("updated Successfully");
          history.push("/employeelist");
        }
      } catch (error) {
        console.log("error signing up:", error);
      }
    }
  }
  return (
    <Container fluid>
      <Row>
        <Col>
          <div className="employee-add-form bg-white main-container p-4 rounded-border-12 min-vh-80 mx-3">
            <h5 className="section-header ml-3 mb-0">Edit Employee Profile</h5>
            {/* {status ? (
                            <div className="d-flex justify-content-center">
                                <Spinner className="rt-spinner" animation="grow" />
                            </div>
                        ) : ( */}
            <form onSubmit={handleSubmit(onSubmit)}>
              <ProgressBar count={count} />
              <div className="text-right">
                <ToastContainer />
              </div>
              {loading ? (
                <div className="d-flex justify-content-center">
                  <Spinner className="rt-spinner" animation="grow" />
                </div>
              ) : Object.keys(employeePersonalInfo).length > 0 ? (
                <>
                  {count === 1 && (
                    <Step1
                      errors={errors}
                      register={register}
                      watch={watch}
                      employeeInfo={employeePersonalInfo}
                      setDisPlayName={setDisPlayName}
                    />
                  )}
                  {count === 2 && (
                    <Step2
                      errors={errors}
                      register={register}
                      employeeInfo={employeePersonalInfo}
                      oid={oid}
                      country={country}
                      region={region}
                      setCountry={setCountry}
                      setRegion={setRegion}
                    />
                  )}
                  {count >= 3 && (
                    <Step3
                      errors={errors}
                      register={register}
                      employeeWorkDetails={employeePersonalInfo}
                    />
                  )}
                  {count > 3 && (
                    <div className="d-flex justify-content-center">
                      <Spinner className="rt-spinner" animation="grow" />
                    </div>
                  )}
                  <div className="form-row">
                    <div className="col-md-12" style={{ marginBottom: "15px" }}>
                      {!isValid && count < 6
                        ? "Please file up required field *"
                        : null}
                    </div>
                  </div>

                  <div className="action-btns">
                    <button
                      className="btn btn-primary mr-3 rounded-3 px-4"
                      type="button"
                      onClick={handleGoBackToPreviousStep}
                      disabled={count < 2}
                      // hidden={status}
                    >
                      Back
                    </button>
                    {count < 3 ? (
                      <button
                        disabled={!isValid}
                        className="btn btn-primary rounded-3 px-4"
                        type="button"
                        onClick={handleStepCompletion}
                      >
                        Next
                      </button>
                    ) : (
                      <button
                        disabled={!isValid}
                        // hidden={status}
                        className="btn btn-primary rounded-3 px-4"
                        type="submit"
                        onClick={handleStepCompletion}
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </>
              ) : null}
            </form>
            {/* )} */}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default EmployeeEdit;
