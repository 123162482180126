/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
function Step1(props) {
    const { errors, register, employeeInfo, setDisPlayName, watch } = props;
    const firstName = watch('firstName');
    const middleName = watch('middleName');
    const lastName = watch('lastName');

    const dName = `${firstName} ${middleName} ${lastName}`;
    setDisPlayName(dName);
    return (
        <div>
            <div className="form-row">
                <div className="form-group col-md-6">
                    <label htmlFor="firstName">First name*</label>
                    <input
                        type="text"
                        className=" form-control h-70"
                        {...register('firstName', {
                            value: employeeInfo.firstName,
                            required: {
                                value: true,
                                message: 'Please type first name',
                            },
                        })}
                        placeholder="Please type first name"
                        id="firstName"
                    />
                    {errors.firstName && (
                        <span style={{ color: 'red' }}>{errors.firstName.message}</span>
                    )}
                </div>
                <div className="form-group col-md-6">
                    <label htmlFor="middleName">Middle Name</label>
                    <input
                        type="text"
                        className=" form-control h-70"
                        {...register('middleName', {
                            value: employeeInfo.middleName,
                        })}
                        placeholder="Please type middle name"
                        id="firstName"
                    />
                    {errors.middleName && (
                        <span style={{ color: 'red' }}>{errors.middleName.message}</span>
                    )}
                </div>
                <div className="form-group col-md-6">
                    <label htmlFor="lastName">Last name*</label>
                    <input
                        type="text"
                        className=" form-control h-70"
                        {...register('lastName', {
                            value: employeeInfo.lastName,
                            required: {
                                value: true,
                                message: 'Please type last name',
                            },
                        })}
                        placeholder="Please type last name"
                        id="lastName"
                    />
                    {errors.lastName && (
                        <span style={{ color: 'red' }}>{errors.lastName.message}</span>
                    )}
                </div>
                <div className="form-group col-md-6">
                    <label htmlFor="displayName">Display Name*</label>
                    <input
                        type="text"
                        className=" form-control h-70"
                        {...register('displayNames', {
                            value: employeeInfo.displayName,
                        })}
                        placeholder="display Name"
                        id="displayName"
                    />
                </div>
                <div className="form-group col-md-6">
                    <label htmlFor="emailAddress"> Email Address </label>
                    <input
                        type="email"
                        className=" form-control h-70 "
                        {...register('emailAddress', {
                            value: employeeInfo.emailAddress,
                            required: 'Email is required',
                            pattern: {
                                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: 'Please enter a valid email',
                            },
                        })}
                        placeholder="Please enter a email"
                        id="emailAddress"
                        required
                    />
                    {errors.emailAddress && (
                        <span style={{ color: 'red' }}>{errors.emailAddress.message}</span>
                    )}
                </div>
                <div className="form-group col-md-6">
                    <label htmlFor="mobileNumber">Mobile Number*</label>
                    <input
                        type="number"
                        className=" form-control h-70 "
                        {...register('mobileNumber', {
                            value: employeeInfo.mobileNumber,
                            required: 'Phone number is required',
                            minLength: {
                                value: 8,
                                message: 'Phone number be at least 8 digits long',
                            },
                        })}
                        placeholder="Please enter a mobile number"
                    />
                    {errors.mobileNumber && (
                        <span style={{ color: 'red' }}>{errors.mobileNumber.message}</span>
                    )}
                </div>

                <div className="form-group col-md-6">
                    <label htmlFor="gender">Gender*</label>
                    <select
                        {...register('gender', {
                            value: employeeInfo.gender,
                            required: {
                                value: true,
                                message: 'Please select a valid gender',
                            },
                        })}
                        className=" form-control h-70"
                        id="gender"
                    >
                        <option value="" disabled>
                            Select Gender
                        </option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                    </select>
                    {errors.gender && <span style={{ color: 'red' }}>{errors.gender.message}</span>}
                </div>

                <div className="form-group col-md-6">
                    <label htmlFor="dateOfBirth">Date of Birth*</label>
                    <input
                        type="date"
                        className=" form-control h-70"
                        id="dateOfBirth"
                        {...register('dateOfBirth', {
                            value: employeeInfo.dob,
                            required: {
                                value: true,
                                message: 'Please select a valid date',
                            },
                        })}
                    />
                    {errors.dateOfBirth && (
                        <span style={{ color: 'red' }}>{errors.dateOfBirth.message}</span>
                    )}
                </div>

                <div className="form-group col-md-12">
                    <input type="text" className="w-100 form-control h-70" disabled />
                    <div className="fileUpload position-absolute top-50 translate-middle-y rounded-3 overflow-hidden">
                        <label htmlFor="profilePicture" className="w-100 h-100 cursor">
                            Choose Picture
                        </label>
                    </div>
                    <input
                        id="profilePicture"
                        {...register('profilePicture')}
                        className="f-input d-none"
                        type="file"
                        accept="image/*"
                    />
                </div>
            </div>
        </div>
    );
}

export default Step1;
