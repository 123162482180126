/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable prettier/prettier */
// eslint-disable-next-line prettier/prettier
import React, { useContext, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import Container from "react-bootstrap/Container";
import { Row, Modal, Form, Col, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import siteApi from "../../../api/siteApi";
import { AuthContext } from "../../../Context/AuthContext";
import "./PlanModal.scss";

function PlanModal({
  statusValue,
  handler,
  modalData,
  year,
  handleCloseModal,
  modalIsOpen,
  removeDataFromPlanModal,
  updateItemHandler,
}) {
  // id and year no from props
  const { id } = modalData[0];
  const { oid, isAdmin } = useContext(AuthContext);
  const { createdAt } = modalData[0];
  const [who, setWho] = useState("");
  const [companyType, setCompanyType] = useState("");
  const [priority, setPriority] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [description, setDescription] = useState("");
  const [trackStatus, setTrackStatus] = useState("");
  const [progress, setProgess] = useState("");

  const submitPlanModalData = (e) => {
    e.preventDefault();

    if (statusValue === "add") {
      const insertData = async () => {
        // add Data object
        const addData = {
          oid,
          Who: who,
          CompanyType: companyType,
          Priority: priority,
          StartDate: startDate,
          EndDate: endDate,
          Description: description,
          TrackStatus: trackStatus,
          Progress: progress,
          YearNo: year,
        };
        if (oid && year) {
          await siteApi.post(
            `https://vfyl6rvuwl.execute-api.us-east-2.amazonaws.com/dev/keypriority`,
            addData
          );
        }
      };
      insertData();
      removeDataFromPlanModal();
      toast("Inserted Successfully!");
      handleCloseModal();
    } else {
      // update Data Object
      const upddatedData = {
        Who: who === "" ? modalData[0].who : who,
        CompanyType:
          companyType === "" ? modalData[0].CompanyType : companyType,
        Priority: priority === "" ? modalData[0].Priority : priority,
        StartDate: startDate === "" ? modalData[0].StartDate : startDate,
        EndDate: endDate === "" ? modalData[0].EndDate : endDate,
        Description:
          description === "" ? modalData[0].Description : description,
        TrackStatus:
          trackStatus === "" ? modalData[0].TrackStatus : trackStatus,
        Progress: progress === "" ? modalData[0].Progress : progress,
      };
      const updateModalData = async () => {
        if (id && createdAt) {
          await siteApi.put(
            `https://vfyl6rvuwl.execute-api.us-east-2.amazonaws.com/dev/keypriority/${id}/${createdAt}`,
            upddatedData
          );
        }
      };
      updateModalData();
      upddatedData.id = id;
      updateItemHandler(upddatedData);
      toast("Updated Successfully!");
    }
  };
  return (
    <>
      <Container>
        <Modal
          className="yearplanmodal"
          show={modalIsOpen}
          onHide={() => {
            handleCloseModal();
            handler();
          }}
        >
          {isAdmin && (
            <Modal.Header onHide={handleCloseModal} onClick={handler}>
              <Modal.Title>Add Key Priority</Modal.Title>
              <Button variant="light" aria-label="Close">
                <AiOutlineClose />
              </Button>
            </Modal.Header>
          )}

          <Form className="pt-4" onSubmit={submitPlanModalData}>
            {modalData.length > 0
              ? // eslint-disable-next-line react/jsx-no-comment-textnodes
                // eslint-disable-next-line no-unused-vars
                modalData.map((input, index) => (
                  <div key={index}>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>Who*</Form.Label>

                          <Form.Control
                            id="who"
                            as="select"
                            disabled={!isAdmin}
                            defaultValue={input.who}
                            onChange={(e) => {
                              setWho(e.target.value);
                            }}
                            required
                          >
                            <option selected disabled value="">
                              Select
                            </option>
                            <option value=" SJ Innovation company">
                              SJ Innovation company
                            </option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>Type*</Form.Label>
                          <Form.Control
                            as="select"
                            id="Type"
                            disabled={!isAdmin}
                            required
                            defaultValue={input.CompanyType}
                            onChange={(e) => {
                              setCompanyType(e.target.value);
                            }}
                          >
                            <option selected disabled value="">
                              Select
                            </option>
                            <option value="Company">Company</option>
                            <option value="Team">Team</option>
                            <option value="Functional">Functional</option>
                            <option value="Personal">Personal</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>Key Priority*</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder=" Key Priority"
                            defaultValue={input.Priority}
                            disabled={!isAdmin}
                            onChange={(e) => {
                              setPriority(e.target.value);
                            }}
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>Start Date*</Form.Label>

                          <Form.Control
                            type="date"
                            defaultValue={input.StartDate}
                            disabled={!isAdmin}
                            onChange={(e) => {
                              setStartDate(e.target.value);
                            }}
                            required
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>End Date*</Form.Label>
                          <Form.Control
                            type="date"
                            required
                            defaultValue={input.EndDate}
                            disabled={!isAdmin}
                            onChange={(e) => {
                              setEndDate(e.target.value);
                            }}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>Description*</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Description"
                            defaultValue={input.Description}
                            disabled={!isAdmin}
                            onChange={(e) => {
                              setDescription(e.target.value);
                            }}
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>Status*</Form.Label>
                          <Form.Control
                            as="select"
                            placeholder="Status*"
                            disabled={!isAdmin}
                            required
                            defaultValue={input.TrackStatus}
                            onChange={(e) => {
                              setTrackStatus(e.target.value);
                            }}
                          >
                            <option selected disabled value="">
                              Select
                            </option>
                            <option value="Complete">Complete</option>
                            <option value="On track">On track</option>
                            <option value="Off track">Off track</option>
                            <option value="Incomplete">Incomplete</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>Progess*</Form.Label>
                          <Form.Control
                            as="select"
                            placeholder="Progess*"
                            disabled={!isAdmin}
                            required
                            defaultValue={input.Progress}
                            onChange={(e) => {
                              setProgess(e.target.value);
                            }}
                          >
                            <option selected disabled value="">
                              Select
                            </option>
                            <option value="0%">0%</option>
                            <option value="10%">10%</option>
                            <option value="20%">20%</option>
                            <option value="30%">30%</option>
                            <option value="40%">40%</option>
                            <option value="50%">50%</option>
                            <option value="60%">60%</option>
                            <option value="70%">70%</option>
                            <option value="80%">80%</option>
                            <option value="90%">90%</option>
                            <option value="99.99%">99.99%</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Button
                      className="mr-2"
                      variant="secondary"
                      onClick={() => {
                        handleCloseModal();
                        handler();
                      }}
                    >
                      Close
                    </Button>
                    {isAdmin && (
                      <Button variant="primary" type="submit">
                        Save Changes
                      </Button>
                    )}
                  </div>
                ))
              : ""}
          </Form>
        </Modal>
      </Container>
    </>
  );
}

export default PlanModal;
