/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-use-before-define */
/* eslint-disable object-shorthand */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-duplicates */
/* eslint-disable import/order */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-fragments */
/* eslint-disable react/button-has-type */
/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/label-has-associated-control */
import JSONDigger from "json-digger";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Spinner, Button, Row, Col, Container } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import toast, { Toaster } from "react-hot-toast";
import OrganizationChart from "../components/ChartContainer";
import "./edit-chart.scss";
import { AuthContext } from "../../../../Context/AuthContext";
import {
  getOrganizationChartNodes,
  addOrganizationChartNode,
  deleteOrganizationChartNode,
} from "../../../../api/organizationChart";

const EditChart = () => {
  const { oid } = useContext(AuthContext);
  const [ds, setDS] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(async () => {
    const result = await getOrganizationChartNodes(oid);
    if (result === undefined) {
      setDS([]);
    } else {
      setDS(result);
    }
    setLoading(false);
  }, [oid]);

  const orgchart = useRef();
  const now = new Date();
  const dsDigger = new JSONDigger(ds, "id", "children");
  const [selectedNodes, setSelectedNodes] = useState("");
  const [isEditMode, setIsEditMode] = useState(true);
  const readSelectedNode = (nodeData, id) => {
    addChildNodes(nodeData, id);
  };

  const clearSelectedNode = () => {
    setSelectedNodes(new Set());
  };

  const getNewNodes = (parentid) => {
    const nodes = {
      id: uuidv4(),
      oid: oid,
      parentID: parentid,
      designation: "",
      personName: "",
      photoURL: "",
      personRoles: ["Set responsbility"],
      tag: "",
      createdAt: now.toISOString(),
    };
    return nodes;
  };

  const addChildNodes = async (value, parentid) => {
    await dsDigger.addChildren(value.id, getNewNodes(parentid));
    const childData = getNewNodes(parentid);
    addOrganizationChartNode(childData);
    setDS({ ...dsDigger.ds });
  };

  // remove nodes
  const remove = async (datasource) => {
    if (datasource.id === ds.id) {
      setDS([]);
    }
    if (datasource) {
      deleteOrganizationChartNode(datasource.id, datasource.createdAt);
    }
    const filterData = new Set([datasource]);
    await dsDigger.removeNodes([...filterData].map((node) => node.id));
    setDS({ ...dsDigger.ds });
  };

  // initial Array to Tree
  const initialArrayToTree = (arr, parentID = 0) =>
    arr
      .filter((item) => item.parentID === parentID)
      .map((child) => ({
        ...child,
        children: initialArrayToTree(arr, child.id),
      }));

  // adding initial value to tree
  const initialValueChart = () => {
    const newData = [
      {
        id: uuidv4(),
        oid: oid,
        parentID: 0,
        designation: "",
        personName: "",
        photoURL: "",
        personRoles: ["Set responsbility"],
        tag: "",
        createdAt: now.toISOString(),
      },
    ];
    const initialRes = initialArrayToTree(newData);
    setDS(initialRes[0]);
    const updateResult = addOrganizationChartNode(newData[0]);
    if (updateResult) {
      toast.success(" New Person Added!", {
        duration: 2000,
        position: "bottom-center",
      });
    }
  };

  const updateIndividualNode = (nodeData) => {
    setSelectedNodes(nodeData);
  };
  const exportTo = (event) => {
    // eslint-disable-next-line no-use-before-define
    orgchart.current.exportTo("Organization Chart", event.target.value);
  };

  return (
    <section className="employee h-100">
      <Container fluid>
        <div className="row bg-white main-container p-4 rounded-border-12 min-vh-80 employee_list-table">
          {loading ? (
            <div className="d-flex justify-content-center">
              <Spinner className="rt-spinner" animation="grow" />
            </div>
          ) : ds.length === 0 ? (
            <Button
              className="position-absolute start-50 top-50 translate-middle click-add-btn"
              onClick={initialValueChart}
            >
              Click to add
            </Button>
          ) : (
            <>
              <div className="row">
                <div className="col-md-2 col-sm-0">{/* <Sidebar /> */}</div>
                <div className="col-md-10 col-sm-12 home-main">
                  <div className="row">
                    <div className="col-md-2" />
                    <div className="col-md-8" />
                    <div className="col-md-2">
                      <div className="dropdown">
                        <button
                          className="btn btn-sm dropdown-toggle"
                          type="button"
                          id="dropdownMenu2"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {/* <FontAwesomeIcon icon={faHdd} className="mr-0" /> */}
                          Export
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenu2"
                        >
                          <button
                            className="dropdown-item"
                            type="button"
                            value="pdf"
                            onClick={(event) => {
                              exportTo(event);
                            }}
                          >
                            PDF
                          </button>
                          <button
                            className="dropdown-item"
                            type="button"
                            value="png"
                            onClick={(event) => {
                              exportTo(event);
                            }}
                          >
                            PNG
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-2 col-sm-0 d-none">
                {/* <Sidebar /> */}
              </div>
              <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 home-main">
                <div className="edit-chart-wrapper">
                  <h5 className="header mx-3 mb-4">Organizational Chart</h5>
                  <OrganizationChart
                    ref={orgchart}
                    datasource={ds}
                    dataSourceLength={ds.length}
                    collapsible={!isEditMode}
                    onClickNode={readSelectedNode}
                    updateIndividualNode={updateIndividualNode}
                    onClickChart={clearSelectedNode}
                    selectedNode={selectedNodes}
                    initialValueChart={initialValueChart}
                    remove={remove}
                    // eslint-disable-next-line react/jsx-boolean-value
                    draggable={true}
                    // eslint-disable-next-line react/jsx-boolean-value
                    pan={true}
                    // eslint-disable-next-line react/jsx-boolean-value
                    zoom={false}
                  />
                </div>
              </div>
            </>
          )}
          <Toaster />
        </div>
      </Container>
    </section>
  );
};

export default EditChart;
