/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-no-constructed-context-values */
import { Auth } from 'aws-amplify';
import React, { createContext, useState } from 'react';

 const AuthContext = createContext();

 const AuthProvider = (props) => {
    const [logged,setLogged] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [eid, setEid] = useState(null);
    const [oid, setOid] = useState();
    const [refresh, setRefresh]= useState(false);

    Auth.currentSession()
    .then((data) => {
        const idToken = data.getIdToken();
        const { email } = idToken.payload;
        setLogged(email,data);
    })
    .catch((err) => console.log(err));

    Auth.currentAuthenticatedUser()
    .then(data =>{
        setOid(data.attributes["custom:CompanyName"])
        setEid(data.username)
        if(!data.signInUserSession.accessToken.payload['cognito:groups']){
            setIsAdmin(false)
            setIsSuperAdmin(false)

        }
        else if(data.signInUserSession.accessToken.payload['cognito:groups'][0] === "Admin"){
            setIsAdmin(true)
        }
        else if(data.signInUserSession.accessToken.payload['cognito:groups'][0] === "SuperAdmin"){
            setIsSuperAdmin(true)
        }
        else{
            setIsAdmin(false)
            setIsSuperAdmin(false)
        }
        
    })

    const values = {eid,isAdmin,logged,isSuperAdmin,oid,refresh,setRefresh}

    // eslint-disable-next-line react/destructuring-assignment
    return (<AuthContext.Provider value={values}>
              {props.children}
        </AuthContext.Provider>);
};


export { AuthContext, AuthProvider };

