/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Form from '../Common/Form';
import { addOrgData } from '../../api/superAdmin';
import { addOrganizationUrl } from '../../api/superAdminApiUrlConstants';

const AddOrganization = () => {
    const [formData, setFromData] = useState({});
    const [loading, setLoading] = useState(false);
    const [phoneWarning, setPhoneWarning] = useState(false);
    const [websiteWarning, setWebsiteWarning] = useState(false);
    const history = useHistory();

    const formFields = [
        { label: 'Company Name', name: 'companyName', type: 'text', mandatory: true },
        { label: 'Billing Address', name: 'billingAddress', type: 'text', mandatory: true },
        { label: 'Office Phone Number', name: 'OfficePhoneNumber', type: 'text', mandatory: true },
        { label: 'Company Size', name: 'companySize', type: 'text', mandatory: true },
        { label: 'Website', name: 'website', type: 'text', mandatory: true },
    ];

    const handleAddOrganization = async (e) => {
        if (phoneWarning === true || websiteWarning === true) {
            return;
        }
        setLoading(true);
        const url = addOrganizationUrl;
        const result = await addOrgData(url, formData);
        if (result) {
            toast.success('You have successfully created an Organization!');
            setTimeout(() => {
                history.push('/org/list');
            }, 1500);
        }
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-10">
                    <h1 className="pl-4 text-dark pb-3">Create An Organization</h1>

                    <div className="admin-list-table">
                        <Form
                            formFields={formFields}
                            formData={formData}
                            setFromData={setFromData}
                            loading={loading}
                            phoneWarning={phoneWarning}
                            setPhoneWarning={setPhoneWarning}
                            websiteWarning={websiteWarning}
                            setWebsiteWarning={setWebsiteWarning}
                            handleAddOrgSubmit={handleAddOrganization}
                        />
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default AddOrganization;
