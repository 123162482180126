/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useContext } from "react";
import { faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
// import siteApi from "../../../api/siteApi";
import { AuthContext } from "../../../Context/AuthContext";
import "../YearPlan/YearPlan.scss";
function PlanGoal({ yearNo }) {
  const [addValueGoal, setAddValueGoal] = useState(false);
  const [updateTrackState, setUpdateTrackState] = useState(false);
  const [goalData, setGoalData] = useState([]);
  const { oid, isAdmin } = useContext(AuthContext);

  // handle input change
  const handleGoalChange = (e, index) => {
    const { name, value, checked } = e.target;
    const list = [...goalData];
    if (name === "GoalFlag") {
      list[index][name] = checked;
      setGoalData(list);
    } else {
      list[index][name] = value;
      setGoalData(list);
    }
  };

  //  goal add
  // eslint-disable-next-line no-shadow
  const addPlanGoal = async (id) => {
    const res = goalData?.filter((data) => data.id === id);
    if (res) {
      res.forEach((i) => {
        // add Data
        const addData = {
          id: i.id,
          GoalFlag: i.GoalFlag,
          Goal: i.Goal,
          oid,
          createdAt: i.createdAt,
          YearNo: yearNo,
        };
        axios.post(
          `https://7u5ubsboy1.execute-api.us-east-2.amazonaws.com/dev/goal`,
          addData
        );
        toast("Created Successfully");
        setAddValueGoal(false);
        const temp = [...goalData];
        setGoalData([]);
        setTimeout(() => {
          setGoalData(temp);
        }, 0.3);
      });
    }
  };

  // goal update
  const updateGoal = (GoalFlagValue, id, goalValue) => {
    if (updateTrackState === true) {
      const insertData = async () => {
        if (id) {
          await axios.put(
            `https://7u5ubsboy1.execute-api.us-east-2.amazonaws.com/dev/goal/${id}`,
            {
              GoalFlag: GoalFlagValue,
              Goal: goalValue,
            }
          );
        }
      };
      insertData();
      toast("Updated");
      setUpdateTrackState(false);
    }
  };

  // goal delete
  // eslint-disable-next-line no-shadow
  const removeGoal = async (event, id) => {
    toast("Deleted Successfully");
    const res = goalData?.filter((data) => data.id !== id);
    setGoalData([]);
    setTimeout(() => {
      setGoalData([...res]);
    }, 0.1);
    await axios.delete(
      `https://7u5ubsboy1.execute-api.us-east-2.amazonaws.com/dev/goal/${id}`
    );
  };
  // add new goal
  const addGoal = () => {
    setGoalData([
      ...goalData,
      {
        Goal: "",
        GoalFlag: "",
        id: uuidv4(),
        createdAt: new Date().toString(),
        oid,
        YearNo: yearNo,
      },
    ]);
  };
  useEffect(() => {
    if (oid && yearNo) {
      // Goal get api calling
      axios
        .get(
          `https://7u5ubsboy1.execute-api.us-east-2.amazonaws.com/dev/${oid}/${yearNo}/goal`
        )
        .then((res) => {
          const data = res ? res.data : "loading";
          setGoalData(data);
        });
    }
  }, [oid, yearNo]);
  return (
    <>
      {/* goal section */}
      {goalData.length > 0
        ? goalData.map((item, index) => (
            <div className="row d-flex">
              <div className="col checkbox-plan-left">
                <input
                  className="form-check-input plan-check"
                  type="checkbox"
                  id="flexCheckDefault"
                  disabled={!isAdmin}
                  name="GoalFlag"
                  onBlur={(e) => {
                    handleGoalChange(e, index);
                    if (addValueGoal === true) {
                      addPlanGoal(item.id);
                    } else {
                      updateGoal(e.target.checked, item.id, item.Goal);
                    }
                  }}
                  onClick={(e) => {
                    if (addValueGoal !== true) {
                      setUpdateTrackState(true);
                    }
                  }}
                  defaultChecked={item.GoalFlag}
                />
                <input
                  className="text-margin-plan-left2"
                  type="text"
                  disabled={!isAdmin}
                  name="Goal"
                  onChange={(e) => {
                    handleGoalChange(e, index);
                    setUpdateTrackState(true);
                  }}
                  onBlur={(e) => {
                    if (addValueGoal === true) {
                      addPlanGoal(item.id);
                    } else {
                      updateGoal(item.GoalFlag, item.id, e.target.value);
                    }
                  }}
                  defaultValue={item.Goal}
                />
                <ToastContainer />
                {isAdmin && (
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={(event) => removeGoal(event, item.id)}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} className="mr-0" />
                  </button>
                )}
              </div>
            </div>
          ))
        : ""}
      <br />

      {isAdmin && (
        <button
          type="button"
          className="btn btn-light plan-add-button-left mb-3"
          onClick={() => {
            setAddValueGoal(true);
            addGoal();
          }}
        >
          <FontAwesomeIcon icon={faPlus} className="mr-0" /> ADD
        </button>
      )}
    </>
  );
}

export default PlanGoal;
