/* eslint-disable react/no-unknown-property */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import { React, useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { BsPlusCircleFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { allTaskWorkStream, deleteTaskStream } from '../../api/taskStream';
import { AuthContext } from '../../Context/AuthContext';
import Pagination from '../Common/Pagination/Pagination';
import TaskStreamTable from './TaskStreamTable';

const TaskStreamList = () => {
    const { isAdmin, oid } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(5);
    const [workStreamListData, setWorkStreamList] = useState([]);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPOst = indexOfLastPost - postsPerPage;
    const paginate = (pageNum) => setCurrentPage(pageNum);
    const nextPage = () => setCurrentPage(currentPage + 1);
    const prevPage = () => setCurrentPage(currentPage - 1);

    const handleDeleteWorkStream = async (id) => {
        const deleteWorkStreamResponse = await deleteTaskStream(id);
        const filterWorkStream = workStreamListData.filter((cp) => cp.id !== id);
        if (deleteWorkStreamResponse) {
            toast.success('Deleted Successfully');
        }
        setWorkStreamList(filterWorkStream);
    };

    useEffect(() => {
        const getEmployeeData = async () => {
            const response = await allTaskWorkStream(oid);
            const currentPosts =
                response?.length > 0 && response?.slice(indexOfFirstPOst, indexOfLastPost);

            setWorkStreamList(currentPosts);
            if (response) {
                setLoading(false);
            }
        };
        getEmployeeData();
    }, [oid, !workStreamListData]);

    return (
        <div className="employee h-100">
            <Container fluid>
                <Row>
                    <Col lg={12} md={10} sm={12}>
                        <div className="employee_list-top d-flex justify-content-between align-items-center pb-4">
                            <h5 className="section-header ml-3 mb-0">Task Streams</h5>
                            {isAdmin && (
                                <>
                                    <Link to="/taskStream/add">
                                        <Button className="add-btn text-white cursor border-0">
                                            <BsPlusCircleFill className="icon text-white mr-2" />{' '}
                                            Add Task Stream
                                        </Button>
                                    </Link>
                                </>
                            )}
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className="employee_list-table bg-white main-container p-4 rounded-border-12 min-vh-80 d-flex flex-column">
                            {loading ? (
                                <div className="d-flex justify-content-center">
                                    <Spinner className="rt-spinner" animation="grow" />
                                </div>
                            ) : workStreamListData.length === 0 ? (
                                <Button className="position-absolute start-50 top-50 translate-middle click-add-btn">
                                    <Link to="/workStream/add">Click to add</Link>
                                </Button>
                            ) : (
                                <>
                                    {/* <div className="d-flex justify-content-center">
                                        {loading === true ? (
                                            <Spinner className="rt-spinner" animation="grow" />
                                        ) : (
                                            ''
                                        )}
                                    </div> */}

                                    <div className="text-right" />
                                    <div id="tableExample" className="flex-grow-1">
                                        <div>
                                            <table className="table bg-white iPhoneXtB pixlTb laptoptbl">
                                                <thead className="border-bottom-0 text-center">
                                                    <tr>
                                                        <th
                                                            className="sort border-0"
                                                            data-sort="name"
                                                        >
                                                            Task Stream Name
                                                        </th>
                                                        <th
                                                            className="sort border-0"
                                                            data-sort="name"
                                                        >
                                                            Add User To Task Stream
                                                        </th>

                                                        {isAdmin && (
                                                            <th
                                                                className="sort border-0"
                                                                data-sort="age"
                                                            >
                                                                Action
                                                            </th>
                                                        )}
                                                    </tr>
                                                </thead>
                                                <tbody className="list text-center">
                                                    {workStreamListData.length > 0 &&
                                                        workStreamListData?.map((item) => (
                                                            <TaskStreamTable
                                                                key={item.id}
                                                                // eslint-disable-next-line prettier/prettier
                                                                item={item}
                                                                deleteWorkStream={
                                                                    handleDeleteWorkStream
                                                                }
                                                                isAdmin={isAdmin}
                                                                // setLoader={setLoader}
                                                            />
                                                        ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <Pagination
                                            postsPerPage={postsPerPage}
                                            totalPosts={workStreamListData.length}
                                            paginate={paginate}
                                            nextPage={nextPage}
                                            prevPage={prevPage}
                                            currentPage={currentPage}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default TaskStreamList;
