/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useEffect, useState } from "react";
import { Button, Form, Row, Col, Container } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  ProcessDocumentationById,
  ProcessDocumentationUpdate,
} from "../../api/processDocumentation";

const EditModal = () => {
  const [editTitle, setEditTitle] = useState("");
  const [editGroup, setEditGroup] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const { id, createdAt } = useParams();

  const history = useHistory();

  useEffect(() => {
    const retrieveProcessDetails = async () => {
      const response = await ProcessDocumentationById(id, createdAt);
      setEditTitle(response.ProcessName);
      setEditGroup(response.Category);
      setEditDescription(response.Description);
    };

    retrieveProcessDetails();
  }, [id, createdAt]);

  const handleSubmit = (e) => {
    const insertData = async () => {
      const data = {
        ProcessName: editTitle,
        Description: editDescription,
        Category: editGroup,
      };
      await ProcessDocumentationUpdate(id, createdAt, data);
    };
    toast("Updated Successfully!");

    insertData();

    e.preventDefault();

    history.push("/processDocumentation");
  };

  return (
    <Container className="pb-4 fluid">
      <Col lg={12}>
        <div className="d-flex justify-content-end">
          <ToastContainer />
          <Button
            onClick={() => history.push("/processDocumentation")}
            className="btn-rt-primary my-3"
            type="button"
          >
            Back
          </Button>
        </div>
        <div className="rt-add-process-doc">
          <Form className="p-4" onSubmit={handleSubmit}>
            <Row>
              <Col lg={12}>
                <Form.Group className="mb-4">
                  <Form.Label className="ml-2">Title</Form.Label>
                  <Form.Control
                    value={editTitle}
                    name="title"
                    onChange={(e) => setEditTitle(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group className="mb-4">
                  <Form.Label className="ml-2">Category</Form.Label>
                  <Form.Control
                    type="text"
                    value={editGroup}
                    name="title"
                    onChange={(e) => setEditGroup(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group className="mb-4">
                  <Form.Label className="ml-2">Description</Form.Label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={editDescription}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setEditDescription(data);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col lg={2}>
                <Button
                  variant="primary"
                  className="btn-rt-primary"
                  type="submit"
                >
                  Update
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Col>
    </Container>
  );
};

export default EditModal;
