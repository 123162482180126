/* eslint-disable consistent-return */
/* eslint-disable no-alert */
/* eslint-disable import/prefer-default-export */
import axios from 'axios'
// import siteApi from './siteApi'

const meetingNotification = process.env.REACT_APP_MEETINGNOTIFICATION_BASE_URL

const addNotification = async formData => {
  const response = await axios.post(
    `${meetingNotification}/meetingnotification`,
    formData
  )
  return response
}

const notificationList = async currentUser => {
  const email = currentUser
  const response =
    await axios.get(`${meetingNotification}/${email}/meetingnotification
`)
  return response?.data
}

export { addNotification, notificationList }
