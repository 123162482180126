/* eslint-disable import/no-duplicates */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable import/newline-after-import */
/* eslint-disable arrow-body-style */
/* eslint-disable import/no-named-as-default-member */

import React from "react";
import BadgeModal from "./BadgeModal/BadgeModal";
import "./Badges.scss";
import Earned from "./Earned/Earned";
import Given from "./Given/Given";

import { useState } from "react";
import { Accordion, Button, Col, Container, Row } from "react-bootstrap";
import { Toaster } from "react-hot-toast";
import { FaPlusCircle } from "react-icons/fa";

const Badges = () => {
  const [show, setShow] = useState(false);
  const [insertData, setInsertData] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getInsertData = (value) => {
    setInsertData([value]);
  };

  return (
    <>
      <Container fluid>
        <div className="module-badge">
          <Row>
            <Col className="d-flex justify-content-center align-items-end flex-column">
              <Button
                data-toggle="modal"
                data-target="#exampleModalLong"
                onClick={handleShow}
                className="d-flex align-items-center custom-btn badge-btn bg-brand"
              >
                <FaPlusCircle className="mr-2" />
                Give Praise
              </Button>
              <BadgeModal
                show={show}
                setShow={setShow}
                handleClose={handleClose}
                handleShow={handleShow}
                getInsertData={getInsertData}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Earned</Accordion.Header>
                  <Accordion.Body>
                    <Earned />
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Given</Accordion.Header>
                  <Accordion.Body>
                    <Given insertData={insertData} />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
        </div>
        <Toaster />
      </Container>
    </>
  );
};

export default Badges;
