/* eslint-disable react/jsx-boolean-value */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import TaskViewModal from './TaskViewModal';
import { getSingleTask } from '../../../api/task';

const TaskView = () => {
    const [modalShow, setModalShow] = useState(true);
    const [singleTaskData, setSingleTaskData] = useState({});
    const { id } = useParams();
    useEffect(() => {
        const getSingleTaskData = async () => {
            const singleTaskResponse = await getSingleTask(id);
            setSingleTaskData(singleTaskResponse);
        };
        getSingleTaskData();
    }, [id]);
    return (
        <div>
            <TaskViewModal
                show={modalShow}
                taskData={singleTaskData}
                onHide={() => setModalShow(false)}
            />
        </div>
    );
};

export default TaskView;
