/* eslint-disable react/no-array-index-key */
import React, { useEffect, useContext, useState } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { Row, Col, Button, Container, Form } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import { AuthContext } from '../../Context/AuthContext';
import MeetingModal from '../MettingNew/Meeting/MeetingModal';
import { getAssigendStreams, insertAssignWorkStream } from '../../api/workStream';
import { getAllEmployeeMergedData } from '../../api/employee';

const AssignedWorkStreamAdd = () => {
    const { workStreamId } = useParams();
    const history = useHistory();
    const { oid } = useContext(AuthContext);
    const [allEmployeeData, setAllEmployeeData] = useState([]);
    const [selectedUser, setSelectedUser] = useState([]);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleCancel = () => {
        setShow(false);
        setSelectedUser([]);
    };

    const handleAddNewUser = (value) => {
        setSelectedUser(value);
    };

    const removeUser = (id) => {
        const res = selectedUser.filter((data, index) => index !== id);
        setSelectedUser(res);
    };

    useEffect(() => {
        const getEmployeeNameEmail = async () => {
            const employeeData = await getAllEmployeeMergedData(oid);
            const workStreamData = await getAssigendStreams(oid, workStreamId);

            const filteredData = employeeData?.data?.filter((el) =>
                workStreamData?.every((item) => item.eid !== el.eid)
            );

            if (filteredData?.length > 0) {
                const formattedUserEmail = filteredData?.map((item) =>
                    item.emailAddress ? item.emailAddress : item.adminEmail
                );

                const formattedName =
                    filteredData?.length > 0 &&
                    filteredData?.map((i) =>
                        i.displayName
                            ? i.displayName
                            : i.adminFirstName.concat(` ${i.adminLastName}`)
                    );

                const profilePicture =
                    filteredData?.length > 0 &&
                    filteredData?.map((i) =>
                        i.ProfilePicture ? i.ProfilePicture : i.profilePicture
                    );
                const eid = filteredData.length > 0 && filteredData?.map((i) => i.eid);

                const result = formattedName?.map((value, index) => ({
                    name: value,
                    email: formattedUserEmail[index],
                    profile: profilePicture[index],
                    eid: eid[index],
                }));

                setAllEmployeeData(result);
            } else {
                const formattedUserEmail = employeeData?.data?.map((item) =>
                    item.emailAddress ? item.emailAddress : item.adminEmail
                );

                const formattedName =
                    employeeData?.data?.length > 0 &&
                    employeeData?.data?.map((i) =>
                        i.displayName
                            ? i.displayName
                            : i.adminFirstName.concat(` ${i.adminLastName}`)
                    );

                const profilePicture =
                    employeeData?.data?.length > 0 &&
                    employeeData?.data?.map((i) =>
                        i.ProfilePicture ? i.ProfilePicture : i.profilePicture
                    );
                const eid = employeeData?.data?.length > 0 && employeeData?.data?.map((i) => i.eid);

                const result = formattedName?.map((value, index) => ({
                    name: value,
                    email: formattedUserEmail[index],
                    profile: profilePicture[index],
                    eid: eid[index],
                }));

                setAllEmployeeData(result);
            }
        };
        getEmployeeNameEmail();
    }, [oid, workStreamId]);

    const assgineUserToWorkStream = (e) => {
        e.preventDefault();

        let success = 0;

        selectedUser.map(async (item) => {
            const insertData = {
                oid,
                workStreamId,
                eid: item.eid,
                displayName: item.name,
                email: item.email,
                profilePicture: item.profile,
            };
            const result = await insertAssignWorkStream(insertData);
            if (result) {
                success = 200;
            }
        });

        if (success) {
            toast.success('User Added To Work Stream');
            history.push('/workStream/list');
        }
    };

    return (
        <>
            <Container fluid>
                <div className="bg-white rounded p-4 create-meeting">
                    <h2 className="py-3 title">Assign User To WorkStream</h2>
                    <Row>
                        <Col lg={12}>
                            <Form onSubmit={assgineUserToWorkStream}>
                                <Row>
                                    <Col lg={6}>
                                        <Form.Group className="mb-2">
                                            <button
                                                type="button"
                                                data-toggle="modal"
                                                data-target="#exampleModalLong"
                                                onClick={handleShow}
                                                className="form-control create-meeting attendees-select"
                                            >
                                                Select Users
                                            </button>
                                            <MeetingModal
                                                show={show}
                                                handleClose={handleClose}
                                                options={allEmployeeData}
                                                handleAddNewUserImage={handleAddNewUser}
                                                handleCancel={handleCancel}
                                            />
                                        </Form.Group>
                                        <Form.Group
                                            className="mb-3 pl-4 custom-margin"
                                            controlId="attendees"
                                        >
                                            {selectedUser.length > 0 &&
                                                selectedUser?.map((item, index) => (
                                                    <div className="guest-list" key={index}>
                                                        <div className="g-list-item">
                                                            <div className="g-name">
                                                                <img
                                                                    src={
                                                                        item.profile !== ''
                                                                            ? item.profile
                                                                            : 'https://i.ibb.co/vVfrXgh/Group-302.png'
                                                                    }
                                                                    alt="Profile"
                                                                />
                                                                <p>{item.name}</p>
                                                            </div>
                                                            <FaTrash
                                                                className="mx-3 rt-denger"
                                                                onClick={() => removeUser(index)}
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} className="text-center mt-5">
                                        <Link to="/workStream/list">
                                            <Button size="lg" variant="outline-primary mr-4">
                                                Back
                                            </Button>
                                        </Link>
                                        <Button
                                            size="lg"
                                            variant="primary"
                                            type="submit"
                                            className="ml-2"
                                            // onClick={() => {
                                            //     SentMailToUser();
                                            // }}
                                        >
                                            Save
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    );
};

export default AssignedWorkStreamAdd;
