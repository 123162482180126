/* eslint-disable prettier/prettier */
/* eslint-disable import/prefer-default-export */
/* eslint-disable consistent-return */

import axios from "axios";
import siteApi from "./siteApi";

const badgeApi = process.env.REACT_APP_PRAISE_BASE_URL;
const getGivenData = async (oid) => {
  if (oid) {
    const result = await axios.get(`${badgeApi}/givenBadge/${oid}`);
    return result;
  }
};

const getEarnData = async (oid) => {
  if (oid) {
    const result = await axios.get(`${badgeApi}/${oid}/EarnBadge`);
    return result;
  }
};

const EarnModalData = async (tripType, item, text, oid, type, logged) => {
  const result = await axios.post(`${badgeApi}/createEarnBadge`, {
    EarnImage: tripType,
    EarnEmail: item,
    EarnComment: text,
    oid,
    EarnType: type,
    badgeEarnFrom: logged,
  });
  return result;
};

const GivenModalData = async (
  id,
  tripType,
  emailSelect,
  text,
  oid,
  type,
  logged,
  createdAt
) => {
  const result = await axios.post(`${badgeApi}/createGivenBadge`, {
    id,
    GivenImage: tripType,
    GivenEmail: emailSelect,
    GivenComment: text,
    oid,
    GivenType: type,
    badgeGiveFrom: logged,
    createdAt,
  });
  return result?.data;
};

const SendEmailData = async (
  loggedUserName,
  text,
  from,
  badge,
  FromReteamnow,
  item
) => {
  const result = await siteApi.post("/BadgeEmail", {
    name: loggedUserName,
    reason: text,
    to: item,
    from,
    badge,
    text: FromReteamnow,
  });
  return result;
};

export {
  SendEmailData,
  getGivenData,
  getEarnData,
  EarnModalData,
  GivenModalData,
};
