/* eslint-disable prefer-template */
/* eslint-disable prettier/prettier */
/* eslint-disable react/button-has-type */
function ProgressBar(props) {
    const { count } = props;
    return (
        <ul id="progressbar">
            <li className={"d-flex align-items-center gap-3 " + (count === 1 ? 'active' : 'inactive')} id="step1">
                <strong>Personal Info</strong>
            </li>
            <li className={"d-flex align-items-center gap-3 " + (count === 2 ? 'active' : 'inactive')} id="step2">
                <strong>Official Info</strong>
            </li>
            <li className={"d-flex align-items-center gap-3 " + (count === 3 ? 'active' : 'inactive')} id="step3">
                <strong>Other Info</strong>
            </li>
        </ul>
    );
}

export default ProgressBar;
