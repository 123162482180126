/* eslint-disable import/order */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable prettier/prettier */
import React, { useRef } from "react";
import OrganizationChart from "../components/ChartContainer";
// eslint-disable-next-line import/newline-after-import
// eslint-disable-next-line import/order, import/newline-after-import
// import { faHdd } from '@fortawesome/free-solid-svg-icons';
// eslint-disable-next-line import/newline-after-import
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const DefaultChart = () => {
  const orgchart = useRef();
  // const ds = {
  //   id: "n1",
  //   name: "Lao Lao",
  //   title: "general manager",
  //   children: [
  //     { id: "n2", name: "Bo Miao", title: "department manager" },
  //     {
  //       id: "n3",
  //       name: "Su Miao",
  //       title: "department manager",
  //       children: [
  //         { id: "n4", name: "Tie Hua", title: "senior engineer" },
  //         {
  //           id: "n5",
  //           name: "Hei Hei",
  //           title: "senior engineer",
  //           children: [
  //             { id: "n6", name: "Dan Dan", title: "engineer" },
  //             { id: "n7", name: "Xiang Xiang", title: "engineer" }
  //           ]
  //         },
  //         { id: "n8", name: "Pang Pang", title: "senior engineer" }
  //       ]
  //     },
  //     { id: "n9", name: "Hong Miao", title: "department manager" },
  //     {
  //       id: "n10",
  //       name: "Chun Miao",
  //       title: "department manager",
  //       children: [{ id: "n11", name: "Yue Yue", title: "senior engineer" }]
  //     }
  //   ]
  // };

  const entries = [
    {
      id: 2,
      name: "babul",
      title: "manager",
      parent: 1,
    },
    {
      id: 3,
      name: "kabul",
      title: "Senior Labor",
      parent: 1,
    },
    {
      id: 4,
      name: "bulbul",
      title: "Labor",
      parent: 1,
    },
    {
      id: 5,
      name: "Hossain",
      title: "Labor",
      parent: 3,
    },
    {
      id: 1,
      name: "abul",
      title: "department manager",
      parent: 0,
    },
  ];

  const arrayToTree = (arr, parent = 0) =>
    arr
      .filter((item) => item.parent === parent)
      .map((child) => ({ ...child, children: arrayToTree(arr, child.id) }));

  const result = arrayToTree(entries);
  const exportTo = (event) => {
    // eslint-disable-next-line no-use-before-define
    orgchart.current.exportTo("Organization Chart", event.target.value);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-2 col-sm-0">{/* <Sidebar /> */}</div>

        <div className="col-md-10 col-sm-12 home-main">
          <div className="row">
            <div className="col-md-2" />
            <div className="col-md-8" />
            <div className="col-md-2">
              <div className="dropdown">
                <button
                  className="btn btn-sm dropdown-toggle"
                  type="button"
                  id="dropdownMenu2"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {/* <FontAwesomeIcon icon={faHdd} className="mr-0" /> */}
                  Export
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                  <button
                    className="dropdown-item"
                    type="button"
                    value="pdf"
                    onClick={(event) => {
                      exportTo(event);
                    }}
                  >
                    PDF
                  </button>
                  <button
                    className="dropdown-item"
                    type="button"
                    value="png"
                    onClick={(event) => {
                      exportTo(event);
                    }}
                  >
                    PNG
                  </button>
                </div>
              </div>
            </div>
          </div>

          <OrganizationChart ref={orgchart} datasource={result[0]} />
        </div>
      </div>
    </>
  );
};

export default DefaultChart;
