/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/no-array-index-key */
/* eslint-disable prettier/prettier */

import React, { useContext, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "../../../../Context/AuthContext";
import "../ClosedMeetingDetails.scss";
import { closeTeamMeetingAgenda } from "../../../../api/closeMeeting";
function ClosedMeetingAgenda({ paramsId }) {
  const { oid } = useContext(AuthContext);
  const [closedMeetingAgenda, setClosedMeetingAgenda] = useState([]);
  console.log(paramsId);
  useEffect(async () => {
    if (oid && paramsId) {
      // Closed Meeting agenda get api calling
      const result = await closeTeamMeetingAgenda(oid, paramsId);
      console.log("Test Close Meeting Agenda", result);
      setClosedMeetingAgenda(result);
    }
  }, [oid, paramsId]);

  return (
    <Container fluid>
      <div className="bg-white rounded p-3 metting-detail">
        <Row>
          <Col>
            <div className="mb-4"></div>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <p className="pl-5">Topics for Discussion</p>
          </Col>
          <Col lg={6}>
            <p className="pl-5">Takeways</p>
          </Col>
        </Row>

        {/* closed meeting agenda data showing */}
        {closedMeetingAgenda.length > 0
          ? closedMeetingAgenda.map((input, index) => (
              <>
                <Row key={index}>
                  <Col lg={12} className="p-4">
                    <div className="agenda-item">
                      {/* timer */}
                      <span className="agenda-timer">
                        <span className="at"> {input.timer}</span>
                      </span>
                      {/* topic name */}
                      <div className="topic">
                        <span>{index + 1}</span>
                        <input
                          type="text"
                          placeholder="Add topics here"
                          name="topicName"
                          value={input.topicName}
                          disabled
                        />
                      </div>
                      {/* take away */}
                      <span className="hr-line">|</span>
                      <div className="tekeway">
                        <input
                          type="text"
                          placeholder="Add takeways here"
                          name="takeAway"
                          value={input.takeAway}
                          disabled
                        />
                      </div>
                      <div className="pr-5 mod"></div>
                    </div>
                  </Col>
                </Row>
              </>
            ))
          : ""}
      </div>
    </Container>
  );
}

export default ClosedMeetingAgenda;
