/* eslint-disable prettier/prettier */
/* eslint-disable import/no-named-as-default-member */
import Amplify from "aws-amplify";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import config from "./aws-exports";
import "./common.scss";
import { AuthProvider } from "./Context/AuthContext";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";

Amplify.configure(config);
ReactDOM.render(
  <>
    <AuthProvider>
      <App />
    </AuthProvider>
  </>,
  document.getElementById("root")
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
