/* eslint-disable arrow-body-style */
/* eslint-disable consistent-return */
/* eslint-disable prettier/prettier */
/* eslint-disable import/prefer-default-export */
import axios from "axios";
import siteApi from "./siteApi";

const teamMeeting = process.env.REACT_APP_TEAMMEETINGMODULE_BASE_URL;
const createTeamMeetingMail =
  process.env.REACT_APP_CREATETEAMMEETINGTEMPLATE_BASE_URL;
const endTeamMeetingEmail =
  process.env.REACT_APP_ENDTEAMMEETINGTEMPLATE_BASE_URL;

const teamMeetingAgenda = process.env.REACT_APP_TEAMMEETINGAGENDA_BASE_URL;

const teamMeetingAgendaAttachment =
  process.env.REACT_APP_TEAMMEETINGAGENDAATTACHMENT_BASE_URL;

const closeMeeting = process.env.REACT_APP_CLOSEMEETING_BASE_URL;

const closeMeetingAgenda = process.env.REACT_APP_CLOSEMEETINGAGENDA_BASE_URL;

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Credentials": true,
};

const createMeeting = async (insertData) => {
  const result = await axios.post(`${teamMeeting}/teammeeting`, insertData, {
    headers,
  });
  return result.data;
};

const sendMail = async (insertData) => {
  const result = await axios.post(`${createTeamMeetingMail}`, insertData);
  return result;
};

const AllEmployee = async (oid) => {
  if (oid) {
    const result = await siteApi.get(`/adminInfo/allAdmin&Employee/${oid}`);
    return result;
  }
};

const removeAgenda = async (id, createdAt) => {
  if (id && createdAt) {
    const result = await axios.delete(
      `${teamMeetingAgenda}/teammeetingagenda/${id}/${createdAt}`
    );
    return result;
  }
};

const addAgenda = async (insertValue) => {
  const result = axios.post(
    `${teamMeetingAgenda}/teammeetingagenda`,
    insertValue
  );
  return result;
};

const updateAgenda = async (id, createdAt, updateData) => {
  const result = await axios.put(
    `${teamMeetingAgenda}/teammeetingagenda/${id}/${createdAt}`,
    updateData
  );
  return result;
};

const endMeetingNotification = async (notificationValue) => {
  const result = await axios.post(
    `${endTeamMeetingEmail}
`,
    notificationValue
  );
  return result;
};

const closeTeamMeetingAdd = (insertValue) => {
  const result = axios.post(`${closeMeeting}/closeteammeeting`, insertValue);
  return result;
};

const closeMeetingAgendaAdd = (insertData) => {
  const result = axios.post(
    `${closeMeetingAgenda}/closeteammeetingagenda`,
    insertData
  );
  return result;
};

const deleteMeeting = async (id, createdAt) => {
  const result = await axios.delete(
    `${teamMeeting}/teammeeting/${id}/${createdAt}`
  );
  return result;
};

const getMeetingAgenda = async (oid, paramsId) => {
  const result = axios
    .get(`${teamMeetingAgenda}/${oid}/${paramsId}/teammeetingagenda`)
    .then((res) => {
      return res.data;
    });
  return result;
};

const getMeetingAgendaBasedOnDate = async (oid, paramsId, meetingDate) => {
  const result = axios
    .get(
      `${teamMeetingAgenda}/teammeetingagendabasedondate/${oid}/${paramsId}/${meetingDate}`
    )
    .then((res) => {
      return res.data;
    });
  return result;
};

const updateTeamMeeting = async (id, createdAt, updateData) => {
  const result = await axios.put(
    `${teamMeeting}/teammeeting/${id}/${createdAt}`,
    updateData
  );
  return result;
};

const getMeetingData = async (oid, paramsId) => {
  if (oid && paramsId) {
    const result = await axios
      .get(`${teamMeeting}/singleteammeeting/${oid}/${paramsId}`)
      .then((res) => {
        return res.data;
      });
    return result;
  }
};

const teamMeetingMain = (oid) => {
  if (oid) {
    const result = axios
      .get(`${teamMeeting}/${oid}/teammeeting`)
      .then((res) => {
        return res;
      });
    return result;
  }
};

const createMeetingDate = async (insertData) => {
  const result = await siteApi.post("/ActiveMeetingDate/add/", insertData);
  return result;
};

const getMeetingDateData = async (oid, paramsId) => {
  if (oid && paramsId) {
    const result = await siteApi
      .get(`/ActiveMeetingDate/${oid}/${paramsId}`)
      .then((res) => {
        return res.data;
      });
    return result;
  }
};

const createMeetingAgendaAttachment = async (insertData) => {
  const result = await axios.post(
    `${teamMeetingAgendaAttachment}/teammeetingagendaattachment`,
    insertData
  );
  return result.data;
};

const getMeetingAgendaAttachment = async (oid, paramsId) => {
  if (oid && paramsId) {
    const result = await axios
      .get(
        `${teamMeetingAgendaAttachment}/${oid}/${paramsId}/teammeetingagendaattachment
`
      )
      .then((res) => {
        return res.data;
      });
    return result;
  }
};

const deleteMeetingAgendaAttachment = async (id, createdAt) => {
  if (id && createdAt) {
    const result = await axios
      .delete(
        `${teamMeetingAgendaAttachment}/teammeetingagendaattachment/${id}/${createdAt}
`
      )
      .then((res) => {
        return res.data;
      });
    return result;
  }
};

export {
  createMeeting,
  sendMail,
  AllEmployee,
  removeAgenda,
  addAgenda,
  updateAgenda,
  endMeetingNotification,
  closeTeamMeetingAdd,
  closeMeetingAgendaAdd,
  deleteMeeting,
  getMeetingAgenda,
  updateTeamMeeting,
  getMeetingData,
  teamMeetingMain,
  createMeetingDate,
  getMeetingDateData,
  getMeetingAgendaBasedOnDate,
  createMeetingAgendaAttachment,
  getMeetingAgendaAttachment,
  deleteMeetingAgendaAttachment,
};
