/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/order */
/* eslint-disable prettier/prettier */
/* eslint-disable arrow-body-style */
import momentEran from "moment/min/moment-with-locales";
import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Moment from "react-moment";
import { getEarnData } from "../../../api/badge";
import { AuthContext } from "../../../Context/AuthContext";

const Earned = () => {
  Moment.globalMoment = momentEran;
  Moment.globalFormat = "D MMM YYYY";
  const { logged, oid } = useContext(AuthContext);
  const [earnData, setEarnData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getDataEarn = async () => {
      const Earn = await getEarnData(oid);
      setEarnData(Earn.data);
      setLoading(false);
    };
    getDataEarn();
  }, [oid]);

  const loggedInUserEarnBadge = earnData.filter(
    (i) => i?.EarnEmail?.email === logged
  );

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner className="rt-spinner" animation="grow" />
        </div>
      ) : (
        <>
          <div className="badge-all-heading px-2">
            <p>Praise</p>
            <p>From</p>
            <p>Date</p>
            <p className="text-left">Message</p>
          </div>

          {loggedInUserEarnBadge?.map((data, index) => (
            <div key={index} className="badge-listing">
              <div>
                <img
                  className="badge-img"
                  src={data.EarnImage}
                  alt={data.EarnType}
                />
                <p>{data.EarnType}</p>
              </div>
              <p>{data.badgeEarnFrom}</p>
              <p>
                <Moment>{data.createdAt}</Moment>
              </p>
              <p className="text-left">{data.EarnComment}</p>
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default Earned;
