/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useRef, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
function Step1(props) {
  const { errors, register, watch, setDisPlayName } = props;

  const firstName = watch("firstName");
  const middleName = watch("middleName");
  const lastName = watch("lastName");
  const fileRef = useRef();
  const [data, setData] = useState({});
  const dName = `${firstName} ${middleName} ${lastName}`;
  setDisPlayName(dName);
  return (
    <div>
      <div className="form-row flex-column">
        <Row>
          <Col>
            <div className="form-group">
              <label htmlFor="firstName">First name*</label>
              <input
                type="text"
                className="form-control h-70"
                {...register("firstName", {
                  required: {
                    value: true,
                    message: "Please type first name",
                  },
                })}
                placeholder="First Name*"
                id="firstName"
              />
              {errors.firstName && (
                <span style={{ color: "red" }}>{errors.firstName.message}</span>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="middleName">Last Name*</label>
              <input
                type="text"
                className="form-control h-70"
                {...register("lastName", {
                  required: {
                    value: true,
                    message: "Please type last name",
                  },
                })}
                placeholder="Last Name*"
                id="lastName"
                required
              />
              {errors.lastName && (
                <span style={{ color: "red" }}>{errors.lastName.message}</span>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="middleName">Date of Birth*</label>
              <input
                type="date"
                className="form-control h-70"
                id="dateOfBirth"
                {...register("dateOfBirth", {
                  required: {
                    value: true,
                    message: "Please select a valid date",
                  },
                })}
              />
              {errors.dateOfBirth && (
                <span style={{ color: "red" }}>
                  {errors.dateOfBirth.message}
                </span>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="middleName">Email Address*</label>
              <input
                type="email"
                className="form-control h-70"
                {...register("emailAddress", {
                  required: "Email is required",
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Please enter a valid email",
                  },
                })}
                placeholder="Email Address*"
                id="emailAddress"
                required
              />
              {errors.emailAddress && (
                <span style={{ color: "red" }}>
                  {errors.emailAddress.message}
                </span>
              )}
            </div>
          </Col>

          <Col>
            <div className="form-group">
              <label htmlFor="middleName">Middle Name</label>
              <input
                type="text"
                className="form-control h-70"
                {...register("middleName", {})}
                placeholder="Middle Name*"
                id="firstName"
              />
            </div>

            <div className="form-group">
              <label htmlFor="middleName">Display Name*</label>
              <input
                type="text"
                className="form-control h-70"
                {...register("displayName")}
                id="displayName"
                //   value={`${firstName} ${middleName} ${lastName}`}
                disabled
                placeholder="display Name"
                value={
                  !firstName
                    ? !middleName && !lastName && "Display Name*"
                    : `${firstName} ${middleName} ${lastName}`
                }
              />
            </div>

            <div className="form-group">
              <label htmlFor="middleName">Gender*</label>
              <Form.Select
                {...register("gender", {
                  required: {
                    value: true,
                    message: "Please select a valid gender",
                  },
                })}
                className="form-control h-70"
                id="gender"
              >
                <option value="" selected disabled>
                  Select Gender*
                </option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </Form.Select>
              {errors.gender && (
                <span style={{ color: "red" }}>{errors.gender.message}</span>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="middleName">Mobile Number*</label>
              <input
                type="number"
                className="form-control h-70"
                {...register("mobileNumber", {
                  required: "Phone number is required",
                  minLength: {
                    value: 8,
                    message: "Phone number be at least 8 digits long",
                  },
                })}
                placeholder="Mobile Number*"
              />
              {errors.mobileNumber && (
                <span style={{ color: "red" }}>
                  {errors.mobileNumber.message}
                </span>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="form-group upload-img position-relative">
              <input
                type="text"
                className="w-100 form-control h-70"
                disabled
                value={(data && data.name) || " "}
              />
              <div className="fileUpload position-absolute top-50 translate-middle-y rounded-3 overflow-hidden">
                <label htmlFor="profilePicture" className="w-100 h-100 cursor">
                  Choose Picture
                </label>
              </div>
              <input
                id="profilePicture"
                {...register("profilePicture", {
                 required: "profilePicture is required",
                })}
                className="f-input d-none"
                type="file"
                accept="image/*"
              />
  
              {errors.profilePicture && (
                <span style={{ color: "red" }}>
                  {errors.profilePicture.message}
                </span>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Step1;
