/* eslint-disable no-alert */
/* eslint-disable no-shadow */
/* eslint-disable react/void-dom-elements-no-children */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { AiFillDelete, AiOutlineMore } from 'react-icons/ai';
import Moment from 'react-moment';
import moment from 'moment/min/moment-with-locales';

const Table = ({ item, handleClick, contextData, handleDelete }) => {
    const { title, createdAt, createdBy } = item;
    const [showDelete, setShowDelete] = useState(false);

    const { isAdmin, logged } = contextData;

    Moment.globalMoment = moment;
    Moment.globalFormat = 'MMM D, YYYY';

    const handleDropdown = () => {
        setShowDelete(!showDelete);
    };

    useEffect(() => {}, [item]);

    return (
        <>
            <tr>
                <td className="name border-right-0 w-50">
                    <span onClick={() => handleClick(item)}>
                        {title?.length < 50
                            ? title.substring(0, title.length)
                            : `${title.substring(0, 50)} ...`}
                    </span>
                </td>
                <td className="name border-right-0 border-left-0" onClick={() => handleClick(item)}>
                    <Moment>{createdAt}</Moment>
                </td>
                <td className="office border-right-0 border-left-0">
                    <img
                        src={createdBy}
                        alt="Profile Img"
                        className="rounded-circle"
                        onClick={() => handleClick(item)}
                        style={{ width: '60px', height: '60px' }}
                    />
                </td>
                <td>
                    {(isAdmin || item.creatorEmail === logged) && (
                        <>
                            <AiOutlineMore
                                className="ml-1"
                                onClick={() => handleDropdown()}
                                size={25}
                            />
                            {showDelete && (
                                <AiFillDelete
                                    className="delete-ico delete-btn-announcement"
                                    variant="danger"
                                    size={25}
                                    onClick={() => handleDelete(item.id, item.createdAt)}
                                />
                            )}
                        </>
                    )}
                </td>
            </tr>
        </>
    );
};

export default Table;
