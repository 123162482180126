/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable arrow-body-style */
/* eslint-disable prettier/prettier */
import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const DeleteModal = ({ show, setShow, handleDelete, deleteId, deleteOId,deleteEId }) => {
    const handleClose = () => {
        setShow(false);
    }
    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Do you really want to delete this?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button onClick={() => handleDelete(deleteId,deleteEId, deleteOId)} variant="danger">Delete</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default DeleteModal;