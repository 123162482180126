import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
const custStyle = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: '1px solid #35A7FF',
        borderRadius: '20px',
        background: '#ffffff0d',
        boxShadow: '2px 2px 10px #0059d24d',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: '25%',
    },
};

const FormModal = ({ value, showModal, handleFormModal, handleFormModalSubmit, title }) => {
    const [roleName, setRoleName] = useState('');
    useEffect(() => setRoleName(value), [value]);

    return (
        <Modal
            isOpen={showModal}
            onRequestClose={handleFormModal}
            dialogClassName="cust-class"
            style={custStyle}
            contentLabel="Create New Role"
        >
            <Form>
                <Container className="form-modal">
                    <Row>
                        <Col>
                            <h3 className="title">{title}</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <input
                                type="text"
                                placeholder="Role Name"
                                name="roleName"
                                className="input-control"
                                value={roleName}
                                onChange={(e) => setRoleName(e.target.value)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="modal-btn-group">
                            <Button className="modal-btn cancel-btn" onClick={handleFormModal}>
                                Cancel
                            </Button>
                            <Button
                                className="modal-btn save-btn"
                                onClick={() => handleFormModalSubmit(roleName)}
                            >
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Form>
        </Modal>
    );
};

export default FormModal;
