/* eslint-disable react/no-danger */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment/min/moment-with-locales';

const TaskViewModal = (props) => {
    const history = useHistory();
    console.log(props.taskData);
    const modalHide = () => {
        props.onHide();
        history.push('/task');
    };
    Moment.globalMoment = moment;
    Moment.globalFormat = 'MMM D, YYYY';
    return (
        <Modal
            {...props}
            size="xl"
            backdrop="static"
            keyboard={false}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            className="task-modal"
            centered
        >
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="example-custom-modal-styling-title">
                        Task Details
                    </h5>
                    <button type="button" className="close" onClick={modalHide}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <Modal.Body className="pt-4">
                    <div className="form-group">
                        <h6>Task Name:</h6>
                        <p>{props.taskData.taskName}</p>
                    </div>
                    <div className="form-group">
                        <h6>Task Due Date:</h6>
                        <p>
                            <Moment>{props.taskData.dueDate}</Moment>
                        </p>
                    </div>
                    <div className="form-group">
                        <h6>Task Effort:</h6>
                        <p>{props.taskData.taskEffort}</p>
                    </div>
                    <div className="form-group">
                        <h6>Task Priority:</h6>
                        <p>{props.taskData.taskPriority}</p>
                    </div>
                    <div className="form-group">
                        <h6>Task Status:</h6>
                        <p>{props.taskData.taskState}</p>
                    </div>
                    <div className="form-group">
                        <h6>Task WorkStream:</h6>
                        <p>{props.taskData.workStream}</p>
                    </div>
                    <div className="form-group">
                        <h6>Task Description:</h6>
                        <p>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: props.taskData.taskDescription,
                                }}
                            />
                        </p>
                    </div>
                    <div className="form-group">
                        <h6>Task Delegated Person:</h6>
                        <p>{props.taskData.delegatedPersonEmail}</p>
                    </div>
                    <div className="form-group">
                        <h6>Loop Users:</h6>
                        <p>{props.taskData?.addUserLoop?.map((user) => user.name)}</p>
                    </div>
                </Modal.Body>
            </div>
        </Modal>
    );
};

export default TaskViewModal;
