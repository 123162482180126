/* eslint-disable consistent-return */
/* eslint-disable no-alert */
/* eslint-disable import/prefer-default-export */

// import siteApi from "./siteApi";
import axios from "axios";

const processDocumentationApi =
  process.env.REACT_APP_PROCESSDOCUMENTATION_BASE_URL;
const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Credentials": true,
};
const addProcessDocumentation = async (formData) => {
  const response = await axios.post(
    `${processDocumentationApi}/processdoc`,
    formData,
    {
      headers,
    }
  );
  return response;
};

const ProcessDocumentationList = async (oid) => {
  const response = await axios.get(
    `${processDocumentationApi}/${oid}/processdoc`
  );
  return response.data;
};

const ProcessDocumentationDelete = async (id, createdAt) => {
  const response = await axios.delete(
    `${processDocumentationApi}/processdoc/${id}/${createdAt}`
  );

  return response.data;
};

const ProcessDocumentationUpdate = async (id, createdAt, data) => {
  const response = await axios.put(
    `${processDocumentationApi}/processdoc/${id}/${createdAt}`,
    data
  );

  return response;
};

const ProcessDocumentationById = async (id, createdAt) => {
  const response = await axios.get(
    `${processDocumentationApi}/processdoc/${id}/${createdAt}`
  );

  return response.data;
};

export {
  addProcessDocumentation,
  ProcessDocumentationList,
  ProcessDocumentationDelete,
  ProcessDocumentationUpdate,
  ProcessDocumentationById,
};
