/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useContext } from "react";
import { faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import siteApi from "../../../api/siteApi";
import { AuthContext } from "../../../Context/AuthContext";
import "../YearPlan/YearPlan.scss";
function PlanIssue({ yearNo }) {
  const [addValueIssue, setAddValueIssue] = useState(false);
  const [updateTrackState, setUpdateTrackState] = useState(false);
  const [issueData, setIssueData] = useState([]);
  const { oid, isAdmin } = useContext(AuthContext);

  // handle input change
  const handleIssueChange = (e, index) => {
    const { name, value, checked } = e.target;
    const list = [...issueData];
    if (name === "IssueCheckbox") {
      list[index][name] = checked;
      setIssueData(list);
    } else {
      list[index][name] = value;
      setIssueData(list);
    }
  };

  //  issue add
  // eslint-disable-next-line no-shadow
  const handleAddIssue = async (id) => {
    const res = issueData?.filter((data) => data.id === id);
    if (res) {
      res.forEach((i) => {
        const addData = {
          id: i.id,
          IssueFlag: i.IssueFlag,
          Issue: i.Issue,
          oid,
          createdAt: i.createdAt,
          YearNo: yearNo,
        };
        axios.post(
          `https://6kb973bsth.execute-api.us-east-2.amazonaws.com/dev/issue`,
          addData
        );
        setAddValueIssue(false);
        toast("Created Successfully");
        const temp = [...issueData];
        setIssueData([]);
        setTimeout(() => {
          setIssueData(temp);
        }, 0.3);
      });
    }
  };

  // issue update
  const handleUpdateIssue = (IssueFlagValue, id, IssueValue) => {
    if (updateTrackState === true) {
      const updateData = async () => {
        if (id) {
          await axios.put(
            `https://6kb973bsth.execute-api.us-east-2.amazonaws.com/dev/issue/${id}`,
            {
              Issue: IssueValue,
              IssueFlag: IssueFlagValue,
            }
          );
        }
      };
      updateData();
      toast("Updated");
      setUpdateTrackState(false);
    }
  };

  // issue delete
  // eslint-disable-next-line no-shadow
  const removeIssue = async (event, id) => {
    toast("Deleted Successfully");
    const res = issueData?.filter((data) => data.id !== id);
    setIssueData([]);
    setTimeout(() => {
      setIssueData([...res]);
    }, 0.1);
    await axios.delete(
      `https://6kb973bsth.execute-api.us-east-2.amazonaws.com/dev/issue/${id}`
    );
  };

  // add issue
  const addIssue = () => {
    setIssueData([
      ...issueData,
      {
        id: uuidv4(),
        oid,
        Issue: "",
        YearNo: yearNo,
        IssueFlag: "",
        createdAt: new Date().toString(),
      },
    ]);
  };
  useEffect(() => {
    if (oid && yearNo) {
      // Issue get api call
      axios
        .get(
          `https://6kb973bsth.execute-api.us-east-2.amazonaws.com/dev/${oid}/${yearNo}/Issue`
        )
        .then((res) => {
          const data = res ? res.data : "loading";
          setIssueData(data);
        });
    }
  }, [oid, yearNo]);
  return (
    <>
      {/* issue section */}
      {issueData.length > 0
        ? issueData.map((input, index) => (
            <div className="row d-flex">
              <div className="col checkbox-plan-left">
                <ToastContainer />
                <input
                  className="form-check-input plan-check"
                  type="checkbox"
                  id="inlineCheckbox3"
                  disabled={!isAdmin}
                  name="IssueFlag"
                  onBlur={(e) => {
                    handleIssueChange(e, index);
                    if (addValueIssue === true) {
                      handleAddIssue(input.id);
                    } else {
                      handleUpdateIssue(
                        e.target.checked,
                        input.id,
                        input.Issue
                      );
                    }
                  }}
                  onClick={(e) => {
                    setUpdateTrackState(true);
                  }}
                  defaultChecked={input.IssueFlag}
                />
                <input
                  className="text-margin-plan-left2"
                  type="text"
                  disabled={!isAdmin}
                  name="Issue"
                  onChange={(e) => {
                    handleIssueChange(e, index);
                    setUpdateTrackState(true);
                  }}
                  onBlur={(event) => {
                    if (addValueIssue === true) {
                      handleAddIssue(input.id);
                    } else {
                      handleUpdateIssue(
                        input.IssueFlag,
                        input.id,
                        event.target.value
                      );
                    }
                  }}
                  defaultValue={input.Issue}
                />
                {isAdmin && (
                  <button type="button" className="btn btn-light">
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      className="mr-0"
                      onClick={(event) => removeIssue(event, input.id)}
                    />
                  </button>
                )}
              </div>
            </div>
          ))
        : ""}
      <br />
      {isAdmin && (
        <button
          type="button"
          className="btn btn-light plan-add-button"
          onClick={() => {
            setAddValueIssue(true);
            addIssue();
          }}
        >
          <FontAwesomeIcon icon={faPlus} className="mr-0" /> ADD
        </button>
      )}
    </>
  );
}

export default PlanIssue;
