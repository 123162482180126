/* eslint-disable consistent-return */
/* eslint-disable prettier/prettier */
import siteApi from "./siteApi";
/* eslint-disable import/prefer-default-export */

const organizationChartApi = process.env.REACT_APP_ORGANIZATIONCHART_BASE_URL;

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Credentials": true,
};
const getOrganizationChartNodes = async (oid) => {
  const response = await siteApi.get(
    `${organizationChartApi}/${oid}/organizationchart`
  );
  return response.data[0];
};

const addOrganizationChartNode = async (newData) => {
  const result = await siteApi.post(
    `${organizationChartApi}/organizationchart`,
    newData,
    {
      headers,
    }
  );
  return result;
};

const deleteOrganizationChartNode = async (id, createdAt) => {
  const result = await siteApi.delete(
    `${organizationChartApi}/organizationchart/${id}/${createdAt}`
  );
  return result;
};

const updateOrganizationChartNode = async (id, createdAt, updateData) => {
  const result = await siteApi.put(
    `${organizationChartApi}/organizationchart/${id}/${createdAt}`,
    updateData
  );
  return result;
};

export {
  getOrganizationChartNodes,
  addOrganizationChartNode,
  deleteOrganizationChartNode,
  updateOrganizationChartNode,
};
