import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import Moment from 'react-moment';
import parse from 'react-html-parser';
import { AiFillDelete, AiFillEye } from 'react-icons/ai';
import moment from 'moment/min/moment-with-locales';
import { MdModeEdit } from 'react-icons/md';
import { Link } from 'react-router-dom';

const ProcessCard = (props) => {
    const { isAdmin, item, setDocumentShow, handleDelete, setModalData } = props;
    Moment.globalMoment = moment;
    Moment.globalFormat = 'D MMM YYYY';
    return (
        <>
            <Card className="mb-2">
                <Card.Body>
                    <Row>
                        <Col md={8} className="align-self-center">
                            <Card.Title>{item.ProcessName}</Card.Title>
                            <Card.Text>
                                <div className="pb-4">
                                    <Moment className="rt-text-primary">{item.createdAt}</Moment>
                                </div>
                                <div className="rt-doc">
                                    {parse(
                                        item.Description.length > 35
                                            ? `${item.Description.substring(0, 165)}...`
                                            : item.Description
                                    )}
                                </div>
                            </Card.Text>
                        </Col>
                        <Col md={4} className="align-self-center text-right">
                            <AiFillEye
                                className="view-ico"
                                size={25}
                                onClick={() => {
                                    setDocumentShow(true);
                                    setModalData(item);
                                }}
                            />

                            {isAdmin && (
                                <Link
                                    to={`/ProcessDocumentation/update/${item.id}/${item.createdAt}`}
                                >
                                    <MdModeEdit size={25} className="edit-ico ml-1" />
                                </Link>
                            )}

                            {isAdmin && (
                                <AiFillDelete
                                    className="delete-ico ml-1"
                                    variant="danger"
                                    size={25}
                                    onClick={() => handleDelete(item.id, item.createdAt)}
                                />
                            )}
                        </Col>
                    </Row>
                    <div className="group mt-3">
                        <span className="rt-tag">{item.Category}</span>
                    </div>
                </Card.Body>
            </Card>
        </>
    );
};

export default ProcessCard;
