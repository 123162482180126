/* eslint-disable import/no-named-as-default-member */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
/* eslint-disable radix */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-return-await */
/* eslint-disable no-shadow */
/* eslint-disable prettier/prettier */

import React, { useContext, useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { FaPlusCircle } from "react-icons/fa";
import toast from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../../../Context/AuthContext";
import "../Details.scss";

import {
  removeAgenda,
  addAgenda,
  updateAgenda,
  endMeetingNotification,
  closeTeamMeetingAdd,
  closeMeetingAgendaAdd,
  deleteMeeting,
  // getMeetingAgenda,
  getMeetingAgendaBasedOnDate,
} from "../../../../api/teamMeeting";
import SingleIteamMeetingAgenda from "./SingleIteamMeetingAgenda";
function MeetingAgenda({
  singleMeetingData,
  paramsId,
  // paramsDate,
  meetingName,
  meetingDescription,
  meetingDate,
  selectedUser,
  enableButton,
}) {
  const history = useHistory();
  const { oid } = useContext(AuthContext);
  const [meetingAgendaData, setMeetingAgendaData] = useState([]);
  console.log("Meeting Date", meetingDate);

  // add new meeting agenda
  const addMeetingAgenda = async () => {
    const insertData = {
      id: uuidv4(),
      oid,
      meetingId: paramsId,
      timer: 0,
      topicName: "",
      takeAway: "",
      agendaType: "",
      meetingDate,
      createdAt: new Date().toISOString(),
    };
    await addAgenda(insertData);
    setMeetingAgendaData([...meetingAgendaData, insertData]);
    toast.success("Created Meeting Agenda Successfully");
  };

  // end meeting notification function
  const endMeetingNotificationtoUsers = () => {
    selectedUser.map(async (user) => {
      const notificationValue = {
        meetingName,
        meetingDescription,
        meetingDate,
        to: user,
        from: "testingsidsj@gmail.com",
        meetingAgendaData,
      };
      endMeetingNotification(notificationValue);
    });
    setTimeout(() => {
      history.push("/meetings");
    }, 3000);
  };

  // create closed meeting and agenda data
  const createCloseMeetingAndMeetingAgenda = () => {
    closeTeamMeetingAdd(singleMeetingData[0]);
    meetingAgendaData.forEach((i) => {
      const insertData = {
        id: i.id,
        oid: i.oid,
        meetingId: i.meetingId,
        meetingDate: i.meetingDate,
        timer: i.timer,
        topicName: i.topicName,
        takeAway: i.takeAway,
        createdAt: i.createdAt,
      };
      closeMeetingAgendaAdd(insertData);
      setTimeout(() => {
        history.push("/meetings");
      }, 1000);
    });
  };

  // delete current meeting
  const deleteRunningMeeting = async () => {
    if (singleMeetingData[0].id && singleMeetingData[0].createdAt) {
      deleteMeeting(singleMeetingData[0].id, singleMeetingData[0].createdAt);
    }
    setTimeout(() => {
      history.push("/meetings");
    }, 3000);
  };

  useEffect(async () => {
    // Meeting agenda get api calling
    let result;
    if (meetingDate !== undefined) {
      result = await getMeetingAgendaBasedOnDate(oid, paramsId, meetingDate);
      setMeetingAgendaData([]);
      setTimeout(() => {
        setMeetingAgendaData(result);
      }, 0.3);
    }
  }, [oid, paramsId, meetingDate]);

  return (
    <Container fluid>
      <div className="bg-white rounded p-3 metting-detail">
        <Row>
          <Col>
            <button
              className="add-agenda-btn mb-4"
              type="button"
              onClick={() => {
                addMeetingAgenda();
              }}
            >
              <h5 className="inline-title">Add Agenda</h5>
              <FaPlusCircle className="ml-2 add-agenda" />
            </button>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <p className="pl-5">Topics for Discussion</p>
          </Col>
          <Col lg={6}>
            <p className="pl-5">Takeways</p>
          </Col>
        </Row>

        {/* meeting agenda show */}
        {meetingAgendaData.length > 0
          ? meetingAgendaData.map((input, index) => (
              <SingleIteamMeetingAgenda
                key={index}
                input={input}
                index={index}
                removeAgenda={removeAgenda}
                addAgenda={addAgenda}
                updateAgenda={updateAgenda}
                meetingAgendaData={meetingAgendaData}
                setMeetingAgendaData={setMeetingAgendaData}
              ></SingleIteamMeetingAgenda>
            ))
          : ""}

        <div className="text-center pt-5">
          <Button
            size="lg"
            variant="primary"
            onClick={() => {
              endMeetingNotificationtoUsers();
              createCloseMeetingAndMeetingAgenda();
              deleteRunningMeeting();
            }}
            disabled={!enableButton}
          >
            End Meeting
          </Button>
        </div>
      </div>
    </Container>
  );
}

export default MeetingAgenda;
