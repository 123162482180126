/* eslint-disable no-return-await */
/* eslint-disable import/prefer-default-export */
/* eslint-disable consistent-return */
/* eslint-disable prettier/prettier */
/* eslint-disable arrow-body-style */
/* eslint-disable arrow-body-style */
import axios from 'axios';

const employeeApi = process.env.REACT_APP_Employee_API;
// get Employee Info
const getEmployeePersonalInfo = async (username) => {
  return await axios
    .get(`${employeeApi}/employee/item/${username}`)
    .then((res) => {
      console.log(res)
      return res?.data;
    });
};

// user activation api
const userActivation = async (eid) => {
  return await axios
    .put(`${employeeApi}/employee/update/${eid}/active`, {
      activeFlag: "True",
    })
    .then((res) => {
      return res?.data;
    });
};

export { userActivation, getEmployeePersonalInfo };
