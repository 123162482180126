/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable arrow-body-style */
/* eslint-disable no-shadow */
/* eslint-disable prettier/prettier */

import { faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useContext } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import siteApi from "../../../api/siteApi";
import "./YearPlan.scss";
import PlanModal from "../PlanModal/PlanModal";
import issue from "../PlanModule/Image/Issues list.svg";
import keypriority from "../PlanModule/Image/Key Priorities.svg";
import plan from "../PlanModule/Image/Plan.svg";
import "../PlanModule/PlanModule.scss";
import PlanRevenue from "../PlanRevenue/PlanRevenue";
import PlanGoal from "../PlanGoal/PlanGoal";
import PlanKeyRevenue from "../PlanKeyRevenue/PlanKeyRevenue";
import PlanIssue from "../PlanIssue/PlanIssue";
import { AuthContext } from "../../../Context/AuthContext";

const YearPlan = ({ oid, yearNo }) => {
  const [status, setStatus] = useState("");
  const [YearsData, setYearsData] = useState([]);
  const [year, setYear] = useState("");
  const [yearValue, setYearValue] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [futureDateVisionPage, setFutureDateVisionPage] = useState("");
  const [measurableTextField, setMeasurableTextField] = useState("");
  const [yearGoal, setYearGoal] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [yearIssue, setYearIssue] = useState("");
  const [measureableKey, setMeasureableKey] = useState("");
  const [futureDateKeyRevenue, setFutureDateKeyRevenue] = useState("");
  const [id, setId] = useState("");
  const { isAdmin } = useContext(AuthContext);

  // key priority modal
  const [modalData, setModalData] = useState([]);
  const [modalShowData, setModalShowData] = useState([
    {
      who: "",
      CompanyType: "",
      Description: "",
      EndDate: "",
      Priority: "",
      Progress: "",
      StartDate: "",
      TrackStatus: "",
      id: "",
      oid: "",
      createdAt: "",
      YearNo: "",
    },
  ]);

  const [updateTrackState, setUpdateTrackState] = useState(false);

  const handleCloseModal = () => setModalIsOpen(false);
  const handleOpenModal = () => setModalIsOpen(true);
  const handleStatus = () => {
    setStatus("edit");
  };
  const handler = () => {
    setStatus("");
    setModalShowData([
      {
        who: "",
        CompanyType: "",
        Description: "",
        EndDate: "",
        Priority: "",
        Progress: "",
        StartDate: "",
        TrackStatus: "",
        id: "",
        oid: "",
        createdAt: "",
        YearNo: "",
      },
    ]);
  };
  const removeDataFromPlanModal = () => {
    setModalData("");
  };
  const updateItemHandler = (updatedData) => {
    const newItems = modalData.map((item) => {
      let itemProps = { ...item };
      if (item.id === updatedData.id) {
        itemProps = {
          ...itemProps,
          ...updatedData,
        };
      }
      return itemProps;
    });
    setModalData(newItems);
    handleCloseModal();
  };
  const handleModalShowValue = (
    id,
    CompanyType,
    Description,
    EndDate,
    Priority,
    Progress,
    StartDate,
    TrackStatus,
    Who,
    YearNo,
    Oid,
    CreatedAt
  ) => {
    setModalShowData([
      {
        who: Who,
        CompanyType,
        Description,
        EndDate,
        Priority,
        Progress,
        StartDate,
        TrackStatus,
        id,
        oid: Oid,
        createdAt: CreatedAt,
        YearNo,
      },
    ]);
  };

  // delete key priorities
  const removeKeyPriority = async (event, idvalue, createdAt) => {
    toast("Deleted Successfully");
    const remainingArr = modalData?.filter((data) => {
      return data.id !== idvalue;
    });
    console.log(remainingArr);
    setModalData(remainingArr);
    await siteApi.delete(
      `https://vfyl6rvuwl.execute-api.us-east-2.amazonaws.com/dev/keypriority/${idvalue}/${createdAt}`
    );
  };

  useEffect(() => {
    if (oid && yearNo) {
      // keypriority   get api
      siteApi
        .get(
          `https://vfyl6rvuwl.execute-api.us-east-2.amazonaws.com/dev/${oid}/${yearNo}/keypriority`
        )
        .then((res) => {
          const keyPriorityData = res ? res.data : "loading";
          console.log("Test", res);
          setModalData(keyPriorityData);
        });

      //  plan get api call
      axios
        .get(
          `https://20bvfw9cl6.execute-api.us-east-2.amazonaws.com/dev/${oid}/${yearNo}/plan`
        )
        .then((res) => {
          setYearsData(res.data);
          setYear(res.data?.map((item) => item.YearNo).toString());
          setId(res.data?.map((item) => item.id).toString());
          setYearValue(res.data?.map((item) => item.YearNo).toString());
          setFutureDateVisionPage(
            res.data?.map((item) => item.FuturedatePlan).toString()
          );
          setMeasurableTextField(
            res.data?.map((item) => item.MeasurablesPlan).toString()
          );
          setYearGoal(res.data?.map((item) => item.Goal).toString());
          setYearIssue(res.data?.map((item) => item.Issue).toString());
          setMeasureableKey(
            res.data?.map((item) => item.Measurableskey).toString()
          );
          setFutureDateKeyRevenue(
            res.data?.map((item) => item.Futuredatekey).toString()
          );
          setCreatedAt(res.data?.map((item) => item.createdAt).toString());
        });
    }
  }, [oid, !modalData]);

  // Plan patch api call function
  const futureDateAndMeasurableUpdate = (e, key) => {
    if (updateTrackState === true) {
      setUpdateTrackState(false);
      const insertData = async () => {
        await axios.put(
          ` https://20bvfw9cl6.execute-api.us-east-2.amazonaws.com/dev/update/${id}/${createdAt}`,
          {
            FuturedatePlan:
              key === "Futuredate" ? e.target.value : futureDateVisionPage,
            Futuredatekey:
              key === "FuturedateKey" ? e.target.value : futureDateKeyRevenue,
            Goal: yearGoal,
            Issue: yearIssue,
            MeasurablesPlan:
              key === "measureable" ? e.target.value : measurableTextField,
            Measurableskey:
              key === "measureableKey" ? e.target.value : measureableKey,
          }
        );
      };
      insertData();
      toast("Updated");
    }
  };

  return (
    <div className="container-fluid">
      {YearsData.length >= 0 &&
        YearsData?.map((i, id) => (
          <>
            <div className="row d-flex" key={id}>
              <div className="col-md-4 col-sm-12 pt-4 plan-body-right">
                <div className="row">
                  <div className="mx-auto">
                    <img
                      className="img-fluid plan-image"
                      src={plan}
                      alt="KeyPriorityImage"
                    />
                    <p className="plan-title-header">Plan</p>
                  </div>
                </div>

                <div className="row pt-4">
                  <div className="col-md-4">
                    <h6 className="future-date-plan-left">Future Date</h6>
                  </div>
                  <div className="col-md-7 pr-4">
                    <Form.Group controlId="dob">
                      <Form.Control
                        type="date"
                        name="dob"
                        placeholder="Start Date"
                        disabled={!isAdmin}
                        defaultValue={i.FuturedatePlan}
                        onChange={(e) => {
                          setFutureDateVisionPage(e.target.value);
                          futureDateAndMeasurableUpdate(e, "Futuredate");
                        }}
                        onClick={() => {
                          setUpdateTrackState(true);
                        }}
                        onBlur={() => {
                          setUpdateTrackState(false);
                        }}
                      />
                    </Form.Group>
                  </div>
                </div>

                <br />
                {/* plan revenue section */}
                <PlanRevenue yearNo={yearNo}></PlanRevenue>

                <p className="plan-left-p mt-3">Measurables</p>

                <div className="input-group-plan-left">
                  <textarea
                    className="form-control"
                    disabled={!isAdmin}
                    aria-label="With textarea"
                    defaultValue={i.MeasurablesPlan}
                    onChange={() => {
                      setUpdateTrackState(true);
                    }}
                    onBlur={(e) => {
                      futureDateAndMeasurableUpdate(e, "measureable");
                      setMeasurableTextField(e.target.value);
                    }}
                  />
                </div>
                <br />
                {/* ----------------------GOAL dynamic field Start-------------------------- */}
                <ToastContainer />
                <p className="plan-left-p">Goals</p>
                <PlanGoal yearNo={yearNo}></PlanGoal>
              </div>
              {/* ----------------------GOAL dynamic field end-------------------------- */}

              <div className="col-md-4 col-sm-12 pt-4 plan-body-right">
                <div className="row">
                  <div className="mx-auto">
                    <img
                      className="img-fluid plan-img"
                      src={keypriority}
                      alt="KeyPriorityImage"
                    />
                    <p className="plan-title-header">Key Priorities</p>
                  </div>
                </div>
                <div className="row pt-4">
                  <div className="col-md-4">
                    <h6 className="future-date-plan-left">Future Date</h6>
                  </div>
                  <div className="col-md-6">
                    <Form.Group controlId="dob">
                      <Form.Control
                        type="date"
                        name="FuturedateKey"
                        placeholder="End Date"
                        defaultValue={i.Futuredatekey}
                        disabled={!isAdmin}
                        onChange={(e) => {
                          setFutureDateKeyRevenue(e.target.value);
                          futureDateAndMeasurableUpdate(e, "FuturedateKey");
                        }}
                        onClick={() => {
                          setUpdateTrackState(true);
                        }}
                        onBlur={() => {
                          setUpdateTrackState(false);
                        }}
                      />
                    </Form.Group>
                  </div>
                </div>
                <br />
                {/*  ----------------------------------------KEY REVENUE START--------------------------------------------------- */}
                <PlanKeyRevenue yearNo={yearNo}></PlanKeyRevenue>
                {/* // ----------------------------------------KEY REVENUE End--------------- */}
                <br />
                <p className="mt-3">Measurables</p>
                <div className="input-group">
                  <textarea
                    className="form-control"
                    aria-label="With textarea"
                    disabled={!isAdmin}
                    defaultValue={i.Measurableskey}
                    onChange={() => {
                      setUpdateTrackState(true);
                    }}
                    onBlur={(e) => {
                      futureDateAndMeasurableUpdate(e, "measureableKey");
                      setMeasureableKey(e.target.value);
                    }}
                  />
                </div>
                <br />
                <p>Key priorities for the quarter</p>
                <br />
                {/* Show Modal */}
                {modalData.length > 0
                  ? modalData?.map((input, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <div key={index} className="row">
                        <div className="col-9 key-priority-title">
                          <p
                            onClick={() => {
                              handleStatus();
                              handleOpenModal();
                              handleModalShowValue(
                                input.id,
                                input.CompanyType,
                                input.Description,
                                input.EndDate,
                                input.Priority,
                                input.Progress,
                                input.StartDate,
                                input.TrackStatus,
                                input.Who,
                                input.YearNo,
                                input.id,
                                input.createdAt
                              );
                            }}
                          >
                            <b>{input.Priority}</b>
                          </p>
                        </div>
                        {isAdmin && (
                          <div className="col-3">
                            <button
                              type="button"
                              className="btn btn-light"
                              onClick={(event) =>
                                removeKeyPriority(
                                  event,
                                  input.id,
                                  input.createdAt
                                )
                              }
                            >
                              <FontAwesomeIcon
                                icon={faTrashAlt}
                                className="mr-0"
                              />
                            </button>
                          </div>
                        )}
                      </div>
                    ))
                  : ""}

                {status === "edit" || status === "add" ? (
                  <PlanModal
                    statusValue={status}
                    handler={handler}
                    handleCloseModal={handleCloseModal}
                    modalIsOpen={modalIsOpen}
                    year={year}
                    modalData={modalShowData !== "" ? modalShowData : ""}
                    removeDataFromPlanModal={removeDataFromPlanModal}
                    updateItemHandler={updateItemHandler}
                  ></PlanModal>
                ) : (
                  ""
                )}

                {/* Add new Modal  value */}
                <br />
                {isAdmin && (
                  <Button
                    variant="primary"
                    onClick={() => {
                      setStatus("add");
                      handleOpenModal();
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} className="mr-0" /> Add Key
                    Priority
                  </Button>
                )}
              </div>
              <div className="col-md-4 col-sm-12 pt-4 plan-body-right">
                <div className="row">
                  <div className="mx-auto">
                    <img
                      className="img-fluid plan-img-issue mb-3"
                      src={issue}
                      alt="PlanImage"
                    />
                    <p className="plan-title-issue">Issues list</p>
                  </div>
                </div>
                {/* ----------------------ISSUE dynamic field Start-------------------------- */}
                <br></br>
                <p>Issues</p>
                <br />
                <PlanIssue yearNo={yearNo}></PlanIssue>
              </div>
              {/* ----------------------ISSUE dynamic field END-------------------------- */}

              <div className="col-md-1 col-sm-0" />
            </div>
          </>
        ))}
    </div>
  );
};

export default YearPlan;
