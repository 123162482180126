/* eslint-disable arrow-body-style */
import React from 'react';

const SuperAdminDashboard = () => {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-10 pr-3">
                    <h1 className="text-primary pl-3">SuperAdmin Dashboard</h1>
                    <hr className="main-border" />
                </div>
            </div>
        </div>
    );
};

export default SuperAdminDashboard;
