/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable prettier/prettier */
import React from "react";
import { Dropdown, SplitButton } from "react-bootstrap";
import "../Details.scss";
function MeetingAgendaType({ direction, enable, id, updateMeetingAgendaType }) {
  return (
    <div>
      <SplitButton
        key={direction}
        id={`dropdown-button-drop-${direction}`}
        drop={direction}
        variant="light"
        className="rt-dropdbtn"
        name="agendaType"
        disabled={!enable}
      >
        <Dropdown.Item
          eventKey="1"
          onClick={() => {
            updateMeetingAgendaType(id, "Decision");
          }}
        >
          Decision
        </Dropdown.Item>
        <Dropdown.Item
          eventKey="2"
          onClick={() => {
            updateMeetingAgendaType(id, "Discussion");
          }}
        >
          Discussion
        </Dropdown.Item>
        <Dropdown.Item
          eventKey="3"
          onClick={() => {
            updateMeetingAgendaType(id, "FYI");
          }}
        >
          FYI
        </Dropdown.Item>
        <Dropdown.Item
          eventKey="4"
          onClick={() => {
            updateMeetingAgendaType(id, "Note");
          }}
        >
          Note
        </Dropdown.Item>
      </SplitButton>
    </div>
  );
}

export default MeetingAgendaType;
