/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { FaTrash } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import { deleteTaskStreamUser, getAssigendTaskStreams } from '../../api/taskStream';
import { AuthContext } from '../../Context/AuthContext';

const AssignedTaskStream = () => {
    const { workStreamId } = useParams();
    const { oid } = useContext(AuthContext);
    const [selectedUser, setSelectedUser] = useState([]);

    const removeUser = async (id) => {
        const deleteUser = await deleteTaskStreamUser(id);
        if (deleteUser) {
            toast.success('User Deleted From This Stream');
        }
        const res = selectedUser.filter((data) => data.id !== id);
        setSelectedUser(res);
    };

    useEffect(() => {
        const getEmployeeNameEmail = async () => {
            const data = await getAssigendTaskStreams(oid, workStreamId);
            if (data) {
                setSelectedUser(data);
            }
        };
        getEmployeeNameEmail();
    }, [oid, !selectedUser, workStreamId]);

    return (
        <Container fluid>
            <div className="bg-white rounded p-4 create-meeting">
                <h2 className="py-3 title">TaskStream User List</h2>
                <Row>
                    <Col lg={12}>
                        {selectedUser.length > 0 ? (
                            selectedUser?.map((item) => (
                                <div className="guest-list" key={item.id}>
                                    <div className="g-list-item">
                                        <div className="g-name">
                                            <img
                                                src={
                                                    item.profilePicture !== ''
                                                        ? item.profilePicture
                                                        : 'https://i.ibb.co/vVfrXgh/Group-302.png'
                                                }
                                                alt="Profile"
                                            />
                                            <p>{item.displayName}</p>
                                        </div>
                                        <FaTrash
                                            className="mx-3 rt-denger"
                                            onClick={() => removeUser(item.id)}
                                        />
                                    </div>
                                </div>
                            ))
                        ) : (
                            <h4>No User Under This Stream Yet!</h4>
                        )}
                    </Col>
                </Row>

                <Link to="/taskStream/list">
                    <Button size="lg" variant="outline-primary mr-4">
                        Back
                    </Button>
                </Link>
            </div>
        </Container>
    );
};

export default AssignedTaskStream;
