import { useContext, useEffect, useState } from 'react';
import { getAdminByEmail, getEmployeeByEmail } from '../api/profile';
import { AuthContext } from '../Context/AuthContext';

const useAdminUserData = () => {
    const { isAdmin, logged } = useContext(AuthContext);

    const [image, setImage] = useState('https://i.ibb.co/vVfrXgh/Group-302.png');
    const [employee, setEmployee] = useState({});
    const [admin, setAdmin] = useState({});

    useEffect(() => {
        const getValues = async () => {
            if (isAdmin) {
                const resultAdmin = await getAdminByEmail(logged);
                setAdmin(resultAdmin[0]);
                if (resultAdmin[0]?.profilePicture !== '') {
                    setImage(resultAdmin[0]?.profilePicture);
                }
            } else {
                const resultEmployee = await getEmployeeByEmail(logged);
                setEmployee(resultEmployee[0]);

                if (resultEmployee[0]?.ProfilePicture !== '') {
                    setImage(resultEmployee[0]?.ProfilePicture);
                }
            }
        };
        getValues();
    }, [isAdmin, logged]);

    return [admin, image, employee];
};

export default useAdminUserData;
