/* eslint-disable no-else-return */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/button-has-type */
/* eslint-disable no-nested-ternary */
/* eslint-disable func-names */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { React, useContext, useEffect, useState } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { Storage } from "aws-amplify";
import { AiOutlineSearch } from "react-icons/ai";
import { BsPlusCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import {
  getAllEmployeeInfo,
  deleteUserFromCognito,
  deleteEmployeeData,
} from "../../../api/employee";
import { AuthContext } from "../../../Context/AuthContext";
import Pagination from "../../Common/Pagination/Pagination";
import "./EmployeeList.scss";
import EmployeeTable from "./EmployeeTable";
import EmployeeTableInactive from "./EmployeeTableInactive";
import AddEmployeeUploadFormatFile from "./AddEmployeeUploadFormatFile";
import UploadEmployeeFromFile from "./UploadEmployeeFromFile";
const EmployeeList = () => {
  const { isAdmin, oid } = useContext(AuthContext);
  const [emp, setEmpInfo] = useState([]);
  const [empCopy, setEmpInfoCopy] = useState([]);
  const [status, setStatus] = useState("true");
  const [inactiveEmployee, setInactiveEmployee] = useState([]);
  const [inactiveEmployeeCopy, setInactiveEmployeeCopy] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(5);
  const [loading, setLoading] = useState(true);
  const [activeButton, setActiveButton] = useState(true);
  const [pendingButton, setPendingButton] = useState(false);
  const [loader, setLoader] = useState(false);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPOst = indexOfLastPost - postsPerPage;

  const totalPosts = emp.length;
  const totalEmployeeInactive = inactiveEmployee.length;
  const paginate = (pageNum) => setCurrentPage(pageNum);
  const nextPage = () => setCurrentPage(currentPage + 1);
  const prevPage = () => setCurrentPage(currentPage - 1);

  useEffect(() => {
    const getEmployeeData = async () => {
      const response = await getAllEmployeeInfo(oid);
      const activeUser = response?.filter((i) => i.activeFlag === "True");
      const inactiveUser = response?.filter((i) => i.activeFlag !== "True");
      const currentPosts =
        activeUser?.length > 0 &&
        activeUser?.slice(indexOfFirstPOst, indexOfLastPost);

      const currentInactiveUser =
        inactiveUser?.length > 0 &&
        inactiveUser?.slice(indexOfFirstPOst, indexOfLastPost);
      setInactiveEmployee(currentInactiveUser);
      setInactiveEmployeeCopy(currentInactiveUser);
      setEmpInfo(currentPosts);
      setEmpInfoCopy(currentPosts);
      if (response) {
        setLoading(false);
      }
    };
    getEmployeeData();
  }, [oid, !empCopy, !inactiveEmployeeCopy]);

  const deleteActiveUser = async (eid, imageUrl) => {
     const removeUrl = imageUrl.split("public/").pop();
     await Storage.remove(removeUrl, { level: "public" });
    const deletePersonalData = await deleteEmployeeData(eid);
    await deleteUserFromCognito({ username: eid });
    const filterEmployee = emp.filter((cp) => cp.eid !== eid);
    if (deletePersonalData) {
      toast.success("Deleted Successfully");
      setLoader(false);
    }
    setEmpInfo(filterEmployee);
  };

  const handleSearch = async (e) => {
    const activeEmployee = empCopy.map((employee) => {
      const data = employee.firstName.toLowerCase();
      if (data.search(e.target.value.toLowerCase()) !== -1) {
        return employee;
      } else {
        return "";
      }
    });

    const result = activeEmployee.filter((value) => value !== "");
    if (e.target.value === "") {
      setEmpInfo(empCopy);
    } else {
      setEmpInfo(result);
    }

    // for InActive

    const inActiveEmployeeRes = inactiveEmployeeCopy.map((employee) => {
      const data = employee.firstName.toLowerCase();
      if (data.search(e.target.value.toLowerCase()) !== -1) {
        return employee;
      } else {
        return "";
      }
    });

    const result1 = inActiveEmployeeRes.filter((value) => value !== "");
    if (e.target.value === "") {
      setInactiveEmployee(inactiveEmployeeCopy);
    } else {
      setInactiveEmployee(result1);
    }
  };

  const deleteInactiveUser = async (eid, imageUrl) => {
    const removeUrl = imageUrl.split("public/").pop();
    await Storage.remove(removeUrl, { level: "public" });
     const deletePersonalData = await deleteEmployeeData(eid);
    await deleteUserFromCognito({ username: eid });
    const filterEmployee = inactiveEmployee.filter((cp) => cp.eid !== eid);
    if (deletePersonalData) {
      toast.success("Deleted Successfully");
      setLoader(false);
    }
    setInactiveEmployee(filterEmployee);
  };

  return (
    <section className="employee h-100">
      <Container fluid>
        <Row>
          <Col lg={12} md={10} sm={12}>
            <div className="employee_list-top d-flex justify-content-between align-items-center pb-4">
              <h5 className="section-header ml-3 mb-0">
                Employee &gt; All Employee
              </h5>
              { isAdmin && (
                <div className="employee_list-right d-sm-flex">
                  <AddEmployeeUploadFormatFile />
                  <UploadEmployeeFromFile />
                  <Link to="/addemployee">
                    <Button className="add-btn text-white cursor border-0 ml-4 mb-0">
                      <BsPlusCircleFill className="icon text-white mr-2" /> Add
                      Employee
                    </Button>
                  </Link>
                </div>
              )}
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="employee_list-table bg-white main-container p-4 rounded-border-12 min-vh-80 d-flex flex-column">
              {loading ? (
                <div className="d-flex justify-content-center">
                  <Spinner className="rt-spinner" animation="grow" />
                </div>
              ) : empCopy.length === 0 && inactiveEmployee.length === 0 ? (
                <Button className="position-absolute start-50 top-50 translate-middle click-add-btn">
                  {/* <Link to="/addemployee">Click to add</Link> */}
                  Click to add
                </Button>
              ) : (
                <>
                  <div className="employee_list-table-header d-flex align-items-center pb-4 gap-3">
                    <div className="section-header ml-3 mb-0 flex-grow-1">
                      <button
                        className={
                          activeButton
                            ? "btn btn-outline-primary mr-2 active-inactive-button"
                            : "btn btn-outline-primary mr-2"
                        }
                        onClick={() => {
                          setStatus("true");
                          setCurrentPage(1);
                          setActiveButton(true);
                          setPendingButton(false);
                        }}
                      >
                        Active
                      </button>
                      <button
                        className={
                          pendingButton
                            ? "btn btn-outline-primary active-inactive-button"
                            : "btn btn-outline-primary"
                        }
                        onClick={() => {
                          setStatus("false");
                          setActiveButton(false);
                          setPendingButton(true);
                        }}
                      >
                        Pending
                      </button>
                    </div>

                    <div className="search-bar position-relative">
                      <input
                        type="text"
                        placeholder="Search"
                        className="search m-0 form-control pl-4"
                        onChange={(event) => {
                          handleSearch(event);
                        }}
                      />
                      <AiOutlineSearch className="position-absolute translate-middle-y top-50 icon pl-2" />
                    </div>

                    <div className="page-listing">
                      <select
                        id="dropdown"
                        value={postsPerPage}
                        onChange={(e) => setPostsPerPage(e.target.value)}
                      >
                        <option value="5">5 per page</option>
                        <option value="10">10 per page</option>
                        <option value="20">20 per page</option>
                        <option value="30">30 per page</option>
                        <option value="40">40 per page</option>
                      </select>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    {loader === true ? (
                      <Spinner className="rt-spinner" animation="grow" />
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="text-right"></div>
                  <div id="tableExample" className="flex-grow-1">
                    <div>
                      <table className="table bg-white iPhoneXtB pixlTb laptoptbl">
                        <thead className="border-bottom-0 text-center">
                          <tr>
                            <th className="sort border-0" data-sort="name">
                              Name
                            </th>
                            <th
                              className="sort border-0"
                              data-sort="designation"
                            >
                              Email
                            </th>
                            <th className="sort border-0" data-sort="office">
                              Designation
                            </th>
                            <th className="sort border-0" data-sort="salary">
                              Location
                            </th>
                            <th className="sort border-0" data-sort="salary">
                              Phone Number
                            </th>
                            {isAdmin && (
                              <th className="sort border-0" data-sort="age">
                                Action
                              </th>
                            )}
                          </tr>
                        </thead>
                        <tbody className="list text-center">
                          {(totalPosts && status === "true") > 0 &&
                            emp?.map((item) => (
                              <EmployeeTable
                                key={item.id}
                                item={item}
                                deleteActiveUser={deleteActiveUser}
                                isAdmin={isAdmin}
                                setLoader={setLoader}
                              />
                            ))}

                          {totalEmployeeInactive > 0 &&
                            status === "false" &&
                            inactiveEmployee?.map((item) => (
                              <EmployeeTableInactive
                                key={item.id}
                                item={item}
                                deleteInactiveUser={deleteInactiveUser}
                                isAdmin={isAdmin}
                                setLoader={setLoader}
                                loader={loader}
                              />
                            ))}

                          {totalPosts === 0 && totalEmployeeInactive === 0 && (
                            <h4>No Result Found</h4>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <Pagination
                      postsPerPage={postsPerPage}
                      totalPosts={
                        status === "true" ? totalPosts : totalEmployeeInactive
                      }
                      paginate={paginate}
                      nextPage={nextPage}
                      prevPage={prevPage}
                      currentPage={currentPage}
                    />
                  </div>
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default EmployeeList;
