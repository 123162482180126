/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../Context/AuthContext";
import "./Dashboard2nd.scss";

const Dashboard2nd = () => {
  const [meetingData, setMeetingData] = useState(null);
  const [taskData, setTaskData] = useState(null);
  const { oid } = useContext(AuthContext);
  const teamMeeting = process.env.REACT_APP_TEAMMEETINGMODULE_BASE_URL;
  const taskList = process.env.REACT_APP_TASKMODULE_BASE_URL;

  useEffect(() => {
    async function MeetingData() {
      try {
        const response = await axios.get(`${teamMeeting}/${oid}/teammeeting`);
        const newData = await response.data;
        // filter the meetings that occur today
        const today = new Date();
        const todayMeetings = newData.filter((meeting) => {
          const meetingDate = new Date(Date.parse(meeting.meetingDate));
          return meetingDate.toDateString() === today.toDateString();
        });
        setMeetingData(todayMeetings);
      } catch (error) {
        console.error(error);
      }
    }
    MeetingData();
  }, [oid]);

  useEffect(() => {
    async function TaskData() {
      try {
        const response = await axios.get(`${taskList}/${oid}/task`);
        const allTaskData = await response.data;
        // filter the meetings that occur today
        const today = new Date();
        const todayTask = allTaskData.filter((task) => {
          const todayTaskData = new Date(Date.parse(task.dueDate));
          return todayTaskData.toDateString() === today.toDateString();
        });
        setTaskData(todayTask);
      } catch (error) {
        console.error(error);
      }
    }
    TaskData();
  }, [oid]);


  return (
    <div className="container-fluid dashboard">
      <div className="row">
        <div className="col-md-12">
          <h1 className="ml-2 page-title pb-3">Dashboard</h1>
        </div>
      </div>
      <div className="overview p-4">
        <div className="row">
          <div className="col">
            <h2 className="overview-section-title pb-4">Overview</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="todays-meeting">
              <h2 className="pl-4 pb-2">Today&apos;s Meeting</h2>
              {meetingData?.map((meeting) => (
                <div key={meeting.id} className="overview-item">
                  <h6 className="item__title">{meeting.meetingName}</h6>
                  <span className="item__label"> Today </span>
                </div>
              ))}
            </div>
          </div>
          <div className="col-md-6">
            <div className="todays-tasks">
              <h2 className="pl-4 pb-2">Today&apos;s Tasks</h2>
              {taskData?.map((task) => (
              <div key={task.id} className="overview-item">
                <h6 className="item__title">
                    {task.taskName}
                </h6>
                <span className="item__label"> Today </span>
              </div>
              ))}
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-6">
            <div className="announcement">
              <div className="announcement-title">
                <span className="icon">
                    <img src="https://reteambucket161233-dev.s3.us-east-2.amazonaws.com/public/mice.png.png" alt="announcement icon" />
                </span>
                <h2 className="pl-4 m-0">Announcements</h2>
              </div>
              <Link to="/announcementListing" className="see-more-button">
                {" "}
                See more{" "}
              </Link>
            </div>
          </div>
          <div className="col-md-6">
            <div className="announcement mb-4 praise">
              <div className="announcement-title">
                <span className="icon">
                    <img src="https://reteambucket161233-dev.s3.us-east-2.amazonaws.com/public/madal.png.png" alt="praise icon" />
                </span>
                <h2 className="pl-4 m-0">Praise</h2>
              </div>
              <Link to="/badges" className="see-more-button">
                {" "}
                See more{" "}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard2nd;
