/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../images/RTN White.png';

const RegistrationLeftPart = () => (
    <div className="col-md-5 text-center bg-card-gradient">
        <div className="position-relative p-4 pt-md-5 pb-md-7 light">
            <div className="bg-holder bg-auth-card-shape" />

            <div className="z-index-1 position-relative">
                <img src={logo} alt="logo" className="mb-4" width="250px" />
                {/* <h2 className="text-white mb-4 font-sans-serif fs-4 d-inline-block fw-bolder">
                    Reteam Now
                </h2> */}

                <p className="opacity-75 text-white">
                    The only solution you need to create a productive, engaging work environment
                    that organizes employee data cleverly. Take the chaos and confusion out of
                    managing your company's paperwork with an intuitive and easy-to-use toolset that
                    makes overseeing your team’s records, performance, and engagement easier and
                    streamlined!
                </p>
            </div>
        </div>
        <div className="mt-3 mb-4 mt-md-4 mb-md-5 light">
            {window.location.pathname === '/registration' && (
                <p className="text-white">
                    Have account?
                    <br />
                    <Link className="text-decoration-underline text-white" to="/">
                        Login!
                    </Link>
                </p>
            )}
            {window.location.pathname === '/' && (
                <p className="text-white">
                    Don't Have account?
                    <br />
                    <Link className="text-decoration-underline text-white" to="/registration">
                        Get Started!
                    </Link>
                </p>
            )}
        </div>
    </div>
);

export default RegistrationLeftPart;
