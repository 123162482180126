/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable */
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Nav, Row, Tab } from "react-bootstrap";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { createTask, getSingleTask } from "../../../api/task";
import { AuthContext } from "../../../Context/AuthContext";
import NotFoundPage from "../../Common/NotFoundPage";
import MeetingModal from "../../MettingNew/Meeting/MeetingModal";
import "./AddTask.scss";
import TaskComment from "./TaskComment";
import { allTaskWorkStream, getAssigendTaskStreams } from "../../../api/taskStream";

const TaskModal = (props) => {
  const { oid, logged } = useContext(AuthContext);
  const rNow = new Date();
  const today = rNow.toLocaleDateString();
  const [editDescription, setEditDescription] = useState("");
  const [currentUserWorkStreams, setCurrentUserWorkStreams] = useState([]);
  const [singleTask, setSingleTask] = useState({});
  const [show, setShow] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCancel = () => {
    setShow(false);
    setSelectedUser([]);
  };
  const handleAddNewUser = (value) => {
    setSelectedUser(value);
  };
  const history = useHistory();
  const currentUrl = window.location.href;
  const taskId = currentUrl.substring(currentUrl.indexOf("add/") + 4);
  const { register, handleSubmit, reset } = useForm({
    defaultValues: singleTask,
  });
  const modalHide = () => {
    props.onHide();
    history.push("/task",{data:true});
  };

  useEffect(() => {
    const getSingleTaskData = async () => {
      const singleTaskResponse = await getSingleTask(taskId);
      const allWorkStreams = await allTaskWorkStream(oid);

      const currentUserWorkStreamsValue = await getAssigendTaskStreams(
        logged
      );

      if (currentUserWorkStreamsValue?.length > 0) {
        const filteredArray = allWorkStreams.filter((item1) => {
          return currentUserWorkStreamsValue.some(
            (item2) => item2.workStreamId === item1.id
          );
        });
        setCurrentUserWorkStreams(filteredArray);
      } else {
        setCurrentUserWorkStreams([]);
      }

      if (singleTaskResponse) {
        setSingleTask(singleTaskResponse);
        setEditDescription(singleTaskResponse?.taskDescription);
        reset(singleTaskResponse);
      }
    };
    getSingleTaskData();
  }, [reset,logged,oid,taskId]);

  //Creating Task
  const onSubmit = (data) => {
    props.onHide();

    const {
      assignPersonEmail,
      dueDate,
      taskName,
      taskState,
      taskPriority,
      taskEffort,
      workStream
    } = data;

    const insertData = {
      oid,
      id: taskId,
      taskName,
      createdTaskPersonName: "",
      createdTaskPersonEmail: logged,
      delegatedPersonEmail: assignPersonEmail,
      workStream,
      dueDate,
      addUserLoop:selectedUser,
      assignPersonName:"",
      assignPersonImage: "",
      assignPersonEmail:"",
      taskDescription: editDescription,
      copyLinkField: currentUrl,
      createdAt: rNow,
      taskState,
      taskPriority,
      taskEffort,
    };

    const response = createTask(insertData);
    if (response) {
      toast.success("Task Created Successfully");
    } else {
      toast.error("Task Failed");
    }
    setTimeout(() => {
      history.push("/task");
    }, 3000);
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(currentUrl);
    toast.success("Link Copied Successfully");
  };

  const emailOfLoopUser = singleTask?.addUserLoop?.map((item) => item.email);
  return (
    <Modal
      {...props}
      size="xl"
      backdrop="static"
      keyboard={false}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
      className="task-modal"
      centered
    >
      <div className="mb-2">
        {Object.keys(singleTask).length === 0|| (emailOfLoopUser !== undefined && emailOfLoopUser?.includes(logged)) ||  singleTask?.delegatedPersonEmail === logged || singleTask ?.createdTaskPersonEmail === logged ? (
            <Modal.Body className="pt-4">
            <Row>
              <Col lg={6}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col lg={12}>
                      <Form.Group className="mb-3 px-2">
                        <Form.Label>Task Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Title here"
                          className="p-full"
                          {...register("taskName")}
                          // some perams
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={12}>
                      <div className="p-2 w-100">
                        <div className="d-flex justify-content-around text-center p-3 border">
                          <div className="creat d-flex flex-column">
                            <label htmlFor="">Created at</label>
                            {today}
                          </div>
                          <div className="due d-flex flex-column du-dt">
                            <label htmlFor="">Due on</label>
                            <input {...register("dueDate")} type="date" />
                          </div>
                        </div>
                      </div>
                    </Col>
  
                    <Col lg={12}>
                      <div className="d-flex p-2">
                        <Form.Select
                          className="my-2 assignee"
                          {...register("assignPersonEmail")}
                          aria-label="Default select example"
                        >
                          <option value="" selected disabled>
                            Select Name
                          </option>
                          {props?.combineEmailName?.map((item) => (
                            <option value={item.email}>{item.name}</option>
                          ))}
                        </Form.Select>
                        <Form.Select
                          className="ml-3 my-2 assignee"
                          {...register("taskState")}
                          aria-label="Default select example"
                        >
                          <option value="" selected disabled>
                            Select Status
                          </option>
                          <option value="inProgress">InProgress</option>
                          <option value="done">Done</option>
                          <option value="onHold">On Hold</option>
                        </Form.Select>
                        <Form.Select
                          className="ml-3 my-2 assignee"
                          {...register("taskPriority")}
                          aria-label="Default select example"
                        >
                          <option value="" selected disabled>
                            Select Priority
                          </option>
                          <option value="normal">Normal</option>
                          <option value="high">High</option>
                          <option value="highest">Highest</option>
                        </Form.Select>
                        <Form.Select
                          className="ml-3 my-2 assignee"
                          {...register("taskEffort")}
                          aria-label="Default select example"
                        >
                          <option value="" selected disabled>
                            Select Effort
                          </option>
                          <option value="easy">Easy</option>
                          <option value="medium">Medium</option>
                          <option value="huge">Huge</option>
                        </Form.Select>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="d-flex p-2">
                        <Form.Select
                          className="my-2 assignee"
                          {...register("workStream")}
                          aria-label="Default select example"
                        >
                          <option selected disabled value="">
                            Select your workStream
                          </option>
                          <option selected value="Personal">
                            Personal
                          </option>
                          {currentUserWorkStreams?.map((item) => (
                            <option
                              key={item.workStreamId}
                              value={item.workStreamName}
                            >
                              {item.workStreamName}
                            </option>
                          ))}
                          
                        </Form.Select>
                        <Form.Group className="ml-2">
                        <button
                          type="button"
                          data-toggle="modal"
                          data-target="#exampleModalLong"
                          onClick={handleShow}
                          className="form-control create-meeting attendees-select"
                        >
                          Add Users To Loop
                        </button>
                        <MeetingModal
                          show={show}
                          handleClose={handleClose}
                          options={props.combineEmailName}
                          handleAddNewUserImage={handleAddNewUser}
                          handleCancel={handleCancel}
                        />
                      </Form.Group>
                       
                      </div>
                    </Col>
  
                    <Col lg={12}>
                      <div className="h-100 w-100 d-inline-block p-2">
                        <Tab.Container defaultActiveKey="notes">
                          <Nav className="d-flex text-white mb-3" variant="pills">
                            <Nav.Item>
                              <Nav.Link
                                className="btn nav-btn mr-3 px-4 py-1"
                                eventKey="notes"
                              >
                                Notes
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                className="btn nav-btn px-4 py-1"
                                eventKey="attachment"
                              >
                                Attachment
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
  
                          <Tab.Content>
                            <Tab.Pane eventKey="notes" className="task-des ">
                              <Form.Group className="mb-4 pr-1">
                                <CKEditor
                                  editor={ClassicEditor}
                                  data={editDescription}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setEditDescription(data);
                                  }}
                                />
                              </Form.Group>
                            </Tab.Pane>
                            <Tab.Pane eventKey="attachment" className="mb-4">
                              <Form.Group controlId="formFileMultiple">
                                <Form.Control
                                  type="file"
                                  multiple
                                  {...register("attachments")}
                                />
                              </Form.Group>
                            </Tab.Pane>
                          </Tab.Content>
                        </Tab.Container>
                      </div>
                    </Col>
  
                    <Col lg={12}>
                      <div className="px-2">
                        <Button
                          type="submit"
                          className="add-btn text-white cursor border-0  mr-3 px-5 py-2"
                        >
                          Save
                        </Button>
                        <Button
                          onClick={handleCopyLink}
                          className="add-btn text-white cursor border-0  mr-3 px-5 py-2"
                        >
                          Copy Link
                        </Button>
                        <Button
                          variant="light"
                          onClick={modalHide}
                          className="btn nav-btn br-12"
                        >
                          Cancel
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Col>
              <Col lg={6}>
                <div
                  className="d-flex align-items-start flex-column bd-highlight mb-3 date-pick"
                  style={{ height: "80vh" }}
                >
                  <div className="w-100">
                    <TaskComment taskId={taskId} today={today} disabled={Object.keys(singleTask).length === 0 ? true : false} />
                  </div>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        ):(
            <>
            <NotFoundPage/> 
              <Button
                          variant="light"
                          onClick={modalHide}
                          className="btn nav-btn br-12"
                        >
                          Close
                        </Button>
            </>
           
          
        )        
        }
        
    </div>
    </Modal>
  );
};

export default TaskModal;
