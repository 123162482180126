/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */

import { Col, Row, Form } from "react-bootstrap";
import { useState } from "react";
/* eslint-disable jsx-a11y/label-has-associated-control */
function Step3(props) {
  const { errors, register } = props;
  const [passValues, setPassValues] = useState({
    password: "",
    showPassword: false,
  });

  // show/hide password func
  const handleClickShowPassword = () => {
    setPassValues({ ...passValues, showPassword: !passValues.showPassword });
  };
  return (
    <div>
      <div className="form-row flex-column">
        <Row>
          <Col>
            <div className="form-group">
              <label htmlFor="officeLocation">Date of Joining*</label>
              <input
                type="date"
                className="form-control h-70"
                id="dateOfJoining"
                aria-describedby="textHelp"
                placeholder="Date of Joining*"
                {...register("dateOfJoining", {
                  required: {
                    value: true,
                    message: "Please add date of joining",
                  },
                })}
              />
              {errors.dateOfJoining && (
                <span style={{ color: "red" }}>
                  {errors.dateOfJoining.message}
                </span>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="officeLocation">Employment Status*</label>
              <Form.Select
                className="form-control h-70"
                id="employmentStatus"
                {...register("employmentStatus", {
                  required: {
                    value: true,
                    message: "Please select employment status",
                  },
                })}
              >
                <option value="" disabled>
                  Employment Status*
                </option>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </Form.Select>
              {errors.employmentStatus && (
                <span style={{ color: "red" }}>
                  {errors.employmentStatus.message}
                </span>
              )}
            </div>
          </Col>

          <Col>
            <div className="form-group">
              <label htmlFor="officeLocation">Default Password*</label>
              <div className="d-flex w-100 align-items-center">
                <input
                  className="form-control h-70"
                  id="password"
                  aria-describedby="textHelp"
                  placeholder="Default password*"
                  type={passValues.showPassword ? "text" : "password"}
                  {...register("password", {
                    required: {
                      value: true,
                      message: "Please add default password",
                    },
                  })}
                />
                <span
                  className={`small-text cursor mr-2 ${
                    passValues.showPassword ? "text-bold" : ""
                  }`}
                  onClick={handleClickShowPassword}
                >
                  Show
                </span>
              </div>

              {errors.password && (
                <span style={{ color: "red" }}>{errors.password.message}</span>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Step3;
