import React from 'react';
import Modal from 'react-modal';
import { Button } from 'react-bootstrap';
import Warning from '../../../../layouts/MainLayout/icon/Warning.svg';
import RecycleBin from '../../../../layouts/MainLayout/icon/RecycleBin.svg';

const custStyle = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: '1px solid #35A7FF',
        borderRadius: '20px',
        padding: '30px 50px',
        background: '#ffffff0d',
        boxShadow: '2px 2px 10px #0059d24d',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: '450px',
    },
};

const InfoModal = ({
    showInfoModal = false,
    handleInfoModal = () => {},
    isDeleteModal = false,
    actionHandler = () => {},
    className = '',
}) => (
    <Modal
        isOpen={showInfoModal}
        onRequestClose={handleInfoModal}
        dialogClassName={`cust-class ${className}`}
        style={custStyle}
        contentLabel="Actions"
    >
        <img
            src={isDeleteModal ? RecycleBin : Warning}
            alt={isDeleteModal ? 'Recycle bin' : 'Warning'}
            width="100"
            height="auto"
            style={{ display: 'block', objectFit: 'cover', marginBottom: '20px' }}
        />
        <h4 className="info-modal-title">
            {isDeleteModal ? 'Are you sure ?' : 'This role has members included!'}
        </h4>
        <p className="info-modal-description text-center">
            {isDeleteModal
                ? 'You want to delete this role!'
                : 'To delete this role you have to remove all the user first.'}
        </p>

        {isDeleteModal ? (
            <div className="btn-groups">
                <Button className="modal-btn secondary-btn" onClick={handleInfoModal}>
                    Cancel
                </Button>
                <Button className="modal-btn primary-btn" onClick={actionHandler}>
                    Delete
                </Button>
            </div>
        ) : (
            <div className="btn-groups">
                <Button className="modal-btn primary-btn" onClick={handleInfoModal}>
                    Got it
                </Button>
            </div>
        )}
    </Modal>
);
export default InfoModal;
