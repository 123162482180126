/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/self-closing-comp */
/* eslint-disable prefer-template */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable prettier/prettier */
import { Auth, Storage } from 'aws-amplify';
import { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import toast, { Toaster } from "react-hot-toast";
import { AiOutlinePlus } from 'react-icons/ai';
import { MdEdit } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { announcementByEmail, announcementUpdate } from "../../api/announcements";
import { getUserByEmail, updateEmployeeProfilePicture } from '../../api/employee';
import { singleAdminInfoByEmail, updateAdminProfilePicture } from '../../api/superAdmin';
import { AuthContext } from "../../Context/AuthContext";
import './Profile.scss';


/* eslint-disable arrow-body-style */
const Profile = () => {
 
 
  const { isAdmin, oid, logged } = useContext(AuthContext);
  const [show, setShow] = useState(false);
  const [image, setImage] = useState("https://i.ibb.co/vVfrXgh/Group-302.png");
  const [uploadImage, setUploadImage] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [error, setError] = useState(false);
  const [newpwError, setnewpwError] = useState(false);
  const[currentPwValidationError,setCurrentPwValidationError]=useState(false);
  const fileRef = useRef(null);
  const [currentPassword, setCurrentPassword] = useState("");
  const [pw, setPasswordValue] = useState("");
  const [cpw, setConfirmPasswordValue] = useState("");
  const [employee, setEmployee] = useState({});
  const [imageFile, setImageFile] = useState({});
  const [admin, setAdmin] = useState({});
  const [filterAnnouncementByEmail, setFilterAnnouncementByEmail] = useState(
    []
  );
   const history = useHistory();
const [passValues, setPassValues] = useState({
  password: "",
  showPassword: false,
});
const [newPassValues, setnewPassValues] = useState({
  password: "",
  showPassword: false,
});
const [confirmPassValues, setconfirmPassValues] = useState({
  password: "",
  showConfirmPassword: false,
});


  const {
    register,
    
    formState: { errors },
  } = useForm();


const handleCancleButton=(e)=>{
  e.preventDefault();
  history.push("/db");
  

}

  let profileImg = "";

  const uploadImageToS3 = async (file, id) => {
    const profileImgUrl = `profileImg/${id}${file?.name}`;
    profileImg = `${process.env.REACT_APP_IMAGE_BASE_URL}//${profileImgUrl}`;
    const uploadedUrl = await Storage.put(`/${profileImgUrl}`, file, {
      level: "public",
      contentType: file?.type,
    });

    return uploadedUrl;
  };

  const handleLogout = async () => {
    try {
      await Auth.signOut({
        global: true,
      });
      
      if(localStorage.userInfo) {
        localStorage.removeItem("userInfo");

    } else {

        sessionStorage.removeItem("userInfo");
    }
     
      window.location.href = "/";
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };
  
  // show/hide password func
  const handleCurrentClickShowPassword = () => {
    // console.log(...passValues,passValues.showPassword);
    setPassValues({ ...passValues, showPassword: !passValues.showPassword });
  };

  // handle mouseDown func
  const handleCurrentMouseDownPassword = (event) => {
    event.preventDefault();
  };
  // new password func
  const handleNewPassClickShowPassword = () => {
    setnewPassValues({
      ...newPassValues,
      showPassword: !newPassValues.showPassword,
    });
    console.log(newPassValues, newPassValues.showPassword);
  };

  // handle mouseDown func
  const handleNewPasswordMouseDownPassword = (e) => {
    console.log("Clicked");
    e.preventDefault();
  };
  // new password func
  const handleConfirmPassClickShowPassword = () => {
    setconfirmPassValues({
      ...confirmPassValues,
      showConfirmPassword: !confirmPassValues.showConfirmPassword,
    });
    
  };

  // handle mouseDown func
  const handleConfirmPasswordMouseDownPassword = (i) => {
    console.log("Clicked");
    i.preventDefault();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShow(false);
    setUploadImage(true);

    if (isAdmin) {
      if (admin.profilePicture) {
        const removeUrl = admin.profilePicture.split("public/").pop();

        await Storage.remove(removeUrl, { level: "public" });
      }
      const resultUrl = uploadImageToS3(imageFile, admin.id);

      if (resultUrl) {
        const updateResult = await updateAdminProfilePicture(
          admin.id,
          profileImg
        );
        setImage(resultUrl);

        let announcementUpdateResult = "";

        filterAnnouncementByEmail.map(async (data) => {
          announcementUpdateResult = await announcementUpdate(
            data.id,
            data.createdAt,
            profileImg
          );
        });

        if (updateResult) {
          toast.success("Profile picture Updated!", {
            duration: 2000,
            position: "bottom-center",
          });
        }
      }
    } else {
      if (employee.ProfilePicture) {
        const removeUrl = employee.ProfilePicture.split("public/").pop();

        await Storage.remove(removeUrl, { level: "public" });
      }
      const resultUrl = uploadImageToS3(imageFile, employee.eid);
      if (resultUrl) {
        const updateResult = await updateEmployeeProfilePicture(
          employee.eid,
          profileImg
        );
        setImage(resultUrl);

        let announcementUpdateResult = "";

        filterAnnouncementByEmail.map(async (data) => {
          announcementUpdateResult = await announcementUpdate(
            data.id,
            data.createdAt,
            profileImg
          );
        });
        if (updateResult) {
          toast.success("Profile picture Updated!", {
            duration: 2000,
            position: "bottom-center",
          });
        }
      }
    }
  };

  async function updatePassword(nowPassword, newPassword) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      
       console.log(nowPassword,newPassword);
      return await Auth.changePassword(user, nowPassword, newPassword);
     
    } catch (e) {
      console.log(e.message);
setCurrentPwValidationError(e.message);
      return false;
    }
  }
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (cpw !== pw) {
      setError(true);
    } else {
      setError(false);
      const result = updatePassword(currentPassword, pw);
if(pw.length < 8){
  setnewpwError(true);
  return;
}
      if (result && !currentPwValidationError) {
        console.log(result.error);
        toast.success("Password Updated!", {
          duration: 2000,
          position: "bottom-center",
        });
        handleLogout();
      }
    }
  };

  const handleCancel = (e) => {
 
e.preventDefault();
     setUploadImage(false)
    setShow(false);
    if (image === "https://i.ibb.co/vVfrXgh/Group-302.png") {
      setPreviewImage("https://i.ibb.co/vVfrXgh/Group-302.png");
    } else {
      setPreviewImage(image);
    }

    
  };
  const handleImageUpload = async (event) => {
    const file = event.target.files[0];

    if (file && file.type.substring(0, 5) === "image") {
      if (file.size < 2000000) {
        setPreviewImage(URL.createObjectURL(event.target.files[0]));
        setUploadImage(true);
        setImageFile(file);
      } else {
        toast.error("file length exceed!", {
          duration: 2000,
          position: "bottom-center",
        });
      }
    } else {
      setUploadImage(false);
      toast.error("That specified file could not be uploaded", {
        duration: 2000,
        position: "bottom-center",
      });
    }
   
  };
  useEffect(() => {
    const getValues = async () => {
      if (isAdmin) {
        const resultAdmin = await singleAdminInfoByEmail(logged);
        setAdmin(resultAdmin[0]);
        if (resultAdmin[0].profilePicture !== "") {
          setImage(resultAdmin[0].profilePicture);
        }
      } else {
        const resultEmployee = await getUserByEmail(logged);
        setEmployee(resultEmployee[0]);

        if (resultEmployee[0]?.ProfilePicture !== "") {
          setImage(resultEmployee[0]?.ProfilePicture);
        }
      }

      const response = await announcementByEmail(logged);
      setFilterAnnouncementByEmail(response);
    };
    getValues();
  }, [image, previewImage, oid]);
  

  return (
    <section className="profile h-100">
      <h5 className="section-header mx-3 mb-4">User Profile</h5>
      <Container fluid>
        <Row>
          <div className="main-container bg-white p-4 rounded-border-12">
            <Col>
              <div className="profile-image-wrapper">
                <div
                  className="profile-image position-relative"
                  style={{ width: "160px", height: "160px" }}
                >
                  <img
                    src={image}
                    alt="image"
                    className="w-100 h-100 rounded-circle"
                  />
                  <div className="edit-icon text-white border rounded-circle text-center position-absolute cursor">
                    <MdEdit onClick={() => setShow(true)} />
                  </div>
                </div>
              </div>
              <Row>
                <Col>
                  <Form onSubmit={handleFormSubmit}>
                    <h5 className="section-header py-4 mb-2">
                      Change Password
                    </h5>
                    
                    <div className="mb-3 w-50 ">
                      <Form.Group
                       controlId="current-pw"
                      >
                        <div className='d-flex align-items-center input-f'>
                          <Form.Control
                         className='profile-form-control'
                          type={
                            passValues.showPassword
                              ? currentPassword
                              : "password"
                          }
                          placeholder="Current password"
                          onChange={(e) => setCurrentPassword(e.target.value)}
                          required
                        />
                         <span
                        className={`small-text cursor ps-3  ${
                          passValues.showPassword ? "text-bold" : ""
                        }`}
                        onClick={handleCurrentClickShowPassword}
                        onMouseDown={handleCurrentMouseDownPassword}
                      >
                        Show
                      </span>

                        </div>
                        
                      </Form.Group>

                     
                    </div>
                    <div>
                      <div className='py-2'>
                   {
                      currentPwValidationError ?
                        <span className="text-danger">
                        Current Password Does Not matched!
                      </span>

                      :
                      <span >
                     
                      </span>

                    }
                      </div>
                   
                  </div>

                    <Form.Group className="mb-3 w-50" controlId="new-pw">
                      <div className="d-flex align-items-center mb-3 input-f ">
                        <Form.Control
                        className='profile-form-control'
                          type={newPassValues.showPassword ? pw : "password"}
                          placeholder="New password"
                          required
                          onChange={(e) => setPasswordValue(e.target.value)}
                          value={pw}
                        />
                        <span
                          className={`small-text cursor ps-3 ${
                            newPassValues.showPassword ? "text-bold" : ""
                          }`}
                          onClick={handleNewPassClickShowPassword}
                          onMouseDown={handleNewPasswordMouseDownPassword}
                        >
                          Show
                        </span>
                      </div>
                    </Form.Group>
                    {newpwError && (
                      <span className="text-danger">
                        Password Should be atlest 8 Charecter !
                      </span>
                    )}

                    <Form.Group
                      className=" w-50  "
                      controlId="confirm-pw"
                    >
                      <div className="d-flex align-items-center mb-3 input-f ">
                        <Form.Control
                          
                          className="profile-form-control"
                          type={
                            confirmPassValues.showConfirmPassword
                              ? cpw
                              : "password"
                          }
                          placeholder="Confirm new password"
                          required
                          onChange={(e) =>
                            setConfirmPasswordValue(e.target.value)
                          }
                          value={cpw}
                        />
                        <span
                          className={`small-text justify-content-end cursor ps-3 ${
                            confirmPassValues.showConfirmPassword
                              ? "text-bold"
                              : ""
                          }`}
                          onClick={handleConfirmPassClickShowPassword}
                          onMouseDown={handleConfirmPasswordMouseDownPassword}
                        >
                          Show
                        </span>
                      </div>
                      
                    </Form.Group>
                    {error && (
                      <span className="text-danger">
                        Confirm password does not matched!
                      </span>
                    )}
                    {error.length}

                    <div className="buttons-wrapper text-center pt-80">
                      <Button
                        onClick={handleCancleButton}
                        className="custom-btn mr-3"
                      >
                        Cancel
                      </Button>
                      <Button type="submit" className="custom-btn">
                        Save
                      </Button>
                    </div>
                  </Form>
                  
                </Col>
              </Row>
            </Col>
          </div>
        </Row>
        
      </Container>
      <Modal show={show} className="custom-modal">
        <Modal.Header className="border-bottom-0">
          <Modal.Title className="font-weight-normal">
            Update profile picture
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <Form onSubmit={handleSubmit}>
            <Form.Group
              className="mb-3 text-center"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Control
                name="pp"
                type="file"
                accept="/images/*"
                placeholder="choose file"
                autoFocus
                hidden
                ref={fileRef}
                onChange={handleImageUpload}
              />
              <Button
                className="profile-upload-icon rounded-circle text-center border-0 p-0"
                onClick={() => fileRef.current.click()}
              >
                {uploadImage ? (
                  <img
                    src={previewImage}
                    alt=""
                    className="profile-upload-icon rounded-circle text-center border-0"
                  />
                ) : (
                  <AiOutlinePlus />
                )}
              </Button>
            </Form.Group>
            <span className="small-text">Maximum image size - 2MB</span>
            <div className="buttons-wrapper text-center mt-3">
              <Button
                type="button"
                className="custom-btn mr-3"
                // onClick={handleCancel}
                onClick={(e) => history.push("/db")}
              >
                Cancel
              </Button>

              <Button type="submit" className="custom-btn">
                Save
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Toaster />
    </section>
  );
}
 
export default Profile;
