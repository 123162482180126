/* eslint-disable react/no-unescaped-entities */
/* eslint-disable arrow-body-style */
/* eslint-disable react/self-closing-comp */
/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/button-has-type */
/* eslint-disable react-hooks/exhaustive-deps */
// import { faBolt, faBullseye, faFlag, faHeart } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React,{ useState } from 'react';
// eslint-disable-next-line import/extensions
import './EmployeeServey.css';



const EmployeeServey = () => {

    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
      });
    
      const updateFormData = event =>
        setFormData({
          ...formData,
          [event.target.name]: event.target.value
        });
    
      const { firstName, lastName, email } = formData;
    

    return (
        <div className="container-fluid">
            <h4 className="main-menu">Employee Servey</h4>
            <div className='row'>
                <div className="col-md-2 col-sm-2">
                    {/* <Sidebar /> */}
                </div>
                <div className='col-md-10 col-sm-10 form-data'>
                <form>
                <br/>
                <h4>1. What are your career goals?</h4>
                <input
                    className='input-field-employee-servey'
                    value={firstName}
                    onChange={e => updateFormData(e)}
                    placeholder="Question 1"
                    type="text"
                    name="firstName"
                    required
                />
                <br/>
                <h4>2. Would you like to receive any training from us?</h4>
                <input
                    className='input-field-employee-servey'
                    value={lastName}
                    onChange={e => updateFormData(e)}
                    placeholder="Question 2"
                    type="text"
                    name="lastName"
                    required
                />
                <br/>
                <h4>3. What's your most recent accomplishment at work?</h4>
                 <input
                    className='input-field-employee-servey'
                    value={email}
                    onChange={e => updateFormData(e)}
                    placeholder="Question 3"
                    type="text"
                    name="lastName"
                    required
                />

                <button type="submit" className='submit-button-employee-servey'>Submit</button>
                </form>
                </div>
            </div>
        </div>
    );
};

export default EmployeeServey;