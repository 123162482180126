/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-plusplus */
/* eslint-disable  react/no-unknown-property */
import React, { useState } from 'react';
import arrowLeft from '../../../images/Arrow left.png';
import arrowRight from '../../../images/Arrow right.png';
import arrowRightGray from '../../../images/Arrow right gray.svg';
import arrowLeftGray from '../../../images/Arrow left gray.svg';
import './Pagination.scss';

const Pagination = (props) => {
    const { postsPerPage, totalPosts, paginate, nextPage, prevPage, currentPage } = props;
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
    }
    const pageLength = pageNumbers.length;
    const [pageNum1, setPageNum1] = useState(currentPage);
    const [pageNum2, setPageNum2] = useState(currentPage + 1);
    const [pageNum3, setPageNum3] = useState(currentPage + 2);
    const updatePreviousPage = () => {
        // prev button will work if the first page box has value greater than 1. we decrease the value of first three page boxes by 1 when we're not in the last page.
        if (pageNum1 > 1 && currentPage !== pageLength) {
            setPageNum1(pageNum1 - 1);
            setPageNum2(pageNum2 - 1);
            setPageNum3(pageNum3 - 1);
        }
        // if we're in the last page then we should subtract the value of the first three page boxes by 3,2,1 accordingly from the pageLength.
        else if (currentPage === pageLength && pageLength > 3) {
            setPageNum1(pageLength - 3);
            setPageNum2(pageLength - 2);
            setPageNum3(pageLength - 1);
        } else if (currentPage === pageLength && pageLength === 3) {
            setPageNum1(1);
            setPageNum2(2);
            setPageNum3(3);
        }
    };
    const updateNextPage = () => {
        // we increase the value of the first three page boxes by 1 when (the first page box has value less pageLength-1 and current page is less than pageLenth-1),(if we have more than 6 pages),(if current page is greater than 2)
        if (
            pageNum1 < pageLength - 1 &&
            pageLength > 6 &&
            currentPage > 2 &&
            currentPage < pageLength - 1
        ) {
            setPageNum1(pageNum1 + 1);
            setPageNum2(pageNum2 + 1);
            setPageNum3(pageNum3 + 1);
        }
    };
    return (
        <div className="d-flex flex-column ">
            <div className="d-flex flex-row align-items-center justify-content-center pagination-height">
                {/* If current page is 1, prev button is disabled, else it'll work */}
                {currentPage !== 1 ? (
                    <div
                        className="d-flex align-items-center justify-content-center me-2 prev-style"
                        onClick={() => {
                            prevPage();
                            updatePreviousPage();
                        }}
                    >
                        <div>
                            <img className="arrow-margin" src={arrowLeft} alt="" />
                        </div>
                        <div className="ms-2">
                            <p className="enabled-prev-next">Prev</p>
                        </div>
                    </div>
                ) : (
                    <button
                        disabled
                        className="d-flex align-items-center justify-content-center me-2 disabled-prev-next-div"
                    >
                        <div>
                            <img className="arrow-margin" src={arrowLeftGray} alt="" />
                        </div>
                        <div className="ms-2">
                            <p className="disabled-prev-next">Prev</p>
                        </div>
                    </button>
                )}
                {/* Display first pagination button if pageLength is greater than 0. this value will be changed as we click on prev & next button */}
                {pageLength > 0 && (
                    <div
                        className={`${currentPage === pageNum1 ? 'active-page' : 'page-item'}`}
                        onClick={() => paginate(pageNum1)}
                    >
                        <p className="page-item-content">{pageNum1}</p>
                    </div>
                )}
                {/* Display the second pagination button if pageLength is greater than 1. this value will be changed as we click on prev & next button */}
                {pageLength > 1 && (
                    <div
                        className={`${currentPage === pageNum2 ? 'active-page' : 'page-item'}`}
                        onClick={() => paginate(pageNum2)}
                    >
                        <p className="page-item-content">{pageNum2}</p>
                    </div>
                )}
                {/* Display the third pagination button if pageLength is greater than 2. this value will be changed as we click on prev & next button */}
                {pageLength > 2 && (
                    <div
                        className={`${currentPage === pageNum3 ? 'active-page' : 'page-item'}`}
                        onClick={() => paginate(pageNum3)}
                    >
                        <p className="page-item-content">{pageNum3}</p>
                    </div>
                )}
                {/* Display the dots if pageLength is greater than 6 */}
                {pageLength > 6 && (
                    <div className="page-item">
                        <p className="page-dot-content">...</p>
                    </div>
                )}
                {/* Display the fourth pagination button if pageLength is greater than 3 and less than 7. This button won't be shown if pageLength is greater than 7 */}
                {pageLength > 3 && pageLength < 7 && (
                    <div
                        className={`${currentPage === 4 ? 'active-page' : 'page-item'}`}
                        onClick={() => paginate(4)}
                    >
                        <p className="page-item-content">4</p>
                    </div>
                )}
                {/* Display the fifth pagination button if pageLength is greater than 4 and less than 7. This button won't be shown if pageLength is greater than 7 */}
                {pageLength > 4 && pageLength < 7 && (
                    <div
                        className={`${currentPage === 5 ? 'active-page' : 'page-item'}`}
                        onClick={() => paginate(5)}
                    >
                        <p className="page-item-content">5</p>
                    </div>
                )}
                {/* Display the sixth pagination button if pageLength is 6. This button won't be shown if pageLength is greater than 7 */}
                {pageLength === 6 && (
                    <div
                        className={`${currentPage === 6 ? 'active-page' : 'page-item'}`}
                        onClick={() => paginate(6)}
                    >
                        <p className="page-item-content">6</p>
                    </div>
                )}
                {/* Display the last pagination button if pageLength is greater than 6 */}
                {pageLength > 6 && (
                    <>
                        <div
                            className={`${
                                currentPage === pageLength ? 'active-page' : 'page-item'
                            }`}
                            onClick={() => paginate(pageLength)}
                        >
                            <p className="page-item-content">{pageLength}</p>
                        </div>
                    </>
                )}
                {/* If current page isn't the last page, next button will work. else it'll be disabled */}
                {currentPage < pageLength ? (
                    <div
                        className="d-flex align-items-center justify-content-center ms-2 prev-style"
                        onClick={() => {
                            nextPage();
                            updateNextPage();
                        }}
                    >
                        <div className="me-2">
                            <p className="enabled-prev-next">Next</p>
                        </div>
                        <div>
                            <img className="arrow-margin" src={arrowRight} alt="" />
                        </div>
                    </div>
                ) : (
                    <button
                        disabled
                        className="d-flex align-items-center justify-content-center ms-2 disabled-prev-next-div"
                    >
                        <div className="me-2">
                            <p className="disabled-prev-next">Next</p>
                        </div>
                        <div>
                            <img className="arrow-margin" src={arrowRightGray} alt="" />
                        </div>
                    </button>
                )}
            </div>
        </div>
    );
};

export default Pagination;
