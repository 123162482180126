/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable object-shorthand */
/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-named-as-default */

import { Auth, Storage } from "aws-amplify";
import React, { useContext, useState, useEffect } from "react";
import { Col, Row, Container, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory, Link } from "react-router-dom";
import {
  addEmployeeInfo,
  confirmUserSignUp,
  disableUser,
  sendEmailToEmployee,
} from "../../../api/employee";
import "../../../App.scss";
import { AuthContext } from "../../../Context/AuthContext";
import "./AddEmployee.scss";
import ProgressBar from "./addEmployeeComponents/Progressbar";
import Step1 from "./addEmployeeComponents/Step1";
import Step2 from "./addEmployeeComponents/Step2";
import Step3 from "./addEmployeeComponents/Step3";

const AddEmployee = () => {
  const activeFlag = "False";
  const history = useHistory();
  const [count, setCount] = useState(1);
  const [status, setStatus] = useState(false);
  const [displayName, setDisPlayName] = useState("");
  const [roles, setRoles] = useState([]);
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [error, setError] = useState("");
  const { oid } = useContext(AuthContext);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });

  const handleStepCompletion = () => {
    setCount((cur) => cur + 1);
  };

  const handleGoBackToPreviousStep = () => {
    setCount((cur) => cur - 1);
  };

  async function onSubmit(data) {
    setStatus(true);
    if (count > 3) {
      try {
        const response = await Auth.signUp({
          username: data.emailAddress,
          password: data.password,
          attributes: {
            "custom:CompanyName": oid,
          },
        });

        let profileImg = "";
        if (data.profilePicture) {
          const profileImgUrl = `profileImg/${response.userSub}${data.profilePicture[0]?.name}`;
          profileImg = `${process.env.REACT_APP_IMAGE_BASE_URL}//${profileImgUrl}`;
          await Storage.put(`/${profileImgUrl}`, data.profilePicture[0], {
            level: "public",
            contentType: data.profilePicture[0]?.type,
          });
        }

        if (data.employmentStatus === "Active") {
          const username = {
            username: response.userSub,
          };
          const res = await confirmUserSignUp(username);
        } else {
          const username = {
            username: response.userSub,
          };
          const res = await disableUser(username);
        }

        const eid = response.userSub;

        const {
          employmentType,
          designation,
          firstName,
          lastName,
          middleName,
          emailAddress,
          mobileNumber,
          gender,
          dateOfBirth,
          dateOfJoining,
          employmentStatus,
        } = data;

        const employeePersonalDetails = {
          eid,
          oid,
          firstName,
          lastName,
          middleName,
          displayName,
          emailAddress,
          mobileNumber,
          gender,
          dob: dateOfBirth,
          activeFlag: activeFlag,
          ProfilePicture: profileImg,
          officeLocation: `${region},${country}`,
          employmentType,
          designation,
          dateOfJoining,
          employmentStatus
        };

        const emailInfo = {
          to: emailAddress, // Change to your recipient
          from: "testingsidsj@gmail.com", // Change to your verified sender
          subject: "@223232",
          text: "Reteam",
          name: firstName,
          email: emailAddress,
          password: data.password,
        };

        const responseAddEmployee = await addEmployeeInfo(
          employeePersonalDetails
        );
      

        if (
          responseAddEmployee 
        ) {
          history.push("/employeelist");
        } else {
          setError("Error Storing Employee Data!!");
          setError(false);
        }

        const responseEmail = await sendEmailToEmployee(emailInfo);
      } catch (e) {
        console.log(e);
        setError(e.message);
        setStatus(false);
      }
    }
  }

  return (
    <Container fluid>
      <Row>
        <Col className="mt-4">
          <div className="employee-add-form bg-white main-container p-4 rounded-border-12 min-vh-80 mx-3">
            <h5 className="section-header ml-3 mb-0">Add Employee</h5>
            {error ? (
              <>
                <div className="mt-5">
                  <h3 className="alert alert-danger">{error}</h3>
                  <Link className="btn btn-primary" to="/employeelist">
                    Back
                  </Link>
                </div>
              </>
            ) : status ? (
              <div className="d-flex justify-content-center">
                <Spinner className="rt-spinner" animation="grow" />
              </div>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <ProgressBar count={count} />
                {count === 1 && (
                  <Step1
                    errors={errors}
                    register={register}
                    watch={watch}
                    setDisPlayName={setDisPlayName}
                  />
                )}
                {count === 2 && (
                  <Step2
                    errors={errors}
                    register={register}
                    oid={oid}
                    roles={roles}
                    country={country}
                    region={region}
                    setCountry={setCountry}
                    setRegion={setRegion}
                  />
                )}
                {count === 3 && <Step3 errors={errors} register={register} />}
                <div className="form-row">
                  <div className="col-md-12" style={{ marginBottom: "15px" }}>
                    {!isValid && count < 3
                      ? "Please file up required field *"
                      : null}
                  </div>
                </div>

                <div className="action-btns">
                  <button
                    className="btn btn-primary mr-3 rounded-3 px-4"
                    type="button"
                    onClick={handleGoBackToPreviousStep}
                    disabled={count < 2}
                    hidden={status}
                  >
                    Back
                  </button>
                  {count < 3 ? (
                    <button
                      disabled={!isValid}
                      className="btn btn-primary rounded-3 px-4"
                      type="button"
                      onClick={handleStepCompletion}
                    >
                      Next
                    </button>
                  ) : (
                    <button
                      disabled={!isValid}
                      hidden={status}
                      className="btn btn-primary rounded-3 px-4"
                      type="submit"
                      onClick={handleStepCompletion}
                    >
                      Submit
                    </button>
                  )}
                </div>
              </form>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default AddEmployee;
