/* eslint-disable prefer-destructuring */
/* eslint-disable prettier/prettier */
/* eslint-disable consistent-return */
/* eslint-disable import/order */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import { deleteAdmin, getAdminList, getOrganizationList } from '../../api/superAdmin';
import Table from '../Common/Table';
import Pagination from '../Common/Pagination/Pagination';
import { deleteUserFromCognito } from '../../api/employee';

const AdminList = () => {
    const [adminList, setAdminList] = useState([]);
    const [org, setOrg] = useState([]);
    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(20);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPOst = indexOfLastPost - postsPerPage;
    const currentPosts = adminList.length > 0 && adminList.slice(indexOfFirstPOst, indexOfLastPost);
    const totalPosts = adminList.length;
    const paginate = (pageNum) => setCurrentPage(pageNum);
    const nextPage = () => setCurrentPage(currentPage + 1);
    const prevPage = () => setCurrentPage(currentPage - 1);


    useEffect(() => {
        const allGetData = async () => {
            const resultAdminList = await getAdminList();
            setAdminList(resultAdminList);

            setLoading(false);
            const resultOrgList = await getOrganizationList();
            setOrg(resultOrgList);
        };
        allGetData();
    }, []);


    const findOrgName = (orgName) => {
        if (orgName) {
            const findValue = org.find((o) => o.id === orgName);
            return findValue?.companyName;
        }
    };

    const excludeKeys = ['id', 'createdAt','eid','profilePicture'];

    const handleDelete = async (id,eid) => {
        const adminDelete = await deleteAdmin(id);
        const filterAdmin = adminList.filter(admin => admin.id !== id);
         const deleteFromCognito =  await deleteUserFromCognito({ username: eid });
        if (adminDelete && deleteFromCognito) {
            toast.success('Admin deleted successfully!');
            setShow(false);
        }
        setAdminList(filterAdmin);
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-10 col-sm-12">
                    <h1 className="text-dark p-4 employee-listing">Admin Listing</h1>
                    {!loading ? (
                        <div>
                            <div className="admin-list-table">
                                {adminList.length > 0 ? (
                                    <Table
                                        col={currentPosts}
                                        data={currentPosts}
                                        excludeKeys={excludeKeys}
                                        findOrgName={findOrgName}
                                        org={org}
                                        adminEdit="/admin/info"
                                        show={show}
                                        setShow={setShow}
                                        handleDelete={handleDelete}
                                    />
                                ) : (
                                    'Load'
                                )}

                                <Pagination
                                    postsPerPage={postsPerPage}
                                    totalPosts={totalPosts}
                                    paginate={paginate}
                                    nextPage={nextPage}
                                    prevPage={prevPage}
                                    currentPage={currentPage}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="d-flex justify-content-center">
                            <Spinner className="rt-spinner" animation="grow" />
                        </div>
                    )}
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default AdminList;
