import React, { useState, useEffect, useContext, useRef } from 'react';
import { Container, Row, Col, Form, Toast, ToastContainer, Spinner } from 'react-bootstrap';
import { AuthContext } from '../../../Context/AuthContext';
import FullAccess from '../../layouts/MainLayout/icon/FullAccess.svg';
import ViewOnly from '../../layouts/MainLayout/icon/ViewOnly.svg';
import Burger from '../../layouts/MainLayout/icon/Burger.svg';
import CustomDropdownMenu from '../../CustomDropdownMenu/CustomDropdownMenu';
import '../RoleManagement.scss';
import './ModuleControl.scss';
import {
    addRolePermission,
    fetchAllModules,
    fetchRolePermission,
    fetchSingleRolePermission,
    getRoles,
    updateRoleAccess,
} from '../../../api/RoleMangment';

// const fetchAllModules = () => siteApi.get('module');
// const fetchAllRoles = (oid) => siteApi.get(`/roles/${oid}`);
// const fetchRolePermission = (oid) => siteApi.get(`rolePermission/${oid}`);
// const fetchSingleRolePermission = (roleId, moduleId) =>
//     siteApi.get(`rolePermission/${roleId}/${moduleId}`);
// const addRolePermission = (body) => siteApi.post('rolePermission/add', { ...body });
// function updateRoleAccess(...params) {
//     return siteApi.patch(`rolePermission/update/${params[0]}`, {
//         access: { ...params[1] },
//     });
// }

const ModuelControl = () => {
    const { oid } = useContext(AuthContext);
    const [refresh, setRefresh] = useState(false);
    const [showToaster, setShowToaster] = useState(false);
    const [fullAccess, setFullAccess] = useState(false);
    const [modules, setModules] = useState([]);
    const currentSelectedModule = useRef(null);
    const filteredRolePermissionByModule = useRef(null);
    const [rolesPermissionList, setRolePermissionList] = useState([]);
    const [showLoader, setShowLoader] = useState(true);

    const selectHandler = (selectedOption) => {
        currentSelectedModule.current = modules.find(({ id }) => id === selectedOption).id;
        setShowLoader(true);
        setRefresh((currentValue) => !currentValue);
    };
    const handleAccessToggle = (roleId, roleName, accessOption, currentAccessState) => {
        (async () => {
            const { data: singleRolePermission } = await fetchSingleRolePermission(
                roleId,
                currentSelectedModule.current
            );
            if (singleRolePermission.length > 0) {
                singleRolePermission[0].access[accessOption === 'full_access' ? 'edit' : 'view'] =
                    Number(currentAccessState);
                await updateRoleAccess(singleRolePermission[0].id, singleRolePermission[0].access);
            } else {
                const newObj = {
                    oid,
                    roleName,
                    moduleId: currentSelectedModule.current,
                    roleId,
                    access: {
                        view: accessOption === 'view_access' ? Number(currentAccessState) : 0,
                        edit: accessOption === 'full_access' ? Number(currentAccessState) : 0,
                    },
                };
                await addRolePermission(newObj);
            }
            if (currentAccessState) {
                const condition = accessOption === 'full_access';
                setFullAccess(condition);
                setShowToaster(true);
            }
        })();
    };

    useEffect(() => setShowLoader(true), []);
    useEffect(() => {
        if (oid) {
            (async () => {
                const { data: roles } = await getRoles(oid);
                const { data: rolesPermission } = await fetchRolePermission(oid);

                if (currentSelectedModule.current === null) {
                    const { data: modulesList } = await fetchAllModules();
                    currentSelectedModule.current = modulesList[0].id;
                    setModules(modulesList);
                }

                filteredRolePermissionByModule.current = rolesPermission.filter(
                    (role) => role.moduleId === currentSelectedModule.current
                );

                const roleList = roles.map(({ id, roleName }, index) => {
                    const existRolePermission = filteredRolePermissionByModule.current.find(
                        ({ roleId }) => roleId === id
                    );
                    const newObj = { roleId: null, roleName: '', edit: 0, view: 0, key: null };
                    newObj.key = index;
                    newObj.roleId = id;
                    newObj.roleName = roleName;
                    if (existRolePermission) {
                        newObj.edit = existRolePermission.access.edit;
                        newObj.view = existRolePermission.access.view;
                    }
                    return newObj;
                });

                setRolePermissionList([...roleList]);
            })();
        }
    }, [oid, refresh]);
    useEffect(() => {
        if (rolesPermissionList.length > 0) {
            setShowLoader(false);
        }
    }, [rolesPermissionList]);

    return (
        <Container className="px-4">
            <Row>
                <Col className="d-flex justify-content-between align-items-center">
                    <h3 className="nav-title">Role & Module &gt; Module Control</h3>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <Container className="content-container">
                        <ToastContainer position="bottom-center" className="p-3">
                            <Toast
                                className="custom-toaster"
                                onClose={() => setShowToaster(false)}
                                show={showToaster}
                                delay={2000}
                                autohide
                            >
                                <Toast.Body>
                                    <img
                                        src={fullAccess ? FullAccess : ViewOnly}
                                        className="rounded me-2"
                                        alt="Access icon"
                                        width="20"
                                        height="20"
                                    />
                                    <span className="toast-msg">
                                        {fullAccess ? 'Full Access Given!' : 'View Access Given!'}
                                    </span>
                                </Toast.Body>
                            </Toast>
                        </ToastContainer>
                        <Container>
                            <Row className="mb-3">
                                <Col className="p-0">
                                    <CustomDropdownMenu
                                        title={
                                            <img
                                                src={Burger}
                                                alt="module dropdown"
                                                width={20}
                                                height="auto"
                                            />
                                        }
                                        menuItems={modules}
                                        menuItemProperty="moduleName"
                                        selectHandler={(selectedOption) =>
                                            selectHandler(selectedOption)
                                        }
                                        className="module-selector"
                                        activeItem={
                                            modules.length > 0 &&
                                            modules.find(
                                                ({ id }) => id === currentSelectedModule.current
                                            ).moduleName
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-3 mx-4">
                                <Col className="col-xxl-8 col-xl-8 col-lg-8 col-md-6 column-title">
                                    Role
                                </Col>
                                <Col className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 column-title text-center">
                                    Edit/Delete/View
                                </Col>
                                <Col className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 column-title text-center">
                                    View Only
                                </Col>
                            </Row>
                            <Row>
                                <Col className="capsule-wrapper">
                                    {showLoader && (
                                        <Row>
                                            <Col className="loader-container">
                                                <Spinner animation="border" role="status">
                                                    <span className="visually-hidden">
                                                        Loading...
                                                    </span>
                                                </Spinner>
                                            </Col>
                                        </Row>
                                    )}
                                    {!showLoader &&
                                        rolesPermissionList.length > 0 &&
                                        rolesPermissionList.map(
                                            ({ key, roleId, roleName, edit, view }) => (
                                                <Row className="capsule mb-3 mx-2" key={key}>
                                                    <Col className="col-xxl-8 col-xl-8 col-lg-8 col-md-6 d-flex align-items-center">
                                                        <span className="w-100">{roleName}</span>
                                                    </Col>
                                                    <Col className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 d-flex justify-content-center align-items-center">
                                                        <Form.Check
                                                            className="cust-switch-btn"
                                                            type="switch"
                                                            id="all-access-switch"
                                                            defaultChecked={Boolean(edit)}
                                                            onChange={(val) =>
                                                                handleAccessToggle(
                                                                    roleId,
                                                                    roleName,
                                                                    'full_access',
                                                                    val.target.checked
                                                                )
                                                            }
                                                        />
                                                    </Col>
                                                    <Col className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 d-flex justify-content-center align-items-center">
                                                        <Form.Check
                                                            className="cust-switch-btn"
                                                            type="switch"
                                                            id="view-only-switch"
                                                            defaultChecked={Boolean(view)}
                                                            onChange={(val) =>
                                                                handleAccessToggle(
                                                                    roleId,
                                                                    roleName,
                                                                    'view_only_access',
                                                                    val.target.checked
                                                                )
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                            )
                                        )}
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
};

export default ModuelControl;
