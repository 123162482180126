import React from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import "./CustomDropdownMenu.scss";

const CustomDropdownMenu = ({
  title,
  menuItems,
  menuItemProperty,
  selectHandler,
  className,
  activeItem,
  eventKey = "id",
}) => (
  <DropdownButton
    title={title}
    className={`cust-dropdown-btn ${className}`}
    onClick={(evt) => evt.stopPropagation()}
    onSelect={selectHandler}
  >
    {menuItems.length > 0 &&
      menuItems.map((item) => (
        <Dropdown.Item
          key={item[menuItemProperty]}
          eventKey={item[eventKey]}
          className={`${item[menuItemProperty] === activeItem ? "active-menu-item" : ""}`}
        >
          {item[menuItemProperty]}
        </Dropdown.Item>
      ))}
  </DropdownButton>
);

export default CustomDropdownMenu;
