/* eslint-disable no-unneeded-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable import/order */
/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState, useContext } from "react";
// eslint-disable-next-line import/order
import { dragNodeService, selectNodeService } from "./service";
import { updateOrganizationChartNode } from "../../../../api/organizationChart";
// eslint-disable-next-line import/newline-after-import, import/order
import Avatar from "react-avatar";
import { AiOutlinePlus } from "react-icons/ai";
import toast from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import { Storage } from "aws-amplify";
import "./ChartNode.scss";
import { AuthContext } from "../../../../Context/AuthContext";
import PersonRole from "./PersonRole";
const propTypes = {
  datasource: PropTypes.object,
  status: PropTypes.bool,
  remove: PropTypes.func,
  NodeTemplate: PropTypes.elementType,
  draggable: PropTypes.bool,
  collapsible: PropTypes.bool,
  changeHierarchy: PropTypes.func,
  onClickNode: PropTypes.func,
  updateIndividualNode: PropTypes.func,
};

const defaultProps = {
  draggable: false,
  collapsible: true,
};

const ChartNode = ({
  datasource,
  status,
  remove,
  NodeTemplate,
  draggable,
  collapsible,
  changeHierarchy,
  onClickNode,
  selectedNode,
  updateIndividualNode,
}) => {
  const node = useRef();
  const values = useContext(AuthContext);
  const fileRef = useRef(null);
  const [isChildrenCollapsed, setIsChildrenCollapsed] = useState(false);
  const [topEdgeExpanded, setTopEdgeExpanded] = useState();
  const [rightEdgeExpanded, setRightEdgeExpanded] = useState();
  const [bottomEdgeExpanded, setBottomEdgeExpanded] = useState();
  const [leftEdgeExpanded, setLeftEdgeExpanded] = useState();
  const [allowedDrop, setAllowedDrop] = useState(false);
  const [selected, setSelected] = useState(false);
  const [edit, setEdit] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [taglist, setTagList] = useState(["ceo", "coo", "manager"]);
  const [searchVal, setSearchVal] = useState("");

  const [tagItem, setTagItem] = useState("");
  const [updateName, setUpdateName] = useState("");
  const [updateDesignation, setUpdateDesignation] = useState("");
  const [avatarName, setAvatarName] = useState("");
  const selectedTag = (e) => {
    setTagItem(e.target.value);
  };

  // eslint-disable-next-line arrow-body-style
  const filteredTag = taglist.filter((item) => {
    return item.includes(searchVal);
  });

  const handleInput = (e) => {
    setSearchVal(e.target.value);
  };

  const handleEdit = () => {
    if (values?.isAdmin === true && status === true) {
      setEdit(true);
    } else {
      setEdit(false);
    }
  };
  let profileImg = "";
  const uploadImageToS3 = async (file, id) => {
    const profileImgUrl = `profileImg/${id}${file?.name}`;
    profileImg = `${process.env.REACT_APP_IMAGE_BASE_URL}//${profileImgUrl}`;
    const uploadedUrl = await Storage.put(`/${profileImgUrl}`, file, {
      level: "public",
      contentType: file?.type,
    });

    return uploadedUrl;
  };
  const imageUpload = async (event, previousPhotoValue) => {
    const {
      id,
      createdAt,
      oid,
      personName,
      parentID,
      designation,
      photoURL,
      personRoles,
      tag,
    } = selectedNode;
    if (photoURL) {
      const removeUrl = photoURL.split("public/").pop();

      await Storage.remove(removeUrl, { level: "public" });
      console.log(removeUrl);
    }
    const file = event.target.files[0];
    const resultUrl = uploadImageToS3(file, id);

    if (resultUrl) {
      const updateData = {
        oid,
        parentID,
        designation: updateDesignation === "" ? designation : updateDesignation,
        personName: updateName === "" ? personName : updateName,
        photoURL: profileImg,
        personRoles,
        tag,
      };
      const updateResult = await updateOrganizationChartNode(
        id,
        createdAt,
        updateData
      );
      if (updateResult) {
        toast.success(" picture Updated!", {
          duration: 2000,
          position: "bottom-center",
        });
      }
    }
  };

  const nodeClass = [
    "oc-node",
    isChildrenCollapsed ? "isChildrenCollapsed" : "",
    allowedDrop ? "allowedDrop" : "",
    selected ? "selected" : "",
  ]
    .filter((item) => item)

    .join(" ");

  useEffect(() => {
    const subs1 = dragNodeService.getDragInfo().subscribe((draggedInfo) => {
      if (draggedInfo) {
        setAllowedDrop(
          !document
            .querySelector(`#${draggedInfo.draggedNodeId}`)
            .closest("li")
            .querySelector(`#${node.current.id}`)
        );
      } else {
        setAllowedDrop(false);
      }
    });

    const subs2 = selectNodeService
      .getSelectedNodeInfo()
      .subscribe((selectedNodeInfo) => {
        if (selectedNodeInfo) {
          setSelected(selectedNodeInfo.selectedNodeId === datasource.id);
        } else {
          setSelected(false);
        }
      });

    return () => {
      subs1.unsubscribe();
      subs2.unsubscribe();
    };
  }, []);

  const tagref = useRef();

  useEffect(() => {
    const handler = (e) => {
      if (!tagref.current.contains(e.target)) {
        setModalShow(false);
      }
    };
    document.addEventListener("mousedown", handler);
  }, []);

  const addArrows = (e) => {
    // eslint-disable-next-line no-shadow
    const node = e.target.closest("li");
    const parent = node.parentNode.closest("li");
    const isAncestorsCollapsed =
      node && parent
        ? parent.firstChild.classList.contains("hidden")
        : undefined;
    const isSiblingsCollapsed = Array.from(node.parentNode.children).some(
      (item) => item.classList.contains("hidden")
    );

    setTopEdgeExpanded(!isAncestorsCollapsed);
    setRightEdgeExpanded(!isSiblingsCollapsed);
    setLeftEdgeExpanded(!isSiblingsCollapsed);
    setBottomEdgeExpanded(!isChildrenCollapsed);
  };

  const removeArrows = () => {
    setTopEdgeExpanded(undefined);
    setRightEdgeExpanded(undefined);
    setBottomEdgeExpanded(undefined);
    setLeftEdgeExpanded(undefined);
  };

  const toggleAncestors = (actionNode) => {
    // eslint-disable-next-line no-shadow
    const node = actionNode.parentNode.closest("li");
    if (!node) return;
    const isAncestorsCollapsed = node.firstChild.classList.contains("hidden");
    if (isAncestorsCollapsed) {
      actionNode.classList.remove("isAncestorsCollapsed");
      node.firstChild.classList.remove("hidden");
    } else {
      const isSiblingsCollapsed = Array.from(
        actionNode.parentNode.children
      ).some((item) => item.classList.contains("hidden"));
      if (!isSiblingsCollapsed) {
        // eslint-disable-next-line no-use-before-define
        toggleSiblings(actionNode);
      }
      actionNode.classList.add(
        ...`isAncestorsCollapsed${
          isSiblingsCollapsed ? "" : " isSiblingsCollapsed"
        }`.split(" ")
      );
      node.firstChild.classList.add("hidden");
      if (
        node.parentNode.closest("li") &&
        !node.parentNode.closest("li").firstChild.classList.contains("hidden")
      ) {
        toggleAncestors(node);
      }
    }
  };

  const topEdgeClickHandler = (e) => {
    e.stopPropagation();
    setTopEdgeExpanded(!topEdgeExpanded);
    toggleAncestors(e.target.closest("li"));
  };

  const bottomEdgeClickHandler = (e) => {
    e.stopPropagation();
    setIsChildrenCollapsed(!isChildrenCollapsed);
    setBottomEdgeExpanded(!bottomEdgeExpanded);
  };

  const toggleSiblings = (actionNode) => {
    let node = actionNode.previousSibling;
    const isSiblingsCollapsed = Array.from(actionNode.parentNode.children).some(
      (item) => item.classList.contains("hidden")
    );
    actionNode.classList.toggle("isSiblingsCollapsed", !isSiblingsCollapsed);
    while (node) {
      if (isSiblingsCollapsed) {
        node.classList.remove("hidden");
      } else {
        node.classList.add("hidden");
      }
      node = node.previousSibling;
    }
    node = actionNode.nextSibling;
    while (node) {
      if (isSiblingsCollapsed) {
        node.classList.remove("hidden");
      } else {
        node.classList.add("hidden");
      }
      node = node.nextSibling;
    }
    const isAncestorsCollapsed = actionNode.parentNode
      .closest("li")
      .firstChild.classList.contains("hidden");
    if (isAncestorsCollapsed) {
      toggleAncestors(actionNode);
    }
  };

  const hEdgeClickHandler = (e) => {
    e.stopPropagation();
    setLeftEdgeExpanded(!leftEdgeExpanded);
    setRightEdgeExpanded(!rightEdgeExpanded);
    toggleSiblings(e.target.closest("li"));
  };

  const filterAllowedDropNodes = (id) => {
    dragNodeService.sendDragInfo(id);
  };

  // eslint-disable-next-line no-unused-vars
  const clickNodeHandler = (event) => {
    if (updateIndividualNode) {
      updateIndividualNode(datasource);
    }
    selectNodeService.sendSelectedNodeInfo(datasource.id);
  };

  const dragstartHandler = (event) => {
    const copyDS = { ...datasource };
    delete copyDS.relationship;
    event.dataTransfer.setData("text/plain", JSON.stringify(copyDS));
    // highlight all potential drop targets
    filterAllowedDropNodes(node.current.id);
  };

  const dragoverHandler = (event) => {
    // prevent default to allow drop
    event.preventDefault();
  };

  const dragendHandler = () => {
    // reset background of all potential drop targets
    dragNodeService.clearDragInfo();
  };

  const dropHandler = (event) => {
    if (!event.currentTarget.classList.contains("allowedDrop")) {
      return;
    }
    dragNodeService.clearDragInfo();
    changeHierarchy(
      JSON.parse(event.dataTransfer.getData("text/plain")),
      event.currentTarget.id
    );
  };

  // change node field name
  const changeName = (event) => {
    const getEventValue = event.target.value;
    setUpdateName(getEventValue);
    const {
      id,
      createdAt,
      oid,
      parentID,
      designation,
      photoURL,
      personRoles,
      tag,
    } = selectedNode;
    const updateData = {
      oid,
      parentID,
      designation: updateDesignation === "" ? designation : updateDesignation,
      personName: getEventValue,
      photoURL,
      personRoles,
      tag,
    };
    if (getEventValue !== updateName) {
      const updateResult = updateOrganizationChartNode(
        id,
        createdAt,
        updateData
      );
      if (updateResult) {
        toast.success(" Name Updated!", {
          duration: 1000,
          position: "bottom-center",
        });
      }
    }
  };

  // change node field designation
  const changeDesignation = (event) => {
    const getEventValue = event.target.value;
    console.log(getEventValue);
    setUpdateDesignation(getEventValue);
    const {
      id,
      createdAt,
      parentID,
      oid,
      personName,
      photoURL,
      personRoles,
      tag,
    } = selectedNode;
    const updateData = {
      oid,
      parentID,
      personName: updateName === "" ? personName : updateName,
      designation: getEventValue,
      photoURL,
      personRoles,
      tag,
    };
    if (getEventValue !== updateDesignation) {
      const updateResult = updateOrganizationChartNode(
        id,
        createdAt,
        updateData
      );
      if (updateResult) {
        toast.success(" Role Updated!", {
          duration: 1000,
          position: "bottom-center",
        });
      }
    }
  };

  return (
    <>
      <li className="oc-hierarchy">
        <div
          ref={node}
          id={datasource.id}
          className={nodeClass}
          draggable={draggable ? "true" : undefined}
          onClick={clickNodeHandler}
          onDragStart={dragstartHandler}
          onDragOver={dragoverHandler}
          onDragEnd={dragendHandler}
          onDrop={dropHandler}
          onMouseEnter={addArrows}
          onMouseLeave={removeArrows}
        >
          {NodeTemplate ? (
            <NodeTemplate nodeData={datasource} />
          ) : (
            <>
              <div className="card tree-card shadow-none">
                <div className="thumbnail">
                  {/* person Designation */}
                  {datasource.designation && !edit ? (
                    <p onClick={handleEdit} className="card-header p-0">
                      {updateDesignation === ""
                        ? datasource.designation
                        : updateDesignation}

                      {values?.isAdmin === true && status === true ? (
                        <button
                          onClick={() => {
                            remove(datasource);
                          }}
                          type="button"
                          className="delete-button"
                          disabled={datasource?.children?.length > 0}
                        >
                          ×
                        </button>
                      ) : values?.isAdmin === true && status === false ? (
                        ""
                      ) : (
                        ""
                      )}
                    </p>
                  ) : (
                    // eslint-disable-next-line react/self-closing-comp, no-unused-vars
                    <div className="d-flex gap-3">
                      <input
                        onClick={handleEdit}
                        onBlur={(event) => {
                          setEdit(false);
                          changeDesignation(event);
                        }}
                        defaultValue={
                          updateDesignation === ""
                            ? datasource.designation
                            : updateDesignation
                        }
                        placeholder="Give a position name"
                        disabled={
                          values.isAdmin === "false" || status === false
                        }
                      ></input>

                      {values?.isAdmin === true && status === true ? (
                        <button
                          onClick={() => {
                            remove(datasource);
                          }}
                          type="button"
                          className="delete-button border-0 bg-transparent"
                          disabled={datasource?.children?.length > 0}
                        >
                          ×
                        </button>
                      ) : values?.isAdmin === true && status === false ? (
                        ""
                      ) : (
                        ""
                      )}

                      {/* <button
                        onClick={() => {
                          remove(datasource);
                        }}
                        type="button"
                        className="delete-button border-0 bg-transparent"
                        disabled={
                          datasource?.children?.length > 0 ||
                          values.isAdmin === "false"
                        }
                      >
                        ×
                      </button> */}
                    </div>
                  )}
                </div>

                <div className="card-body relative px-0 pb-0">
                  <div className="d-flex tree-text align-items-center">
                    <Avatar
                      name={avatarName ? avatarName : datasource.personName}
                      // src={datasource.photoURL}
                      className="text-dark"
                      color="#F0F0F0"
                      size="30"
                      round
                      type="file"
                      accept="/images/*"
                      ref={fileRef}
                    />

                    {datasource.personName && !edit ? (
                      <p
                        onClick={handleEdit}
                        className="seat-owner mb-0"
                        disabled={values.isAdmin === "false"}
                      >
                        {updateName === "" ? datasource.personName : updateName}
                      </p>
                    ) : (
                      // eslint-disable-next-line react/self-closing-comp, no-unused-vars
                      <div>
                        {values?.isAdmin === true && status === true ? (
                          <input
                            onBlur={(event) => {
                              setEdit(false);
                              changeName(event);
                              setAvatarName(event.target.value);
                            }}
                            defaultValue={
                              updateName === ""
                                ? datasource.personName
                                : updateName
                            }
                            className="seat-owner ml-2"
                            placeholder="Who is in this seat?"
                            disabled={values?.isAdmin === "false"}
                          />
                        ) : (
                          <p className="seat-owner mb-0"></p>
                        )}
                      </div>
                    )}
                  </div>
                  <PersonRole
                    personrole={datasource?.personRoles}
                    status={status}
                    selectedNode={selectedNode}
                  ></PersonRole>
                  {/* <Form>
                    <Form.Control
                      name="pp"
                      type="file"
                      accept="/images/*"
                      placeholder="choose file"
                      autoFocus
                      hidden
                      ref={fileRef}
                      onChange={imageUpload}
                    />
                  </Form> */}
                  {/* <input
                    name="upload-image"
                    type="file"
                    accept="/images/*"
                    ref={fileRef}
                    onChange={(event) => {
                      imageUpload(event, datasource.photoURL);
                    }}
                    // onChange={(event) => {
                    //   readImage(event, setImage);
                    // }}
                  /> */}
                  {/* <div className="tag-wrapper text-left pt-3 d-flex align-items-center gap-2">
                    <ul
                      className="tags gap-2"
                      disabled={values?.isAdmin !== "true"}
                    >
                      {tag.map((item) => (
                        <li>{item}</li>
                      ))}
                    </ul>
                    <span
                      className="tag-icon cursor"
                      onClick={() => setModalShow(true)}
                    >
                      <BsTagFill />
                    </span>
                  </div> */}
                  {/* <!-- Modal --> */}
                  <div
                    className="modal fade"
                    id="exampleModal"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel">
                            Child Node
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">...</div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            Close
                          </button>
                          <button type="button" className="btn btn-primary">
                            Save changes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {values?.isAdmin === true && status === true ? (
                    <span
                      className="tree-add-icon"
                      onClick={() => {
                        onClickNode(datasource, datasource.id);
                      }}
                    >
                      <AiOutlinePlus />
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </>
          )}
          {collapsible &&
            datasource.relationship &&
            datasource.relationship.charAt(0) === "1" && (
              <i
                className={`oc-edge verticalEdge topEdge oci ${
                  topEdgeExpanded === undefined
                    ? ""
                    : topEdgeExpanded
                    ? "oci-chevron-down"
                    : "oci-chevron-up"
                }`}
                onClick={topEdgeClickHandler}
              />
            )}
          {collapsible &&
            datasource.relationship &&
            datasource.relationship.charAt(1) === "1" && (
              <>
                <i
                  className={`oc-edge horizontalEdge rightEdge oci ${
                    rightEdgeExpanded === undefined
                      ? ""
                      : rightEdgeExpanded
                      ? "oci-chevron-left"
                      : "oci-chevron-right"
                  }`}
                  onClick={hEdgeClickHandler}
                />
                <i
                  className={`oc-edge horizontalEdge leftEdge oci ${
                    leftEdgeExpanded === undefined
                      ? ""
                      : leftEdgeExpanded
                      ? "oci-chevron-right"
                      : "oci-chevron-left"
                  }`}
                  onClick={hEdgeClickHandler}
                />
              </>
            )}
          {collapsible &&
            datasource.relationship &&
            datasource.relationship.charAt(2) === "1" && (
              <i
                className={`oc-edge verticalEdge bottomEdge oci ${
                  bottomEdgeExpanded === undefined
                    ? ""
                    : bottomEdgeExpanded
                    ? "oci-chevron-up"
                    : "oci-chevron-down"
                }`}
                onClick={bottomEdgeClickHandler}
              />
            )}

          {/* modalShow={modalShow}  */}

          {modalShow && (
            <div className="tag-modal" ref={tagref}>
              <div className="tag-content">
                <input
                  className="w-100"
                  placeholder="Search or create new"
                  onChange={handleInput}
                  value={searchVal}
                  type="text"
                  name="product-search"
                  id="product-search"
                />

                <ul className="tags tag-list flex-column align-items-start gap-3 mt-3">
                  {filteredTag.map((item) => (
                    <li onClick={selectedTag}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
        {datasource.children && datasource.children.length > 0 && (
          <ul className={isChildrenCollapsed ? "hidden" : ""}>
            {datasource.children.map((node) => (
              <ChartNode
                datasource={node}
                status={status}
                remove={remove}
                NodeTemplate={NodeTemplate}
                id={node.id}
                key={node.id}
                draggable={draggable}
                collapsible={collapsible}
                changeHierarchy={changeHierarchy}
                onClickNode={onClickNode}
                selectedNode={selectedNode}
                updateIndividualNode={updateIndividualNode}
              />
            ))}
          </ul>
        )}
      </li>
    </>
  );
};

ChartNode.propTypes = propTypes;
ChartNode.defaultProps = defaultProps;

export default ChartNode;
