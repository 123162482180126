import React, { useContext, useEffect, useState } from 'react';
import { getAllEmployeeMergedData } from '../../../api/employee';
import { AuthContext } from '../../../Context/AuthContext';
import TaskModal from './TaskModal';
const AddTask = () => {
    const { oid, logged } = useContext(AuthContext);
    const [modalShow, setModalShow] = useState(true);
    const [combineEmailName, setCombineEmailName] = useState([]);
    useEffect(() => {
        const getEmployeeNameEmail = async () => {
            const EmployeeData = await getAllEmployeeMergedData(oid);
            const formattedUserEmail = EmployeeData?.data
                .map((item) => (item.emailAddress ? item.emailAddress : item.adminEmail))
                .filter((email) => email !== logged);

            const formattedName =
                EmployeeData?.data.length > 0 &&
                EmployeeData?.data
                    ?.filter((item) =>
                        item.emailAddress
                            ? item.emailAddress !== logged
                            : item.adminEmail !== logged
                    )
                    ?.map((i) =>
                        i.displayName
                            ? i.displayName
                            : i.adminFirstName.concat(` ${i.adminLastName}`)
                    );

            const profilePicture =
                EmployeeData?.data.length > 0 &&
                EmployeeData?.data
                    ?.filter((item) =>
                        item.emailAddress
                            ? item.emailAddress !== logged
                            : item.adminEmail !== logged
                    )
                    ?.map((i) => (i.ProfilePicture ? i.ProfilePicture : i.profilePicture));

            setCombineEmailName(
                formattedName?.map((value, index) => ({
                    name: value,
                    email: formattedUserEmail[index],
                    profile: profilePicture[index],
                }))
            );
        };
        getEmployeeNameEmail();
    }, [oid, logged]);

    return (
        <>
            <TaskModal
                show={modalShow}
                combineEmailName={combineEmailName}
                onHide={() => setModalShow(false)}
            />
        </>
    );
};

export default AddTask;
