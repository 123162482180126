/* eslint-disable no-return-await */
/* eslint-disable import/prefer-default-export */
/* eslint-disable consistent-return */
/* eslint-disable prettier/prettier */
/* eslint-disable arrow-body-style */

import  axios  from 'axios';

const workStreamApi =  process.env.REACT_APP_WorkStream_API;

const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
};

const allWorkStream = async (oid) => {
    const response = await axios.get(`${workStreamApi}/workStream/${oid}`);
    if (response) {
        return response?.data;
    }
};

const insertWorkStream = async (data) => {
    const response = await axios.post(`${workStreamApi}/workStream/add`, data);
    if (response) {
        return response;
    }
};

const getSingleWorkStream = async (id) => {
    const response = await axios.get(`${workStreamApi}/workStream/item/${id}`);
    if (response) {
        return response?.data;
    }
};

const updateWorkStream = async (id, data) => {
    const response = await axios.put(`${workStreamApi}/workStream/update/${id}`,data,{headers});
    if (response) {
        return response;
    }
};

const deleteWorkStream = async (id) => {
    const response = await axios.delete(`${workStreamApi}/workStream/delete/${id}`);
    if (response) {
        return response;
    }
};


// Assigned Work Stream 

const insertAssignWorkStream = async (data) => {
    const response = await axios.post(`${workStreamApi}/assignedWorkStream/add`, data);
    if (response) {
        return response;
    }
};

const getAssigendStreams = async (oid,workStreamId) => {
    const response = await axios.get(`${workStreamApi}/assignedWorkStream/all/${oid}/${workStreamId}`);
    if (response) {
        return response?.data;
    }
};

const getAssigendStreamsByEmail = async (email) => {
    const response = await axios.get(`${workStreamApi}/assignedWorkStream/getByEmail/items/${email}`);
    if (response) {
        return response?.data;
    }
};

const deleteWorkStreamUser = async (id) => {
    const response = await axios.delete(`${workStreamApi}/assignedWorkStream/delete/${id}`);
    if (response) {
        return response;
    }
};


export {allWorkStream,insertWorkStream,getSingleWorkStream,updateWorkStream,deleteWorkStreamUser,deleteWorkStream,insertAssignWorkStream,getAssigendStreams,getAssigendStreamsByEmail}