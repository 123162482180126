/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
// eslint-disable-next-line prefer-arrow-callback, array-callback-return, consistent-return, func-names
import { React, useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import { AiOutlineSearch } from 'react-icons/ai';
import { BsPlusCircleFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { announcementDelete, announcementList } from '../../../api/announcements';
import { AuthContext } from '../../../Context/AuthContext';
import Pagination from '../../Common/Pagination/Pagination';
import AnnouncementModal from '../AnnouncemetModal';
import Table from './Table';


const AnnouncementList = () => {
    const { isAdmin, oid, logged } = useContext(AuthContext);
    const [announcements, setAnnouncements] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(5);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPOst = indexOfLastPost - postsPerPage;
    const currentPosts = announcements.length > 0 && announcements.slice(indexOfFirstPOst, indexOfLastPost);
    const totalPosts = announcements.length;
    const paginate = (pageNum) => setCurrentPage(pageNum);
    const nextPage = () => setCurrentPage(currentPage + 1);
    const prevPage = () => setCurrentPage(currentPage - 1);
    const [searchTerm, setSearchTerm] = useState('');
    const [documentShow, setDocumentShow] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getData = async () => {
            const response = await announcementList(oid)
            setAnnouncements(response);
            if(response){
                setLoading(false)
             }
        }
        getData()
    }, [oid, !currentPosts])

    const handleClick = (item) => {
        setDocumentShow(true)
        setModalData(item)
    }

    const handleDelete = async (id, createdAt) => {
        const deleteAnnouncement = await announcementDelete(id, createdAt);
        const filterAnnouncement = announcements.filter(cp => cp.id !== id);
        if (deleteAnnouncement) {
            toast('Deleted Successfully');
        }
        setAnnouncements(filterAnnouncement);
    };
   
    return (
        <section className="employee h-100">
            <Container fluid>
                <Row>
                    <Col lg={12} md={10} sm={12}>
                        <div className="employee_list-top d-flex justify-content-between align-items-center pb-4">
                            <h5 className="section-header ml-3 mb-0">Announcements &gt; All Announcements</h5>
                            <Link to="/addAnnouncement">
                                <Button className='add-btn text-white cursor border-0'><BsPlusCircleFill className='icon text-white mr-2' />Post Announcement</Button>
                            </Link>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className='employee_list-table bg-white main-container p-4 rounded-border-12 min-vh-80 d-flex flex-column'>
                            {
                              loading ? (
                                <div className="d-flex justify-content-center">
                                  <Spinner className="rt-spinner" animation="grow" />
                                 </div>
                              ):(announcements?.length === 0 ? <Link to="/addAnnouncement"><Button className='position-absolute start-50 top-50 translate-middle click-add-btn'>Click to add</Button></Link> 
                              :<>
                              <div className='employee_list-table-header d-flex align-items-center pb-4 gap-3'>
                                  <h5 className="section-header ml-3 mb-0 flex-grow-1">Announcements</h5>
                                  <div className="search-bar position-relative">
                                      <input type="text" placeholder='Search' className='search m-0 form-control pl-4' onChange={event => { setSearchTerm(event.target.value) }} />
                                      <AiOutlineSearch className='position-absolute translate-middle-y top-50 icon pl-2' />
                                  </div>

                                  <div className="page-listing">
                                      <select id="dropdown" value={postsPerPage} onChange={e => setPostsPerPage(e.target.value)}>
                                          <option value="5">5 per page</option>
                                          <option value="10">10 per page</option>
                                          <option value="20">20 per page</option>
                                          <option value="30">30 per page</option>
                                          <option value="40">40 per page</option>
                                      </select>
                                  </div>
                              </div>
                              <div className="text-right">
                                 <ToastContainer />
                             </div>
                              <div id="tableExample" className='flex-grow-1'>
                                  <div>
                                      <table className="table bg-white iPhoneXtB pixlTb laptoptbl">
                                      <thead className="border-bottom-0 text-center">
                                                <tr>
                                                    <th className="sort border-0" data-sort="name">
                                                        Announcement Title
                                                    </th>
                                                    <th className="sort border-0" data-sort="designation">
                                                        Posted On
                                                    </th>
                                                    <th className='sort border-0' data-sort='office'>
                                                        Posted By
                                                    </th>
                                                </tr>
                                            </thead>
                                          <tbody className="list text-center">
                                              { totalPosts > 0 &&
                                                  currentPosts?.map((item) => (
                                                    <Table
                                                        item={item}
                                                        handleClick={handleClick}
                                                        handleDelete={handleDelete}
                                                        contextData={{ isAdmin, logged }}
                                                   />
                                                  ))
                                              }

                                          </tbody>
                                      </table>
                                  </div>
                              </div>
                              <div className='d-flex justify-content-center'>
                              <Pagination
                                  postsPerPage={postsPerPage}
                                  totalPosts={totalPosts}
                                  paginate={paginate}
                                  nextPage={nextPage}
                                  prevPage={prevPage}
                                  currentPage={currentPage}
                              />
                             </div>
                             <AnnouncementModal
                                    documentShow={documentShow}
                                    setDocumentShow={setDocumentShow}
                                    modalData={modalData}
                                />
                          </>
                              )  
                            }
                        
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default AnnouncementList;