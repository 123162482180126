/* eslint-disable no-unreachable */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable array-callback-return */
/* eslint-disable prettier/prettier */
/* eslint-disable consistent-return */

import momentGiven from 'moment/min/moment-with-locales';
import React, { useContext, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Moment from 'react-moment';
import { getGivenData } from '../../../api/badge';
import { AuthContext } from '../../../Context/AuthContext';

const Given = ({ insertData }) => {
  Moment.globalMoment = momentGiven;
  Moment.globalFormat = 'D MMM YYYY';
  const [Givendata, setGivendata] = useState([]);
  const [loading, setLoading] = useState(true);

  const { oid, logged, refresh } = useContext(AuthContext);

  useEffect(() => {
    let isMounted = true;
    if (oid) {
      const getData = async () => {
        const givenData = await getGivenData(oid);
        if (isMounted) {
          const filteredGivenData = givenData.data.filter(
            (i) => i.badgeGiveFrom === logged,
          )
          setGivendata([...filteredGivenData]);
          setLoading(false);
        }
      };
      getData();
    }
    return () => {
      isMounted = false
      return isMounted
    };

  }, [oid, logged, refresh])


  return (
    <>
      {
        loading ? <div className="d-flex justify-content-center">
          <Spinner className="rt-spinner" animation="grow" />
        </div> :
          <>
            <div className="badge-all-heading px-2">
              <p>Praise</p>
              <p>To</p>
              <p>Date</p>
              <p className="text-left">Message</p>
            </div>
            {/* {JSON.stringify(Givendata.length)} */}
            {Givendata && Givendata.map((data) => (
              <div className="badge-listing">
                <div>
                  <img
                    className="badge-img"
                    src={data.GivenImage}
                    alt={data.GivenType}
                  />
                  <p>{data.GivenType}</p>
                </div>
                <div>
                  {data.GivenEmail?.map((givenPerson, index) => (
                    <p key={index}>{givenPerson.email}</p>
                  ))}
                </div>

                <p>
                  <Moment>{data.createdAt}</Moment>
                </p>
                <p className="text-left">{data.GivenComment}</p>
              </div>
            ))}
          </>
      }

    </>
  );
};

export default Given;
