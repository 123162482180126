/* eslint-disable react/no-danger */
import React from 'react';
import { Modal } from 'react-bootstrap';
import Moment from 'react-moment';
import moment from 'moment/min/moment-with-locales';

const AnnouncementModal = (props) => {
    const { documentShow, setDocumentShow, modalData } = props;
    Moment.globalMoment = moment;
    Moment.globalFormat = 'MMM D, YYYY';
    return (
        <>
            <Modal
                show={documentShow}
                onHide={() => setDocumentShow(false)}
                dialogClassName="rt-modal view-modal"
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <div className="d-flex">
                        <img
                            src={modalData?.createdBy}
                            alt="ProfileImage"
                            style={{ width: '70px', height: '70px' }}
                            className="rounded-circle mr-4"
                        />
                        <div>
                            <h6>{modalData?.creatorName}</h6>
                            <Moment style={{ color: '#877b4b', fontSize: '14px' }}>
                                {modalData?.createdAt}
                            </Moment>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body style={{ minHeight: '450px' }}>
                    {/* <div> */}
                    <h3>{modalData?.title}</h3>
                    <p>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: modalData?.description,
                            }}
                        />
                    </p>
                    {/* </div> */}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AnnouncementModal;
