/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable prefer-template */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable prefer-const */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable array-callback-return */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-return-assign */
/* eslint-disable prettier/prettier */
import { useRef, useEffect, useContext, useState } from "react";
import { Container, Form, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { FaPlusCircle } from "react-icons/fa";
import MeetingModal from "./MeetingModal";
import { AuthContext } from "../../../Context/AuthContext";
import "./Details.scss";
import MeetingAgenda from "./MeetingAgenda/MeetingAgenda";
import {
  updateTeamMeeting,
  sendMail,
  AllEmployee,
  getMeetingData,
  getMeetingDateData,
} from "../../../api/teamMeeting";
import NotFoundPage from "../../Common/NotFoundPage";

function Details() {
  const ref = useRef(null);
  const params = useParams();
  const { oid, logged } = useContext(AuthContext);
  const [singleMeetingData, setSingleMeetingData] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [meetingName, setMeetingName] = useState("");
  const [meetingDate, setMeetingDate] = useState([]);
  const [meetingTime, setMeetingTime] = useState("");
  const [workstream, setWorkStream] = useState("");
  const [meetingType, setMeetingType] = useState("");
  const [description, setDescription] = useState("");
  const [meetingLink, setMeetingLink] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [multipleMeetingDate, setMultipleMeetingDate] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [id, setId] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [enable, setEnable] = useState("");
  const [combineEmailName, setCombineEmailName] = useState([]);
  const [allDataWithoutLoginUser, setAllDataWithoutLoginUser] = useState([]);
  const [allUserInMeeting, setAllUserInMeeting] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // update new user name in the database
  const addNewUserData = async (value) => {
    const updateData = {
      meetingName,
      meetingDate,
      meetingTime,
      workstream,
      description,
      meetingLocation: meetingLink,
      meetingTimezone: timeZone,
      addGuest: value.map((su) => su),
      meetingType,
    };

    if (id && createdAt) {
      await updateTeamMeeting(id, createdAt, updateData);
    }
  };
  // add new user in the meeting
  const handleAddNewUser = async (value) => {
    const newSelected = value;
    setSelectedUser(value);
    const check = selectedUser.includes(value.name);
    if (check === false) {
      addNewUserData(newSelected);

      newSelected.map((ns) => {
        const insertData = {
          meetingName,
          meetingDate,
          meetingTime,
          to: ns.email,
          from: "testingsidsj@gmail.com",
          workstream,
          description,
          meetingLink,
          timeZone,
        };
        sendMail(insertData);
      });
    }
  };

  useEffect(async () => {
    // get all meeting information
    if (oid) {
      const getEmployeeEmailName = async () => {
        const Emails = await AllEmployee(oid);
        const formattedUserEmail = Emails?.data
          .map((item) =>
            item.emailAddress ? item.emailAddress : item.adminEmail
          )
          .filter((email) => email !== logged);

        const allDataExceptLoginUser = Emails?.data
          .map((item) => item)
          .filter((i) =>
            i.emailAddress ? i.emailAddress !== logged : i.adminEmail !== logged
          );
        setAllDataWithoutLoginUser(allDataExceptLoginUser);

        const formattedName =
          Emails?.data.length > 0 &&
          Emails?.data
            ?.filter((item) =>
              item.emailAddress
                ? item.emailAddress !== logged
                : item.adminEmail !== logged
            )
            ?.map((i) =>
              i.displayName
                ? i.displayName
                : i.adminFirstName.concat(` ${i.adminLastName}`)
            );

        const profilePicture =
          Emails?.data.length > 0 &&
          Emails?.data
            ?.filter((item) => item.emailAddress !== logged)
            ?.map((i) =>
              i.ProfilePicture ? i.ProfilePicture : i.profilePicture
            );

        const combineNameEmail = formattedName?.map((value, index) => ({
          name: value,
          email: formattedUserEmail[index],
          profile: profilePicture[index],
        }));
        setCombineEmailName(combineNameEmail);
      };
      getEmployeeEmailName();
    }
    if (oid && params.id) {
      // team meeting get api
      const res = await getMeetingData(oid, params.id);
      const res1 = await getMeetingDateData(oid, params.id);
      setMultipleMeetingDate(res1);
      if (res) {
        let allUser = [res[0]?.meetingCreator];
        const assigneePerson = res[0]?.addGuest;
        for (let key in assigneePerson) {
          allUser.push(assigneePerson[key].email);
        }
        setAllUserInMeeting(allUser);
        setSingleMeetingData(res);
        setSelectedUser(res[0]?.addGuest);
        setCreatedAt(res[0]?.createdAt);
        setId(res[0]?.id);
        setMeetingName(res[0]?.meetingName);
        setMeetingDate([res[0]?.meetingDate]);
        setMeetingTime(res[0]?.meetingTime);
        setWorkStream(res[0]?.workstream);
        setDescription(res[0]?.description);
        setMeetingLink(res[0]?.meetingLocation);
        setMeetingType(res[0]?.meetingType);
        setTimeZone(res[0]?.meetingTimezone);
        setEnable(res[0]?.meetingCreator === logged ? true : "");
      }
    }
  }, [oid, params.id]);
  return (
    <Container fluid>
      {meetingName ? (
        <div>
          {allUserInMeeting?.includes(logged) ? (
            <div className="bg-white rounded p-3 metting-detail">
              <div className="d-flex justify-content-between">
                <div className="metting-header">
                  <h2 className="pt-3 title">{meetingName}</h2>
                  <p>{description}</p>
                  <h6 className="pt-3 meeting-other-info d-flex">
                    Workstream:
                    <span className="meeting-other-info-content">
                      <p>{workstream}</p>
                    </span>
                  </h6>
                  <h6 className="pt-3 meeting-other-info d-flex">
                    Meeting Date:
                    <span className="meeting-other-info-content">
                      <p>
                        {meetingDate[0]?.slice(5, 7) +
                          "-" +
                          meetingDate[0]?.slice(8, 10) +
                          "-" +
                          meetingDate[0]?.slice(0, 4)}
                      </p>
                    </span>
                  </h6>
                  <h6 className="pt-3 meeting-other-info d-flex">
                    Meeting Time:
                    <span className="meeting-other-info-content">
                      <p>{meetingTime}</p>
                    </span>
                  </h6>

                  <h6 className="pt-3 meeting-other-info d-flex">
                    Meeting Type:
                    <span className="meeting-other-info-content">
                      <p>{meetingType}</p>
                    </span>
                  </h6>

                  <div className="addTeam">
                    <div className="attendies mb-5">
                      {selectedUser &&
                        selectedUser?.map((item, index) => (
                          <img
                            key={index}
                            className="meeting-img"
                            src={
                              item.profile
                                ? item.profile
                                : "https://i.ibb.co/vVfrXgh/Group-302.png"
                            }
                            alt={item.name}
                          />
                        ))}

                      {enable ? (
                        <FaPlusCircle
                          className="add-agenda"
                          onClick={handleShow}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    {enable && (
                      <div ref={ref}>
                        <>
                          <MeetingModal
                            show={show}
                            handleClose={handleClose}
                            options={combineEmailName}
                            selectedUser={selectedUser}
                            handleAddNewUserImage={handleAddNewUser}
                            detailsPage={true}
                          />
                        </>
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <div className="date-select">
                    <Form>
                      <Form.Group>
                        {multipleMeetingDate?.length < 1 ? (
                          meetingDate?.map((item, index) => (
                            <Form.Control as="select">
                              <option value={item} key={index}>
                                {item?.slice(5, 7) +
                                  "-" +
                                  item?.slice(8, 10) +
                                  "-" +
                                  item?.slice(0, 4)}
                              </option>
                            </Form.Control>
                          ))
                        ) : (
                          <Form.Control
                            as="select"
                            onChange={(e) => setSelectedDate(e.target.value)}
                          >
                            {multipleMeetingDate.map((item, index) => (
                              <option
                                defaultValue={item.meetingDate}
                                key={index}
                              >
                                {item?.meetingDate?.slice(5, 7) +
                                  "-" +
                                  item?.meetingDate?.slice(8, 10) +
                                  "-" +
                                  item?.meetingDate?.slice(0, 4)}
                              </option>
                            ))}
                          </Form.Control>
                        )}
                      </Form.Group>
                    </Form>
                  </div>
                </div>
              </div>
              <MeetingAgenda
                singleMeetingData={singleMeetingData}
                paramsId={params.id}
                paramsDate={params.date}
                meetingName={meetingName}
                meetingDescription={description}
                meetingDate={
                  selectedDate === "" ? meetingDate[0] : selectedDate
                }
                selectedUser={selectedUser}
                enableButton={enable}
              ></MeetingAgenda>
            </div>
          ) : (
            <NotFoundPage />
          )}
        </div>
      ) : (
        <div className="d-flex justify-content-center">
          <Spinner className="rt-spinner" animation="grow" />
        </div>
      )}
    </Container>
  );
}

export default Details;
