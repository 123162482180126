/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable prettier/prettier */
// eslint-disable-next-line prettier/prettier
import React, { useState, useEffect, useContext } from "react";
import { faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
// import siteApi from "../../../api/siteApi";
import { AuthContext } from "../../../Context/AuthContext";
import "../YearPlan/YearPlan.scss";
function PlanRevenue({ yearNo }) {
  const [planRevenueData, setPlanRevenueData] = useState([]);
  const [updateTrackState, setUpdateTrackState] = useState(false);
  const [addValuePlan, setAddValuePlan] = useState(false);
  const { oid, isAdmin } = useContext(AuthContext);

  useEffect(() => {
    if (oid && yearNo) {
      //  plan revenue get api call
      axios
        .get(
          `https://axsi08q5q0.execute-api.us-east-2.amazonaws.com/dev/${oid}/${yearNo}/planrevenue`
        )
        .then((res) => {
          const data = res ? res.data : "loading";
          setPlanRevenueData(data);
        });
    }
  }, [oid, yearNo]);

  //  plan revenue insert
  // eslint-disable-next-line no-shadow
  const handleAddPlanRevenue = (id) => {
    const res = planRevenueData?.filter((data) => data.id === id);
    res.forEach((i) => {
      if (i.PlanRevenue || i.PlanRevenueAmount) {
        axios.post(
          `https://axsi08q5q0.execute-api.us-east-2.amazonaws.com/dev/planrevenue`,
          {
            id: i.id,
            PlanRevenue: i.PlanRevenue,
            PlanRevenueAmount: i.PlanRevenueAmount,
            oid,
            createdAt: i.createdAt,
            YearNo: yearNo,
          }
        );

        console.log({
          id: i.id,
          PlanRevenue: i.PlanRevenue,
          PlanRevenueAmount: i.PlanRevenueAmount,
          oid,
          createdAt: i.createdAt,
          YearNo: yearNo,
        });

        setAddValuePlan(false);
        toast("Created Successfully");
        const temp = [...planRevenueData];
        setPlanRevenueData([]);
        setTimeout(() => {
          setPlanRevenueData(temp);
        }, 0.3);
      }
    });
  };

  // handle input change
  const handlePlanRevenueChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...planRevenueData];
    list[index][name] = value;
    setPlanRevenueData(list);
  };

  //  plan revenue  update
  const updatePlanRevenue = (planRevenue, planRevenueAmount, idValue) => {
    if (updateTrackState === true) {
      const insertData = async () => {
        await axios.patch(
          `https://axsi08q5q0.execute-api.us-east-2.amazonaws.com/dev/planrevenue/${idValue}`,
          {
            PlanRevenue: planRevenue,
            PlanRevenueAmount: planRevenueAmount,
          }
        );
      };
      insertData();
      toast("Updated");
      setUpdateTrackState(false);
    }
  };

  // plan revenue delete
  const removePlanRevenue = async (event, idvalue) => {
    toast("Deleted Successfully");
    const res = planRevenueData?.filter((data) => data.id !== idvalue);
    setPlanRevenueData([]);
    setTimeout(() => {
      setPlanRevenueData([...res]);
    }, 0.1);
    await axios.delete(
      `https://axsi08q5q0.execute-api.us-east-2.amazonaws.com/dev/planrevenue/${idvalue}`
    );
  };

  // add new plan revenue
  const addPlanRevenue = () => {
    setPlanRevenueData([
      ...planRevenueData,
      {
        PlanRevenue: "",
        PlanRevenueAmount: "",
        id: uuidv4(),
        createdAt: new Date().toString(),
        oid,
        YearNo: yearNo,
      },
    ]);
  };
  return (
    <>
      {/* Plan Revenue Section */}
      {planRevenueData &&
        planRevenueData?.map((item, index) => (
          <div className="row d-flex" key={index}>
            <div className="col-md-5 col-xl-5 col-xs-5 col-lg-5">
              <input
                className="text-margin-plan"
                name="PlanRevenue"
                placeholder="Revenue"
                disabled={!isAdmin}
                defaultValue={item.PlanRevenue}
                onChange={(e) => {
                  handlePlanRevenueChange(e, index);
                  setUpdateTrackState(true);
                }}
                onBlur={(e) => {
                  if (addValuePlan === true) {
                    handleAddPlanRevenue(item.id);
                  } else {
                    updatePlanRevenue(
                      e.target.value,
                      item.PlanRevenueAmount,
                      item.id
                    );
                  }
                }}
              />
              <ToastContainer />
            </div>
            <div className="col-md-5 col-xl-5 col-xs-5 col-lg-5">
              <input
                className="text-margin-plan"
                name="PlanRevenueAmount"
                placeholder="Amount"
                defaultValue={item.PlanRevenueAmount}
                disabled={!isAdmin}
                onChange={(e) => {
                  handlePlanRevenueChange(e, index);
                  setUpdateTrackState(true);
                }}
                onBlur={(e) => {
                  if (addValuePlan === true) {
                    handleAddPlanRevenue(item.id);
                  }
                  updatePlanRevenue(item.PlanRevenue, e.target.value, item.id);
                }}
              />
            </div>
            <div className="col-md-2 col-xl-2 col-xs-2 col-lg-2">
              {isAdmin && (
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={(event) => removePlanRevenue(event, item.id)}
                >
                  <FontAwesomeIcon icon={faTrashAlt} className="mr-0" />
                </button>
              )}
            </div>
          </div>
        ))}
      {isAdmin && (
        <button
          className="btn btn-light plan-add-button-left"
          onClick={() => {
            setAddValuePlan(true);
            addPlanRevenue();
          }}
        >
          <FontAwesomeIcon icon={faPlus} className="mr-0" /> ADD
        </button>
      )}
    </>
  );
}

export default PlanRevenue;
