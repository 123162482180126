/* eslint-disable prefer-template */
/* eslint-disable dot-notation */
/* eslint-disable no-undef */
/* eslint-disable no-shadow */
/* eslint-disable arrow-body-style */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable prettier/prettier */

import React, { useContext, useState, useEffect } from "react";
import { Auth, Storage } from "aws-amplify";
import { Button, Form, Modal} from "react-bootstrap";
import CSVReader from "react-csv-reader";
import { toast, Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import { BsUpload } from "react-icons/bs";
import { AuthContext } from "../../../Context/AuthContext";
import {
  confirmUserSignUp,
  disableUser,
  sendEmailToEmployee,
  addEmployeeInfo
} from "../../../api/employee";
import "./EmployeeList.scss";
import "../../Profile/Profile.scss";

const UploadEmployeeFromFile = () => {
  const { oid } = useContext(AuthContext);
  const [employeeData, setEmployeeData] = useState([]);
  let successCount = 0;
  let errorCount = 0;

  const uploadBulkImportDataFunction = () => {
    if (employeeData.length > 0) {
      employeeData.forEach(async (i, idx, array) => {
        // last element of the loop track
        if (idx === array.length - 1) {
          if (successCount === 0 && errorCount === 0) {
            toast.success(`Total ${idx + 1} Number of Items Inserted!`, {
              duration: 2000,
            });
          }
          if (successCount !== 0 || errorCount !== 0) {
            toast.success(
              `Total ${successCount} Users Inserted and ${errorCount} Users already Exists!`,
              {
                duration: 2000,
              }
            );
          }
          console.log(errorCount);
          errorCount = 0;
          successCount = 0;
        }
        // remaining data without current uploading one
        const filteredData = employeeData.filter((employee) => {
          return employee.emailaddress === i.emailaddress;
        });

        if (filteredData.length === 1) {
          await uploadSingleUserData(i);
        }
      });
    } else {
      toast.error("File is Empty.Please fill Up With Data!", {
        duration: 2000,
      });
    }
  };

  const sendingUserInformationToEmail = async (data, pass) => {
    const emailInfo = {
      to: data.emailaddress, // Change to your recipient
      from: "testingsidsj@gmail.com", // Change to your verified sender
      subject: "Added you in ReteamNow",
      text: "Reteam",
      name: data.firstname,
      email: data.emailaddress,
      password: pass,
    };
    const responseEmail = await sendEmailToEmployee(emailInfo);
  };

  const insertUsersDataToDatabase = async (data, response) => {
    const eid = response.userSub;
  
    const employeePersonalDetails = {
      eid,
      oid,
      firstName: data.firstname,
      lastName: data.lastname,
      middleName: data.middlename,
      displayName: data.displayname,
      emailAddress: data.emailaddress,
      mobileNumber: data.mobilenumber,
      gender: data.gender,
      dob: data.dob,
      ProfilePicture: data.profilepicture,
      officeLocation: "",
      employmentType: data.employmenttype,
      assignRole: data.assignrole,
      designation: data.designation,
      dateOfJoining: data.dateofjoining,
      employmentStatus: data.employmentstatus,
    };


     const responseAddEmployee = await addEmployeeInfo(employeePersonalDetails);
  };

  const callAllInputFunction = async (value, data, randomstring) => {
    await makeUserActive(value, data);
    await sendingUserInformationToEmail(value, randomstring);
    await insertUsersDataToDatabase(value, data);
    countNumberOfUserInserted();
  };

  const countNumberOfUserInserted = () => {
    successCount += 1;
  };

  // add new user to cognito and make user active based on em
  const uploadSingleUserData = async (value) => {
    let response = "";

    if (value.emailaddress) {
      const randomstring = Math.random().toString(36).slice(-8);
      const userEmail = value.emailaddress;
      response = await Auth.signUp({
        username: userEmail,
        password: randomstring,
        attributes: {
          "custom:CompanyName": oid,
        },
      })
        .then((data) => callAllInputFunction(value, data, randomstring))
        .catch((err) => {
          if (err) {
            errorCount += 1;
          }
        });
    }
  };

  const makeUserActive = async (data, response) => {
    if (data.employmentstatus === "Active") {
      const username = {
        username: response.userSub,
      };
      confirmUserSignUp(username);
    } else {
      const username = {
        username: response.userSub,
      };
      await disableUser(username);
      await confirmUserSignUp(username);
    }
  };

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
  };

  const handleUploadData = (data) => {
    setEmployeeData(data);
  };

  const clickIcon = {

  }

  

  // const[showModal, setShowModal] = useState(false)
  const [show, setShow] = useState(false);


  useEffect(() => {}, [oid]);

  return (
    <div>
      <Toaster />
      <p
        className="add-btn text-white cursor border-0 mb-0"
        onClick={() => setShow(true)}
      >
        <BsUpload className="icon custom-icon-style text-white mr-2" /> Import
      </p>

      <Modal show={show} className="custom-modal">
        <Modal.Header className="border-bottom-0">
          <Modal.Title className="font-weight-normal">
            Upload your file
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">

          <CSVReader
            cssClass="react-csv-input profile-upload-icon rounded-circle text-center border-0 p-0"
            className="add-btn import-btn text-white cursor border-0"
            onFileLoaded={handleUploadData}
            parserOptions={papaparseOptions}
          />
          <div className="upload-file">
            Upload a file
          </div>
          <div className="bottom-text">
            All .csv file types (upto 50MB in size) are supported.
          </div>
          <div className="buttons-wrapper text-center mt-3">
            <Button
              type="button"
              className="custom-btn mr-3"
              onClick={() => setShow(false)}
            >
              Cancel
            </Button>

            <Button type="submit" className="custom-btn"  onClick={() => {
              uploadBulkImportDataFunction();
            }}>
              Submit
            </Button>
          </div>
        </Modal.Body>
      </Modal>

    </div>
    
  );
};

export default UploadEmployeeFromFile;
