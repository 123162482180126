import React from "react";
import { Link } from "react-router-dom";
import { MdModeEdit } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";

const EmployeeTableInactive = (props) => {
  const { item, isAdmin, deleteInactiveUser, setLoader } = props;

  return (
    <>
      <tr key={item.eid}>
        <td className="name border-right-0">
          <Link to={`/employee-view/${item.eid}`} className="textdec mr-4">
            {`${item.firstName} ${item.lastName}`}
          </Link>
        </td>
        <td className="email border-right-0 border-left-0">
          {item.emailAddress}
        </td>
        <td className="designation border-right-0 border-left-0">
          {item.designation}{" "}
        </td>
        <td className="office border-right-0 border-left-0">
          {item.officeLocation}
        </td>
        <td className="salary border-right-0 border-left-0">
          {item.mobileNumber}{" "}
        </td>
        {isAdmin && (
          <td>
            <Link to={`/employeelist/${item.eid}`} className="textdec mr-4">
              <MdModeEdit size={25} className="edit-ico ml-1" />
            </Link>

            <AiFillDelete
              className="delete-ico ml-1"
              variant="danger"
              size={25}
              onClick={() => {
                deleteInactiveUser(item.eid, item.ProfilePicture);
                setLoader(true);
              }}
            />
          </td>
        )}
      </tr>
    </>
  );
};

export default EmployeeTableInactive;
