import axios from 'axios';

const RoleAPi = process.env.REACT_APP_Role_API;

const createModule = async (formData) => {
    const result = await axios.post(`${RoleAPi}/module/create`, formData);
    return result;
};
// get All module
const fetchAllModules = () => axios.get(`${RoleAPi}/module/all`);
// get Single module

const getSingleModule = async (id) => {
    const result = await axios.get(`${RoleAPi}/module/single/${id}`);
    return result?.data;
};

// Update module

const updateModule = async (id, formData) => {
    const result = await axios.put(`${RoleAPi}/module/update/${id}`, formData);
    return result;
};

// delete module

const deleteModule = async (id) => {
    const result = await axios.delete(`${RoleAPi}/module/delete/${id}`);
    return result;
};

// Role Add

const addRole = (payload) => axios.post(`${RoleAPi}/role/add`, { ...payload });

// get All Role
const getRoles = (oid) => axios.get(`${RoleAPi}/role/${oid}`);
// get Single Role

const getSingleRole = async (id) => {
    const result = await axios.get(`${RoleAPi}/role/item/${id}`);
    return result?.data;
};

// Update Role

const updateRoleName = (selectedRoleId, roleName, oid) =>
    axios.patch(`role/update/${selectedRoleId.current}/${oid}`, { roleName });

// delete Role

const deleteRole = (roleId) => axios.delete(`${RoleAPi}/role/delete/${roleId}`);

// add Assigned Role

const assignedRoleAdd = async (formData) => {
    const result = await axios.post(`${RoleAPi}/assignedRole/add`, formData);
    return result;
};

// get All Assigned Role
const getAllAssignedRole = async (oid, roleId) => {
    const result = await axios.get(`${RoleAPi}/assignedRole/${oid}/${roleId}}`);
    return result?.data;
};

// get single Assigned Role
const getSingleAssignedRole = async (eid) => {
    const result = await axios.get(`${RoleAPi}/assignedRole/item/${eid}`);
    return result?.data;
};

// update Assigned Role
const updateEmployeeRole = (eid, selectedItemId) =>
    axios.patch(`assignedRole/update/${eid}`, { roleId: selectedItemId });

// update Profile Role

const updateProfile = async (eid) => {
    const result = await axios.put(`${RoleAPi}/assignedRole/update/profile/${eid}`);
    return result;
};

// delete Assigned Role

const deleteProfile = async (eid) => {
    const result = await axios.put(`${RoleAPi}/assignedRole/delete/${eid}`);
    return result;
};

// Add Role Permission

const addRolePermission = (body) => axios.post(`${RoleAPi}/rolePermission/add`, { ...body });

// Get Role Permission

const fetchRolePermission = (oid) => axios.get(`${RoleAPi}/rolePermission/${oid}`);

// Get Single Role Permission

const fetchSingleRolePermission = (roleId, moduleId) =>
    axios.get(`${RoleAPi}/rolePermission/${roleId}/${moduleId}`);

const getEmployeeDetails = (oid, roleId) => axios.get(`${RoleAPi}/assignedRole/${oid}/${roleId}`);

function updateRoleAccess(...params) {
    return axios.patch(`${RoleAPi}/rolePermission/update/${params[0]}`, {
        access: { ...params[1] },
    });
}

export {
    createModule,
    fetchAllModules,
    getSingleModule,
    addRolePermission,
    fetchRolePermission,
    fetchSingleRolePermission,
    updateModule,
    deleteModule,
    addRole,
    getRoles,
    getSingleRole,
    updateRoleName,
    deleteRole,
    assignedRoleAdd,
    getAllAssignedRole,
    getSingleAssignedRole,
    updateEmployeeRole,
    updateProfile,
    deleteProfile,
    getEmployeeDetails,
    updateRoleAccess,
};
