/* eslint-disable prettier/prettier */
import React, { useState, useContext } from "react";
import { Button, Form, Row, Col, Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { AuthContext } from "../../Context/AuthContext";
import "./ProcessdocumentAdd.scss";
import { addProcessDocumentation } from "../../api/processDocumentation";
const ProcessdocumentAdd = () => {
  const [Title, setTitle] = useState("");
  const [Group, setGroup] = useState("");
  const [Description, setDescription] = useState("");
  const { oid } = useContext(AuthContext);

  const history = useHistory();

  const handleSubmit = (e) => {
    const insertData = async () => {
      const data = {
        ProcessName: Title,
        Category: Group,
        Description,
        oid,
      };

      const res = await addProcessDocumentation(data);
      if (res?.status === 200) {
        history.push("/processDocumentation");
      }
    };
    insertData();

    e.preventDefault();
  };

  return (
    <Container className="pb-4 fluid">
      <Col lg={12}>
        <div className="d-flex justify-content-end">
          <Button
            onClick={() => history.push("/processDocumentation")}
            className="btn-rt-primary my-3"
            type="button"
          >
            Back
          </Button>
        </div>
        <div className="rt-add-process-doc">
          <Form className="p-4" onSubmit={handleSubmit}>
            <Row>
              <Col lg={12}>
                <Form.Group className="mb-4">
                  <Form.Label className="ml-2">Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Title here"
                    required
                    value={Title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group className="mb-4">
                  <Form.Label className="ml-2">Category</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Category here"
                    required
                    value={Group}
                    onChange={(e) => setGroup(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group className="mb-4">
                  <Form.Label className="ml-2">Description</Form.Label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={Description}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setDescription(data);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col lg={2}>
                <Button
                  variant="primary"
                  className="btn-rt-primary"
                  type="submit"
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Col>
    </Container>
  );
};

export default ProcessdocumentAdd;
