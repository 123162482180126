/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
import { Auth } from 'aws-amplify';
import { React, useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import './AddAdmin.scss';
import '../../common.scss';
import { addAdminInfo, getOrganizationList, sendEmailToAdmin } from '../../api/superAdmin';
import { addUserToGroup, confirmUserSignUp } from '../../api/employee';

const AddAdmin = () => {
    const history = useHistory();
    const [organization, setOrganization] = useState([]);
    const [oid, setOid] = useState('');
    const [adminFirstName, setAdminFirstName] = useState('');
    const [adminLastName, setAdminLastName] = useState('');
    const [adminEmail, setAdminEmail] = useState('');
    const [adminPassValues, setAdminPassValues] = useState({
        password: "",
        showPassword: false,
    });
    const [adminConfirmPassValues, setAdminConfirmPassValues] = useState({
        password: "",
        showPassword: false,
    });
    const [adminPassword, setAdminPassword] = useState('');
    const [adminConfirmPassword, setAdminConfirmPassword] = useState('');
    const [gender, setGender] = useState('');
    const [dob, setDob] = useState('');
    const [personalPhoneNumber, setPersonalPhoneNumber] = useState('');
    const [alternatePhoneNumber, setAlternatePhoneNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [emailWarning, setEmailWarning] = useState(false);
    const [personalNumWarning, setPersonalNumWarning] = useState(false);
    const [alternateNumWarning, setAlternateNumWarning] = useState(false);
    const [passWarning, setPassWarning] = useState(false);
    const [confirmPassWarning, setConfirmPassWarning] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    useEffect(() => {
         
         const getData = async()=>{
           const res = await getOrganizationList() ;
           if(res){
            setOrganization(res);
           }
         }
         getData()
    }, []);

    const handleAdminShowPassword = () => {
        setAdminPassValues({ ...adminPassValues, showPassword: !adminPassValues.showPassword });
    }
    const handleAdminConfirmShowPassword = () => {
        setAdminConfirmPassValues({ ...adminConfirmPassValues, showPassword: !adminConfirmPassValues.showPassword });
    }
    const emailRegx = /^[A-Za-z0-9.]+@[A-Za-z0-9]+\.([A-Za-z]{2,})+$/;
    const handleCheckEmail = (value) => {
        const isValid = emailRegx.test(value);
        if (isValid === false) {
            setEmailWarning(true);
        }
        else {
            setEmailWarning(false);
        }
    }
    const handlePersonalNumCheck = (num) => {
        if (num.length < 10) {
            setPersonalNumWarning(true);
        }
        else {
            setPersonalNumWarning(false);
        }
    }
    const handleAlternateNumCheck = (num) => {
        if (num.length < 10) {
            setAlternateNumWarning(true);
        }
        else {
            setAlternateNumWarning(false);
        }
    }
    const handlePasswordCheck = (num) => {
        if (num.length < 8) {
            setPassWarning(true);
        }
        else {
            setPassWarning(false);
        }
    }
    const handleConfirmPassCheck = (num) => {
        if (num.length < 8) {
            setConfirmPassWarning(true);
        }
        else {
            setConfirmPassWarning(false);
        }
    }

    const handleAddAdmin = async (e) => {
        if (emailWarning === true || personalNumWarning === true || alternateNumWarning === true || passWarning === true || confirmPassWarning === true) {
            return;
        }
        if (adminPassword !== adminConfirmPassword) {
            toast.error("Password & Confirm Password don't match!");
            return;
        }
        setLoading(true);

        try {
            if (adminPassword === adminConfirmPassword) {
                const registerNewAdmin = await Auth.signUp({
                    username: adminEmail,
                    password: adminPassword,
                    attributes: {
                        'custom:CompanyName': oid,
                    },
                });
                console.log(adminEmail)
                if (registerNewAdmin) {
                    const username = {
                        username: registerNewAdmin.userSub,
                    };

                    const formData ={
                         username: registerNewAdmin.userSub,
                         groupName: 'Admin'
                    }
                     
                    await confirmUserSignUp(username) 

                    await addUserToGroup(formData)

                    const adminData ={
                        oid,
                        eid:registerNewAdmin.userSub,
                        adminFirstName,
                        adminLastName,
                        adminEmail,
                        gender,
                        DOB: dob,
                        personalPhoneNumber,
                        AlternativePhoneNumber: alternatePhoneNumber,
                    }

                    const response = await addAdminInfo(adminData)

                    if (response) {
                        setLoading(false);
                        toast.success('Admin Added Successfully!!');
                        setTimeout(() => {
                            history.push('/admin/list');
                        }, 1500);
                    }
        
                }
            }


            const emailInfo = {
                to: adminEmail, // Change to your recipient
                from: 'testingsidsj@gmail.com', // Change to your verified sender
                subject: '@223232',
                text: 'Reteam',
                name: adminFirstName,
                email: adminEmail,
                password: adminPassword,
            } 

           
            const responseEmail = await sendEmailToAdmin(emailInfo);
            
        } catch (error) {
            setLoading(false);
            console.log(error);
            toast.error("Admin wasn't created as the email already exists!");
        }
    };
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-10">
                    <h1 className="pl-4 text-dark pb-3">Create An Admin</h1>
                    <div className="admin-list-table">
                        {
                            !loading ? <form onSubmit={handleSubmit(handleAddAdmin)} className="pl-4">
                                <div className="form-row">
                                    <div className="form-group col-md-4 one">
                                        <h6 className="required">Select Organization</h6>
                                        <select {...register('orgName', {
                                            required: {
                                                value: true,
                                                message: 'Organization Name is required!'
                                            },
                                        })}
                                            name='orgName'
                                            className="form-control"
                                            // value={oid}
                                            onBlur={(e) => setOid(e.target.value)}
                                        >
                                            <option selected value="">
                                                Select
                                            </option>
                                            {organization?.map((org) => (
                                                <option key={org.id} value={org.id}>
                                                    {org.companyName}
                                                </option>
                                            ))}
                                        </select>
                                        {errors.orgName?.type === 'required' && <span className='input-warning-style'>{errors.orgName.message}</span>}
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-4">
                                        <h6 className="required">Admin First Name</h6>
                                        <input {...register('firstName', {
                                            required: {
                                                value: true,
                                                message: 'This field is required!'
                                            },
                                        })}
                                            type="text"
                                            name='firstName'
                                            className="form-control"
                                            id="exampleInputText"
                                            aria-describedby="textHelp"
                                            placeholder="Admin First Name"
                                            onBlur={(e) => setAdminFirstName(e.target.value)}
                                        />
                                         {errors.orgName?.type === 'required' && <span className='input-warning-style'>{errors.firstName.message}</span>}
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-4">
                                        <h6 className="required">Admin Last Name</h6>
                                        <input {...register('lastName', {
                                            required: {
                                                value: true,
                                                message: 'This field is required!'
                                            },
                                        })}
                                            type="text"
                                            name='lastName'
                                            className="form-control"
                                            id="exampleInputText"
                                            aria-describedby="textHelp"
                                            placeholder="Admin Last Name"
                                            onBlur={(e) => setAdminLastName(e.target.value)}
                                        />
                                        {errors.lastName?.type === 'required' && <span className='input-warning-style'>{errors.lastName.message}</span>}
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-4 one">
                                        <h6 className="required">Admin Email</h6>
                                        <input {...register("email", {
                                            required: {
                                                value: true,
                                                message: 'This field is required!'
                                            },
                                        })}
                                            type="text"
                                            id="title"
                                            className="form-control"
                                            placeholder="Admin Email"
                                            name="email"
                                            onBlur={(e) => { setAdminEmail(e.target.value); handleCheckEmail(e.target.value); }}
                                        />
                                        {errors.email?.type === 'required' && <span className='input-warning-style'>{errors.email.message}</span>}
                                        {emailWarning && <span className='input-warning-style'>Please give a valid email.</span>}
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-4 one">
                                        <h6 className="required">Password</h6>
                                        <input {...register("password", {
                                            required: {
                                                value: true,
                                                message: 'This field is required!'
                                            },
                                        })}
                                            type={adminPassValues.showPassword ? "text" : "password"}
                                            id="title"
                                            className="form-control"
                                            placeholder="Password"
                                            name="password"
                                            onBlur={(e) => { setAdminPassword(e.target.value); handlePasswordCheck(e.target.value); }}
                                        />
                                        <span className={`small-text cursor show-password-position ${adminPassValues.showPassword ? "text-bold" : ""}`} onClick={handleAdminShowPassword}>Show</span>
                                        {errors.password?.type === 'required' && <span className='input-warning-style'>{errors.password.message}</span>}
                                        {passWarning && <span className='input-warning-style'>Password must be 8 characters or longer.</span>}
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-4 one">
                                        <h6 className="required">Confirm Password</h6>
                                        <input {...register("confirmPassword", {
                                            required: {
                                                value: true,
                                                message: 'This field is required!'
                                            },
                                        })}
                                            type={adminConfirmPassValues.showPassword ? "text" : "password"}
                                            id="title"
                                            className="form-control"
                                            placeholder="Confirm Password"
                                            name="confirmPassword"
                                            onBlur={(e) => { setAdminConfirmPassword(e.target.value); handleConfirmPassCheck(e.target.value); }}
                                        />
                                        <span className={`small-text cursor show-password-position ${adminConfirmPassValues.showPassword ? "text-bold" : ""}`} onClick={handleAdminConfirmShowPassword}>Show</span>
                                        {errors.confirmPassword?.type === 'required' && <span className='input-warning-style'>{errors.confirmPassword.message}</span>}
                                        {confirmPassWarning && <span className='input-warning-style'>Password must be 8 characters or longer.</span>}
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-4 one">
                                        <h6 className="required">Gender</h6>
                                        <select {...register('gender', {
                                            required: {
                                                value: true,
                                                message: 'This field is required!'
                                            },
                                        })}
                                            className="form-control"
                                            name='gender'
                                            // value={gender}
                                            onBlur={(e) => setGender(e.target.value)}
                                        >
                                            <option selected value="">
                                                Select
                                            </option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                            <option value="Others">Others</option>
                                        </select>
                                        {errors.gender?.type === 'required' && <span className='input-warning-style'>{errors.gender.message}</span>}
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-4 one">
                                        <h6 className="required">Date of Birth</h6>
                                        <input {...register('date', {
                                            required: {
                                                value: true,
                                                message: 'This field is required!'
                                            },
                                        })}
                                            type="date"
                                            id="title"
                                            className="form-control"
                                            placeholder="DOb"
                                            name="date"
                                            onBlur={(e) => setDob(e.target.value)}
                                        />
                                        {errors.date?.type === 'required' && <span className='input-warning-style'>{errors.date.message}</span>}
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-4 one">
                                        <h6 className="required">Personal Phone Number</h6>
                                        <input {...register('phone', {
                                            required: {
                                                value: true,
                                                message: 'This field is required!'
                                            },
                                        })}
                                            type="text"
                                            id="title"
                                            className="form-control"
                                            placeholder="Personal Phone Number"
                                            name="phone"
                                            onBlur={(e) => { setPersonalPhoneNumber(e.target.value); handlePersonalNumCheck(e.target.value); }}
                                        />
                                        {errors.phone?.type === 'required' && <span className='input-warning-style'>{errors.phone.message}</span>}
                                        {personalNumWarning && <span className='input-warning-style'>Minimum length should be 10.</span>}
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-4 one">
                                        <h6>Alternative Phone Number</h6>
                                        <input {...register('alternatePhone', {
                                        })}
                                            type="text"
                                            id="title"
                                            className="form-control"
                                            placeholder="Alternate Number"
                                            name="alternatePhone"
                                            onBlur={(e) => { setAlternatePhoneNumber(e.target.value); handleAlternateNumCheck(e.target.value); }}
                                        />
                                        {alternateNumWarning && <span className='input-warning-style'>Minimum length should be 10.</span>}
                                    </div>
                                </div>

                                <button type="submit" className="btn btn-primary mb-5">
                                    Submit
                                </button>
                            </form> : <div className="d-flex justify-content-center">
                                <Spinner className="rt-spinner" animation="grow" />
                            </div>
                        }
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default AddAdmin;
