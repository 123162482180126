/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable prettier/prettier */
import React, { useEffect, useState, useRef } from "react";
import { FaPause, FaPlay } from "react-icons/fa";

function Timer({
  onclickId,
  id,
  timeCalculation,
  setTimer,
  timer,
  input,
  updateMeetingAgendaTime,
  dispSecondsAsMins,
  enable,
}) {
  const [start, setStart] = useState(false);
  const firstStart = useRef(true);
  const tick = useRef();

  // start timer
  const toggleStart = () => {
    setStart(true);
  };

  // end timer
  const toggleOff = () => {
    setStart(false);
    updateMeetingAgendaTime(id, dispSecondsAsMins(timer));
  };

  // rerender page based on changing start value
  useEffect(() => {
    if (firstStart.current) {
      firstStart.current = !firstStart.current;
      return;
    }
    if (start) {
      tick.current = setInterval(() => {
        setTimer((timer) => timer + 1);
      }, 1000);
    } else {
      clearInterval(tick.current);
    }
    return () => clearInterval(tick.current);
  }, [start]);

  return (
    <div>
      <p
        name="timer"
        className={start && "on"}
        onClick={() => {
          if (enable) {
            toggleStart();
          }
          if (start && enable) {
            toggleOff();
          }
        }}
        disabled={!enable}
      >
        {onclickId === id ? timeCalculation : input}
        {start ? <FaPause /> : <FaPlay />}
      </p>
    </div>
  );
}

export default Timer;
