/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
import moment from "moment/min/moment-with-locales";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row, Spinner } from "react-bootstrap";
import { FaPlusCircle } from "react-icons/fa";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "../../Context/AuthContext";
import Pagination from "../Common/Pagination/Pagination";
import ProcessCard from "./ProcessCard";
import "./ProcessDocumentation.scss";
import {
  ProcessDocumentationList,
  ProcessDocumentationDelete,
} from "../../api/processDocumentation";
const ProcessDocumentation = () => {
  const [documentShow, setDocumentShow] = useState(false);
  const [modalData, setModalData] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(2);
  const [processData, setProcessData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [dataShow, setDataShow] = useState(false)
  const { oid, isAdmin } = useContext(AuthContext);
  Moment.globalMoment = moment;
  Moment.globalFormat = "D MMM YYYY";

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPOst = indexOfLastPost - postsPerPage;
  const currentPosts =
    processData.length > 0 &&
    processData.slice(indexOfFirstPOst, indexOfLastPost);
  const totalPosts = processData.length;
  const paginate = (pageNum) => setCurrentPage(pageNum);
  const nextPage = () => setCurrentPage(currentPage + 1);
  const prevPage = () => setCurrentPage(currentPage - 1);

  useEffect(() => {
    const getData = async () => {
      const response = await ProcessDocumentationList(oid);
      if (response) {
        setLoading(false);
        setProcessData(response);
      }
    };
    getData();


    setTimeout(() => {
      setDataShow(true)
    }, 2000);


  }, [oid, !currentPosts]);



  const handleDelete = async (id, createdAt) => {
    const deleteProcess = await ProcessDocumentationDelete(id, createdAt);
    const filterProcess = processData.filter((cp) => cp.id !== id);
    if (deleteProcess) {
      toast("Deleted Successfully");
    }
    setProcessData(filterProcess);
  };

  return (
    <>
      {
        loading
          ?
          <div className="d-flex justify-content-center">
            <Spinner className="rt-spinner" animation="grow" />
          </div>
          :
          <Container fluid>
            <div className="text-right">
              {isAdmin && (
                <Link to="/ProcessDocumentationAdd">
                  {" "}
                  <Button className="btn-rt-primary-icon my-3">
                    <FaPlusCircle /> Add New
                  </Button>
                </Link>
              )}
            </div>

            <div className="process-documentation p-4">
              <Row>
                <Col md={12} sm={12}>
                  <h1 className="rt-title mb-5">Process Documentation</h1>
                  <div className="text-right">
                    <ToastContainer />
                  </div>
                  {processData?.length === 0 ? (
                    <Link className={dataShow ? "d-block" : "d-none"} to="/ProcessDocumentationAdd">
                      <Button className="position-absolute start-50 top-50 translate-middle click-add-btn">
                        Click to add
                      </Button>
                    </Link>
                  ) : (
                    <>
                      {totalPosts > 0 &&
                        currentPosts?.map((item) => (
                          <ProcessCard
                            item={item}
                            handleDelete={handleDelete}
                            setDocumentShow={setDocumentShow}
                            setModalData={setModalData}
                            isAdmin={isAdmin}
                          />
                        ))}
                      <div className="d-flex justify-content-center">
                        <Pagination
                          postsPerPage={postsPerPage}
                          totalPosts={totalPosts}
                          paginate={paginate}
                          nextPage={nextPage}
                          prevPage={prevPage}
                          currentPage={currentPage}
                        />
                      </div>
                      <Modal
                        show={documentShow}
                        onHide={() => setDocumentShow(false)}
                        dialogClassName="rt-modal view-modal"
                        size="xl"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                      >
                        <Modal.Header closeButton>
                          <Modal.Title id="contained-modal-title-vcenter">
                            {modalData?.ProcessName}
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <p>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: modalData?.Description,
                              }}
                            />
                          </p>
                        </Modal.Body>
                      </Modal>
                    </>
                  )}
                </Col>
              </Row>
            </div>
          </Container>
      }
    </>
  )
}

export default ProcessDocumentation;
