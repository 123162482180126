/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useContext } from "react";
import { faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
// import siteApi from "../../../api/siteApi";
import { AuthContext } from "../../../Context/AuthContext";
import "../YearPlan/YearPlan.scss";
function PlanKeyRevenue({ yearNo }) {
  const [addValueKey, setAddValueKey] = useState(false);
  const [updateTrackState, setUpdateTrackState] = useState(false);
  const [keyRevenueData, setKeyRevenueData] = useState([]);
  const { oid, isAdmin } = useContext(AuthContext);

  useEffect(() => {
    if (oid && yearNo) {
      //  key revenue get api call
      axios
        .get(
          `https://dtsuederjg.execute-api.us-east-2.amazonaws.com/dev/${oid}/${yearNo}/keyrevenue`
        )
        .then((res) => {
          const keyRevenue = res ? res.data : "loading";
          setKeyRevenueData(keyRevenue);
        });
    }
  }, [oid, yearNo]);

  //  key revenue insert
  // eslint-disable-next-line no-shadow
  const handleAddKeyRevenue = async (id) => {
    const res = keyRevenueData?.filter((data) => data.id === id);
    res.forEach((i) => {
      if (i.KeyRevenue || i.KeyRevenueAmount) {
        const insertData = {
          id: i.id,
          KeyRevenue: i.KeyRevenue,
          KeyRevenueAmount: i.KeyRevenueAmount,
          oid,
          createdAt: i.createdAt,
          YearNo: yearNo,
        };
        axios.post(
          `https://dtsuederjg.execute-api.us-east-2.amazonaws.com/dev/keyrevenue`,
          insertData
        );
        setAddValueKey(false);
        toast("Created Successfully");
        const temp = [...keyRevenueData];
        setKeyRevenueData([]);
        setTimeout(() => {
          setKeyRevenueData(temp);
        }, 0.3);
      }
    });
  };

  // handle input change
  const handleKeyRevenueChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...keyRevenueData];
    list[index][name] = value;
    setKeyRevenueData(list);
  };
  //  key revenue update
  const updateKeyRevenue = (
    KeyRevenueValue,
    KeyRevenueAmountValue,
    idValue
  ) => {
    if (updateTrackState === true) {
      const keyReveneueUpdate = async () => {
        if (idValue) {
          const updateData = {
            KeyRevenue: KeyRevenueValue,
            KeyRevenueAmount: KeyRevenueAmountValue,
            id: idValue,
          };
          await axios.put(
            `https://dtsuederjg.execute-api.us-east-2.amazonaws.com/dev/keyrevenue/${idValue}`,
            updateData
          );
        }
      };
      keyReveneueUpdate();
      toast("Updated");
      setUpdateTrackState(false);
    }
  };

  //  key revenue  delete
  // eslint-disable-next-line no-shadow
  const removeKeyRevenue = async (event, id) => {
    toast("Deleted Successfully");
    const res = keyRevenueData.filter((data) => data.id !== id);
    setKeyRevenueData([]);
    setTimeout(() => {
      setKeyRevenueData([...res]);
    }, 0.1);
    await axios.delete(
      `https://dtsuederjg.execute-api.us-east-2.amazonaws.com/dev/keyrevenue/${id}`
    );
  };
  // add new key revenue
  const addKeyRevenue = () => {
    setKeyRevenueData([
      ...keyRevenueData,
      {
        id: uuidv4(),
        oid,
        KeyRevenue: "",
        KeyRevenueAmount: "",
        YearNo: yearNo,
        createdAt: new Date().toString(),
      },
    ]);
  };

  return (
    <>
      {/* key revenue section */}
      {keyRevenueData.length > 0
        ? keyRevenueData.map((input, index) => (
            <div className="row d-flex pr-2">
              <div className="col-md-5 col-xl-5 col-xs-5 col-lg-5">
                <input
                  className="text-margin-plan"
                  name="KeyRevenue"
                  placeholder="Revenue"
                  disabled={!isAdmin}
                  onChange={(e) => {
                    handleKeyRevenueChange(e, index);
                    setUpdateTrackState(true);
                  }}
                  onBlur={(event) => {
                    if (addValueKey === true) {
                      handleAddKeyRevenue(input.id);
                    } else {
                      updateKeyRevenue(
                        event.target.value,
                        input.KeyRevenueAmount,
                        input.id
                      );
                    }
                  }}
                  defaultValue={input.KeyRevenue}
                />
                <ToastContainer />
              </div>
              <div className="col-md-5 col-xl-5 col-xs-5 col-lg-5">
                <input
                  className="text-margin-plan"
                  name="KeyRevenueAmount"
                  disabled={!isAdmin}
                  placeholder="Amount"
                  onChange={(e) => {
                    handleKeyRevenueChange(e, index);
                    setUpdateTrackState(true);
                  }}
                  onBlur={(event) => {
                    if (addValueKey === true) {
                      handleAddKeyRevenue(input.id);
                    } else {
                      updateKeyRevenue(
                        input.KeyRevenue,
                        event.target.value,
                        input.id
                      );
                    }
                  }}
                  defaultValue={input.KeyRevenueAmount}
                />
              </div>
              <div className="col-md-2 col-xl-2 col-xs-2 col-lg-2">
                {isAdmin && (
                  <button type="button" className="btn btn-light">
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      className="mr-0"
                      onClick={(event) => removeKeyRevenue(event, input.id)}
                    />
                  </button>
                )}
              </div>
            </div>
          ))
        : ""}
      <br />
      {isAdmin && (
        <button
          type="button"
          className="btn btn-light plan-add-button"
          onClick={() => {
            setAddValueKey(true);
            addKeyRevenue();
          }}
        >
          <FontAwesomeIcon icon={faPlus} className="mr-0" /> ADD
        </button>
      )}
    </>
  );
}

export default PlanKeyRevenue;
