/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable prettier/prettier */
import React from "react";
import fileDownload from "js-file-download";
// import { BsDownload } from "react-icons/bs";
import axios from "axios";
import { FaDownload, FaTrash } from "react-icons/fa";
// import { Col, Row } from "react-bootstrap";
import { Storage } from "aws-amplify";
import { deleteMeetingAgendaAttachment } from "../../../../api/teamMeeting";
function MeetingAgendaAttachmentDownloadFile({
  attachmentFileLink,
  id,
  createdAt,
  meetingAgendaAttachmentFile,
  setMeetingAgendaAttachmentFile,
}) {
  const handleBulkImportFormatFile = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  const deleteAttachment = async (ID, Date, URL) => {
    const res = meetingAgendaAttachmentFile?.filter((data) => data.id !== ID);
    setMeetingAgendaAttachmentFile(res);
    setMeetingAgendaAttachmentFile([]);
    setTimeout(() => {
      setMeetingAgendaAttachmentFile([...res]);
    }, 0.1);
    const removeUrl = URL.split("public/").pop();
    await Storage.remove(removeUrl, { level: "public" });
    await deleteMeetingAgendaAttachment(ID, Date);
  };
  return (
    <>
      <div className="d-flex align-items-center badge-listing">
        <span className="flex-grow-1">{attachmentFileLink}</span>
        <span
          onClick={() => {
            handleBulkImportFormatFile(
              `${attachmentFileLink}`,
              `${attachmentFileLink}`
            );
          }}
        >
          {/* {attachmentFileLink} */}
          <FaDownload className="rt-info" />
        </span>

        <span>
          <FaTrash
            className="rt-denger"
            onClick={() => deleteAttachment(id, createdAt, attachmentFileLink)}
          />
        </span>
      </div>
    </>
  );
}

export default MeetingAgendaAttachmentDownloadFile;
