/* eslint-disable no-unused-vars */
/* eslint-disable no-bitwise */
/* eslint-disable no-self-compare */
/* eslint-disable array-callback-return */
/* eslint-disable no-else-return */
/* eslint-disable import/named */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable arrow-body-style */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/button-has-type */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Spinner, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaPlusCircle } from "react-icons/fa";
import { AiOutlineSearch } from "react-icons/ai";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "./Meetings.scss";
import MeetingCreator from "./Meeting/MeetingCreator/MeetingCreator";
import MeetingAssignee from "./Meeting/MeetingAssignee/MeetingAssignee";
import CloseMeetingCreator from "./ClosedMeeting/CloseMeetingCreator/CloseMeetingCreator";
import ClosedMeetingAssignee from "./ClosedMeeting/ClosedMeetingAssignee/ClosedMeetingAssignee";
import { teamMeetingMain } from "../../api/teamMeeting";
import { closeTeamMeetingMain } from "../../api/closeMeeting";
import { AuthContext } from "../../Context/AuthContext";
import NotFoundPage from "../Common/NotFoundPage";
// import Notification  from "../layouts/MainLayout/Notification";

function MeetingMain() {
  const [meetingsData, setMeetingsData] = useState([]);
  const [meetingDataCopy, setMeetingDataCopy] = useState([]);
  const [meetingDataAssignee, setMeetingDataAssignee] = useState([]);
  const [meetingDataAssigneeCopy, setMeetingDataAssigneeCopy] = useState([]);
  const [closedMeetingCreatorData, setClosedMeetingCreatorData] = useState([]);
  const [closedMeetingCreatorDataCopy, setCloseMeetingCreatorDataCopy] =
    useState([]);
  const [closedMeetingAssigneeData, setClosedMeetingAssigneeData] = useState(
    []
  );
  const [closedMeetingAssigneeDataCopy, setClosedMeetingAssigneeDataCopy] =
    useState([]);
  const [selectDateRange, setSelectDateRange] = useState("");
  const { oid, logged } = useContext(AuthContext);

  const thisMonthDataAssignee = [];
  const thisMonthDataCurrentUser = [];
  const thisMonthCloseMeeting = [];
  const thisMonthCurrentUserCloseMeeting = [];
  const thisWeekDataAssignee = [];
  const thisWeekDataCurrentUser = [];
  const thisWeekDataCloseMeeting = [];
  const thisWeekDataCloseMeetingCurrentUser = [];
  const nextWeekDataAssignee = [];
  const nextWeekDataCurrentUser = [];
  const nextWeekDataCloseMeeting = [];
  const nextWeekDataCloseMeetingCurrentUser = [];

  const isDateThisWeek = (date) => {
    const today = new Date();
    const day = today.getDay();
    const diff = today.getDate() - day + (day === 0 ? -6 : 0);
    const monday = new Date(today.setDate(diff));
    const sunday = new Date(monday.getTime() + 7 * 24 * 60 * 60 * 1000);
    return date > monday && date < sunday;
  };

  const isDateNextWeek = (date) => {
    const today = new Date();
    const day = today.getDay();
    const diff = today.getDate() - day + (day === 0 ? -6 : 0);
    const monday = new Date(today.setDate(diff + 7));
    const sunday = new Date(monday.getTime() + 7 * 24 * 60 * 60 * 1000);
    return date > monday && date < sunday;
  };

  const isDateThisMonth = (date) => {
    const today = new Date();
    const currentMonth = today.getMonth();
    const meetingMonth = date.getMonth();
    return currentMonth === meetingMonth ? true : false;
  };

  const mapData = (data, funName, arrayName) => {
    data.map((as) => {
      const result = funName(new Date(as.meetingDate));
      if (result === true) {
        arrayName.push(as);
      }
    });
  };

  useEffect(async () => {
    // const socket = io('http://localhost:5000')

    if (oid) {
      const res = await teamMeetingMain(oid);
      console.log(res);
      const currentUser = res.data.filter((i) => i.meetingCreator === logged);
      const assignee = res.data.filter((i) => {
        return i.addGuest.some((guestEmail) => guestEmail.email === logged);
      });

      const response = await closeTeamMeetingMain(oid);
      const currentUserClosedMeeting = response.data.filter(
        (i) => i.meetingCreator === logged
      );

      const assigneeUserClosedMeeting = response.data.filter((item) => {
        return item.addGuest.some((guestEmail) => guestEmail.email === logged);
      });

      if (selectDateRange === "thisMonth") {
        mapData(assignee, isDateThisMonth, thisMonthDataAssignee);
        mapData(
          assigneeUserClosedMeeting,
          isDateThisMonth,
          thisMonthCloseMeeting
        );
        mapData(
          currentUserClosedMeeting,
          isDateThisMonth,
          thisMonthCurrentUserCloseMeeting
        );
        mapData(currentUser, isDateThisMonth, thisMonthDataCurrentUser);

        setMeetingDataAssignee(thisMonthDataAssignee);
        setMeetingDataAssigneeCopy(thisMonthDataAssignee);
        setMeetingsData(thisMonthDataCurrentUser);
        setMeetingDataCopy(thisMonthDataCurrentUser);
        setClosedMeetingCreatorData(thisMonthCurrentUserCloseMeeting);
        setCloseMeetingCreatorDataCopy(thisMonthCurrentUserCloseMeeting);
        setClosedMeetingAssigneeData(thisMonthCloseMeeting);
        setClosedMeetingAssigneeDataCopy(thisMonthCloseMeeting);
      } else if (selectDateRange === "thisWeek") {
        mapData(assignee, isDateThisWeek, thisWeekDataAssignee);
        mapData(
          assigneeUserClosedMeeting,
          isDateThisWeek,
          thisWeekDataCloseMeeting
        );
        mapData(currentUser, isDateThisWeek, thisWeekDataCurrentUser);
        mapData(
          currentUserClosedMeeting,
          isDateThisWeek,
          thisWeekDataCloseMeetingCurrentUser
        );

        setMeetingDataAssignee(thisWeekDataAssignee);
        setMeetingDataAssigneeCopy(thisWeekDataAssignee);
        setMeetingsData(thisWeekDataCurrentUser);
        setMeetingDataCopy(thisWeekDataCurrentUser);
        setClosedMeetingCreatorData(thisWeekDataCloseMeetingCurrentUser);
        setCloseMeetingCreatorDataCopy(thisWeekDataCloseMeetingCurrentUser);
        setClosedMeetingAssigneeData(thisWeekDataCloseMeeting);
        setClosedMeetingAssigneeDataCopy(thisWeekDataCloseMeeting);
      } else if (selectDateRange === "nextWeek") {
        mapData(assignee, isDateNextWeek, nextWeekDataAssignee);
        mapData(currentUser, isDateNextWeek, nextWeekDataCurrentUser);
        mapData(
          assigneeUserClosedMeeting,
          isDateNextWeek,
          nextWeekDataCloseMeeting
        );
        mapData(
          currentUserClosedMeeting,
          isDateNextWeek,
          nextWeekDataCloseMeetingCurrentUser
        );

        setMeetingDataAssignee(nextWeekDataAssignee);
        setMeetingDataAssigneeCopy(nextWeekDataAssignee);
        setMeetingsData(nextWeekDataCurrentUser);
        setMeetingDataCopy(nextWeekDataCurrentUser);
        setClosedMeetingCreatorData(nextWeekDataCloseMeetingCurrentUser);
        setCloseMeetingCreatorDataCopy(nextWeekDataCloseMeetingCurrentUser);
        setClosedMeetingAssigneeData(nextWeekDataCloseMeeting);
        setClosedMeetingAssigneeDataCopy(nextWeekDataCloseMeeting);
      } else {
        setMeetingDataAssignee(assignee);
        setMeetingDataAssigneeCopy(assignee);
        setMeetingsData(currentUser);
        setMeetingDataCopy(currentUser);
        setClosedMeetingCreatorData(currentUserClosedMeeting);
        setCloseMeetingCreatorDataCopy(currentUserClosedMeeting);
        setClosedMeetingAssigneeData(assigneeUserClosedMeeting);
        setClosedMeetingAssigneeDataCopy(assigneeUserClosedMeeting);
      }
    }
  }, [oid, logged, !meetingsData, !meetingDataAssignee, selectDateRange]);

  const removeTeamMeetingData = async (id) => {
    const res = meetingsData.filter((data) => data.id !== id);
    setMeetingsData([...res]);
    toast("Deleted Successfully");
  };
  const removeClosedMeetingData = (id) => {
    const res = closedMeetingCreatorData.filter((data) => data.id !== id);
    setClosedMeetingCreatorData([...res]);
    toast("Deleted Successfully");
  };

  // console.log(notification logged ={logged}s);

  const handleMeetingSearch = (e) => {
    // meeting creator name search
    const activeMeeting = meetingDataCopy.map((meeting) => {
      const data = meeting.meetingName.toLowerCase();
      if (data.search(e.target.value.toLowerCase()) !== -1) {
        return meeting;
      } else {
        return "";
      }
    });

    const result = activeMeeting.filter((value) => value !== "");
    if (e.target.value === "") {
      setMeetingsData(meetingDataCopy);
    } else {
      setMeetingsData(result);
    }

    // meeting assignee name search
    const activeMeetingAssignee = meetingDataAssigneeCopy.map((meeting) => {
      const data = meeting.meetingName.toLowerCase();
      if (data.search(e.target.value.toLowerCase()) !== -1) {
        return meeting;
      } else {
        return "";
      }
    });

    const result1 = activeMeetingAssignee.filter((value) => value !== "");
    if (e.target.value === "") {
      setMeetingDataAssignee(meetingDataAssigneeCopy);
    } else {
      setMeetingDataAssignee(result1);
    }

    // close meeting creator name search

    const closeMeeting = closedMeetingCreatorDataCopy.map((meeting) => {
      const profileName = meeting.meetingName.toLowerCase();
      if (profileName.search(e.target.value.toLowerCase()) !== -1) {
        return meeting;
      } else {
        return "";
      }
    });

    const result2 = closeMeeting.filter((value) => value !== "");
    if (e.target.value === "") {
      setClosedMeetingCreatorData(closedMeetingCreatorDataCopy);
    } else {
      setClosedMeetingCreatorData(result2);
    }

    // close meeting assignee name search

    const closeMeetingAssignee = closedMeetingAssigneeDataCopy.map(
      (meeting) => {
        const profileName = meeting.meetingName.toLowerCase();
        if (profileName.search(e.target.value.toLowerCase()) !== -1) {
          return meeting;
        } else {
          return "";
        }
      }
    );

    const result3 = closeMeetingAssignee.filter((value) => value !== "");
    if (e.target.value === "") {
      setClosedMeetingAssigneeData(closedMeetingAssigneeDataCopy);
    } else {
      setClosedMeetingAssigneeData(result3);
    }
  };

  return (
    <Container fluid>
      <div className="section-metting">
        <Row>
          <Col lg={8}>
            <Link to="/workStream/list">
              <Button className="btn-rt-primary-icon my-1">Work Streams</Button>
            </Link>
          </Col>
          <Col lg={4}>
            <div className="employee_list-table-header d-flex align-items-center pb-4 gap-3">
              <div className="search-bar position-relative">
                <input
                  type="text"
                  placeholder="Search By Name"
                  className="search m-0 form-control pl-4"
                  onChange={(event) => {
                    handleMeetingSearch(event);
                  }}
                />
                <AiOutlineSearch className="position-absolute translate-middle-y top-50 icon pl-2" />
              </div>
              <div className="page-listing">
                <select
                  id="dropdown"
                  value={selectDateRange}
                  onChange={(e) => setSelectDateRange(e.target.value)}
                >
                  <option value="" selected>
                    Select
                  </option>
                  <option value="thisWeek">This Week</option>
                  <option value="nextWeek">Next Week</option>
                  <option value="thisMonth">This Month</option>
                </select>
              </div>

              <div>
                <Link to="/meeting/createMeeting/">
                  <Button className="btn-rt-primary-icon my-1">
                    <FaPlusCircle />
                    New Meeting
                  </Button>
                </Link>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={12}>
            <div className="accordion" id="accordion">
              <div className="card">
                <div className="card-header" id="headingOne">
                  <h5 className="mb-0">
                    <button
                      className="btn btn-link btn-block text-left title"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Open Meetings
                    </button>
                  </h5>
                </div>
                <ToastContainer />
                <div
                  id="collapseOne"
                  className="collapse show"
                  aria-labelledby="headingOne"
                  data-parent="#accordion"
                >
                  <div className="card-body ">
                    <h5 className="mb-4 title">Team Meetings</h5>
                    <Link to="/meeting/createMeeting/">
                      <FaPlusCircle className="ml-2 add-meeting" />
                    </Link>
                    {!meetingDataAssignee && !meetingsData && (
                      <div className="d-flex justify-content-center">
                        <Spinner className="rt-spinner" animation="grow" />
                      </div>
                    )}
                    {meetingDataAssignee ? (
                      meetingDataAssignee.map((item, index) => (
                        <MeetingAssignee key={index} meetingData={item} />
                      ))
                    ) : (
                      <NotFoundPage />
                    )}
                    {meetingsData !== [] &&
                      meetingsData.map((item, index) => (
                        <MeetingCreator
                          key={index}
                          meetingData={item}
                          trackMeetingData={meetingsData}
                          removeTeamMeetingData={(e) =>
                            removeTeamMeetingData(e)
                          }
                        />
                      ))}

                    {meetingsData.length === 0 &&
                      meetingDataAssignee.length === 0 && (
                        <div className="d-flex justify-content-center">
                          <NotFoundPage />
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingThree">
                  <h5 className="mb-0">
                    <button
                      className="btn btn-link btn-block text-left collapsed"
                      data-toggle="collapse"
                      data-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Closed Meetings
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseThree"
                  className="collapse"
                  aria-labelledby="headingThree"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    {!closedMeetingCreatorData &&
                      !closedMeetingAssigneeData && (
                        <div className="d-flex justify-content-center">
                          <Spinner className="rt-spinner" animation="grow" />
                        </div>
                      )}
                    {closedMeetingAssigneeData &&
                      closedMeetingAssigneeData.map((item, index) => (
                        <ClosedMeetingAssignee
                          key={index}
                          closedMeetingData={item}
                        />
                      ))}

                    {closedMeetingCreatorData &&
                      closedMeetingCreatorData.map((item, index) => (
                        <CloseMeetingCreator
                          key={index}
                          closedMeetingData={item}
                          removeClosedMeetingData={(e) =>
                            removeClosedMeetingData(e)
                          }
                        />
                      ))}

                    {closedMeetingCreatorData.length === 0 &&
                      closedMeetingAssigneeData.length === 0 && (
                        <div className="d-flex justify-content-center">
                          <NotFoundPage />
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default MeetingMain;
