/* eslint-disable dot-notation */
/* eslint-disable import/extensions */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable prettier/prettier */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-sequences */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-globals */
/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-else-return */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import '../../common.scss';

const Form = ({ formFields, formData, setFromData, loading, phoneWarning, setPhoneWarning, websiteWarning, setWebsiteWarning, handleAddOrgSubmit }) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const handleOnChange = (e, name) => {
        const newFromData = { ...formData };
        if (name) {
            newFromData[name] = e;
        }
        else {
            const field = e.target.name;
            const value = e.target.value;
            newFromData[field] = value;
        }
        setFromData(newFromData);
    };
    const handlePhoneWarning = (num) => {
        if (num.length < 10) {
            setPhoneWarning(true);
        }
        else {
            setPhoneWarning(false);
        }
    }
    // eslint-disable-next-line no-useless-escape
    const websiteRegx = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    const handleCheckWebsite = (value) => {
        const isValid = websiteRegx.test(value);
        if (isValid === false) {
            setWebsiteWarning(true);
        }
        else {
            setWebsiteWarning(false);
        }
    }
    return (
        <>
            {
                !loading ? <form onSubmit={handleSubmit(handleAddOrgSubmit)} className="pl-4">
                    {formFields &&
                        formFields.map(({ label, type, name, mandatory }, index) => {
                            if (
                                name === 'companyName'
                            ) {
                                return (
                                    <>
                                        <div className="form-row" key={index}>
                                            <div className="form-group col-md-4 one">
                                                <label className={mandatory ? 'required' : ''}>
                                                    {label}
                                                </label>
                                                <input {...register('companyName', {
                                                    required: {
                                                        value: true,
                                                        message: 'This field is required!'
                                                    },
                                                })}
                                                    type={type}
                                                    className="form-control"
                                                    placeholder={label}
                                                    defaultValue={formData[name]}
                                                    name={name}
                                                    onBlur={(e) => handleOnChange(e, null)}
                                                />
                                                {errors.companyName?.type === 'required' && <span className='input-warning-style'>{errors.companyName.message}</span>}
                                            </div>
                                        </div>
                                    </>
                                );
                            } else if (name === 'billingAddress') {
                                return (
                                    <>
                                        <div className="form-row" key={index}>
                                            <div className="form-group col-md-4 one">
                                                <label className={mandatory ? 'required' : ''}>
                                                    {label}
                                                </label>
                                                <input {...register('billingAddress', {
                                                    required: {
                                                        value: true,
                                                        message: 'This field is required!'
                                                    },
                                                })}
                                                    type={type}
                                                    className="form-control"
                                                    placeholder={label}
                                                    defaultValue={formData[name]}
                                                    name={name}
                                                    onBlur={(e) => handleOnChange(e, null)}
                                                />
                                                {errors.billingAddress?.type === 'required' && <span className='input-warning-style'>{errors.billingAddress.message}</span>}
                                            </div>
                                        </div>
                                    </>
                                );
                            } else if (name === 'OfficePhoneNumber') {
                                return (
                                    <>
                                        <div className="form-row" key={index}>
                                            <div className="form-group col-md-4 one">
                                                <label className={mandatory ? 'required' : ''}>
                                                    {label}
                                                </label>
                                                <input   {...register('OfficePhoneNumber', {
                                                    required: {
                                                        value: true,
                                                        message: 'Please type first name',
                                                    },
                                                })}
                                                    type={type}
                                                    className="form-control"
                                                    placeholder={label}
                                                    defaultValue={formData[name]}
                                                    name={name}
                                                    onBlur={(e) => { handleOnChange(e, null); handlePhoneWarning(e.target.value); }}
                                                />
                                                {errors.OfficePhoneNumber?.type === 'required' && <span className='input-warning-style'>{errors.OfficePhoneNumber.message}</span>}
                                                {phoneWarning && <span className='input-warning-style'>Minimum length should be 10.</span>}
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                            else if (name === 'companySize') {
                                return (
                                    <>
                                        <div className="form-row" key={index}>
                                            <div className="form-group col-md-4 one">
                                                <label className={mandatory ? 'required' : ''}>
                                                    {label}
                                                </label>
                                                <input {...register('companySize', {
                                                    required: {
                                                        value: true,
                                                        message: 'This field is required!'
                                                    },
                                                })}
                                                    type={type}
                                                    className="form-control"
                                                    placeholder={label}
                                                    defaultValue={formData[name]}
                                                    name={name}
                                                    onBlur={(e) => handleOnChange(e, null)}
                                                />
                                                {errors.companySize?.type === 'required' && <span className='input-warning-style'>{errors.companySize.message}</span>}
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                            else if (name === 'website') {
                                return (
                                    <>
                                        <div className="form-row" key={index}>
                                            <div className="form-group col-md-4 one">
                                                <label className={mandatory ? 'required' : ''}>
                                                    {label}
                                                </label>
                                                <input {...register('website', {
                                                    required: {
                                                        value: true,
                                                        message: 'This field is required!'
                                                    },
                                                })}
                                                    type={type}
                                                    className="form-control"
                                                    placeholder={label}
                                                    defaultValue={formData[name]}
                                                    name={name}
                                                    onBlur={(e) => { handleOnChange(e, null); handleCheckWebsite(e.target.value); }}
                                                />
                                                {errors.website?.type === 'required' && <span className='input-warning-style'>{errors.website.message}</span>}
                                                {websiteWarning && <span className='input-warning-style'>Please give a valid website.</span>}
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                        })}
                    <button type="submit" className="btn btn-primary mb-5">
                        Submit
                    </button>
                </form> : <div className="d-flex justify-content-center">
                    <Spinner className="rt-spinner" animation="grow" />
                </div>
            }
        </>
    );
};

export default Form;
