/* eslint-disable react/no-unescaped-entities */
import React from 'react';

const Footer = () => (
    <div>
        <footer className="footer-bg border-top">
            <div className="container">
                <div className="row ">
                    <div className="footer">
                        <p>Copyright ©2022 SJ Innovation, All Rights Reserved</p>
                    </div>
                </div>
            </div>
        </footer>
    </div>
);

export default Footer;
