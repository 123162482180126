/* eslint-disable no-alert */
/* eslint-disable no-lone-blocks */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/order */
/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable prettier/prettier */
import SideNav, { NavIcon, NavItem, NavText } from "@trendmicro/react-sidenav";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import { Auth } from "aws-amplify";
import React, { useContext, useState } from "react";
import { Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { FaPlusCircle } from "react-icons/fa";
import { Link, useHistory, useLocation } from "react-router-dom";
import { AuthContext } from "../../../Context/AuthContext";
import useAdminUserData from "../../../Hooks/useAdminUserData";
import checDouble from "../../../images/check-double.svg";
import helloIcon from "../../../images/helloIcon.png";
import Announcement from "./icon/Announcement.svg";
import Badges from "./icon/Badges.svg";
import Dashboard from "./icon/Dashboard.svg";
import Employee from "./icon/Employee.svg";
import Logout from "./icon/Logout.svg";
import Meetings from "./icon/Meetings.svg";
import organizationChart from "./icon/organizationChart.svg";
import processDocumentation from "./icon/processDocumentation.svg";
import RoleModule from "./icon/RoleModule.svg";
import Vision from "./icon/Vision.svg";
import "./index.css";
import Notification from './Notification';
function MainLayout(props) {
  const history = useHistory();
  const location = useLocation();
  const [sidebar, setSidebar] = useState("true");
  const { children, socket} = props;
  const [image, employee] = useAdminUserData();
  const employeeImg = employee?.ProfilePicture;
  const adminImg = image?.profilePicture;
  const [warning, setWarning] = useState(false);
  const [firstRender, setFirstRender] = useState(true);

  const values = useContext(AuthContext);

  // setTimeout(()=>{
  //   setFirstRender(false)
  // },60000)

  const handleLogout = async () => {
    try {
      await Auth.signOut({
        global: true,
      });

      if (localStorage.userInfo) {
        localStorage.removeItem("userInfo");
      } else {
        sessionStorage.removeItem("userInfo");
      }
      window.location.href = "/";
    } catch (error) {
      console.log("error signing out: ", error);
      if (error?.message === "Access Token has been revoked") {
        await Auth.signOut();
      }
    }
  };

  setTimeout(() => {
    setWarning(true);
  }, 3540000);
  setTimeout(() => {
    setWarning(false);
    handleLogout();
  }, 3600000);

  return (
    <>
      <div className="container-fluid container-bg">
        <div className="row">
          <div className="col-md-2 col-sm-3 col-xs-3 sidebar-height">
            <SideNav
              expanded={sidebar}
              onSelect={(selected) => {
                const to = `/${selected}`;
                if (location.pathname !== to) {
                  history.push(to);
                }
              }}
            >
              <SideNav.Nav defaultSelected="db">
                <NavItem eventKey="profile" className="border-btm top-area">
                  <div className="col-md-12">
                    <div className="row avatar-style">
                      <div className="col-md-3">
                        {adminImg ? (
                          <img
                            className="admin-avatar"
                            src={image?.profilePicture}
                            alt="Avatar-icon"
                          />
                        ) : (
                          <img
                            className="admin-avatar"
                            src={employeeImg}
                            alt="Avatar-icon"
                          />
                        )}
                      </div>
                      <div className="col-md-7">
                        <div className="d-flex align-items-center ">
                          <p className="avatar-grettings ms-2">Hello </p>
                          <div>
                            <img
                              className="hello-icon "
                              src={helloIcon}
                              alt=""
                            />
                          </div>
                        </div>

                        <p className="avatar-name ">{values.logged}</p>
                      </div>
                      <div className="col-md-2">
                        <div className="d-flex align-items-center justify-content-end">
                          <Notification socket={socket} firstRender={firstRender}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </NavItem>
                {values.isSuperAdmin && (
                  <NavItem eventKey="superAdmin/dashboard">
                    <NavIcon>
                      <img src={Dashboard} alt="Dashboard icon" />
                    </NavIcon>
                    <NavText>Dashboard</NavText>
                  </NavItem>
                )}
                {values.isSuperAdmin && (
                  <NavItem eventKey="org/add">
                    <NavIcon>
                      <img src={Dashboard} alt="Dashboard icon" />
                    </NavIcon>
                    <NavText>Add Organization</NavText>
                  </NavItem>
                )}

                {values.isSuperAdmin && (
                  <NavItem eventKey="org/list">
                    <NavIcon>
                      <img src={Dashboard} alt="Dashboard icon" />
                    </NavIcon>
                    <NavText>Organization List</NavText>
                  </NavItem>
                )}

                {values.isSuperAdmin && (
                  <NavItem eventKey="admin/add">
                    <NavIcon>
                      <img src={Dashboard} alt="Dashboard Icon" />
                    </NavIcon>
                    <NavText>Add An Admin</NavText>
                  </NavItem>
                )}
                {!values.isSuperAdmin && (
                  <NavItem eventKey="db">
                    <NavIcon>
                      <img src={Dashboard} alt="Dashboard-icon" />
                    </NavIcon>
                    <NavText>Dashboard</NavText>
                  </NavItem>
                )}

                {!values.isSuperAdmin && (
                  <NavItem eventKey="processDocumentation">
                    <NavIcon>
                      <img
                        src={processDocumentation}
                        alt="process Documentation icon"
                      />
                    </NavIcon>
                    <NavText>Process Documentation</NavText>
                  </NavItem>
                )}

                {!values.isSuperAdmin && (
                  <NavItem eventKey="meetings">
                    <NavIcon>
                      <img src={Meetings} alt="Meetings icon" />
                    </NavIcon>
                    <NavText>
                      <Link
                        to="/meetings"
                        className="text-decoration-none"
                        style={{ color: "inherit" }}
                      >
                        Meetings
                      </Link>
                    </NavText>
                  </NavItem>
                )}


                {!values.isSuperAdmin && (
                  <NavItem eventKey="badges">
                    <NavIcon>
                      <img src={Badges} alt="Badges icon" />
                    </NavIcon>
                    <NavText>
                      <Link
                        to="/badges"
                        className="text-decoration-none"
                        style={{ color: "inherit" }}
                      >
                        Praise
                      </Link>
                    </NavText>
                  </NavItem>
                )}

                {values.isSuperAdmin && (
                  <NavItem eventKey="admin/list">
                    <NavIcon>
                      <img src={Dashboard} alt="Dashboard icon" />
                    </NavIcon>
                    <NavText>
                      <Link
                        to="/admin/list"
                        className="text-decoration-none"
                        style={{ color: "inherit" }}
                      >
                        Admin List
                      </Link>
                    </NavText>
                  </NavItem>
                )}

                {!values.isSuperAdmin && (
                  <NavItem eventKey="visionAndPlan">
                    <NavIcon>
                      <img src={Vision} alt="Vision-icon" />
                    </NavIcon>
                    <NavText>Vision & Plan</NavText>
                    <NavItem eventKey="visionModule" className="submenu-style">
                      <NavText>Vision</NavText>
                    </NavItem>
                    <NavItem eventKey="planModule" className="submenu-style">
                      <NavText>Strategic Plan</NavText>
                    </NavItem>
                  </NavItem>
                )}

                {!values.isSuperAdmin && (
                  <NavItem eventKey="edit-chart">
                    <NavIcon>
                      <img
                        src={organizationChart}
                        alt="organization Chart Icon"
                      />
                    </NavIcon>
                    <NavText>Organization Chart</NavText>
                  </NavItem>
                )}

                {!values.isSuperAdmin && (
                  <NavItem eventKey="employeelist">
                    <NavIcon>
                      <img src={Employee} alt="Employee icon" />
                    </NavIcon>
                    <NavText>Employee</NavText>
                  </NavItem>
                )}
                {!values.isSuperAdmin && (
                  <NavItem eventKey="task">
                    <NavIcon>
                      <img src={checDouble} alt="Task icon" />
                    </NavIcon>
                    <NavText
                      className="text-decoration-none"
                      style={{ color: "inherit" }}
                    >
                      Task
                    </NavText>
                  </NavItem>
                )}

                {!values.isSuperAdmin && (
                  <NavItem eventKey="announcementListing">
                    <NavIcon>
                      <img src={Announcement} alt="Announcement icon" />
                    </NavIcon>
                    <NavText>Announcements</NavText>
                  </NavItem>
                )}

                {!values.isSuperAdmin && (
                <NavItem eventKey="roleAndModule">
                    <NavIcon>
                      <img src={RoleModule} alt="Role and Module" />
                    </NavIcon>
                    <NavText>Role & Module</NavText>
                    <NavItem eventKey="roleManagement" className="submenu-style">
                      <NavText>Role Management</NavText>
                    </NavItem>
                    <NavItem eventKey="moduleControl" className="submenu-style">
                      <NavText>Module Control</NavText>
                    </NavItem>
                  </NavItem>
                )}
                
                <NavItem eventKey="log-out">
                  <NavIcon>
                    <img src={Logout} alt="Logout icon" />
                  </NavIcon>
                  <NavText
                    onClick={() => handleLogout()}
                    className="text-decoration-none"
                    style={{ color: "inherit" }}
                  >
                    Logout
                  </NavText>
                </NavItem>
              </SideNav.Nav>
            </SideNav>
          </div>

          <main className="col-md-10 col-sm-9 col-xs-9">
            {!values.isSuperAdmin && (
              <>
               <div className="d-flex align-items-center justify-content-end my-3">
              <Link to="/meeting/createMeeting/">
                <Button className="btn-rt-primary-icon my-1 mr-5">
                  <FaPlusCircle />
                  New Meeting
                </Button>
              </Link>
             </div>
              
              </>
              
            )}
            
            <Container fluid>
              {/* <Nav.Link href="#">Breadcrumbs</Nav.Link> */}

              <Nav
                className="me-auto my-2 my-lg-0"
                style={{ maxHeight: "100px" }}
                navbarScroll
              ></Nav>
              {warning && (
                <h4 className="session-warning mt-3">
                  Session Expiring Soon,You Will be logged Out !
                </h4>
              )}
            </Container>

            <div className="row children-container mh-75">{children}</div>
          </main>
        </div>
      </div>
    </>
  );
}

export default MainLayout;
