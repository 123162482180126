/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */
/* eslint-disable arrow-body-style */
/* eslint-disable prettier/prettier */
import axios from 'axios'
// import siteApi from './siteApi'

const closeMeeting = process.env.REACT_APP_CLOSEMEETING_BASE_URL

const closeMeetingAgenda = process.env.REACT_APP_CLOSEMEETINGAGENDA_BASE_URL

const closeTeamMeetingAgenda = async (oid, paramsId) => {
  if (oid && paramsId) {
    const result = await axios
      .get(
        `${closeMeetingAgenda}/${oid}/${paramsId}/closeteammeetingagenda  
`
      )
      .then(res => {
        return res.data
      })
    return result
  }
}

const removeCloseMeetingAgenda = async (id, createdAt) => {
  const result = await axios.delete(
    `${closeMeeting}/closeteammeeting/${id}/${createdAt}
`
  )
  return result
}

const closeTeamMeeting = (oid, paramsId) => {
  if (oid && paramsId) {
    const result = axios
      .get(`${closeMeeting}/singlecloseteammeeting/${oid}/${paramsId}`)
      .then(res => {
        return res.data
      })
    return result
  }
}

const closeTeamMeetingMain = oid => {
  if (oid) {
    const result = axios
      .get(`${closeMeeting}/${oid}/closeteammeeting`)
      .then(res => {
        return res
      })
    return result
  }
}
export {
  closeTeamMeetingAgenda,
  removeCloseMeetingAgenda,
  closeTeamMeeting,
  closeTeamMeetingMain
}
