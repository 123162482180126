/* eslint-disable no-unused-vars */
/* eslint-disable import/named */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
/* eslint-disable radix */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-return-await */
/* eslint-disable no-shadow */
/* eslint-disable prettier/prettier */

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import React, { useState, useEffect, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { FaFileUpload, FaTrash } from "react-icons/fa";
import toast from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import "../Details.scss";
import { AuthContext } from "../../../../Context/AuthContext";
import { getMeetingAgendaAttachment } from "../../../../api/teamMeeting";
import EnableButtonPage from "./enableButtonPage";
import Timer from "./Timer";
import MeetingAgendaType from "./MeetingAgendaType";
import MeetingAgendaAttachment from "./MeetingAgendaAttachment";
import MeetingAgendaAttachmentDownloadFile from "./MeetingAgendaAttachmentDownloadFile";
function SingleIteamMeetingAgenda({
  input,
  index,
  removeAgenda,
  updateAgenda,
  trackAddMeetingAgenda,
  meetingAgendaData,
  setMeetingAgendaData,
}) {
  const { oid } = useContext(AuthContext);
  const [meetingAgendaAttachmentFile, setMeetingAgendaAttachmentFile] =
    useState([]);
  const [id, setId] = useState("");
  const [timer, setTimer] = useState(0);
  const [updateTrackState, setUpdateTrackState] = useState(false);
  const [enable, setEnable] = useState(false);
  const handleEnable = () => setEnable(true);
  const handleDisable = () => setEnable(false);
  console.log("Meeting Agenda Attachment", meetingAgendaAttachmentFile);
  // convert to second
  const secondConverter = (timer) => {
    const min = parseInt(timer.toString().split(":")[0]) * 60;
    const sec = parseInt(timer.toString().split(":").pop());
    const totalSec = min + sec;
    setTimer(totalSec);
  };

  // calculate time
  const dispSecondsAsMins = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const seconds_ = seconds % 60;
    return `${mins.toString()}:${seconds_ === 0 ? "00" : seconds_.toString()}`;
  };

  // handle CkEditor change
  const handleMeetingAgenda = (editor, name, index) => {
    const value = editor.getData();
    const list = [...meetingAgendaData];
    list[index][name] = value;
    setMeetingAgendaData(list);
  };

  // call calculate time function
  const timeCalculation = dispSecondsAsMins(timer);

  //  meeting agenda delete
  const removeMeetingAgenda = async (event, id, createdAt) => {
    toast.success("Deleted Successfully");
    const res = meetingAgendaData.filter((data) => data.id !== id);
    setMeetingAgendaData([]);
    setTimeout(() => {
      setMeetingAgendaData([...res]);
    }, 0.1);
    removeAgenda(id, createdAt);
  };

  //  meeting agenda update
  const updateMeetingAgenda = (
    topicName,
    takeAway,
    timer,
    agendaType,
    id,
    createdAt
  ) => {
    if (updateTrackState === true) {
      const updateMeetingAgendaData = async () => {
        const updateData = {
          timer,
          topicName,
          takeAway,
          agendaType,
        };
        if (id && createdAt) {
          updateAgenda(id, createdAt, updateData);
        }
      };
      updateMeetingAgendaData();
      toast.success("Update Meeting Agenda Data");
      setUpdateTrackState(false);
    }
  };

  // update meeting agenda time
  const updateMeetingAgendaTime = async (id) => {
    const res = meetingAgendaData.filter((data) => data.id === id);
    res[0].timer = timeCalculation;
    const newItems = meetingAgendaData.map((item) => {
      let itemProps = { ...item };
      if (item.id === res.id) {
        itemProps = {
          ...itemProps,
          ...res,
        };
      }
      return itemProps;
    });
    setMeetingAgendaData(newItems);
    const updateData = {
      timer: res[0].timer,
      topicName: res[0].topicName,
      takeAway: res[0].takeAway,
      agendaType: res[0].agendaType,
    };
    if (id && res[0].createdAt) {
      updateAgenda(id, res[0].createdAt, updateData);
    }
  };

  // update meeting agenda type
  const updateMeetingAgendaType = async (id, value) => {
    const res = meetingAgendaData.filter((data) => data.id === id);
    res[0].agendaType = value;
    const newItems = meetingAgendaData.map((item) => {
      let itemProps = { ...item };
      if (item.id === res.id) {
        itemProps = {
          ...itemProps,
          ...res,
        };
      }
      return itemProps;
    });
    setMeetingAgendaData(newItems);
    const updateData = {
      timer: res[0].timer,
      topicName: res[0].topicName,
      takeAway: res[0].takeAway,
      agendaType: res[0].agendaType,
    };
    if (id && res[0].createdAt) {
      updateAgenda(id, res[0].createdAt, updateData);
      toast.success("Update Meeting Agenda Data");
    }
  };

  useEffect(async () => {
    // Meeting agenda Attachment get api calling
    const result = await getMeetingAgendaAttachment(oid, input.id);
    setMeetingAgendaAttachmentFile(result);
  }, [oid, input.id]);

  return (
    <>
      <Row key={index}>
        <Col lg={12} className="p-4">
          <div className="agenda-item">
            {/* timer */}
            <span className="agenda-timer">
              <span
                className="at"
                onClick={() => {
                  setId(input.id);
                  secondConverter(input.timer);
                }}
              >
                <Timer
                  onclickId={id}
                  timer={timer}
                  input={input.timer}
                  id={input.id}
                  setTimer={setTimer}
                  timeCalculation={timeCalculation}
                  dispSecondsAsMins={dispSecondsAsMins}
                  updateMeetingAgendaTime={updateMeetingAgendaTime}
                  enable={enable}
                />
              </span>
              <span> {input.agendaType}</span>
            </span>

            <div className="flex-grow-1">
              {/* <span>{index + 1}</span> */}
              {/* topic name */}
              <div className="p-3">
                <CKEditor
                  editor={ClassicEditor}
                  placeholder="Add topics here"
                  onChange={(event, editor, name = "topicName") => {
                    handleMeetingAgenda(editor, name, index);
                    if (trackAddMeetingAgenda !== true) {
                      setUpdateTrackState(true);
                    }
                  }}
                  onBlur={(event, editor) => {
                    const data = editor.getData();
                    updateMeetingAgenda(
                      data,
                      input.takeAway,
                      input.timer,
                      input.agendaType,
                      input.id,
                      input.createdAt
                    );
                  }}
                  data={input.topicName}
                  disabled={!enable}
                />
              </div>
            </div>

            {/* meeting agenda type */}
            <span className="hr-line d-flex">
              <div className="rt-dw">
                {["start"].map((direction) => (
                  <MeetingAgendaType
                    direction={direction}
                    enable={enable}
                    id={input.id}
                    updateMeetingAgendaType={updateMeetingAgendaType}
                  />
                ))}
              </div>
              |
            </span>

            {/* take away name */}
            <div className="flex-grow-1">
              <div className="p-3">
                <CKEditor
                  editor={ClassicEditor}
                  placeholder="Add Takeaways here"
                  onChange={(event, editor, name = "takeAway") => {
                    handleMeetingAgenda(editor, name, index);
                    if (trackAddMeetingAgenda !== true) {
                      setUpdateTrackState(true);
                    }
                  }}
                  onBlur={(event, editor) => {
                    const data = editor.getData();
                    updateMeetingAgenda(
                      input.topicName,
                      data,
                      input.timer,
                      input.agendaType,
                      input.id,
                      input.createdAt
                    );
                  }}
                  data={input.takeAway}
                  disabled={!enable}
                />
              </div>
            </div>
            <div className="flex-shrink-1">
              <span className="hr-line d-flex ">
                <div className="rt-dw">
                  {["down"].map((direction) => (
                    <MeetingAgendaAttachment
                      direction={direction}
                      meetingAgendaId={input.id}
                      enable={enable}
                      meetingAgendaAttachmentFile={meetingAgendaAttachmentFile}
                      setMeetingAgendaAttachmentFile={
                        setMeetingAgendaAttachmentFile
                      }
                    />
                  ))}
                </div>
              </span>
            </div>
            <div className="d-flex flex-column p-3">
              <span className="mb-3">
                <EnableButtonPage
                  enable={enable}
                  handleEnable={handleEnable}
                  handleDisable={handleDisable}
                />
              </span>
              <FaTrash
                className="rt-denger"
                onClick={(event) =>
                  removeMeetingAgenda(event, input.id, input.createdAt)
                }
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        {meetingAgendaAttachmentFile.length > 0
          ? meetingAgendaAttachmentFile.map((input, index) => (
              <Col lg={12}>
                <MeetingAgendaAttachmentDownloadFile
                  attachmentFileLink={input.attachmentFileLink}
                  id={input.id}
                  createdAt={input.createdAt}
                  meetingAgendaAttachmentFile={meetingAgendaAttachmentFile}
                  setMeetingAgendaAttachmentFile={
                    setMeetingAgendaAttachmentFile
                  }
                />
              </Col>
            ))
          : ""}
      </Row>
    </>
  );
}

export default SingleIteamMeetingAgenda;
