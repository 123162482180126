/* eslint-disable react/no-unescaped-entities */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-template */
/* eslint-disable arrow-body-style */
/* eslint-disable eqeqeq */
/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
/* eslint-disable prettier/prettier */
import React, { useEffect, useContext, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "./CreateMeeting.scss";
import { FaTrash } from "react-icons/fa";
import toast from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../../../Context/AuthContext";
import {
  createMeeting,
  sendMail,
  // AllEmployee,
  createMeetingDate,
} from "../../../../api/teamMeeting";
import { getAllEmployeeMergedData } from "../../../../api/employee";
import MeetingModal from "../MeetingModal";
import {
  allWorkStream,
  getAssigendStreamsByEmail,
} from "../../../../api/workStream";
import { addNotification } from "../../../../api/notification";

export default function CreateMeeting({ socket }) {
  const history = useHistory();
  const { oid, logged } = useContext(AuthContext);
  const [meetingName, setMeetingName] = useState("");
  const [meetingDate, setMeetingDate] = useState("");
  const [meetingTime, setMeetingTime] = useState("");
  const [workstream, setWorkstream] = useState("");
  const [currentUserWorkStreams, setCurrentUserWorkStreams] = useState([]);
  const [meetingType, setMeetingType] = useState("");
  const [description, setDescription] = useState("");
  const [meetingLink, setMeetingLink] = useState("");
  const [checked, setChecked] = useState(false);
  const [combineEmailName, setCombineEmailName] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [trackDayValue, setTrackDayValue] = useState("");

  const currentDataValue = new Date();
  const strLen = currentDataValue.toString().length;
  const currentTimeZone = currentDataValue.toString().slice(24, strLen);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCancel = () => {
    setShow(false);
    setSelectedUser([]);
  };

  const currentDate = new Date().toJSON().slice(0, 10);

  //  remove user function
  const removeUser = (id) => {
    const res = selectedUser.filter((data, index) => index !== id);
    setSelectedUser(res);
  };

  const handleAddNewUser = (value) => {
    setSelectedUser(value);
  };

  const weekOfTheMonth = (date) => {
    const day = date.getDate();
    const weekDay = date.getDay();
    let week = Math.ceil(day / 7);

    const ordinal = ["First", "Second", "Third", "Fourth", "Last"];
    const weekDays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    // Check the next day of the week and if it' on the same month, if not, respond with "Last"
    const nextWeekDay = new Date(date.getTime() + 1000 * 60 * 60 * 24 * 7);
    if (nextWeekDay.getMonth() !== date.getMonth()) {
      week = 5;
    }

    return `${ordinal[week - 1]} ${weekDays[weekDay]}`;
  };

  const trackDay = (date) => {
    const days = [new Date(`${date}`)];
    for (let i = 0; i < days.length; i += 1) {
      const d = days[i];
      const storeValue = weekOfTheMonth(d);
      setTrackDayValue(storeValue);
    }
  };

  // create new meeting
  const createNewMeeting = async (e) => {
    e.preventDefault();

    const weekday = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const date = new Date();
    const currentDay = weekday[date.getDay()];
    // insert data
    const insertData = {
      oid,
      meetingName,
      meetingDate,
      meetingTime,
      workstream,
      description,
      meetingLocation: meetingLink,
      meetingTimezone: currentTimeZone,
      addGuest: selectedUser,
      meetingType,
      meetingDay: currentDay,
      meetingCreator: logged,
      meetingTrackDay: "",
    };

    const insertDataWeekly = {
      oid,
      meetingName,
      meetingDate,
      meetingTime,
      workstream,
      description,
      meetingLocation: meetingLink,
      meetingTimezone: currentTimeZone,
      addGuest: selectedUser,
      meetingType,
      meetingDay: currentDay,
      meetingCreator: logged,
      meetingTrackDay: trackDayValue,
    };

    if (meetingType === "Monthly") {
      const response = await createMeeting(insertDataWeekly);
      const insertValue = {
        oid,
        meetingId: response.id,
        meetingDate,
        meetingType,
        meetingDay: currentDay,
        meetingTrackDay: trackDayValue,
      };
      await createMeetingDate(insertValue);
    } else {
      const response = await createMeeting(insertData);
      const insertValue = {
        oid,
        meetingId: response.id,
        meetingDate,
        meetingType,
        meetingDay: currentDay,
        meetingTrackDay: trackDayValue,
      };
      await createMeetingDate(insertValue);
    }

    toast.success("Created Meeting Successfully");

    const email = [logged];

    selectedUser.map((su) => email.push(su.email));

    email.map(async (e) => {
      const insertData = {
        oid,
        email: e,
        meetingTitle: meetingName,
        meetingDate,
        meetingTime,
      };
      await addNotification(insertData);
    });

    setTimeout(() => {
      history.push("/meetings");
    }, 3000);

    email.map((e) => {
      socket?.emit("sendNotification", {
        meetingTitle: meetingName,
        meetingDate,
        meetingTime,
        email: e,
      });
    });
  };

  // set mail to user
  const SentMailToUser = () => {
    if (
      meetingName &&
      meetingDate &&
      meetingTime &&
      selectedUser &&
      workstream &&
      description &&
      meetingLink &&
      currentTimeZone &&
      checked === true
    ) {
      selectedUser.map(async (item) => {
        const insertData = {
          meetingName,
          meetingDate,
          meetingTime,
          to: item.email,
          from: "testingsidsj@gmail.com",
          workstream,
          description,
          meetingLink,
          currentTimeZone,
        };
        sendMail(insertData);
      });
    }
  };

  // cancel button
  const cancelButton = () => {
    history.push("/meetings");
  };

  useEffect(() => {
    const getEmployeeNameEmail = async () => {
      const EmployeeData = await getAllEmployeeMergedData(oid);
      const formattedName =
        EmployeeData?.length > 0 &&
        EmployeeData?.filter((item) =>
          item.emailAddress
            ? item.emailAddress !== logged
            : item.adminEmail !== logged
        )?.map((i) =>
          i.displayName
            ? i.displayName
            : i.adminFirstName.concat(` ${i.adminLastName}`)
        );
      const formattedUserEmail = EmployeeData?.map((item) =>
        item.emailAddress ? item.emailAddress : item.adminEmail
      ).filter((email) => email !== logged);
      const profilePicture =
        EmployeeData?.length > 0 &&
        EmployeeData?.filter((item) =>
          item.emailAddress
            ? item.emailAddress !== logged
            : item.adminEmail !== logged
        )?.map((i) => (i.ProfilePicture ? i.ProfilePicture : i.profilePicture));
      setCombineEmailName(
        formattedName?.map((value, index) => ({
          name: value,
          email: formattedUserEmail[index],
          profile: profilePicture[index],
        }))
      );
      const allWorkStreams = await allWorkStream(oid);
      const currentUserWorkStreamsValue = await getAssigendStreamsByEmail(
        logged
      );

      if (currentUserWorkStreamsValue?.length > 0) {
        const filteredArray = allWorkStreams.filter((item1) => {
          return currentUserWorkStreamsValue.some(
            (item2) => item2.workStreamId === item1.id
          );
        });
        setCurrentUserWorkStreams(filteredArray);
      } else {
        setCurrentUserWorkStreams([]);
      }
    };
    getEmployeeNameEmail();
  }, [oid]);

  return (
    <>
      <Container fluid>
        <div className="bg-white rounded p-4 create-meeting">
          <h2 className="py-3 title">Create New Meetings</h2>
          <Row>
            <Col lg={12}>
              <Form onSubmit={createNewMeeting}>
                <Row>
                  <Col lg={6}>
                    <Form.Group className="mb-4">
                      <Form.Control
                        type="text"
                        placeholder="Name your meeting"
                        onChange={(e) => setMeetingName(e.target.value)}
                        required
                      />
                    </Form.Group>
                    <Row>
                      <Col lg={6}>
                        <Form.Group className="mb-4">
                          <Form.Control
                            min={currentDate}
                            type="date"
                            placeholder="Select Date"
                            onChange={(e) => {
                              setMeetingDate(e.target.value);
                              trackDay(e.target.value);
                            }}
                            required
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="mb-4">
                          <Form.Control
                            type="time"
                            placeholder="Select Time"
                            onChange={(e) => setMeetingTime(e.target.value)}
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group className="mb-4">
                      <Form.Select
                        as="select"
                        onChange={(e) => setWorkstream(e.target.value)}
                        required
                      >
                        <option selected disabled value="">
                          Select your workstream
                        </option>
                        <option selected value="Personal">
                          Personal
                        </option>
                        {currentUserWorkStreams?.map((item) => (
                          <option
                            key={item.workStreamId}
                            value={item.workStreamName}
                          >
                            {item.workStreamName}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Control
                        as="textarea"
                        placeholder="Add description"
                        style={{ height: "100px" }}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                      />
                    </Form.Group>

                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <Form.Control
                        type="text"
                        placeholder="Meeting Link"
                        onChange={(e) => setMeetingLink(e.target.value)}
                        required
                      />
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Select as="select" required>
                        <option value={currentTimeZone}>
                          {currentTimeZone}
                        </option>
                      </Form.Select>
                      <p>
                        ***We have auto detected Timezone based on your
                        computer's time zone.
                      </p>
                    </Form.Group>
                  </Col>

                  <Col lg={6}>
                    <Form.Group className="mb-4">
                      <Form.Select
                        as="select"
                        onChange={(e) => setMeetingType(e.target.value)}
                        required
                      >
                        <option selected disabled value="">
                          Select Meeting Type
                        </option>
                        <option value="Does Not Repeat">Does Not Repeat</option>
                        <option value="Daily">Daily</option>
                        <option value="Weekly">Weekly</option>
                        <option value="Every Two Weeks">Every Two Weeks</option>
                        <option value="Monthly">Monthly</option>
                      </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-2">
                      <button
                        type="button"
                        data-toggle="modal"
                        data-target="#exampleModalLong"
                        onClick={handleShow}
                        className="form-control create-meeting attendees-select"
                      >
                        Select Attendees
                      </button>
                      <MeetingModal
                        show={show}
                        handleClose={handleClose}
                        options={combineEmailName}
                        handleAddNewUserImage={handleAddNewUser}
                        handleCancel={handleCancel}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3 pl-4 custom-margin"
                      controlId="attendees"
                    >
                      {selectedUser.length > 0 &&
                        selectedUser?.map((item, index) => (
                          <div className="guest-list" key={index}>
                            <div className="g-list-item">
                              <div className="g-name">
                                <img
                                  src={
                                    item.profile !== ""
                                      ? item.profile
                                      : "https://i.ibb.co/vVfrXgh/Group-302.png"
                                  }
                                  alt="Profile"
                                />
                                <p>{item.name}</p>
                              </div>
                              <FaTrash
                                className="mx-3 rt-denger"
                                onClick={() => removeUser(index)}
                              />
                            </div>
                          </div>
                        ))}
                    </Form.Group>
                    <Form.Check
                      className="attendees-checkbox"
                      checked={checked}
                      onChange={(e) => setChecked(e.target.checked)}
                    />
                    <label className="attendees-label">
                      Ask attendees to contribute to the agenda
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} className="text-center mt-5">
                    <Button
                      size="lg"
                      variant="outline-primary mr-4"
                      onClick={cancelButton}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="lg"
                      variant="primary"
                      type="submit"
                      onClick={() => {
                        SentMailToUser();
                      }}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
}
