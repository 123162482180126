/* eslint-disable consistent-return */
import axios from 'axios';
// import siteApi from './siteApi';

const employeeApi = process.env.REACT_APP_Employee_API;
/* eslint-disable import/prefer-default-export */

const confirmUserSignUp = async (username) => {
    const response = axios.post(`${employeeApi}/employee/confirmSignUp`, username);
    return response.data;
};

const enableUser = async (username) => {
    const response = axios.post(`${employeeApi}/employee/enableUser`, username);
    return response.data;
};

const disableUser = async (username) => {
    const response = axios.post(`${employeeApi}/employee/disableUser`, username);
    return response.data;
};

const deleteUserFromCognito = async (username) => {
    const response = axios.post(`${employeeApi}/employee/deleteUser`, username);
    return response.data;
};

const addUserToGroup = async (formData) => {
    const response = axios.post(`${employeeApi}/employee/addUserToGroup`, formData);
    return response.data;
};

const addEmployeeInfo = async (employeePersonalDetails) => {
    const response = await axios.post(`${employeeApi}/employee/add`, employeePersonalDetails);
    return response;
};

const sendEmailToEmployee = async (emailInfo) => {
    const response = await axios.post(`${employeeApi}/employee/sendmail`, emailInfo);
    return response;
};

const getEmployeeInfo = async (username) => {
    const response = await axios.get(`${employeeApi}/employee/item/${username}`);
    return response?.data?.Item;
};

const getUserByEmail = async (emailAddress) => {
    const response = await axios.get(`${employeeApi}/getByEmail/${emailAddress}`);
    return response?.data;
};

const updateEmployeeInfo = async (username, employeePersonalDetailsUpdate) => {
    const response = await axios.put(
        `${employeeApi}/employee/update/${username}`,
        employeePersonalDetailsUpdate
    );
    return response;
};

const updateEmployeeProfilePicture = async (username, profilePicture) => {
    const response = await axios.put(
        `${employeeApi}/employee/update/profile/${username}`,
        profilePicture
    );
    return response;
};

const updateEmployeeActive = async (username, activeFlag) => {
    const response = await axios.put(
        `${employeeApi}/employee/update/${username}/active`,
        activeFlag
    );
    return response;
};

const getAllEmployeeInfo = async (oid) => {
    const response = await axios.get(`${employeeApi}/employee/${oid}`);
    if (response) {
        return response?.data;
    }
};

// get merged Data

const getAllEmployeeMergedData = async (oid) => {
    const response = await axios.get(`${employeeApi}/employee/all/${oid}`);
    return response;
};

// delete
const deleteEmployeeData = async (eid) => {
    const response = await axios.delete(`${employeeApi}/employee/delete/${eid}`);
    return response;
};

export {
    confirmUserSignUp,
    enableUser,
    disableUser,
    deleteUserFromCognito,
    addEmployeeInfo,
    sendEmailToEmployee,
    getEmployeeInfo,
    updateEmployeeInfo,
    getAllEmployeeInfo,
    getAllEmployeeMergedData,
    deleteEmployeeData,
    updateEmployeeProfilePicture,
    updateEmployeeActive,
    getUserByEmail,
    addUserToGroup,
};
