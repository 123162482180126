/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */

import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { Form, Row, Col } from 'react-bootstrap';

function Step2(props) {
    const { errors, register, employeeInfo, setCountry, setRegion, country, region } = props;
    const selectCountry = (val) => {
        setCountry(val);
    };

    const selectRegion = (val) => {
        setRegion(val);
    };

    return (
        <div>
            <div className="form-row flex-column">
                <Row>
                    <Col>
                        <div className="form-group">
                            <label htmlFor="officeLocation">Office Country*</label>
                            <CountryDropdown
                                className="form-control h-70"
                                defaultOptionLabel={country}
                                value={country}
                                onChange={(val) => selectCountry(val)}
                            />
                            {/* {errors.officeCountry && (
                                <span style={{ color: 'red' }}>{errors.officeCountry.message}</span>
                            )} */}
                        </div>

                        <div className="form-group">
                            <label htmlFor="officeLocation">Designation*</label>
                            <input
                                type="text"
                                className="form-control h-70"
                                id="designation"
                                aria-describedby="textHelp"
                                placeholder="Designation*"
                                {...register('designation', {
                                    value: employeeInfo.designation,
                                    required: {
                                        value: true,
                                        message: 'Please add designation',
                                    },
                                })}
                            />
                            {errors.designation && (
                                <span style={{ color: 'red' }}>{errors.designation.message}</span>
                            )}
                        </div>
                    </Col>

                    <Col>
                        <div className="form-group">
                            <label htmlFor="officeLocation">Office Region*</label>
                            <RegionDropdown
                                className="form-control h-70"
                                country={country}
                                value={region}
                                onChange={(val) => selectRegion(val)}
                            />
                            {/* {errors.officeRegion && (
                                <span style={{ color: 'red' }}>{errors.officeRegion.message}</span>
                            )} */}
                        </div>

                        <div className="form-group">
                            <label htmlFor="officeLocation">Employment Type*</label>
                            <Form.Select
                                className="form-control h-70"
                                id="employmentType"
                                {...register('employmentType', {
                                    value: employeeInfo.employmentType,
                                    required: {
                                        value: true,
                                        message: 'Please select employment type',
                                    },
                                })}
                            >
                                <option value="" selected disabled>
                                    Employment Type*
                                </option>
                                <option value="Permanent">Permanent</option>
                                <option value="Contractual">Contractual</option>
                            </Form.Select>
                            {errors.employmentType && (
                                <span style={{ color: 'red' }}>
                                    {errors.employmentType.message}
                                </span>
                            )}
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Step2;
