/* eslint-disable consistent-return */
/* eslint-disable no-alert */
/* eslint-disable import/prefer-default-export */

import axios from 'axios';

const announcementApi = process.env.REACT_APP_Announcement_API;

const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
};

const addAnnouncement = async (formData) => {
    const response = await axios.post(`${announcementApi}/announcement/add`, formData, {
        headers,
    });
    return response;
};

const announcementList = async (oid) => {
    const response = await axios.get(`${announcementApi}/announcement/${oid}`);
    if (response) {
        return response?.data;
    }
};

const announcementDelete = async (id) => {
    const response = await axios.delete(`${announcementApi}/announcement/delete/${id}`);
    if (response) {
        return response.data;
    }
};

const announcementByEmail = async (creatorEmail) => {
    const response = await axios.get(`${announcementApi}/announcement/getByEmail/${creatorEmail}`);
    if (response) {
        return response?.data;
    }
};

const announcementUpdate = async (id, createdBy) => {
    const response = await axios.put(`${announcementApi}/announcement/updateProfile/${id}`, {
        createdBy,
    });
    if (response) {
        return response;
    }
};

export {
    addAnnouncement,
    announcementList,
    announcementDelete,
    announcementByEmail,
    announcementUpdate,
};
