import React, { useState, useRef, useContext, useEffect, useCallback } from 'react';
import {
    Container,
    Row,
    Col,
    Button,
    Accordion,
    Spinner,
    ToastContainer,
    Toast,
} from 'react-bootstrap';
import { FaPlusCircle } from 'react-icons/fa';
import Delete from '../../layouts/MainLayout/icon/Delete.svg';
import Edit from '../../layouts/MainLayout/icon/Edit.svg';
import '../RoleManagement.scss';
import { AuthContext } from '../../../Context/AuthContext';
import useAdminUserData from '../../../Hooks/useAdminUserData';
import FormModal from '../Components/CustomModal/FormModal/FormModal';
import InfoModal from '../Components/CustomModal/InfoModal/InfoModal';
import CustomDropdownMenu from '../../CustomDropdownMenu/CustomDropdownMenu';
import {
    addRole,
    deleteRole,
    getEmployeeDetails,
    getRoles,
    updateEmployeeRole,
    updateRoleName,
} from '../../../api/RoleMangment';

const RoleManagement = () => {
    const { oid } = useContext(AuthContext);
    const selectedRoleId = useRef(null);
    const selectedRoleName = useRef(null);
    const isComponentMounted = useRef(null);
    const [roles, setRoles] = useState([]);
    const [employee, setEmployee] = useState([]);
    const [showLoader, setShowLoader] = useState(true);
    const [showEmployeeLoader, setShowEmployeeLoader] = useState(false);
    const [showAddFormModal, setShowAddFormModal] = useState(false);
    const [showEditFormModal, setShowEditFormModal] = useState(false);
    const [showWarningInfoModal, setShowWarningInfoModal] = useState(false);
    const [showDeleteInfoModal, setShowDeleteInfoModal] = useState(false);
    const [userRoleChanged, setUserRoleChanged] = useState(false);
    const [showToaster, setShowToaster] = useState(false);
    const [image] = useAdminUserData();

    const handleLoader = () => setShowLoader((currentState) => !currentState);
    const handleEmployeeLoader = () => setShowEmployeeLoader((currentState) => !currentState);
    const handleAddFormModal = () => setShowAddFormModal((currentState) => !currentState);
    const handleEditFormModal = () => setShowEditFormModal((currentState) => !currentState);
    const handleWarningInfoModal = () => setShowWarningInfoModal((currentState) => !currentState);
    const handleDeleteInfoModal = () => setShowDeleteInfoModal((currentState) => !currentState);
    const handleToaster = () => setShowToaster((currentValue) => !currentValue);
    const handleGetRolesAndSet = useCallback(async () => {
        try {
            const response = await getRoles(oid);
            if (response.status === 200 && isComponentMounted.current) {
                setRoles(response.data);
                setShowLoader(false);
            }
        } catch (error) {
            console.log(error);
            setRoles([]);
            setShowLoader(false);
        }
    }, [oid]);
    const handleAddRole = (roleName) => {
        handleAddFormModal();
        handleLoader();
        (async () => {
            try {
                await addRole({ oid, roleName });
                handleGetRolesAndSet();
            } catch (error) {
                handleLoader();
                console.log(error);
            }
        })();
    };
    const handleEditRole = async (roleName) => {
        handleEditFormModal();
        handleLoader();
        try {
            await updateRoleName(selectedRoleId, roleName, oid);
            handleGetRolesAndSet();
            setUserRoleChanged(false);
            handleToaster();
        } catch (err) {
            console.log(err);
            setUserRoleChanged(false);
            handleLoader();
        }
    };
    const DeleteRoleModalHandler = async () => {
        handleDeleteInfoModal();
        handleLoader();
        try {
            await deleteRole(selectedRoleId.current);
            handleGetRolesAndSet();
        } catch (error) {
            console.log(error);
            setShowLoader(false);
        }
    };
    const getEmployeeDetailsAndSet = async (roleId) => {
        try {
            const response = await getEmployeeDetails(oid, roleId);
            setEmployee(response?.data);
            setShowEmployeeLoader(false);
        } catch (err) {
            console.log(err);
            handleEmployeeLoader();
            setEmployee([]);
        }
    };
    const handleAccordionItem = (evt, roleId) => {
        handleEmployeeLoader();
        setTimeout(() => {
            let accordionBtnList = document.getElementsByClassName('accordion-button');
            accordionBtnList = [...accordionBtnList];
            const isAccordionExpanded =
                (accordionBtnList.length &&
                    accordionBtnList.filter((ele) =>
                        ele.getAttribute('aria-expanded') === 'true' ? ele : false
                    )) ||
                [];
            if (isAccordionExpanded.length > 0) {
                getEmployeeDetailsAndSet(roleId);
            } else {
                setEmployee([]);
                handleEmployeeLoader();
            }
        }, 200);
    };
    const handleDeleteRole = (evt, id, empCount) => {
        evt.stopPropagation();
        selectedRoleId.current = id;
        if (empCount) {
            handleWarningInfoModal();
        } else {
            handleDeleteInfoModal();
        }
    };
    const handleUserRoleChange = (eid, selectedItemId) => {
        handleLoader();
        (async () => {
            try {
                await updateEmployeeRole(eid, selectedItemId);
                handleGetRolesAndSet();
                setUserRoleChanged(true);
                setShowToaster(true);
            } catch (error) {
                console.log(error);
                handleToaster();
            }
        })();
    };

    useEffect(() => {
        isComponentMounted.current = true;
        if (oid) {
            handleGetRolesAndSet();
        }
        return () => {
            isComponentMounted.current = false;
            return isComponentMounted.current;
        };
    }, [oid, handleGetRolesAndSet]);

    return (
        <Container className="px-4">
            <Row>
                <Col>
                    {showAddFormModal && (
                        <FormModal
                            value=""
                            showModal={showAddFormModal}
                            handleFormModal={handleAddFormModal}
                            handleFormModalSubmit={handleAddRole}
                            title="Add Role"
                        />
                    )}

                    {showEditFormModal && (
                        <FormModal
                            value={selectedRoleName.current}
                            showModal={showEditFormModal}
                            handleFormModal={handleEditFormModal}
                            handleFormModalSubmit={handleEditRole}
                            title="Edit Role"
                        />
                    )}

                    {showWarningInfoModal && (
                        <InfoModal
                            showInfoModal={showWarningInfoModal}
                            handleInfoModal={handleWarningInfoModal}
                            isDeleteModal={false}
                        />
                    )}

                    {showDeleteInfoModal && (
                        <InfoModal
                            showInfoModal={showDeleteInfoModal}
                            handleInfoModal={handleDeleteInfoModal}
                            isDeleteModal
                            actionHandler={DeleteRoleModalHandler}
                        />
                    )}
                </Col>
            </Row>
            <Row className={`${showEditFormModal ? 'pe-none' : 'pe-auto'} `}>
                <Col className="d-flex justify-content-between align-items-center">
                    <h3 className="nav-title">Role & Module &gt; Role Management</h3>
                    <Button className="btn-rt-primary-icon my-3" onClick={handleAddFormModal}>
                        <FaPlusCircle /> Add New
                    </Button>
                </Col>
            </Row>

            {showLoader ? (
                <Row>
                    <Col className="loader-container">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </Col>
                </Row>
            ) : (
                (roles.length > 0 && (
                    <>
                        <Row>
                            <Col>
                                <Accordion
                                    defaultActiveKey="0"
                                    className={`${showEditFormModal ? 'pe-none' : 'pe-auto'} `}
                                >
                                    {roles.length > 0 &&
                                        roles.map(({ id, roleName, empCount = 0 }) => (
                                            <Accordion.Item
                                                key={id}
                                                eventKey={id}
                                                className="cust-accordion-item mt-3 mt-0"
                                                onClick={(evt) => handleAccordionItem(evt, id)}
                                            >
                                                <div className="d-flex justify-content-between">
                                                    <Accordion.Header className="cust-accordion-header w-100">
                                                        <div className="w-100">
                                                            <span className="title">
                                                                {roleName}
                                                            </span>
                                                            <span className="count">
                                                                {empCount}
                                                            </span>
                                                        </div>
                                                    </Accordion.Header>
                                                    <div className="d-flex pr-3 ctrl">
                                                        <Button
                                                            className="img-btn mr-3"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                selectedRoleId.current = id;
                                                                selectedRoleName.current = roleName;
                                                                handleEditFormModal();
                                                            }}
                                                        >
                                                            <img
                                                                src={Edit}
                                                                alt="Edit Role Icon"
                                                                width={15}
                                                                height={15}
                                                            />
                                                        </Button>
                                                        <Button
                                                            className="img-btn"
                                                            onClick={(evt) =>
                                                                handleDeleteRole(evt, id, empCount)
                                                            }
                                                        >
                                                            <img
                                                                src={Delete}
                                                                alt="Delete Role Icon"
                                                                width={15}
                                                                height={15}
                                                            />
                                                        </Button>
                                                    </div>
                                                </div>

                                                <Accordion.Body className="cust-accordion-body text-center">
                                                    {showEmployeeLoader ? (
                                                        <Spinner animation="border" role="status">
                                                            <span className="visually-hidden">
                                                                Loading...
                                                            </span>
                                                        </Spinner>
                                                    ) : (
                                                        <Container>
                                                            <Row>
                                                                <Col className="text-left mt-4 m-3 px-4">
                                                                    Employee
                                                                </Col>
                                                            </Row>
                                                            {employee.length > 0 &&
                                                                employee.map(({ eid, empName }) => (
                                                                    <Row
                                                                        className="employee-role-listing mb-3"
                                                                        key={eid}
                                                                    >
                                                                        <Col className="col-xxl-1 col-xl-1 col-lg-1 profile-picture-container">
                                                                            <img
                                                                                src={
                                                                                    image?.profilePicture
                                                                                }
                                                                                alt="profile"
                                                                            />
                                                                        </Col>
                                                                        <Col className="col-xxl-9 col-xl-9 col-lg-9  employee-name">
                                                                            {empName}
                                                                        </Col>
                                                                        <Col className="col-xxl-2 col-xl-2 col-lg-2 actions">
                                                                            <CustomDropdownMenu
                                                                                title="Change Role"
                                                                                menuItems={roles.filter(
                                                                                    (role) =>
                                                                                        role.roleName !==
                                                                                        roleName
                                                                                )}
                                                                                menuItemProperty="roleName"
                                                                                selectHandler={(
                                                                                    selectedItemId
                                                                                ) =>
                                                                                    handleUserRoleChange(
                                                                                        eid,
                                                                                        selectedItemId
                                                                                    )
                                                                                }
                                                                                eventKey="id"
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                ))}
                                                        </Container>
                                                    )}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        ))}
                                </Accordion>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <ToastContainer
                                    position="bottom-center"
                                    className="p-3 custom-toaster-position"
                                >
                                    <Toast
                                        className="custom-toaster"
                                        onClose={() => setShowToaster(false)}
                                        show={showToaster}
                                        delay={5000}
                                        autohide
                                    >
                                        <Toast.Body>
                                            <img
                                                src={userRoleChanged ? '' : ''}
                                                className="rounded me-2"
                                                alt="Access icon"
                                                width="20"
                                                height="20"
                                            />
                                            <span className="toast-msg">
                                                {userRoleChanged
                                                    ? 'User Role Changed!'
                                                    : 'Role Renamed!'}
                                            </span>
                                        </Toast.Body>
                                    </Toast>
                                </ToastContainer>
                            </Col>
                        </Row>
                    </>
                )) || (
                    <Row>
                        <Col>
                            <Container className="role-management-container">
                                <Button className="initial-btn" onClick={handleAddFormModal}>
                                    Click to add
                                </Button>
                            </Container>
                        </Col>
                    </Row>
                )
            )}
            {}
        </Container>
    );
};

export default RoleManagement;
