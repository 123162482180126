/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/order */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/newline-after-import */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/aria-proptypes */
/* eslint-disable react/jsx-boolean-value */
// eslint-disable-next-line import/newline-after-import
// eslint-disable-next-line arrow-body-style
// eslint-disable-next-line no-plusplus

import Multiselect from "multiselect-react-dropdown";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import { v4 as uuidv4 } from "uuid";
import {
  EarnModalData,
  GivenModalData,
  SendEmailData,
} from "../../../api/badge";
import { getAllEmployeeMergedData } from "../../../api/employee";
import { AuthContext } from "../../../Context/AuthContext";
import "./BadgeModal.scss";

const BadgeModal = ({ show, handleClose, setShow, getInsertData }) => {
  const [tripType, setTripType] = useState("");
  const [email, setEmail] = useState([]);
  const [emailSelect, setEmailSelect] = useState([]);
  const [text, setText] = useState();
  const [type, setType] = useState("");
  const { logged, oid, refresh, setRefresh } = useContext(AuthContext);

  const [onlyNames, setOnlyNames] = useState([]);

  const loggedUserEmail = logged?.split("@");
  const loggedUserName = loggedUserEmail?.[0];

  useEffect(() => {
    const getEmployeeEmails = async () => {
      const Emails = await getAllEmployeeMergedData(oid);

      const formattedEmail = Emails?.map((item) =>
        item.emailAddress ? item.emailAddress : item.adminEmail
      ).filter((value) => value !== logged);
      setEmail(formattedEmail);

      if (Emails?.length > 0) {
        const formattedName =
          Emails?.length > 0 &&
          Emails?.filter((item) =>
            item.emailAddress
              ? item.emailAddress !== logged
              : item.adminEmail !== logged
          )?.map((i) => i.emailAddress);

        setOnlyNames(formattedName);
      }
    };
    getEmployeeEmails();
  }, [oid]);

  const combinedArrayOfNameAndEmail = onlyNames?.map((value, index) => ({
    name: value,
    email: email[index],
  }));

  const from = "testingsidsj@gmail.com";
  const badge = "badge";
  const FromReteamnow = "From Reteamnow";
  const insertData = async () => {
    emailSelect.map(async (item) => {
      EarnModalData(tripType, item, text, oid, type, logged);
    });

    if (emailSelect.length > 0) {
      const result = await GivenModalData(
        uuidv4(),
        tripType,
        emailSelect,
        text,
        oid,
        type,
        logged
      );
      getInsertData(result);
      emailSelect.map(async (item) => {
        SendEmailData(
          loggedUserName,
          text,
          from,
          badge,
          FromReteamnow,
          item.email
        );
      });
      toast.success("sent Successfully");
      setRefresh(!refresh);
    }
  };

  const handleSubmit = (e) => {
    if (emailSelect.length === 0) {
      toast.error("please put at least one of the member name!", {
        duration: 2000,
      });
    } else {
      insertData();
      setShow(false);
    }
    e.preventDefault();
    setShow(false);
  };

  return (
    <div className="badge-modal">
      <Container>
        <Row>
          <Col className="pt-4">
            <Modal show={show} onHide={handleClose} className="custom-modal">
              <Modal.Body>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col>
                      <Multiselect
                        required
                        displayValue="name"
                        placeholder="Type name of team members"
                        isObject={true}
                        onRemove={(event) => {
                          console.log(event);
                        }}
                        onSelect={(e) => {
                          setEmailSelect(e);
                        }}
                        options={combinedArrayOfNameAndEmail}
                        avoidHighlightFirstOption={true}
                        hidePlaceholder={true}
                      />
                      <textarea
                        className="form-control border-0 px-0"
                        aria-label="With textarea"
                        placeholder="Send a message with your badge"
                        onChange={(e) => setText(e.target.value)}
                        required
                      />
                      <p class="text-left">Pick a badge:</p>
                      <div className="radio-btn-container mb-3">
                        <div className="badge-box d-flex align-items-center justify-content-center overflow-hidden h-auto">
                          <div
                            className="radio-btn h-100 w-100"
                            required
                            onClick={(e) => {
                              setTripType(
                                `${process.env.REACT_APP_IMAGE_BASE_URL}/Wow!.png`
                              );
                              setType("Wow!");
                            }}
                          >
                            <input
                              type="radio"
                              value={tripType}
                              name="tripType"
                              checked={
                                tripType ===
                                `${process.env.REACT_APP_IMAGE_BASE_URL}/Wow!.png`
                              }
                              required
                            />
                            <div className="badge-box-items d-flex align-items-center flex-column justify-content-center text-center w-100 h-100">
                              <img
                                src={`${process.env.REACT_APP_IMAGE_BASE_URL}/Wow!.png`}
                                className="image-badge"
                                alt=""
                              />
                              <span>Wow!</span>
                            </div>
                          </div>
                        </div>
                        <div className="badge-box d-flex align-items-center justify-content-center overflow-hidden h-auto">
                          <div
                            className="radio-btn h-100 w-100"
                            onClick={() => {
                              setTripType(
                                `${process.env.REACT_APP_IMAGE_BASE_URL}/Touchdown.png`
                              );
                              setType("Touchdown");
                            }}
                          >
                            <input
                              type="radio"
                              value={tripType}
                              name="tripType"
                              checked={
                                tripType ===
                                `${process.env.REACT_APP_IMAGE_BASE_URL}/Touchdown.png`
                              }
                              required
                            />
                            <div className="badge-box-items d-flex align-items-center flex-column justify-content-center text-center w-100 h-100">
                              <img
                                src={`${process.env.REACT_APP_IMAGE_BASE_URL}/Touchdown.png`}
                                className="image-badge"
                                alt=""
                              />

                              <span>Touchdown</span>
                            </div>
                          </div>
                        </div>
                        <div className="badge-box d-flex align-items-center justify-content-center overflow-hidden h-auto">
                          <div
                            className="radio-btn h-100 w-100"
                            onClick={() => {
                              setTripType(
                                `${process.env.REACT_APP_IMAGE_BASE_URL}/The+Boss!.png`
                              );
                              setType("The Boss!");
                            }}
                          >
                            <input
                              type="radio"
                              value={tripType}
                              name="tripType"
                              checked={
                                tripType ===
                                `${process.env.REACT_APP_IMAGE_BASE_URL}/The+Boss!.png`
                              }
                              required
                            />
                            <div className="badge-box-items d-flex align-items-center flex-column justify-content-center text-center w-100 h-100">
                              <img
                                src={`${process.env.REACT_APP_IMAGE_BASE_URL}/The+Boss!.png`}
                                className="image-badge"
                                alt=""
                              />

                              <span>The Boss!</span>
                            </div>
                          </div>
                        </div>
                        <div className="badge-box d-flex align-items-center justify-content-center overflow-hidden h-auto">
                          <div
                            className="radio-btn h-100 w-100"
                            onClick={() => {
                              setTripType(
                                `${process.env.REACT_APP_IMAGE_BASE_URL}/That+Hurts!.png`
                              );
                              setType("That Hurts!");
                            }}
                          >
                            <input
                              type="radio"
                              value={tripType}
                              name="tripType"
                              checked={
                                tripType ===
                                `${process.env.REACT_APP_IMAGE_BASE_URL}/That+Hurts!.png`
                              }
                              required
                            />
                            <div className="badge-box-items d-flex align-items-center flex-column justify-content-center text-center w-100 h-100">
                              <img
                                src={`${process.env.REACT_APP_IMAGE_BASE_URL}/That+Hurts!.png`}
                                className="image-badge"
                                alt=""
                              />

                              <span>That Hurts!</span>
                            </div>
                          </div>
                        </div>
                        <div className="badge-box d-flex align-items-center justify-content-center overflow-hidden h-auto">
                          <div
                            className="radio-btn h-100 w-100"
                            onClick={() => {
                              setTripType(
                                `${process.env.REACT_APP_IMAGE_BASE_URL}/Thank+You!.png`
                              );
                              setType("Thank You!");
                            }}
                          >
                            <input
                              type="radio"
                              value={tripType}
                              name="tripType"
                              checked={
                                tripType ===
                                `${process.env.REACT_APP_IMAGE_BASE_URL}/Thank+You!.png`
                              }
                              required
                            />
                            <div className="badge-box-items d-flex align-items-center flex-column justify-content-center text-center w-100 h-100">
                              <img
                                src={`${process.env.REACT_APP_IMAGE_BASE_URL}/Thank+You!.png`}
                                className="image-badge"
                                alt=""
                              />

                              <span>Thank you!</span>
                            </div>
                          </div>
                        </div>
                        <div className="badge-box d-flex align-items-center justify-content-center overflow-hidden h-auto">
                          <div
                            className="radio-btn h-100 w-100"
                            onClick={() => {
                              setTripType(
                                `${process.env.REACT_APP_IMAGE_BASE_URL}/Sweet.png`
                              );
                              setType("Sweet");
                            }}
                          >
                            <input
                              type="radio"
                              value={tripType}
                              name="tripType"
                              checked={
                                tripType ===
                                `${process.env.REACT_APP_IMAGE_BASE_URL}/Sweet.png`
                              }
                              required
                            />
                            <div className="badge-box-items d-flex align-items-center flex-column justify-content-center text-center w-100 h-100">
                              <img
                                src={`${process.env.REACT_APP_IMAGE_BASE_URL}/Sweet.png`}
                                className="image-badge"
                                alt=""
                              />

                              <span>Sweet!</span>
                            </div>
                          </div>
                        </div>
                        {/* //rockstar */}
                        <div className="badge-box d-flex align-items-center justify-content-center overflow-hidden h-auto">
                          <div
                            className="radio-btn h-100 w-100"
                            onClick={() => {
                              setTripType(
                                `${process.env.REACT_APP_IMAGE_BASE_URL}/Rockstar.png`
                              );
                              setType("Rockstar");
                            }}
                          >
                            <input
                              type="radio"
                              value={tripType}
                              name="tripType"
                              checked={
                                tripType ===
                                `${process.env.REACT_APP_IMAGE_BASE_URL}/Rockstar.png`
                              }
                              required
                            />
                            <div className="badge-box-items d-flex align-items-center flex-column justify-content-center text-center w-100 h-100">
                              <img
                                src={`${process.env.REACT_APP_IMAGE_BASE_URL}/Rockstar.png`}
                                className="image-badge"
                                alt=""
                              />

                              <span>Rock Star!</span>
                            </div>
                          </div>
                        </div>
                        {/* rocket  */}
                        <div className="badge-box d-flex align-items-center justify-content-center overflow-hidden h-auto">
                          <div
                            className="radio-btn h-100 w-100"
                            onClick={() => {
                              setTripType(
                                `${process.env.REACT_APP_IMAGE_BASE_URL}/Rocket.png`
                              );
                              setType("Rocket");
                            }}
                          >
                            <input
                              type="radio"
                              value={tripType}
                              name="tripType"
                              checked={
                                tripType ===
                                `${process.env.REACT_APP_IMAGE_BASE_URL}/Rocket.png`
                              }
                              required
                            />
                            <div className="badge-box-items d-flex align-items-center flex-column justify-content-center text-center w-100 h-100">
                              <img
                                src={`${process.env.REACT_APP_IMAGE_BASE_URL}/Rocket.png`}
                                className="image-badge"
                                alt=""
                              />

                              <span>Rocket</span>
                            </div>
                          </div>
                        </div>
                        {/* // Oh!WiseOne  */}
                        <div className="badge-box d-flex align-items-center justify-content-center overflow-hidden h-auto">
                          <div
                            className="radio-btn h-100 w-100"
                            onClick={() => {
                              setTripType(
                                `${process.env.REACT_APP_IMAGE_BASE_URL}/Oh!+Wise+One!.png`
                              );
                              setType("Oh! Wise One!");
                            }}
                          >
                            <input
                              type="radio"
                              value={tripType}
                              name="tripType"
                              checked={
                                tripType ===
                                `${process.env.REACT_APP_IMAGE_BASE_URL}/Oh!+Wise+One!.png`
                              }
                              required
                            />
                            <div className="badge-box-items d-flex align-items-center flex-column justify-content-center text-center w-100 h-100">
                              <img
                                src={`${process.env.REACT_APP_IMAGE_BASE_URL}/Oh!+Wise+One!.png`}
                                className="image-badge"
                                alt=""
                              />

                              <span>Oh! Wise One</span>
                            </div>
                          </div>
                        </div>
                        {/* // Mentor */}
                        <div className="badge-box d-flex align-items-center justify-content-center overflow-hidden h-auto">
                          <div
                            className="radio-btn h-100 w-100"
                            onClick={() => {
                              setTripType(
                                `${process.env.REACT_APP_IMAGE_BASE_URL}/Mentor.png`
                              );
                              setType("Mentor");
                            }}
                          >
                            <input
                              type="radio"
                              value={tripType}
                              name="tripType"
                              checked={
                                tripType ===
                                `${process.env.REACT_APP_IMAGE_BASE_URL}/Mentor.png`
                              }
                              required
                            />
                            <div className="badge-box-items d-flex align-items-center flex-column justify-content-center text-center w-100 h-100">
                              <img
                                src={`${process.env.REACT_APP_IMAGE_BASE_URL}/Mentor.png`}
                                className="image-badge"
                                alt=""
                              />

                              <span>Mentor</span>
                            </div>
                          </div>
                        </div>
                        {/* // Lifesaver  */}
                        <div className="badge-box d-flex align-items-center justify-content-center overflow-hidden h-auto">
                          <div
                            className="radio-btn h-100 w-100"
                            onClick={() => {
                              setTripType(
                                `${process.env.REACT_APP_IMAGE_BASE_URL}/Lifesaver.png`
                              );
                              setType("LifeSaver!");
                            }}
                          >
                            <input
                              type="radio"
                              value={tripType}
                              name="tripType"
                              checked={
                                tripType ===
                                `${process.env.REACT_APP_IMAGE_BASE_URL}/Lifesaver.png`
                              }
                              required
                            />
                            <div className="badge-box-items d-flex align-items-center flex-column justify-content-center text-center w-100 h-100">
                              <img
                                src={`${process.env.REACT_APP_IMAGE_BASE_URL}/Lifesaver.png`}
                                className="image-badge"
                                alt=""
                              />

                              <span>Life Saver!</span>
                            </div>
                          </div>
                        </div>

                        {/* // Hilarious  */}
                        <div className="badge-box d-flex align-items-center justify-content-center overflow-hidden h-auto">
                          <div
                            className="radio-btn h-100 w-100"
                            onClick={() => {
                              setTripType(
                                `${process.env.REACT_APP_IMAGE_BASE_URL}/Hilarious!.png`
                              );
                              setType("Hilarious!");
                            }}
                          >
                            <input
                              type="radio"
                              value={tripType}
                              name="tripType"
                              checked={
                                tripType ===
                                `${process.env.REACT_APP_IMAGE_BASE_URL}/Hilarious!.png`
                              }
                              required
                            />
                            <div className="badge-box-items d-flex align-items-center flex-column justify-content-center text-center w-100 h-100">
                              <img
                                src={`${process.env.REACT_APP_IMAGE_BASE_URL}/Hilarious!.png`}
                                className="image-badge"
                                alt=""
                              />

                              <span>Hilarious </span>
                            </div>
                          </div>
                        </div>
                        {/* // High+Five  */}
                        <div className="badge-box d-flex align-items-center justify-content-center overflow-hidden h-auto">
                          <div
                            className="radio-btn h-100 w-100"
                            onClick={() => {
                              setTripType(
                                `${process.env.REACT_APP_IMAGE_BASE_URL}/High+Five!.png`
                              );
                              setType("High Five!");
                            }}
                          >
                            <input
                              type="radio"
                              value={tripType}
                              name="tripType"
                              checked={
                                tripType ===
                                `${process.env.REACT_APP_IMAGE_BASE_URL}/High+Five!.png`
                              }
                              required
                            />
                            <div className="badge-box-items d-flex align-items-center flex-column justify-content-center text-center w-100 h-100">
                              <img
                                src={`${process.env.REACT_APP_IMAGE_BASE_URL}/High+Five!.png`}
                                className="image-badge"
                                alt=""
                              />

                              <span>High Five! </span>
                            </div>
                          </div>
                        </div>

                        {/* // Hazardous  */}
                        <div className="badge-box d-flex align-items-center justify-content-center overflow-hidden h-auto">
                          <div
                            className="radio-btn h-100 w-100"
                            onClick={() => {
                              setTripType(
                                `${process.env.REACT_APP_IMAGE_BASE_URL}/Hazardous+Duty.png`
                              );
                              setType("Hazardous Duty!");
                            }}
                          >
                            <input
                              type="radio"
                              value={tripType}
                              name="tripType"
                              checked={
                                tripType ===
                                `${process.env.REACT_APP_IMAGE_BASE_URL}/Hazardous+Duty.png`
                              }
                              required
                            />
                            <div className="badge-box-items d-flex align-items-center flex-column justify-content-center text-center w-100 h-100">
                              <img
                                src={`${process.env.REACT_APP_IMAGE_BASE_URL}/Hazardous+Duty.png`}
                                className="image-badge"
                                alt=""
                              />

                              <span>Hazardous Duty! </span>
                            </div>
                          </div>
                        </div>

                        {/* Good+Luck */}
                        <div className="badge-box d-flex align-items-center justify-content-center overflow-hidden h-auto">
                          <div
                            className="radio-btn h-100 w-100"
                            onClick={() => {
                              setTripType(
                                `${process.env.REACT_APP_IMAGE_BASE_URL}/Good+Luck.png`
                              );
                              setType("Good Luck");
                            }}
                          >
                            <input
                              type="radio"
                              value={tripType}
                              name="tripType"
                              checked={
                                tripType ===
                                `${process.env.REACT_APP_IMAGE_BASE_URL}/Good+Luck.png`
                              }
                              required
                            />
                            <div className="badge-box-items d-flex align-items-center flex-column justify-content-center text-center w-100 h-100">
                              <img
                                src={`${process.env.REACT_APP_IMAGE_BASE_URL}/Good+Luck.png`}
                                className="image-badge"
                                alt=""
                              />
                              <span>Good Luck! </span>
                            </div>
                          </div>
                        </div>
                        {/* Goal+Achiever.png */}

                        <div className="badge-box d-flex align-items-center justify-content-center overflow-hidden h-auto">
                          <div
                            className="radio-btn h-100 w-100"
                            onClick={() => {
                              setTripType(
                                `${process.env.REACT_APP_IMAGE_BASE_URL}/Goal+Achiever.png`
                              );
                              setType("Goal Achiever!");
                            }}
                          >
                            <input
                              type="radio"
                              value={tripType}
                              name="tripType"
                              checked={
                                tripType ===
                                `${process.env.REACT_APP_IMAGE_BASE_URL}/Goal+Achiever.png`
                              }
                              required
                            />
                            <div className="badge-box-items d-flex align-items-center flex-column justify-content-center text-center w-100 h-100">
                              <img
                                src={`${process.env.REACT_APP_IMAGE_BASE_URL}/Goal+Achiever.png`}
                                className="image-badge"
                                alt=""
                              />

                              <span>Goal Achiever! </span>
                            </div>
                          </div>
                        </div>
                        {/* Go! */}
                        <div className="badge-box d-flex align-items-center justify-content-center overflow-hidden h-auto">
                          <div
                            className="radio-btn h-100 w-100"
                            onClick={() => {
                              setTripType(
                                `${process.env.REACT_APP_IMAGE_BASE_URL}/Go!.png`
                              );
                              setType("Go!");
                            }}
                          >
                            <input
                              type="radio"
                              value={tripType}
                              name="tripType"
                              checked={
                                tripType ===
                                `${process.env.REACT_APP_IMAGE_BASE_URL}/Go!.png`
                              }
                              required
                            />
                            <div className="badge-box-items d-flex align-items-center flex-column justify-content-center text-center w-100 h-100">
                              <img
                                src={`${process.env.REACT_APP_IMAGE_BASE_URL}/Go!.png`}
                                className="image-badge"
                                alt=""
                              />

                              <span>Go! </span>
                            </div>
                          </div>
                        </div>
                        {/* Gifted */}
                        <div className="badge-box d-flex align-items-center justify-content-center overflow-hidden h-auto">
                          <div
                            className="radio-btn h-100 w-100"
                            onClick={() => {
                              setTripType(
                                `${process.env.REACT_APP_IMAGE_BASE_URL}/Gifted.png`
                              );
                              setType("Gifted!");
                            }}
                          >
                            <input
                              type="radio"
                              value={tripType}
                              name="tripType"
                              checked={
                                tripType ===
                                `${process.env.REACT_APP_IMAGE_BASE_URL}/Gifted.png`
                              }
                              required
                            />
                            <div className="badge-box-items d-flex align-items-center flex-column justify-content-center text-center w-100 h-100">
                              <img
                                src={`${process.env.REACT_APP_IMAGE_BASE_URL}/Gifted.png`}
                                className="image-badge"
                                alt=""
                              />
                              <span>Gifted! </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="action-btns d-flex gap-3 justify-content-center">
                    <Button
                      variant="primary"
                      type="submit"
                      className="custom-btn badge-btn"
                    >
                      Give
                    </Button>
                    <Button
                      onClick={handleClose}
                      className="custom-btn badge-btn"
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BadgeModal;
