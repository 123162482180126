import React from 'react';
import { Link } from 'react-router-dom';
import { AiFillDelete } from 'react-icons/ai';
import { MdModeEdit } from 'react-icons/md';
import { BiUserPlus } from 'react-icons/bi';

const workStreamTable = (props) => {
    const { item, isAdmin, deleteWorkStream } = props;
    return (
        <>
            <tr key={item.id}>
                <td className="name border-right-0">
                    {' '}
                    <Link to={`/assignedWorkStreamList/${item.id}`}>{item.workStreamName}</Link>
                </td>
                <td className="name border-right-0  border-left-0">
                    {' '}
                    <Link to={`/addAssignedWorkStream/${item.id}`}>
                        <BiUserPlus size={50} />
                    </Link>
                </td>
                {isAdmin && (
                    <td>
                        <Link to={`/workStream/edit/${item.id}`} className="textdec mr-4">
                            <MdModeEdit size={25} className="edit-ico ml-1" />
                        </Link>

                        <AiFillDelete
                            className="delete-ico ml-1"
                            variant="danger"
                            size={25}
                            onClick={() => {
                                deleteWorkStream(item.id);
                            }}
                        />
                    </td>
                )}
            </tr>
        </>
    );
};

export default workStreamTable;
