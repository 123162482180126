/* eslint-disable react/jsx-boolean-value */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-cycle */
// import { withAuthenticator, AmplifySignOut  } from '@aws-amplify/ui-react'
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default */
import { useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.scss';
import AddAdmin from './components/AddAdmin/AddAdmin';
import AddOrganization from './components/AddOrganization/AddOrganization';
import AdminList from './components/AdminList/AdminList';
import AnnouncementAdd from './components/Announcement/AnnouncementAdd/AnnouncementAdd';
import AnnouncementListing from './components/Announcement/AnnouncementListing/AnnouncementListing';
import Badges from './components/Badges/Badges';
import Dashboard2nd from './components/Dashboard/Dashboard2nd';
import EditAdminInfo from './components/EditAdminInfo/EditAdminInfo';
import EditOrganization from './components/EditOrganization/EditOrganization';
import AddEmployee from './components/Employee/AddEmployee/AddEmployee';
import EmployeeEdit from './components/Employee/EmployeeEdit/EmployeeEdit';
import EmployeeList from './components/Employee/EmployeeList/EmployeeList';
import EmployeeView from './components/Employee/EmployeeView/EmployeeView';
import EmployeeServey from './components/EmployeeServey/EmployeeServey';
import Footer from './components/Footer/Footer';
import ForgotPass from './components/ForgotPass/ForgotPass';
import MainLayout from './components/layouts/MainLayout';
import ClosedMeetingDetails from './components/MettingNew/ClosedMeeting/ClosedMeetingDetails';
import CreateMeeting from './components/MettingNew/Meeting/AddMeeting/CreateMeeting';
import Details from './components/MettingNew/Meeting/Details';
import UpdateMeeting from './components/MettingNew/Meeting/UpdateMeeting/UpdateMeeting';
import MeetingMain from './components/MettingNew/MeetingMain';
import OrganizationList from './components/Organization LIsting/OrganizationList';
import DefaultChart from './components/OrgChart/OrganizationalChart/default-chart/default-chart';
import EditChart from './components/OrgChart/OrganizationalChart/edit-chart/edit-chart';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import EditModal from './components/ProcessDocumentation/EditModal';
import ProcessdocumentAdd from './components/ProcessDocumentation/ProcessdocumentAdd';
import ProcessDocumentation from './components/ProcessDocumentation/ProcessDocumentation';
import Profile from './components/Profile/Profile';
import Registration from './components/Registration/Registration';
import ModuleControl from './components/RoleAndModel/ModuleControl/ModuleControl';
import RoleManagement from './components/RoleAndModel/RoleManagement/RoleManagement';
import SignIn from './components/SignIn/SignIn';
import SuperAdminDashboard from './components/SuperAdminDashboard/SuperAdminDashboard';
import AddTask from './components/Task/AddTask/AddTask';
import TaskList from './components/Task/TaskList/TaskList';
import TaskView from './components/Task/TaskList/TaskView';
import AddTaskStream from './components/TaskStream/AddTaskStream';
import AssignedTaskStream from './components/TaskStream/AssignedTaskStream';
import AssignedTaskStreamAdd from './components/TaskStream/AssignedTaskStreamAdd';
import EditTaskStream from './components/TaskStream/EditTaskStream';
import TaskStreamList from './components/TaskStream/TaskStreamList';
import PlanModule from './components/VisionAndPlan/PlanModule/PlanModule';
import VisionModule from './components/VisionAndPlan/VisionModule/VisionModule';
import AddWorkStream from './components/WorkStream/AddWorkStream';
import AssignedWorkStreamAdd from './components/WorkStream/AssignedWorkStreamAdd';
import AssignedWorkStreamList from './components/WorkStream/AssignedWorkStreamLIst';
import EditWorkStream from './components/WorkStream/EditWorkStream';
import WorkStreamList from './components/WorkStream/WorkStreamList';
import { OrgProvider } from './Context/OrganizationContext';

function App() {
    const [socket, setSocket] = useState(null);

    // useEffect(() => {
    //     setSocket(io('http://localhost:5000'));
    // }, []);

    return (
        <>
            <Router forceRefresh={true}>
                <Switch>
                    <Route exact path="/">
                        <SignIn />
                    </Route>
                    <Route path="/addUser">
                        <Registration />
                    </Route>
                    <Route path="/forgotpass">
                        <ForgotPass />
                    </Route>
                    <PrivateRoute>
                        <MainLayout socket={socket}>
                            <PrivateRoute path="/visionModule">
                                <VisionModule />
                            </PrivateRoute>
                            <PrivateRoute exact path="/db">
                                <Dashboard2nd />
                            </PrivateRoute>
                            <PrivateRoute path="/planModule">
                                <PlanModule />
                            </PrivateRoute>
                            <PrivateRoute path="/superAdmin/dashboard">
                                <SuperAdminDashboard />
                            </PrivateRoute>
                            <PrivateRoute path="/org/add">
                                <AddOrganization />
                            </PrivateRoute>
                            <PrivateRoute path="/org/item/:id">
                                <EditOrganization />
                            </PrivateRoute>
                            <PrivateRoute path="/admin/add">
                                <AddAdmin />
                            </PrivateRoute>
                            <PrivateRoute path="/admin/list">
                                <AdminList />
                            </PrivateRoute>
                            {/* org provider  */}
                            <OrgProvider>
                                <PrivateRoute path="/org/list">
                                    <OrganizationList />
                                </PrivateRoute>
                            </OrgProvider>
                            {/* close */}
                            <PrivateRoute path="/edit-chart" component={EditChart} />
                            <PrivateRoute path="/default-chart">
                                <DefaultChart />
                            </PrivateRoute>
                            <PrivateRoute exact path="/employee-view/:username">
                                <EmployeeView />
                            </PrivateRoute>
                            <PrivateRoute path="/admin/info/:id/:oid">
                                <EditAdminInfo />
                            </PrivateRoute>
                            <PrivateRoute path="/addemployee">
                                <AddEmployee />
                            </PrivateRoute>
                            <PrivateRoute path="/addAnnouncement">
                                <AnnouncementAdd />
                            </PrivateRoute>
                            <PrivateRoute path="/meetingDetail/:id">
                                <Details />
                            </PrivateRoute>
                            <PrivateRoute path="/closedMeetingDetail/:id">
                                <ClosedMeetingDetails />
                            </PrivateRoute>
                            <PrivateRoute path="/meeting/createMeeting/">
                                <CreateMeeting socket={socket} />
                            </PrivateRoute>
                            <PrivateRoute path="/meeting/updateMeeting/:id">
                                <UpdateMeeting />
                            </PrivateRoute>
                            <PrivateRoute path="/meetings">
                                <MeetingMain />
                            </PrivateRoute>
                            <PrivateRoute path="/badges">
                                <Badges />
                            </PrivateRoute>
                            <PrivateRoute exact path="/ProcessDocumentation/update/:id/:createdAt">
                                <EditModal />
                            </PrivateRoute>
                            <PrivateRoute exact path="/ProcessDocumentationAdd">
                                <ProcessdocumentAdd />
                            </PrivateRoute>
                            <PrivateRoute path="/employeeservey">
                                <EmployeeServey />
                            </PrivateRoute>
                            <PrivateRoute exact path="/processDocumentation">
                                <ProcessDocumentation />
                            </PrivateRoute>
                            <PrivateRoute path="/announcementListing">
                                <AnnouncementListing />
                            </PrivateRoute>

                            <PrivateRoute exact path="/employeelist">
                                <EmployeeList />
                            </PrivateRoute>
                            <PrivateRoute exact path="/employeeList/:username">
                                <EmployeeEdit />
                            </PrivateRoute>
                            <PrivateRoute path="/profile">
                                <Profile />
                            </PrivateRoute>
                            <PrivateRoute path="/task">
                                <TaskList />
                            </PrivateRoute>
                            <PrivateRoute path="/task/view/:id">
                                <TaskView />
                            </PrivateRoute>
                            <PrivateRoute path="/task/add/:id">
                                <AddTask />
                            </PrivateRoute>
                            {/* work Stream Routes */}
                            <PrivateRoute path="/workStream/edit/:id">
                                <EditWorkStream />
                            </PrivateRoute>
                            <PrivateRoute exact path="/workStream/list">
                                <WorkStreamList />
                            </PrivateRoute>
                            <PrivateRoute exact path="/workStream/add">
                                <AddWorkStream />
                            </PrivateRoute>
                            <PrivateRoute path="/addAssignedWorkStream/:workStreamId">
                                <AssignedWorkStreamAdd />
                            </PrivateRoute>
                            <PrivateRoute path="/assignedWorkStreamList/:workStreamId">
                                <AssignedWorkStreamList />
                            </PrivateRoute>
                            <PrivateRoute path="/taskStream/list">
                                <TaskStreamList />
                            </PrivateRoute>
                            <PrivateRoute path="/taskStream/add">
                                <AddTaskStream />
                            </PrivateRoute>
                            <PrivateRoute path="/taskStream/edit/:id">
                                <EditTaskStream />
                            </PrivateRoute>
                            <PrivateRoute path="/addAssignedTaskStream/:workStreamId">
                                <AssignedTaskStreamAdd />
                            </PrivateRoute>
                            <PrivateRoute path="/assignedTaskStreamList/:workStreamId">
                                <AssignedTaskStream />
                            </PrivateRoute>
                            <PrivateRoute path="/roleManagement">
                                <RoleManagement />
                            </PrivateRoute>
                            <PrivateRoute path="/moduleControl">
                                <ModuleControl />
                            </PrivateRoute>
                        </MainLayout>
                    </PrivateRoute>
                </Switch>
                <Footer />
            </Router>
            <Toaster />
        </>
    );
}

export default App;
