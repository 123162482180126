/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { BiEnvelope } from "react-icons/bi";
import logo from "../../images/logo.svg";
import '../SignIn/SignIn.scss';

const ForgotPass = () => (
    <div className='sign-in'>
        <div className="container-fluid min-vh-100">
            <div className="row">
                <div className="col d-flex align-items-center justify-content-center min-vh-100">
                    <div className='m-5'>
                    <div className='logo text-center pb-5'>
                                <img src={logo} alt="ReTeamNow Logo" />
                            </div>
                        <div className='sign-in-box'>
                            <h3 className='title'>Forgot Password</h3>
                            <p className='desc'>Enter your email to reset password</p>
                            <form>
                                <div className="mb-3 input-f">
                                    <BiEnvelope size={30} />
                                    <input
                                        placeholder="Email or username"
                                        className="form-control"
                                        id="card-email"
                                        type="email"
                                    />
                                </div>
                                <div className="mt-3">
                                    <button
                                        className="btn log-btn"
                                        type="submit"
                                        name="submit"
                                    >
                                        Submit
                                    </button>
                                </div>
                                <p className='text-center pt-2'>If your email is correct, we will email you a reset link.</p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default ForgotPass;
