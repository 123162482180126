/* eslint-disable */
import React, { useEffect } from 'react'
import { AiFillDelete, AiOutlineEye } from 'react-icons/ai'
import { MdGridView, MdModeEdit } from 'react-icons/md'
import toast from "react-hot-toast";
import { deleteTask } from '../../../api/task'
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment/min/moment-with-locales';

const MyTask = ({ myTask, refreshTaskList }) => {
  const taskDelete = (id, createAt) => {
    const isTaskDeleted = deleteTask(id, createAt)
    if (isTaskDeleted) {
      toast.success('Task deleted successfully!');
      refreshTaskList(id)
  }
   
  }

  Moment.globalMoment = moment;
  Moment.globalFormat = 'MMM D, YYYY';

  useEffect(() => {}, [myTask])

  return (
    <>
      <div id='tableExample' className='flex-grow-1'>
        <div>
          <table className='table bg-white iPhoneXtB pixlTb laptoptbl'>
            <thead className='border-bottom-0 text-center'>
              <tr>
                <th className='border-0 text-left'>
                  <span className='ml-5'>Task Name</span>
                </th>
                <th className='border-0'>Due Date</th>
                <th className='border-0'>Asignee</th>
                <th className='border-0'>Action</th>
              </tr>
            </thead>

            <tbody className='list text-center'>
              {myTask.length ? (
                myTask.map(task => (
                  <tr key={task.id}>
                    <td className=' border-right-0 text-left'>
                      <span className='ml-5'>{task?.taskName}</span>
                    </td>
                    <td className=' border-right-0 border-left-0'>
                      <Moment>{task?.dueDate}</Moment>
                    </td>
                    <td className=' border-right-0 border-left-0'>
                      <img
                        src='https://placeimg.com/192/192/people'
                        className='rounded-circle h-75'
                        alt=''
                      />
                    </td>
                    <td className='border-left-0'>
                      <Link to={`/task/view/${task?.id}`}>
                        <AiOutlineEye size={25} className='edit-ico mr-3' />
                      </Link>
                      <Link to={`/task/add/${task?.id}`}>
                        <MdModeEdit size={25} className='edit-ico ml-3' />
                      </Link>
                      <AiFillDelete
                        size={25}
                        className='delete-ico ml-3'
                        onClick={() => taskDelete(task?.id, task?.createdAt)}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr className='loader'>
                  <td colSpan={4}>No Task Available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default MyTask
