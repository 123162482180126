/* eslint-disable no-return-await */
/* eslint-disable import/prefer-default-export */
/* eslint-disable consistent-return */
/* eslint-disable prettier/prettier */
/* eslint-disable arrow-body-style */

import  axios  from 'axios';

const taskStreamApi =  process.env.REACT_APP_TaskWorkStream_BASE_URL;

const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
};

const allTaskWorkStream = async (oid) => {
    const response = await axios.get(`${taskStreamApi}/taskworkStream/${oid}`);
    if (response) {
        return response?.data;
    }
};

const insertTaskWorkStream = async (data) => {
    const response = await axios.post(`${taskStreamApi}/taskworkStream/add`, data);
    if (response) {
        return response;
    }
};

const getSingleTaskWorkStream = async (id) => {
    const response = await axios.get(`${taskStreamApi}/taskworkStream/item/${id}`);
    if (response) {
        return response?.data;
    }
};

const updateTaskStream = async (id, data) => {
    const response = await axios.put(`${taskStreamApi}/taskworkStream/update/${id}`,data,{headers});
    if (response) {
        return response;
    }
};

const deleteTaskStream = async (id) => {
    const response = await axios.delete(`${taskStreamApi}/taskworkStream/delete/${id}`);
    if (response) {
        return response;
    }
};


// Assigned Work Stream 

const insertAssignTaskStream = async (data) => {
    const response = await axios.post(`${taskStreamApi}/assignedWorkStream/add`, data);
    if (response) {
        return response;
    }
};

const getAssigendTaskStreams = async (oid,workStreamId) => {
    const response = await axios.get(`${taskStreamApi}/assignedWorkStream/all/${oid}/${workStreamId}`);
    if (response) {
        return response?.data;
    }
};

const getAssigendTaskStreamsByEmail = async (email) => {
    const response = await axios.get(`${taskStreamApi}/assignedWorkStream/getByEmail/items/${email}`);
    if (response) {
        return response?.data;
    }
};

const deleteTaskStreamUser = async (id) => {
    const response = await axios.delete(`${taskStreamApi}/assignedWorkStream/delete/${id}`);
    if (response) {
        return response;
    }
};


export {allTaskWorkStream, insertTaskWorkStream, getSingleTaskWorkStream, updateTaskStream, deleteTaskStream, insertAssignTaskStream, getAssigendTaskStreams, getAssigendTaskStreamsByEmail, deleteTaskStreamUser}