/* eslint-disable no-unused-expressions */
/* eslint-disable prettier/prettier */
import React from "react";
import { FaPen } from "react-icons/fa";
import "../Details.scss";
function EnableButtonPage({ enable, handleEnable, handleDisable }) {
  return (
    <>
      <FaPen
        className={enable === true ? "rt-info-active" : "rt-info"}
        onClick={() => {
          enable === true ? handleDisable() : handleEnable();
        }}
      />
    </>
  );
}

export default EnableButtonPage;
