/* eslint-disable prefer-destructuring */
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable prettier/prettier */
import React, { useState, useContext } from "react";
import { Storage } from "aws-amplify";
import { SplitButton } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { createMeetingAgendaAttachment } from "../../../../api/teamMeeting";
import { AuthContext } from "../../../../Context/AuthContext";
function MeetingAgendaAttachment({
  direction,
  meetingAgendaId,
  enable,
  meetingAgendaAttachmentFile,
  setMeetingAgendaAttachmentFile,
}) {
  const [storeFile, setStoreFile] = useState([]);
  const { oid } = useContext(AuthContext);

  const uploadFile = async (e) => {
    const files = e.target.files;
    setStoreFile([...storeFile, files[0]]);
    const Url = `profileImg/${files[0]?.name}`;
    const attachmentUrl = `${process.env.REACT_APP_IMAGE_BASE_URL}//${Url}`;

    await Storage.put(`/${Url}`, files[0], {
      level: "public",
      contentType: files[0]?.type,
    });

    const insertValue = {
      id: uuidv4(),
      oid,
      meetingAgendaId,
      attachmentFileLink: attachmentUrl,
      createdAt: new Date().toString(),
    };

    const result = await createMeetingAgendaAttachment(insertValue);
    setMeetingAgendaAttachmentFile([...meetingAgendaAttachmentFile, result]);
  };

  return (
    <div>
      <SplitButton
        key={direction}
        id={`dropdown-button-drop-${direction}`}
        drop={direction}
        variant="light"
        className="rt-dropdbtn"
        name="agendaType"
        disabled={!enable}
      >
        <input type="file" onChange={uploadFile} />
      </SplitButton>
    </div>
  );
}

export default MeetingAgendaAttachment;
