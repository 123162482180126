/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
function Step3(props) {
  const { errors, register, employeeWorkDetails } = props;
  return (
    <div>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label htmlFor="dateOfJoining">Date of Joining*</label>
          <input
            type="date"
            className=" form-control h-70"
            id="dateOfJoining"
            aria-describedby="textHelp"
            placeholder="Please add Date of Joining"
            {...register("dateOfJoining", {
              value: employeeWorkDetails.dateOfJoining,
              required: {
                value: true,
                message: "Please add date of joining",
              },
            })}
          />
          {errors.dateOfJoining && (
            <span style={{ color: "red" }}>{errors.dateOfJoining.message}</span>
          )}
        </div>

        <div className="form-group col-md-6">
          <label htmlFor="employmentStatus">Employment Status*</label>
          <select
            className="form-control h-70"
            id="employmentStatus"
            {...register("employmentStatus", {
              value: employeeWorkDetails.employmentStatus,
              required: {
                value: true,
                message: "Please select employment status",
              },
            })}
          >
            <option value="" disabled>
              Please select employment status
            </option>
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
          </select>
          {errors.employmentStatus && (
            <span style={{ color: "red" }}>
              {errors.employmentStatus.message}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

export default Step3;
