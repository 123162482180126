/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/newline-after-import */
/* eslint-disable react/self-closing-comp */
/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/button-has-type */
/* eslint-disable react-hooks/exhaustive-deps */
// import { faBolt, faBullseye, faFlag, faHeart } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AuthContext } from "../../../Context/AuthContext";
import YearPlan from "../YearPlan/YearPlan";
import "./PlanModule.scss";

const tabStateChange = [false, false, false];

const paramsList = {
  1: 0,
  3: 1,
  10: 2,
};
const PlanModule = () => {
  // changes tab based on onClick
  const [tab, setTab] = useState([true, false, false]);
  const history = useHistory();
  const params = useLocation();
  const { oid } = useContext(AuthContext);
  const yearNo = history.location.search
    ? history.location.search?.split("=")[1]
    : "1";
  // tab changes function
  const handleTab = (index) => {
    const newTabValue = [...tabStateChange];
    newTabValue[index] = true;
    setTab(newTabValue);
  };
  useEffect(() => {
    handleTab(paramsList[yearNo]);
  }, [yearNo]);

  return (
    <>
      <div className="container-fluid">
        <div className="row plan-header d-flex">
          <div className="col pt-4 ">
            <h5
              className={`m-0 tab-style ${
                tab[0] ? "active_vision_top" : "vision-top"
              }`}
              onClick={() => {
                history.push("/planModule/?plan=1");
              }}
            >
              1 YEAR PLAN
            </h5>
          </div>

          <div className="col pt-4 ">
            <h5
              className={`m-0 tab-style ${
                tab[1] ? "active_vision_top" : "vision-top"
              }`}
              onClick={() => {
                history.push("/planModule/?plan=3");
              }}
            >
              3 YEAR PLAN
            </h5>
          </div>
          <div className="col pt-4 ">
            <h5
              className={`m-0 tab-style ${
                tab[2] ? "active_vision_top" : "vision-top"
              }`}
              onClick={() => {
                history.push("/planModule/?plan=10");
              }}
            >
              10 YEAR PLAN
            </h5>
          </div>
        </div>

        <div className="row d-flex">
          <div className="col-md-12 col-sm-12 pt-2 bg-white">
            {tab[0] && <YearPlan yearNo={yearNo} oid={oid}></YearPlan>}
          </div>
        </div>

        <div className="row d-flex">
          <div className="col-md-12 col-sm-12 pt-2 bg-white">
            {tab[1] && <YearPlan yearNo={yearNo} oid={oid}></YearPlan>}
          </div>
        </div>

        <div className="row d-flex">
          <div className="col-md-12 col-sm-12 pt-2 bg-white">
            {tab[2] && <YearPlan yearNo={yearNo} oid={oid}></YearPlan>}
          </div>
        </div>
      </div>
    </>
  );
};

export default PlanModule;
