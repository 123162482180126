/* eslint-disable prefer-template */
/* eslint-disable arrow-body-style */
/* eslint-disable react/self-closing-comp */
/* eslint-disable prettier/prettier */
import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
function MeetingAssignee({ meetingData }) {
  const {
    id,
    meetingName,
    meetingType,
    meetingTime,
    meetingDate,
    meetingTimezone,
    meetingLocation,
    addGuest,
  } = meetingData;

  return (
    <div className="meetingItem">
      <Row>
        <Col>
          <Link to={`/meetingDetail/${id}`}>
            <div className="meeting-name">{meetingName}</div>
          </Link>
        </Col>
        <Col>
          <div className="meeting-name">{meetingType}</div>
        </Col>
        <Col className="text-center">
          <div className="meeting-date">
            {meetingDate.slice(5, 7) +
              "-" +
              meetingDate.slice(8, 10) +
              "-" +
              meetingDate.slice(0, 4)}
          </div>
        </Col>
        <Col className="text-center">
          <div className="meeting-date">{`${meetingTime}  ${meetingTimezone}`}</div>
        </Col>
        <Col className="text-center">
          <div className="meeting-date">{meetingLocation}</div>
        </Col>
        <Col className="text-center d-flex justify-content-center align-items-center">
          <div className="meeting-date">
            {addGuest?.slice(0, 3).map((attendees) => {
              return (
                <>
                  <img
                    src={attendees?.profile}
                    alt="img"
                    className="attendees-img rounded-circle"
                  />
                </>
              );
            })}
          </div>
          <div>{addGuest.length > 3 && <span className="ml-2">...</span>}</div>
        </Col>
      </Row>
    </div>
  );
}

export default MeetingAssignee;
