/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
/* eslint-disable react/self-closing-comp */
/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Auth } from "aws-amplify";
import React, { useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { BiEnvelope, BiKey } from "react-icons/bi";
import { userActivation, getEmployeePersonalInfo } from "../../api/user";
import logo from "../../images/logo.svg";
import sjiIcon from "../../images/SJI-logo-icon.jpg";
import "./SignIn.scss";

const initialUserState = {
  showMsg: 0,
};

const SignIn = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [user, setUserState] = useState(initialUserState);
  const { showMsg } = user;
  const [msg, setMsg] = useState("");
  const [showFields, setShowFields] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rememberMe, setRemeberMe] = useState(false);
  const [emailField, setEmailField] = useState("");
  const [error, setError] = useState("");

  // handle the value inside email field
  const handleChange = (e) => {
    setEmailField(e.target.value);
  };

  // click function with cont button
  const handleClick = (e) => {
    e.preventDefault();
    if (emailField.trim().length !== 0) {
      setError("");
      if (emailField.trim().length >= 4) {
        setShowFields(true);
      } else {
        setError("Length should be no less than 4");
      }
    } else {
      setError("Please add an email or usename");
    }
  };

  const [passValues, setPassValues] = useState({
    password: "",
    showPassword: false,
  });

  // password change func
  const handlePasswordChange = (prop) => (event) => {
    setPassValues({ ...passValues, [prop]: event.target.value });
  };

  // show/hide password func
  const handleClickShowPassword = () => {
    setPassValues({ ...passValues, showPassword: !passValues.showPassword });
  };

  // handle mouseDown func
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // sign in func
  async function signIn(data) {
    setIsLoading(true);
    try {
      await Auth.signIn(data.email, data.password).then(async (res) => {
        if (res) {
          setUserState(() => ({ ...user, showMsg: 1 }));
          setMsg(
            "You have successfully logged in and will be redirected to dashboard"
          );
          const singleUserData = await getEmployeePersonalInfo(res?.username);
          if (Object.keys(singleUserData).length !== 0) {
            const result = await userActivation(res?.username);
          }
          window.setTimeout(() => {
            window.location.href =
              res.signInUserSession?.accessToken.payload[
                "cognito:groups"
              ]?.[0] !== "SuperAdmin"
                ? "/db"
                : "/superAdmin/dashboard";
          }, 1000);
          if (rememberMe) {
            localStorage.userInfo = JSON.stringify(res);
          } else {
            sessionStorage.setItem("userInfo", JSON.stringify(res));
          }
        }
      });
    } catch (err) {
      if (err) {
        setUserState(() => ({ ...user, showMsg: 2 }));
        setMsg(err);

        Array.from(document.querySelectorAll("input")).forEach(
          // eslint-disable-next-line no-return-assign
          (input) => (input.value = "")
        );
        setTimeout(() => {
          setUserState(initialUserState);
          setMsg("");
          window.location.reload();
        }, 2000);
      }
    }
    setIsLoading(false);
  }

  return (
    <div className="sign-in">
      <Container fluid>
        <Row>
          <Col className="d-flex align-items-center justify-content-center min-vh-100">
            <div className="m-5">
              <div className="logo text-center pb-5">
                <img src={logo} alt="ReTeamNow Logo" />
              </div>
              <div className="sign-in-box">
                <h3 className="title">Sign In</h3>
                <p className="desc">Login to manage your account</p>
                <form onSubmit={handleSubmit(signIn)}>
                  {showFields && (
                    <div className="mb-3 input-f border-0 org-field">
                      <img
                        src={sjiIcon}
                        alt="SJI Logo"
                        className="small-icon-radius"
                      />
                      <input
                        className="form-control"
                        id="card-org"
                        type="text"
                        value="Sjinnovation llc"
                        disabled
                      />
                    </div>
                  )}
                  <div className="mb-3 input-f">
                    <BiEnvelope className="icon-color" size={30} />
                    <input
                      placeholder="Email or username"
                      className="form-control"
                      id="card-email"
                      type="email"
                      {...register("email", { required: true })}
                      onChange={handleChange}
                      value={emailField}
                    />
                    {errors.email && <span>This field is required</span>}
                  </div>
                  {error && (
                    <span className="text-danger d-block pb-3  pl-3 mt-n3">
                      {error}
                    </span>
                  )}
                  {showFields && (
                    <div className="mb-3 input-f">
                      <BiKey className="icon-color" size={30} />
                      <div className="d-flex w-100 align-items-center">
                        <input
                          placeholder="Password"
                          className="form-control"
                          id="card-password"
                          type={passValues.showPassword ? "text" : "password"}
                          {...register("password", {
                            required: true,
                          })}
                          onChange={handlePasswordChange("password")}
                          value={passValues.password}
                        />
                        <span
                          className={`small-text cursor ${
                            passValues.showPassword ? "text-bold" : ""
                          }`}
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          Show
                        </span>
                      </div>
                      {errors.password && <span>This field is required</span>}
                    </div>
                  )}
                  <div className="d-flex justify-content-between">
                    <div className="d-flex">
                      <input
                        type="checkbox"
                        className="checkbox"
                        onChange={(event) => {
                          setRemeberMe(event.target.checked);
                        }}
                        value={rememberMe}
                      />
                      <label className="ml-2">Remember me</label>
                    </div>
                  </div>

                  <div className="mt-3">
                    {showFields && (
                      <button
                        className="btn log-btn"
                        type="submit"
                        name="submit"
                      >
                        {isLoading ? (
                          <Spinner
                            as="span"
                            animation="border"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          "Log In"
                        )}
                      </button>
                    )}
                    <button
                      className={`btn mt-31 log-btn border-0 ${
                        showFields ? "d-none" : ""
                      }`}
                      name=""
                      onClick={handleClick}
                      type="submit"
                    >
                      Continue
                    </button>
                    <div className="pt-3">
                      {showMsg === 1 && (
                        <div className="alert alert-success" role="alert">
                          {msg}
                        </div>
                      )}
                      {showMsg === 2 && (
                        <div className="alert alert-danger" role="alert">
                          Email or Password is incorrect
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SignIn;
