/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-else-return */
/* eslint-disable arrow-body-style */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/no-array-index-key */
// eslint-disable-next-line arrow-body-style
// eslint-disable-next-line array-callback-return
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AiFillDelete } from 'react-icons/ai';
// import { Button, Modal } from 'react-bootstrap';
import DeleteModal from './DeleteModal';

// eslint-disable-next-line prettier/prettier, no-unused-vars
const Table = ({ col, data, excludeKeys, findOrgName, adminEdit, orgEdit, handleDelete, show, setShow }) => {
    const [deleteId, setDeleteId] = useState('');
    const [deleteOId, setDeleteOId] = useState('');
    const [deleteEId, setDeleteEId] = useState('');
    let headerData = col[0];
    const columns = [];

    if (headerData) {
        Object.keys(headerData).map((key) => {
            columns.push(key);
        });
    }

    // eslint-disable-next-line no-const-assign
    headerData = columns;

    const handleShowModal = (id, oid,eid) => {
        setShow(true);
        setDeleteId(id);
        setDeleteOId(oid);
        setDeleteEId(eid);
    };

    return (
        <div>
            {show && <DeleteModal show={show} setShow={setShow} handleDelete={handleDelete} deleteId={deleteId} deleteOId={deleteOId} deleteEId={deleteEId}/>}
            <table className="table bg-white iPhoneXtB pixlTb laptoptbl">
                {!headerData ? (
                    <h2>Loading...</h2>
                ) : (
                    <thead className="bg-light text-900 text-dark">
                        <tr>
                            {headerData.map((c) => {
                                return (
                                    !excludeKeys.includes(c) && (
                                        <th scope="col">{c === 'oid' ? 'org Name' : c}</th>
                                    )
                                );
                            })}
                            <th scope="col" width="13%">
                                Action
                            </th>
                        </tr>
                    </thead>
                )}
                {!data ? (
                    <h2>Loading...</h2>
                ) : (
                    <tbody className="list">
                        {data.map((d) => (
                            <tr>
                                {headerData.map((a) => {
                                    return (
                                        !excludeKeys.includes(a) && (
                                            <td>{a === 'oid' ? findOrgName(d[a]) : d[a]}</td>
                                        )
                                    );
                                })}
                                <td>
                                    {adminEdit !== undefined && (
                                        <Link
                                            to={`${adminEdit}/${d.id}/${d.oid}`}
                                            className="textdec text-primary mr-2"
                                        >
                                            Edit
                                        </Link>
                                    )}
                                    {orgEdit !== undefined && (
                                        <Link
                                            to={`${orgEdit}/${d.id}`}
                                            className="textdec text-primary mr-2"
                                        >
                                            Edit
                                        </Link>
                                    )}
                                    {orgEdit !== undefined && (
                                        <AiFillDelete
                                            className="delete-ico ml-1"
                                            variant="danger"
                                            size={25}
                                            onClick={() => handleShowModal(d.id, d.oid)}
                                        />
                                    )}
                                    {adminEdit !== undefined && (
                                        <AiFillDelete
                                            className="delete-ico ml-1"
                                            variant="danger"
                                            size={25}
                                            onClick={() => handleShowModal(d.id, d.oid, d.eid)}
                                        />
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                )}

            </table>
        </div>
    );
};

export default Table;
