/* eslint-disable */
import React, { useRef, useState } from 'react'
import { AiFillDelete } from 'react-icons/ai'
import { MdModeEdit } from 'react-icons/md'

const SingleComment = ({ comment, removeComment, today, index }) => {

    const [commEdit, setCommEdit] = useState("false")
    const chatRef = useRef(null);
    const saveRef = useRef(null);

    const editComment = e => {
        console.log(e)
        setCommEdit("true")
        chatRef.current.focus();

    }


    return (
        <div className='d-flex' >
            <img src="https://placeimg.com/192/192/people" alt="" className='rounded-circle mr-3' style={{ height: '40px' }} />
            <div className="chat-body p-2 w-100">
                <div className="chat-body-hading d-flex justify-content-between">
                    <div className='d-flex align-items-end'>
                        <h6 className='mb-2 mr-2'>Mushifq</h6>
                        <span className='date mb-2'>{today}</span>
                    </div>
                    <div>
                        {/* <BsFillReplyFill className='reply-ico' /> */}
                        <MdModeEdit onClick={() => editComment(index)} className="edit-ico ml-1" />
                        <AiFillDelete onClick={() => removeComment(index)} className="delete-ico ml-1" />
                    </div>
                </div>
                <p className='chat-text' ref={chatRef} contenteditable={commEdit}  >{comment.commentValue}</p>
                <div className={commEdit == "true" ? "d-block" : "d-none"}>
                    <p className='text-right pb-0 mb-0'>
                        <span className='btn nav-btn px-4 py-1' ref={saveRef} onClick={() => setCommEdit("false")}>
                            Save
                        </span>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default SingleComment