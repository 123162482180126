/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import shape1 from '../../images/shape-1.png';
import RegistrationLeftPart from '../SignIn/RegistrationLeftPart';
import './Registration.css';

const initialCodeState = {
    showCode: 0,
};
const Registration = () => {
    const [count, setCount] = useState(1);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const [codeform, setCodeForm] = useState(initialCodeState);
    const [formFields, setFieldState] = useState();
    const { showCode } = codeform;
    // const onSubmit = data => console.log(data);
    function onChange(e) {
        e.persist();
        setFieldState(() => ({ ...formFields, [e.target.name]: e.target.value }));
    }

    async function onSubmit(data, e) {
        e.preventDefault();
        const { username, password } = formFields;
        console.log(formFields);
        setCodeForm(() => ({ ...codeform, showCode: 1 }));
        try {
            const response = await Auth.signUp({
                username,
                password,
            });
            console.log(response);
        } catch (error) {
            console.log('error signing up:', error);
        }
    }
    async function confirmSignup(e) {
        e.preventDefault();
        const { email, code } = formFields;
        try {
            await Auth.confirmSignUp(email, code).then((res) => {
                console.log(res);
                if (res === 'SUCCESS') {
                    setCodeForm(() => ({ ...codeform, showCode: 3 }));
                }
            });
        } catch (error) {
            console.log('error signing up:', error);
        }

        // setFormState(() => ({ ...formState, formType: "signIn"}))
    }
    //   const onSubmit = async ({username,email,password,phoneNumber,country,businessType,companyName,employeeNumber,yourMsg}) => {
    //
    // };

    return (
        <div className="container-fluid sign-wraper">
            <div className="row min-vh-100 flex-center g-0">
                <img src={shape1} alt="" className="bg-img1" />
                <img src={shape1} alt="" className="bg-img2" />
                <div className="col-lg-8 col-xxl-5 py-3 position-relative">
                    <div className="card overflow-hidden z-index-1">
                        <div className="card-body p-0">
                            <div className="row g-0 h-100 res-row">
                                <RegistrationLeftPart />
                                <div className="col-md-7 d-flex flex-center">
                                    <div className="p-4 p-md-5 flex-grow-1">
                                        <div className="row flex-between-center">
                                            <div className="col-auto">
                                                <h3 className="pb-4">Register</h3>
                                            </div>
                                        </div>
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            {count === 1 && (
                                                <>
                                                    <div className="mb-3">
                                                        <label
                                                            className="form-label"
                                                            htmlFor="card-email"
                                                        >
                                                            Name
                                                        </label>
                                                        <input
                                                            placeholder="jhon doe"
                                                            className="form-control"
                                                            id="card-email"
                                                            type="text"
                                                            {...register('name', {
                                                                required: true,
                                                            })}
                                                            onChange={onChange}
                                                        />
                                                        {errors.name && (
                                                            <span>This field is required</span>
                                                        )}
                                                    </div>
                                                    <div className="mb-3">
                                                        <label
                                                            className="form-label"
                                                            htmlFor="card-email"
                                                        >
                                                            Email address
                                                        </label>
                                                        <input
                                                            placeholder="jhon@example.com"
                                                            className="form-control"
                                                            id="card-email"
                                                            type="email"
                                                            {...register('username', {
                                                                required: true,
                                                            })}
                                                            onChange={onChange}
                                                        />
                                                        {errors.username && (
                                                            <span>This field is required</span>
                                                        )}
                                                    </div>
                                                    <div className="mb-3">
                                                        <div className="d-flex justify-content-between">
                                                            <label
                                                                className="form-label"
                                                                htmlFor="card-password"
                                                            >
                                                                Password
                                                            </label>
                                                        </div>
                                                        <input
                                                            placeholder="*****"
                                                            className="form-control"
                                                            id="card-password"
                                                            type="password"
                                                            {...register('password', {
                                                                required: true,
                                                            })}
                                                            onChange={onChange}
                                                        />
                                                        {errors.password && (
                                                            <span>This field is required</span>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                            {count === 2 ? (
                                                <div>
                                                    <div className="form-group">
                                                        <label htmlFor="phoneNumber">
                                                            Phone number
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="phoneNumber"
                                                            {...register('phoneNumber', {
                                                                required: true,
                                                            })}
                                                            placeholder="+97145XXX"
                                                            onChange={onChange}
                                                        />
                                                        {errors.phoneNumber && (
                                                            <span>This field is required</span>
                                                        )}
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="country">Country</label>
                                                        <select
                                                            {...register('country', {
                                                                required: true,
                                                            })}
                                                            className="form-control"
                                                            id="exampleFormControlSelect1"
                                                            onChange={onChange}
                                                        >
                                                            <option selected>Choose..</option>

                                                            <option>Bangladesh</option>
                                                            <option>India</option>
                                                            <option>New York</option>
                                                            <option>Ukrain</option>
                                                            <option>Canada</option>
                                                        </select>
                                                        {errors.country && (
                                                            <span>This field is required</span>
                                                        )}
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="businessType">
                                                            Business Type
                                                        </label>
                                                        <select
                                                            {...register('businessType', {
                                                                required: true,
                                                            })}
                                                            className="form-control"
                                                            id="exampleFormControlSelect1"
                                                            onChange={onChange}
                                                        >
                                                            <option selected>Choose..</option>

                                                            <option>Private Company</option>
                                                            <option>Public Company</option>
                                                            <option>Group OF Limited</option>
                                                            <option>LLC</option>
                                                        </select>
                                                        {errors.businessType && (
                                                            <span>This field is required</span>
                                                        )}
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="companyName">
                                                            Company Name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="companyName"
                                                            {...register('companyName', {
                                                                required: true,
                                                            })}
                                                            placeholder="SJ Innovation LLC "
                                                            onChange={onChange}
                                                        />
                                                        {errors.companyName && (
                                                            <span>This field is required</span>
                                                        )}
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="employeeNumber">
                                                            Number of employees
                                                        </label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            name="employeeNumber"
                                                            {...register('employeeNumber', {
                                                                required: true,
                                                            })}
                                                            placeholder="140"
                                                            onChange={onChange}
                                                        />
                                                        {errors.employeeNumber && (
                                                            <span>This field is required</span>
                                                        )}
                                                    </div>
                                                </div>
                                            ) : null}
                                            {count === 3 && showCode !== 3 ? (
                                                <div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">
                                                            <small>
                                                                {' '}
                                                                What are the changes you are looking
                                                                for!{' '}
                                                            </small>
                                                        </label>
                                                        <div className="form-group">
                                                            <label htmlFor="yourMsg">
                                                                Your Message
                                                            </label>
                                                            <textarea
                                                                {...register('yourMsg', {
                                                                    required: true,
                                                                })}
                                                                className="form-control"
                                                                id="exampleFormControlTextarea1"
                                                                rows="3"
                                                                placeholder="Your Message"
                                                                onChange={onChange}
                                                            />
                                                            {errors.yourMsg && (
                                                                <span>This field is required</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className=" form-group form-check">
                                                        <input
                                                            type="checkbox"
                                                            className="checkbox mr-3"
                                                            id="exampleCheck1"
                                                            onChange={onChange}
                                                        />
                                                        <label className="form-check-label">
                                                            I agree to term and conditions
                                                        </label>
                                                    </div>
                                                </div>
                                            ) : null}
                                            {count === 3 && showCode !== 3 ? (
                                                <button
                                                    className="btn btn-primary btn-block mb-3"
                                                    type="submit"
                                                    disabled={showCode}
                                                >
                                                    Register
                                                </button>
                                            ) : null}
                                            {count === 3 ? (
                                                showCode === 1 ? (
                                                    <div className="form-group mt-3">
                                                        <label htmlFor="password">
                                                            Verification Code
                                                        </label>
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            name="code"
                                                            placeholder="Confirmation Code"
                                                            onChange={onChange}
                                                        />
                                                        <button
                                                            type="submit"
                                                            className="btn btn-success mb-2 mt-3"
                                                            onClick={confirmSignup}
                                                        >
                                                            Verify
                                                        </button>
                                                        <Link to="/" className="continue">
                                                            Continue Without Verification
                                                        </Link>
                                                    </div>
                                                ) : null
                                            ) : null}
                                        </form>
                                        {showCode === 3 ? (
                                            <div className="alert alert-success" role="alert">
                                                You have been successfully verified. Please{' '}
                                                <Link to="/">login</Link>
                                            </div>
                                        ) : (
                                            <div>
                                                <button
                                                    className="btn btn-danger mt-2"
                                                    type="submit"
                                                    onClick={() => setCount(count - 1)}
                                                    disabled={count < 2}
                                                >
                                                    Back
                                                </button>
                                                <button
                                                    className="btn btn-primary mt-2 ml-3"
                                                    type="submit"
                                                    onClick={() => setCount(count + 1)}
                                                    disabled={count > 2}
                                                >
                                                    Next
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Registration;
