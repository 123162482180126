/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import { Container, Row, Col, Button, Tab, Nav} from 'react-bootstrap'
import { AiOutlineSearch } from 'react-icons/ai'
import { BsPlusCircleFill } from 'react-icons/bs'
import './TaskList.scss'
import MyTask from './MyTask'
import DelegatedTasks from './DelegatedTasks'
import { Link, useHistory } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { getAllTaskDada } from '../../../api/task'
import props from 'prop-types';
import { AuthContext } from '../../../Context/AuthContext'

const TaskList = () => {
  const {location} = props;
  const { oid, logged } = useContext(AuthContext)

  const id = uuidv4()
  const [modalShow, setModalShow] = useState(false)
  const [result, setResult] = useState([])
  const [myTask, setMyTask] = useState([])
  const [myTaskCopy, setMyTaskCopy] = useState([])
  const [delegatedTasks, setDelegatedTasks] = useState([])
  const [delegatedTasksCopy, setDelegatedTasksCopy] = useState([])

  let history = useHistory()

  const eventKeyMyTask = 'my-task' 
  const eventKeyDelegatedTask = 'delegated-tasks' 

  const dualPrpose = () => {
    history.push(`/task/add/${id}`)
    setModalShow(true)
  }
  if(modalShow){
     window.location.reload()
  }

  const refreshTaskList = id => {
    const res = myTask?.filter(data => data.id !== id)
    setMyTask(res)
  }

  const refreshDelegatedTaskList = id => {
    const res = delegatedTasks?.filter(data => data.id !== id)
    setDelegatedTasks(res)
  }

  const getAllTask = async () => {
    const response = await getAllTaskDada(oid)
    setResult(response)
    const myTaskData = response?.filter(
      task => task.delegatedPersonEmail === logged || (task.delegatedPersonEmail ==="" &&  task.createdTaskPersonEmail === logged )
    )
      setMyTask(myTaskData)
      setMyTaskCopy(myTaskData)

    const delegatedTasksData = response?.filter(
      task => task.createdTaskPersonEmail === logged && task.delegatedPersonEmail !== "" 
    )
    setDelegatedTasks(delegatedTasksData)
    setDelegatedTasksCopy(delegatedTasksData)
  }

  const handleTaskSearch = event => {

    const foundMyTask = myTaskCopy.map((task) => {
      const data = task?.taskName.toLowerCase();
      if (data.search(event.target.value.toLowerCase()) !== -1) {
        return task;
      } else {
        return "";
      }
    });
    
    const foundDelegatedTasks = delegatedTasksCopy.map((task) => {
      const data = task?.taskName.toLowerCase();
      if (data.search(event.target.value.toLowerCase()) !== -1) {
        return task;
      } else {
        return "";
      }
    });
    // my task
    const resultMyTask = foundMyTask.filter((value) => value !== "");
    if(resultMyTask.length > 0){
      setMyTask(resultMyTask)
    }else{
      setMyTask(myTaskCopy)
    }  

    // delegated task

    const resultDelegatedTasks = foundDelegatedTasks.filter((value) => value !== "");
    if(resultDelegatedTasks.length > 0){
      setDelegatedTasks(resultDelegatedTasks)
    }else{
      setDelegatedTasks(delegatedTasksCopy)
    }  
  }

  

  if(location?.state.data){
    window.location.reload()
  }

  useEffect(() => {
    getAllTask(oid)
  }, [oid])
  

  return (
    <section className='task h-100'>
      <Container fluid>
        <Tab.Container defaultActiveKey='my-task'>
          <Row>
            <Col lg={12} md={10} sm={12}>
              <div className='employee_list-top d-flex justify-content-between align-items-center pb-4'>
                <h5 className='section-header ml-3 mb-0'>Task</h5>
                <div onClick={dualPrpose}>
                  <Button className='add-btn text-white cursor border-0'>
                    <BsPlusCircleFill className='icon text-white mr-2' /> Create
                    Task
                  </Button>
                </div>
                
              </div>
              <Link to="/taskStream/list">
                  <Button className='add-btn text-white cursor border-0 mb-4'>
                      Task Streams
                  </Button>
                </Link>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='bg-white main-container p-4 rounded-border-12 min-vh-80 d-flex flex-column mb-3'>
                <div className='d-flex align-items-center pb-4 gap-3'>
                  <div className='flex-grow-1'>
                    <Nav className='d-flex text-white' variant='pills'>
                      <Nav.Item>
                        <Nav.Link
                          className='btn nav-btn mr-3 px-4 py-1'
                          eventKey={eventKeyMyTask}
                        >
                          My Task
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          className='btn nav-btn px-4 py-1'
                          eventKey={eventKeyDelegatedTask}
                        >
                          Delegated Tasks
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                  <div className='search-bar position-relative'>
                    <input
                      type='text'
                      placeholder='Search tasks'
                      className='search m-0 form-control pl-4'
                      onChange={(event) => {
                        handleTaskSearch(event);
                      }}
                    />
                    <AiOutlineSearch className='position-absolute translate-middle-y top-50 icon pl-2' />
                  </div>
                </div>

                <>
                  <Tab.Content>
                    <Tab.Pane eventKey='my-task'>
                      <MyTask
                        myTask={myTask}
                        refreshTaskList={refreshTaskList}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey='delegated-tasks'>
                      <DelegatedTasks 
                      delegatedTasks={delegatedTasks}
                      refreshTaskList={refreshDelegatedTaskList} />
                    </Tab.Pane>
                  </Tab.Content>
                </>
              </div>
            </Col>
          </Row>
        </Tab.Container>
      </Container>

      {/* <AddTask show={modalShow} setModalShow={setModalShow} onHide={() => setModalShow(false)} /> */}
    </section>
  )
}

export default TaskList
