/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useState,useEffect} from 'react';
import siteApi from '../api/siteApi';

 const OrgContext = createContext();

 const OrgProvider = (props) => {
    const [organization,setOrganization] = useState({});

    useEffect(()=>{
      siteApi.get('/org').then((res) =>setOrganization(res.data));
    },[])

    const values = {organization}

    // eslint-disable-next-line react/destructuring-assignment
    return (<OrgContext.Provider value={values}>
              {props.children}
        </OrgContext.Provider>);
};


export {OrgContext,OrgProvider}
