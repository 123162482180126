/* eslint-disable arrow-body-style */
/* eslint-disable consistent-return */
/* eslint-disable prettier/prettier */
/* eslint-disable import/prefer-default-export */
import axios from "axios";
import siteApi from "./siteApi";
// create  Task
const taskApi = process.env.REACT_APP_TASKMODULE_BASE_URL;
const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Credentials": true,
};
const createTask = async (insertData) => {
  const result = await axios.post(`${taskApi}/task`, insertData, {
    headers,
  });
  return result;
};

// get  Task
const getAllTaskDada = async (oid) => {
  const response = await axios.get(`${taskApi}/${oid}/task`);
  return response?.data;
};

// get Single Task
const getSingleTask = async (id) => {
  const response = await siteApi.get(`${taskApi}/task/${id}`);
  return response?.data;
};

// delete Task
const deleteTask = async (id) => {
  const result = await axios.delete(`${taskApi}/task/${id}`);
  return result;
};

// get all employee data
const AllEmployee = async (oid) => {
  if (oid) {
    const result = await siteApi.get(`/adminInfo/allAdmin&Employee/${oid}`);
    return result;
  }
};

export { createTask, getSingleTask, getAllTaskDada, deleteTask, AllEmployee };
