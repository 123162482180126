/* eslint-disable object-shorthand */
/* eslint-disable import/extensions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-expressions */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable react/self-closing-comp */
/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/button-has-type */
/* eslint-disable react-hooks/exhaustive-deps */
// import { faBolt, faBullseye, faFlag, faHeart } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Accordion } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
// import siteApi from "../../../api/siteApi";
import "./VisionModule.scss";
import { AuthContext } from "../../../Context/AuthContext";

const VisionModule = () => {
  const [id, setId] = useState();
  const [createdAt, setCreatedAt] = useState();
  const [coreValues, setCoreValues] = useState();
  const [purposePassion, setPurposePassion] = useState();
  const [ourNiche, setOurNiche] = useState();
  const [longTermKeyPriorityValue, setLongTermKeyPriority] = useState();
  const [targetMarket, setTargetMarket] = useState();
  const [unique, setUnique] = useState();
  const [provenProcess, setProvenProcess] = useState();
  const [guaranteValue, setGuarantee] = useState();
  const { oid, isAdmin } = useContext(AuthContext);
  // vision state
  const [vision, setVision] = useState([]);
  const [updateTrackState, setUpdateTrackState] = useState(false);
  useEffect(() => {
    // vision get api call
    if (oid) {
      axios
        .get(
          `https://t6k2cbe9e7.execute-api.us-east-2.amazonaws.com/dev/${oid}/vision`
        )
        .then((res) => {
          setVision(res.data);
          setId(res.data[0]?.id);
          setCreatedAt(res.data[0]?.createdAt);
          setUnique(res.data[0]?.Companyunique);
          setOurNiche(res.data[0]?.Niche);
          setCoreValues(res.data[0]?.coreValue);
          setGuarantee(res.data[0]?.guarantee);
          setLongTermKeyPriority(res.data[0]?.longTermKeyPriorities);
          setProvenProcess(res.data[0]?.proven);
          setPurposePassion(res.data[0]?.purpose);
          setTargetMarket(res.data[0]?.target);
        });
    }
  }, [oid, AuthContext]);

  // update vision information function
  const updateVisionInformation = async (e, key) => {
    if (updateTrackState === true) {
      const updateValue = {
        Niche: key === "our niche" ? e.target.value : ourNiche,
        coreValue: key === "core value" ? e.target.value : coreValues,
        createdAt: createdAt,
        Companyunique: key === "unique" ? e.target.value : unique,
        guarantee: key === "guarantee" ? e.target.value : guaranteValue,
        proven: key === "proven process" ? e.target.value : provenProcess,
        purpose: key === "purpose passion" ? e.target.value : purposePassion,
        target: key === "target market" ? e.target.value : targetMarket,
        longTermKeyPriorities:
          key === "longTermkeyPriority"
            ? e.target.value
            : longTermKeyPriorityValue,
      };
      // update vision data
      const updateData = async () => {
        await axios.put(
          `https://t6k2cbe9e7.execute-api.us-east-2.amazonaws.com/dev/vision/${id}/${createdAt}`,
          updateValue
        );
        toast("Updated");
      };
      await updateData();
      setUpdateTrackState(false);
    }
  };

  return (
    <>
      <Container fluid>
        <ToastContainer />
        <div className="module-vison">
          <Row>
            <Col lg={12}>
              {vision &&
                vision.map((item, i) => (
                  <Accordion defaultActiveKey="0" key={i}>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Core Values</Accordion.Header>
                      <Accordion.Body>
                        <textarea
                          disabled={!isAdmin}
                          className="form-control text-area-field-style"
                          aria-label="With textarea"
                          defaultValue={item.coreValue}
                          placeholder=" 1. Result driven 
                                                     2. Customer centric 
                                                     3. Strive for excellence"
                          onChange={() => {
                            setUpdateTrackState(true);
                          }}
                          onBlur={(e) => {
                            updateVisionInformation(e, "core value");
                            setCoreValues(e.target.value);
                          }}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Focus</Accordion.Header>
                      <Accordion.Body>
                        <p className="vision-subtitle">Purpose and passion</p>
                        <textarea
                          disabled={!isAdmin}
                          className="form-control text-area-field-style"
                          aria-label="With textarea"
                          defaultValue={item.purpose}
                          placeholder=" 1. Result driven 
                                                     2. Customer centric 
                                                     3. Strive for excellence"
                          onChange={() => {
                            setUpdateTrackState(true);
                          }}
                          onBlur={(e) => {
                            updateVisionInformation(e, "purpose passion");
                            setPurposePassion(e.target.value);
                          }}
                        />
                        <br />
                        <p className="vision-subtitle">Our Niche</p>
                        <textarea
                          disabled={!isAdmin}
                          className="form-control text-area-field-style"
                          aria-label="With textarea"
                          defaultValue={item.Niche}
                          placeholder=" 1. Result driven 
                                                     2. Customer centric 
                                                     3. Strive for excellence"
                          onChange={() => {
                            setUpdateTrackState(true);
                          }}
                          onBlur={(e) => {
                            updateVisionInformation(e, "our niche");
                            setOurNiche(e.target.value);
                          }}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        Long Term Key Priorities
                      </Accordion.Header>
                      <Accordion.Body>
                        <textarea
                          disabled={!isAdmin}
                          className="form-control text-area-field-style"
                          aria-label="With textarea"
                          placeholder="Where you want to be in next ten years?"
                          defaultValue={item.longTermKeyPriorities}
                          onChange={() => {
                            setUpdateTrackState(true);
                          }}
                          onBlur={(e) => {
                            updateVisionInformation(e, "longTermkeyPriority");
                            setLongTermKeyPriority(e.target.value);
                          }}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Marketing Strategy</Accordion.Header>
                      <Accordion.Body>
                        <p className="vision-subtitle">Target market</p>
                        <div>
                          <textarea
                            disabled={!isAdmin}
                            className="form-control text-area-field-style"
                            aria-label="With textarea"
                            placeholder=" 1. Digital agencies
                                                      2. Small and mid size companies"
                            defaultValue={item.target}
                            onChange={() => {
                              setUpdateTrackState(true);
                            }}
                            onBlur={(e) => {
                              updateVisionInformation(e, "target market");
                              setTargetMarket(e.target.value);
                            }}
                          />
                        </div>
                        <br />
                        <p className="vision-subtitle">Differentiators:</p>
                        <div>
                          <textarea
                            disabled={!isAdmin}
                            className="form-control text-area-field-style"
                            aria-label="With textarea"
                            placeholder=" 1. Honest and open
                      2. Passionate about what we do 
                      3. Ten years of expertise in field"
                            defaultValue={item.Companyunique}
                            onChange={() => {
                              setUpdateTrackState(true);
                            }}
                            onBlur={(e) => {
                              updateVisionInformation(e, "unique");
                              setUnique(e.target.value);
                            }}
                          />
                        </div>
                        <br />
                        <p className="vision-subtitle">Proven Process</p>

                        <textarea
                          disabled={!isAdmin}
                          className="form-control text-area-field-style"
                          aria-label="With textarea"
                          placeholder="Where you want to be in next ten years?"
                          defaultValue={item.proven}
                          onChange={() => {
                            setUpdateTrackState(true);
                          }}
                          onBlur={(e) => {
                            updateVisionInformation(e, "proven process");
                            setProvenProcess(e.target.value);
                          }}
                        />
                        <br />
                        <br />
                        <p className="vision-subtitle">Guarantee</p>
                        <textarea
                          disabled={!isAdmin}
                          className="form-control text-area-field-style"
                          aria-label="With textarea"
                          placeholder="Where you want to be in next ten years?"
                          defaultValue={item.guarantee}
                          onChange={() => {
                            setUpdateTrackState(true);
                          }}
                          onBlur={(e) => {
                            updateVisionInformation(e, "guarantee");
                            setGuarantee(e.target.value);
                          }}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                ))}
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default VisionModule;
